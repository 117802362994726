import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import styles from './CardTable.module.scss';

const CardTable = createClass({
  displayName: 'CardTable',
  mixins: [PureRenderMixin],
  propTypes: {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    footer: PropTypes.any,
    header: PropTypes.any,
    hover: PropTypes.bool,
    lightHeader: PropTypes.bool,
    noFooterPadding: PropTypes.bool,
    stripedColumns: PropTypes.bool,
    stripedRows: PropTypes.bool,
    style: PropTypes.object,
  },

  getDefaultProps() {
    return {
      hover: false,
      lightHeader: false,
      noFooterPadding: false,
      stripedColumns: false,
      stripedRows: true,
    };
  },

  render() {
    let cardClass = styles.card;
    if (this.props.className) {
      cardClass += ' ' + this.props.className;
    }

    let bodyClass = styles.body;
    if (this.props.stripedColumns) {
      bodyClass += ' ' + styles.stripedColumns;
    }

    let headerClass = styles.header;
    if (this.props.lightHeader === true) {
      headerClass += ' ' + styles.lightHeader;
    }

    let footerClass = styles.footer;
    if (this.props.noFooterPadding === true) {
      footerClass += ' ' + styles.noPadding;
    }

    let tableClass = 'table';
    if (this.props.stripedRows) {
      tableClass += ' table-striped';
    }
    if (this.props.hover) {
      tableClass += ' table-hover';
    }

    return <div className={cardClass + ' card card-table'} style={this.props.style}>
      <table className={tableClass}>
        <thead className={headerClass}>
          {this.props.header}
        </thead>
        <tbody className={bodyClass}>
          {this.props.children}
        </tbody>
      </table>
      {this.props.footer ? <div className={footerClass}>{this.props.footer}</div> : null}
    </div>;
  },
});

module.exports = CardTable;
