import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import Card from 'components/layout/Card/Card';
import TableResults from 'components/layout/ReportsGenerics/TableResults/TableResults';
import {BarChart} from 'components/charts';
import Results from 'components/layout/ReportsGenerics/LargeResults/LargeResults';
import Immutable from 'immutable';
import {ReportSettings, AccountStatusReport} from 'models';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const Actives = createClass({
  displayName: 'Actives',
  mixins: [PureRenderMixin],
  propTypes: {
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    report: PropTypes.instanceOf(AccountStatusReport).isRequired,
    intl: intlShape.isRequired,
    getRoleName: PropTypes.func.isRequired,
  },

  render() {
    let {report, settings, getRoleName} = this.props;
    let {formatMessage} = this.props.intl;
    let summary = Immutable.fromJS({
      text: formatMessage({
        id: 'reports.active.summary',
      }, {
        from: settings.from.format('ll'),
        to: settings.to.format('ll'),
      }),
      number: report.totalUnlocked,
    });
    let compareSummary = null;
    if (report.compare && settings.compare && settings.compare.from && settings.compare.to) {
      compareSummary = Immutable.fromJS({
        text: formatMessage({
          id: 'reports.active.summary',
        }, {
          from: settings.compare.from.format('ll'),
          to: settings.compare.to.format('ll'),
        }),
        number: report.compare.totalUnlocked,
      });
    }

    let data = [];
    let valueField = ['value'];

    if (!compareSummary) {
      data = report.unlockedPerRole.map((x, role) => {return {name: getRoleName(role), value: x};}).toArray();
    } else {
      data = report.unlockedPerRole
        .map((x, role) => ({name: getRoleName(role), value: x, compare: report.compare.unlockedPerRole.get(role)}))
        .toArray();
      valueField = ['value', 'compare'];
    }

    return <div>
      <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{formatMessage({id: 'reports.active.title'})}</span></h3>}
        lightHeader={true}
        headerFontSize={16}
        style={{marginBottom: 20}} >
        <div className='row'>
          <div className='col-sm-12'>
            <BarChart data={data} valueField={valueField} />
            <div className='row'>
              <div className='col-sm-12'>
                <Results colSize={compareSummary ? 'col-sm-6' : 'col-sm-12'} marginBottom={20} light={false}
                  text={summary.get('text')} number={summary.get('number')}
                  dealerAverage={summary.get('number') / report.totalDealers} storeAverage={summary.get('number') / report.totalStores} />
                {compareSummary ? <Results colSize='col-sm-6' marginBottom={20} light={false} bottom={true} text={compareSummary.get('text')}
                  number={compareSummary.get('number')}
                  dealerAverage={compareSummary.get('number') / report.totalDealers} storeAverage={compareSummary.get('number') / report.totalStores} /> : null}
              </div>
            </div>
            <TableResults header={<thead>
              <tr>
                <th><FormattedMessage id='reports.status.authentication' /></th>
                <th className='text-right'><FormattedMessage id='reports.count' />
                  <small>(
                    <FormattedMessage id='reports.resets.dateRange' values={{
                      from: settings.from.format('ll'),
                      to: settings.to.format('ll'),
                    }} />
                  )</small>
                </th>
                {compareSummary ?
                  <th className='text-right'><FormattedMessage id='reports.count' />
                    <small>(
                      <FormattedMessage id='reports.resets.dateRange' values={{
                        from: settings.compare.from.format('ll'),
                        to: settings.compare.to.format('ll'),
                      }} />
                    )</small>
                  </th> : null}
              </tr>
            </thead>}>
              {report.unlockedPerRole.map((num, role) => {
                return <tr key={role}>
                  <td>{getRoleName(role)}</td>
                  <td className='text-right'>{num || 0}</td>
                  {compareSummary ? <td className='text-right'>{report.compare.unlockedPerRole.get(role) || 0}</td> : null}
                </tr>;
              })}
            </TableResults>
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(Actives);
