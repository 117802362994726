import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import {DragDropContext} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import {List} from 'immutable';

import styles from './NestedSortable.module.scss';

import SortableCard from './SortableCard/SortableCard';

/**
 * Updates indented levels of entire list so if parent is moved all children move up a level.
 * @param  {List} list  Immutable list of sortable cards.
 * @param  {number} index =             0 Current index of item in list that is being recursed on.
 * @return {List}       Immutable list with correct indents for all cards.
 **/
function fixIndents(list, index = 0) {
  let previous = index > 0 ? list.get(index - 1).horizontalIndent : -1;
  let card = list.get(index);
  card.previousIndent = previous;
  card.horizontalIndent = Math.max(0, Math.min(previous + 1, card.horizontalIndent));
  let updated = list.set(index, card);
  if (index < list.size - 1) {
    return fixIndents(updated, index + 1);
  }
  return updated;
}

const _NestedSortable = createClass({
  displayName: 'NestedSortable',
  mixins: [PureRenderMixin],
  propTypes: {
    draggable: PropTypes.bool,
    input: PropTypes.instanceOf(List).isRequired,
    striped: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      cards: this.props.input,
    };
  },

  getDefaultProps() {
    return {
      draggable: true,
    };
  },

  componentWillReceiveProps(nextProps) {
    this.setState({
      cards: nextProps.input,
    });
  },

  getCards() {
    let cards = this.state.cards.toJS();
    let nested = [];
    let latestLevels = {
      '-1': nested,
    };
    for (let card of cards) {
      card.children = [];
      if (card && (card.horizontalIndent !== null && card.horizontalIndent !== undefined)) {
        latestLevels[(card.horizontalIndent - 1).toString()].push(card);
        latestLevels[(card.horizontalIndent).toString()] = card.children;
      }
    }
    return nested;
  },


  moveCard(dragIndex, hoverIndex, indent) {
    const { cards } = this.state;
    const dragCard = cards.get(dragIndex);
    dragCard.horizontalIndent = indent;

    let updatedCards = cards.splice(dragIndex, 1);
    updatedCards = updatedCards.splice(hoverIndex, 0, dragCard);

    updatedCards = fixIndents(updatedCards);

    this.setState({
      cards: updatedCards,
    });
  },

  render() {
    let cards = this.state.cards.map((x, i) => <div key={x.id || 'newcard'} className={this.props.striped ? styles.striped : null}>
      <SortableCard
        id={x.id}
        draggable={this.props.draggable}
        index={i}
        previousIndent={x.previousIndent || 0}
        horizontalIndent={x.horizontalIndent}
        title={x.title}
        moveCard={this.moveCard} />
    </div>
    ).toJS();
    return <div style={{overflow: 'hidden'}}>
      {cards}
    </div>;
  },
});

const DragableSortable = DragDropContext(HTML5Backend)(_NestedSortable);

// Wraps component to easily access getCards function without having to use getDecoratedComponentInstance
const NestedSortable = React.createClass({
  displayName: 'NestedSortable',
  mixins: [PureRenderMixin],
  propTypes: {
    draggable: PropTypes.bool,
    input: PropTypes.instanceOf(List).isRequired,
    striped: PropTypes.bool,
  },

  getCards() {
    if (this.refs.underlying && this.refs.underlying.getDecoratedComponentInstance()) {
      return this.refs.underlying.getDecoratedComponentInstance().getCards();
    }
    return new List();
  },

  render() {
    return <DragableSortable input={this.props.input} draggable={this.props.draggable} striped={this.props.striped} ref='underlying' />;
  },
});

export default NestedSortable;
