import {connect} from 'react-redux';
import {performSearch, deleteUser} from 'api/user';
import {clearUserSearch} from 'redux/actions/users';
import {getApps} from 'api/app';

import UserManagement from 'components/pages/Users/Users';

function mapStateToProps({data, routing, locale}) {
  return {
    apps: data.get('apps'),
    hasUserSearched: data.get('hasUserSearched'),
    users: data.get('userSearch'),
    rolesCopy: locale.get('roles'),
    locale: locale.get('locale'),
    path: routing.locationBeforeTransitions.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearUserSearch: () => dispatch(clearUserSearch()),
    deleteUser: id => dispatch(deleteUser(id)),
    getApps: (...args) => dispatch(getApps(...args)),
    searchUsers: (...args) => dispatch(performSearch(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
