import {connect} from 'react-redux';
import {getDashboardInfo} from 'api/dashboard';
import {getProfile} from 'api/profile';

import Home from 'components/pages/Home/Home';

function mapStateToProps({data, locale}) {
  return {
    user: data.get('currentUser'),
    userAccounts: data.getIn(['dashboard', 'UserAccounts']),
    locale: locale.get('locale'),
    roles: locale.get('roles'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser: () => dispatch(getProfile()),
    getDashboardInfo: (...args) => dispatch(getDashboardInfo(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
