import {connect} from 'react-redux';
import {clearAppStatus} from 'redux/actions/apps';
import {clearUserStatus} from 'redux/actions/users';
import {addToast, clearToast} from 'redux/actions/toasts';
import {toggleModal} from 'redux/actions/modals';
import {getProfile} from 'api/profile';
import {setLocale} from '../../locale/generateLocales.js!';
import {getRoles} from 'api/copy';

import App from 'components/layout/App/App';


function mapStateToProps({token, data, routing, ui, locale}) {
  return {
    currentUser: data.get('currentUser'),
    disclaimerModalVisible: ui.getIn(['modals', 'disclaimer']),
    initialLoad: locale.get('initial'),
    loc: routing && routing.locationBeforeTransitions ? routing.locationBeforeTransitions.pathname || '' : '',
    locale: locale.get('locale'),
    possibleLocales: locale.get('possibleLocales'),
    privacyModalVisible: ui.getIn(['modals', 'privacy']),
    status: data.get('status'),
    toasts: ui.get('toasts').toList(),
    token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (...args) => dispatch(addToast(...args)),
    clearAppStatus: () => dispatch(clearAppStatus()),
    clearToast: (...args) => dispatch(clearToast(...args)),
    clearUserStatus: () => dispatch(clearUserStatus()),
    getCurrentUser: (...args) => dispatch(getProfile(...args)),
    getRoles: (...args) => dispatch(getRoles(...args)),
    setLocale: (...args) => dispatch(setLocale(...args)),
    toggleDisclaimerModal: (...args) => dispatch(toggleModal('disclaimer', ...args)),
    togglePrivacyModal: (...args) => dispatch(toggleModal('privacy', ...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
