import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import styles from './WizardProgress.module.scss';

const WizardProgress = createClass({
  displayName: 'WizardProgress',
  mixins: [PureRenderMixin],
  propTypes: {
    steps: PropTypes.any.isRequired,
    active: PropTypes.number.isRequired,
  },

  render() {
    let steps = this.props.steps;
    let numSteps = 0;
    if (steps instanceof Array) {
      numSteps = steps.length;
      steps = this.props.steps.map((x, i) => {
        let stepClass = styles.step + ' ';
        if (this.props.active === i) {
          stepClass += styles.active;
        } else if (this.props.active < i) {
          stepClass += styles.future;
        } else if (this.props.active > i) {
          stepClass += styles.past;
        }

        return <div className={stepClass} key={i}>
          <div className={styles.number}>{i + 1}</div>{x}
        </div>;
      });
    } else {
      let keys = Object.keys(steps);
      numSteps = keys.length;
      steps = keys.map((num, i) => {
        let title = steps[num];
        num = parseInt(num);
        let stepClass = styles.step + ' ';
        if (this.props.active === num) {
          stepClass += styles.active;
        } else if (this.props.active < num) {
          stepClass += styles.future;
        } else if (this.props.active > num) {
          stepClass += styles.past;
        }

        return <div className={stepClass} key={num}>
          <div className={styles.number}>{i + 1}</div>{title}
        </div>;
      });
    }
    return <div className={styles.wizard + ' ' + styles['steps-' + numSteps]}>
      {steps}
    </div>;
  },
});

export default WizardProgress;
