import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import Modal from '../Modal/Modal';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

import styles from './Footer.module.scss';

const Footer = createClass({
  displayName: 'Footer',
  mixins: [PureRenderMixin],
  propTypes: {
    disclaimerModalVisible: PropTypes.bool.isRequired,
    hideDisclaimerModal: PropTypes.func.isRequired,
    openDisclaimerModal: PropTypes.func.isRequired,
    privacyModalVisible: PropTypes.bool.isRequired,
    hidePrivacyModal: PropTypes.func.isRequired,
    openPrivacyModal: PropTypes.func.isRequired,
    getPrivacyPolicy: PropTypes.func.isRequired,
    getTermsOfUse: PropTypes.func.isRequired,
    privacyPolicy: PropTypes.object,
    termsOfUse: PropTypes.object,
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
  },

  fetchData(props) {
    props.getTermsOfUse(props.locale);
    props.getPrivacyPolicy(props.locale);
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.locale !== this.props.locale) {
      this.fetchData(nextProps);
    }
  },

  componentWillMount() {
    this.fetchData(this.props);
  },

  hideModals() {
    this.props.hideDisclaimerModal();
    this.props.hidePrivacyModal();
  },

  render() {
    return <div className={styles.footer}>
        <div className={styles.spacer}>
          <div className={'container ' + styles.info}>
            <p className='pull-left'><FormattedMessage id='footer.copyright' values={{year: new Date().getFullYear()}} /></p>
            <p className='pull-right'>
              <span onClick={this.props.openDisclaimerModal} className={styles.legalLink}><FormattedMessage id='footer.terms' /></span>
              <span className='separator'> | </span>
              <span onClick={this.props.openPrivacyModal} className={styles.legalLink}><FormattedMessage id='footer.privacy' /></span>
            </p>
          </div>
        </div>
        <div>
          {/*Disclaimer Modal*/}
          <Modal visible={this.props.disclaimerModalVisible} close={this.props.hideDisclaimerModal} id='app-disclaimer-modal' size='lg' calloutFooter={false}
              header={<h3><FormattedMessage id='footer.terms' /></h3>} footer={<button className='btn btn-primary' onClick={this.hideModals}>Close</button>}>
            {this.props.termsOfUse ? <div dangerouslySetInnerHTML={this.props.termsOfUse} /> // eslint-disable-line react/no-danger
             : null}
          </Modal>
          {/*Privacy Modal*/}
          <Modal visible={this.props.privacyModalVisible} close={this.props.hidePrivacyModal} id='app-privacy-modal' size='lg' calloutFooter={false}
              header={<h3><FormattedMessage id='footer.privacy' /></h3>} footer={<button className='btn btn-primary' onClick={this.hideModals}>Close</button>}>
            {this.props.privacyPolicy ? <div dangerouslySetInnerHTML={this.props.privacyPolicy} /> // eslint-disable-line react/no-danger
             : null}
          </Modal>
        </div>
      </div>;
  },
});

export default injectIntl(Footer);
