import {connect} from 'react-redux';
import {getUser, saveUser, addUserToGroup, removeUserFromGroup, checkEmailUsed} from 'api/user';
import {getGroups} from 'api/group';
import EditUser from 'components/pages/Users/Edit/Edit';
import {getApps} from 'api/app';
import {setUser, setStoreProvision} from 'redux/actions/users';
import {addToast} from 'redux/actions/toasts';

function mapStateToProps({data, ui, routing, locale}, ownProps) {
  return {
    apps: data.get('apps').toList(),
    user: data.getIn(['users', parseInt(ownProps.params.id)]),
    dealers: data.get('groups').filter(x => x.type === 0),
    path: routing.locationBeforeTransitions.pathname,
    visibleStores: data.getIn(['userProvisioning', 'visibleStores']),
    locale: locale.get('locale'),
    rolesCopy: locale.get('roles'),
    possibleLocales: locale.get('possibleLocales'),
    regions: locale.get('regions'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addUserToGroup: (userID, groupID) => dispatch(addUserToGroup(userID, groupID)),
    checkEmailUsed: (...args) => dispatch(checkEmailUsed(...args)),
    getApps: (...args) => dispatch(getApps(...args)),
    getUser: (id) => dispatch(getUser(id)),
    getDealers: () => dispatch(getGroups(0)),
    removeUserFromGroup: (userID, groupID) => dispatch(removeUserFromGroup(userID, groupID)),
    saveUser: (user) => dispatch(saveUser(user)),
    setUser: (...args) => dispatch(setUser(...args)),
    toggleStoreVisible: (...args) => dispatch(setStoreProvision(...args)),
    addToast: (...args) => dispatch(addToast(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
