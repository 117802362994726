import {createTokenManager} from 'redux-oidc';
import createTokenManagerConfig from './tokenManager';
import {getCSRFToken} from './csrfHelper';
import qwest from 'qwest';

export const methodTypes = {
  get: 'GET',
  delete: 'DELETE',
  post: 'POST',
  put: 'PUT',
  head: 'HEAD',
};

export default function createRequest(url, params) {
  let parsedParams = Object.assign({
    method: methodTypes.get,
    dataType: 'post',
    body: null,
    headers: {},
    authorize: false,
    cors: true,
  }, params || {});

  if (parsedParams.authorize === true) {
    const manager = createTokenManager(createTokenManagerConfig());

    parsedParams.headers['Authorization'] = manager['id_token'];
  }

  if([methodTypes.post, methodTypes.put, methodTypes.delete].includes(parsedParams.method) && parsedParams.authorize === true){
    parsedParams.headers['X-XSRF-Token'] = getCSRFToken();
  }

  let execute;

  switch (parsedParams.method) {
    case methodTypes.get: {
      execute = qwest.get;
      break;
    }
    case methodTypes.post: {
      execute = qwest.post;
      break;
    }
    case methodTypes.delete: {
      execute = qwest.delete;
      break;
    }
    case methodTypes.head: {
      execute = qwest.head;
      break;
    }
    default: {
      execute = qwest.get;
      break;
    }
  }

  let options = {};
  if (Object.keys(parsedParams.headers).length > 0) {
    options.headers = parsedParams.headers;
  }
  if (parsedParams.dataType) {
    options.dataType = parsedParams.dataType;
  }
  if (!parsedParams.cors) {
    options.cache = !parsedParams.cors;
  }

  let promise = makeCancelable(execute(url, parsedParams.body, options)
    .then((xhr, response) => {
      return response;
    }));

  return promise;
}

export function makeCancelable(promise) {
  let shouldResolve = true;
  let output = {
    cancel: function() {shouldResolve = false;},
    promise: function() {
      return new Promise((resolve, reject) => {
        promise.then(response => {
          if (shouldResolve) {
            resolve(response);
          } else {
            reject({canceled: true});
          }
        });
      });
    },
  };

  return output;
}

export class PromiseStore {
  constructor(...args) {
    let promises = [];
    if (args && args.length > 0 && args[0] instanceof Array) {
      promises = args[0];
    } else if (args && args.length > 0) {
      promises = args;
    }

    this.promises = promises;
  }

  add(p) {
    this.promises.push(p);
    return p;
  }

  cleanUp() {
    for (let p of this.promises) {
      if (p && p.cancel && typeof p.cancel === 'function') {
        p.cancel();
      }
    }
  }
}

export const PromiseStoreMixin = {
  componentWillMount() {
    this.promises = new PromiseStore();
  },

  componentWillUnmount() {
    this.promises.cleanUp();
  },
};
