import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import styles from './Status.module.scss';
import {List} from 'immutable';

/*Here are the sections for the users status report*/
import InnerForm from 'containers/layout/InnerForm';
import Actives from './Actives/Actives';
import Inactives from './Inactives/Inactives';

import {ReportSettings, AccountStatusReport} from 'models';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {getTranslatedName} from 'helpers/roles';
import {csvToUrl, textToCsv, arrayToCsv} from 'helpers/csv';

const Status = createClass({
  displayName: 'Status',
  mixins: [PureRenderMixin],
  propTypes: {
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    report: PropTypes.instanceOf(AccountStatusReport).isRequired,
    getAccountStatusReport: PropTypes.func.isRequired,
    updateSettings: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
    rolesCopy: PropTypes.instanceOf(List).isRequired,
  },

  export() {
    let {formatMessage} = this.props.intl;
    let {report, settings} = this.props;
    const compare = report && report.compare && settings && settings.compare && settings.compare.from && settings.compare.to;


    let output = [[formatMessage({id: 'reports.status.authentication'}), formatMessage({id: 'reports.active.title'})]];
    if (compare) {
      output[0].push(formatMessage({id: 'reports.filters.compare'}));
    }
    output[0].push(formatMessage({id: 'reports.inactive.title'}));
    if (compare) {
      output[0].push(formatMessage({id: 'reports.filters.compare'}));
    }

    let rows = report.unlockedPerRole.keySeq().map(name => {
      let out = [name, report.unlockedPerRole.get(name)];
      if (compare) {
        out.push(report.compare.unlockedPerRole.get(name) || 0);
      }
      out.push(report.lockedPerRole.get(name));
      if (compare) {
        out.push(report.compare.lockedPerRole.get(name) || 0);
      }
      return out;
    }).toArray();

    output = output.concat(rows);

    let csv = csvToUrl(textToCsv(arrayToCsv(output), 'export.csv'));

    window.open(csv);
  },

  render() {
    let {settings, report, locale, rolesCopy} = this.props;
    let {formatMessage} = this.props.intl;

    let getRoleName = getTranslatedName.bind(null, rolesCopy, locale);

    return <div>
      <PageHeader title={formatMessage({id: 'reports.status.pageHeader'})} visible={true} right={<div className={styles.exportButton}>
        <button className={'btn btn-primary'} disabled={!report} onClick={this.export}><FormattedMessage id='reports.export' /></button>
        </div>} backButton={true} />
      <InnerForm run={this.props.getAccountStatusReport} settings={this.props.settings} update={this.props.updateSettings} />
      {report ? <div>
        <Actives settings={settings} report={report} getRoleName={getRoleName} />
        <Inactives settings={settings} report={report} getRoleName={getRoleName} />
      </div> : null}
    </div>;
  },
});

export default injectIntl(Status);
