import React, {createClass, PropTypes} from 'react';

import {wrapIfString} from 'helpers/strings';

import styles from './Card.module.scss';

const Card = createClass({
  displayName: 'Card',
  propTypes: {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    footer: PropTypes.any,
    header: PropTypes.any,
    button: PropTypes.any,
    headerBackground: PropTypes.string,
    headerFontSize: PropTypes.number,
    headerHeight: PropTypes.number,
    lightHeader: PropTypes.bool,
    minHeight: PropTypes.number,
    noBorder: PropTypes.bool,
    noHeaderPadding: PropTypes.bool,
    noPadding: PropTypes.bool,
    style: PropTypes.object,
  },

  getDefaultProps() {
    return {
      lightHeader: false,
      noBorder: false,
      noPadding: false,
      noHeaderPadding: false,
      minHeight: 200,
    };
  },

  render() {
    let headerStyles = {style: {}};
    let outerHeaderStyles = {};
    if (this.props.headerFontSize) {
      headerStyles.style.fontSize = this.props.headerFontSize;
    }
    if (this.props.headerHeight) {
      outerHeaderStyles.height = this.props.headerHeight;
    }
    if (this.props.headerBackground) {
      outerHeaderStyles.background = this.props.headerBackground;
    }
    let header = wrapIfString(this.props.header, 'h3', null, headerStyles);

    let cardClass = styles.card;
    if (this.props.className) {
      cardClass += ' ' + this.props.className;
    }

    let bodyClass = styles.body;
    if (this.props.noPadding === true) {
      bodyClass += ' ' + styles.noPadding;
    }

    let headerClass = styles.header;
    if (this.props.noHeaderPadding === true) {
      headerClass += ' ' + styles.noPadding;
    }
    if (this.props.lightHeader === true) {
      headerClass += ' ' + styles.lightHeader;
    }


    let additionalStyles = {minHeight: this.props.minHeight};
    if (this.props.noBorder) {
      additionalStyles.border = 0;
    }

    return <div className={cardClass + ' card'} style={Object.assign(this.props.style || {}, additionalStyles)}>
      {this.props.header ?
        <div className={headerClass} style={outerHeaderStyles}>
          {header}{this.props.button}
        </div>
      : null}
      <div className={bodyClass}>{this.props.children}</div>
      {this.props.footer ? <div className={styles.footer}>{this.props.footer}</div> : null}
    </div>;
  },
});

export default Card;
