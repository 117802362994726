import {SET_TERMS_OF_USE, SET_PRIVACY_POLICY} from './index';

export function setTermsOfUse(val) {
  return {
    type: SET_TERMS_OF_USE,
    value: val,
  };
}

export function setPrivacyPolicy(val) {
  return {
    type: SET_PRIVACY_POLICY,
    value: val,
  };
}
