import {connect} from 'react-redux';
import ReportsLocked from 'components/pages/Reports/Locked/Locked';

import {getLockEventsReport} from 'api/report';
import {setReportSettings} from 'redux/actions/reports';

function mapStateToProps({data}) {
  return {
    report: data.getIn(['reports', 'lockEvents', 'data']),
    settings: data.getIn(['reports', 'lockEvents', 'settings']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLockEventsReport: (...args) => dispatch(getLockEventsReport(...args)),
    updateSettings: (...args) => dispatch(setReportSettings('lockEvents', ...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsLocked);
