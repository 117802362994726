import {connect} from 'react-redux';

import EditDealer from 'components/pages/Dealers/Edit/Edit';

import {getGroupUsers, getGroup,saveGroup, removeGroup, checkSoldTo, checkShipTo} from 'api/group';
import {addToast} from 'redux/actions/toasts';
import {setDeleteStore, clearDeleteStore} from 'redux/actions/dealers';


function mapStateToProps({data, ui, routing, locale}, {params}) {
  return {
    dealerUsers: data.getIn(['groupUsers', parseInt(params.id)]),
    usersForGroup: id => data.getIn(['groupUsers', id]),
    dealer: data.get('groups').get(parseInt(params.id)),
    path: routing.locationBeforeTransitions.pathname,
    storeDeleteVisible: ui.getIn(['modals', 'deleteStore']),
    storeToDelete: data.getIn(['delete', 'store']),
    regions: locale.get('regions'),
    possibleLocales: locale.get('possibleLocales'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: toast => dispatch(addToast(toast)),
    checkShipTo: (...args) => dispatch(checkShipTo(...args)),
    checkSoldTo: (...args) => dispatch(checkSoldTo(...args)),
    deleteDealer: (...args) => dispatch(removeGroup(...args)),
    getGroup: id => dispatch(getGroup(id)),
    saveDealer: dealer => dispatch(saveGroup(dealer)),
    setDeleteStore: (...args) => dispatch(setDeleteStore(...args)),
    clearDeleteStore: (...args) => dispatch(clearDeleteStore(...args)),
    getGroupUsers: (...args) => dispatch(getGroupUsers(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDealer);
