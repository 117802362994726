import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import Card from 'components/layout/Card/Card';
import styles from './Modified.module.scss';

/*Here are the sections for the users locked report*/
import InnerForm from 'containers/layout/InnerForm';
import Results from 'components/layout/ReportsGenerics/LargeResults/LargeResults';
import {BarChart} from 'components/charts';

import {UsersModifiedReport, ReportSettings} from 'models';
import {csvToUrl, textToCsv, arrayToCsv} from 'helpers/csv';

import {injectIntl, FormattedMessage, intlShape} from 'react-intl';

const Modified = createClass({
  displayName: 'Modified',
  mixins: [PureRenderMixin],
  propTypes: {
    report: PropTypes.instanceOf(UsersModifiedReport),
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    updateSettings: PropTypes.func.isRequired,
    getUsersModifiedReport: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  },

  export() {
    let {formatMessage} = this.props.intl;
    let {settings, report} = this.props;

    let compare = report && report.compare && settings && settings.compare && settings.compare.from && settings.compare.to;

    let row = [];
    let header = [];

    header.push(formatMessage({id: 'reports.modified.title'}));
    row.push(report.totalModified);
    if (compare) {
      header.push(formatMessage({id: 'reports.filters.compare'}));
      row.push(report.compare.totalModified);
    }

    let output = [header, row];

    let csv = csvToUrl(textToCsv(arrayToCsv(output), 'export.csv'));
    window.open(csv);
  },

  render() {
    let {settings, report} = this.props;
    let {formatMessage} = this.props.intl;

    let compare = report && settings && report.compare && settings.compare && settings.compare.from && settings.compare.to;

    let summary, compareSummary;
    let data = [];

    if (report) {
      summary = formatMessage({
        id: 'reports.modified.summary',
      }, {
        from: settings.from.format('ll'),
        to: settings.to.format('ll'),
      });
      data.push({
        name: formatMessage({
            id: 'reports.modified.dateRange',
          }, {
          from: settings.from.format('ll'),
          to: settings.to.format('ll'),
        }),
        value: report.totalModified,
      });

      if (compare) {
        compareSummary =  formatMessage({
          id: 'reports.modified.summary',
        }, {
          from: settings.compare.from.format('ll'),
          to: settings.compare.to.format('ll'),
        });
        data.push({
          name: formatMessage({
            id: 'reports.modified.dateRange',
          }, {
            from: settings.compare.from.format('ll'),
            to: settings.compare.to.format('ll'),
          }),
          value: report.compare.totalModified,
        });
      }
    }

    return <div>
      <PageHeader title={formatMessage({id: 'reports.modified.pageHeader'})} visible={true} right={<div className={styles.exportButton}>
        <button className={'btn btn-primary'} disabled={!report} onClick={this.export}><FormattedMessage id='reports.export' /></button>
        </div>} backButton={true} />
      <InnerForm run={this.props.getUsersModifiedReport} update={this.props.updateSettings} settings={settings} />
        {report ? <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{formatMessage({id: 'reports.modified.title'})}</span></h3>}
          lightHeader={true}
          headerFontSize={16}
          style={{marginBottom: 20}} >
          <div className='row'>
            <div className='col-sm-12'>
              <BarChart data={data} />
              <Results key={0} colSize={compare ? 'col-sm-6' : 'col-sm-12'} marginBottom={20} light={false} text={summary}
                number={report.totalModified}
                dealerAverage={report.totalModified / report.totalDealers} storeAverage={report.totalModified / report.totalStores} />
              {compareSummary ? <Results key={1} colSize='col-sm-6' marginBottom={20} light={false}
                textGray={false} text={compareSummary} number={report.compare.totalModified}
                dealerAverage={report.compare.totalModified / report.compare.totalDealers}
                storeAverage={report.compare.totalModified / report.compare.totalStores}  /> : null}
            </div>
          </div>
        </Card> : null}
    </div>;
  },
});

export default injectIntl(Modified);
