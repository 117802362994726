/**
 * Custom version of PureRenderMixin that includes context.
 **/
export const PureRenderMixin = {
  shouldComponentUpdate: function(nextProps, nextState, nextContext) {
    return shallowCompare(this, nextProps, nextState, nextContext);
  },
};

/**
 * Custom version of shallowCompare addon that includes context.
 * @param {object}  instance    Current element.
 * @param {object}  nextProps   Props for the component that got passed in.
 * @param {object}  nextState   State for the component that got passed in.
 * @param {object}  nextContext Context for the component that got passed in.
 * @returns {bool}              Boolean for whether the component should re-render or not.
 **/
export function shallowCompare(instance, nextProps, nextState, nextContext) {
  // TODO: Update this to include reacts shallowEqual function. Probably faster.
  return !(Object.is(instance.props, nextProps) && Object.is(instance.state, nextState) && Object.is(instance.context, nextContext));
}
