import {connect} from 'react-redux';
import {searchDealers, removeGroup, saveGroup, checkSoldTo} from 'api/group';
import {clearDealerSearch} from 'redux/actions/groups';
import {toggleModal} from 'redux/actions/modals';
import {setDeleteDealer, clearDeleteDealer, setInlineDealer, clearInlineDealer} from 'redux/actions/dealers';
import {addToast} from 'redux/actions/toasts';


import DealerManagement from 'components/pages/Dealers/Dealers';

function mapStateToProps({data, ui, routing}) {
  return {
    dealers: data.get('dealerSearch'),
    deleteModalVisible: ui.getIn(['modals', 'deleteDealer']),
    dealerToDelete: data.getIn(['delete', 'dealer']),
    inlineDealer: ui.get('inlineDealerEdit'),
    hasUserSearched: data.get('hasUserSearched'),
    path: routing.locationBeforeTransitions.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (...args) => dispatch(addToast(...args)),
    checkSoldTo: (...args) => dispatch(checkSoldTo(...args)),
    clearDealerSearch: () => dispatch(clearDealerSearch()),
    clearDeleteDealer: () => dispatch(clearDeleteDealer()),
    clearInlineDealer: () => dispatch(clearInlineDealer()),
    deleteDealer: (...args) => dispatch(removeGroup(...args)),
    hideDeleteModal: () => dispatch(toggleModal('deleteDealer', false)),
    saveDealer: (...args) => dispatch(saveGroup(...args)),
    searchDealers: (...args) => dispatch(searchDealers(...args)),
    setDeleteDealer: (...args) => dispatch(setDeleteDealer(...args)),
    setInlineDealer: (...args) => dispatch(setInlineDealer(...args)),
    showDeleteModal: () => dispatch(toggleModal('deleteDealer', true)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DealerManagement);
