import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import Card from 'components/layout/Card/Card';

import styles from './Locked.module.scss';

/*Here are the sections for the users locked report*/
import InnerForm from 'containers/layout/InnerForm';
import TableResults from 'components/layout/ReportsGenerics/TableResults/TableResults';
import {BarChart} from 'components/charts';

import {LockEventsReport, ReportSettings} from 'models';

const dateFormat = 'll';
import {csvToUrl, textToCsv, arrayToCsv} from 'helpers/csv';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const Locked = createClass({
  displayName: 'Locked',
  mixins: [PureRenderMixin],
  propTypes: {
    report: PropTypes.instanceOf(LockEventsReport).isRequired,
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    getLockEventsReport: PropTypes.func.isRequired,
    updateSettings: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  },

  export() {
    let {formatMessage} = this.props.intl;
    let {settings, report} = this.props;

    let compare = report && report.compare && settings && settings.compare && settings.compare.from && settings.compare.to;

    let row = [];
    let header = [];

    header.push(formatMessage({id: 'reports.locked.manualTitle'}));
    row.push(report.get('manualLocked'));
    if (compare) {
      header.push(formatMessage({id: 'reports.filters.compare'}));
      row.push(report.compare.manualLocked);
    }

    header.push(formatMessage({id: 'reports.locked.autoTitle'}));
    row.push(report.autoLocked);
    if (compare) {
      header.push(formatMessage({id: 'reports.filters.compare'}));
      row.push(report.compare.autoLocked);
    }

    let output = [header, row];

    let csv = csvToUrl(textToCsv(arrayToCsv(output), 'export.csv'));
    window.open(csv);
  },

  render() {
    let {settings, report} = this.props;
    let {formatMessage} = this.props.intl;

    let compare = report && report.compare && settings && settings.compare && settings.compare.from && settings.compare.to;

    let manualData = [];
    let autoData = [];
    let valueFields = ['value'];

    if (report) {
      manualData.push({
        name: formatMessage({
          id: 'reports.locked.dateRange',
        }, {
          from: settings.from.format(dateFormat),
          to: settings.to.format(dateFormat),
        }),
        value: report.manualLocked || 0,
      });
      autoData.push({
        name: formatMessage({
          id: 'reports.locked.dateRange',
        }, {
          from: settings.from.format(dateFormat),
          to: settings.to.format(dateFormat),
        }),
        value: report.autoLock || 0,
      });
      if (compare) {
        manualData.push({
          name: formatMessage({
            id: 'reports.locked.dateRange',
          }, {
            from: settings.compare.from.format(dateFormat),
            to: settings.compare.to.format(dateFormat),
          }),
          value: report.compare.manualLocked || 0,
        });
        autoData.push({
          name: formatMessage({
            id: 'reports.locked.dateRange',
          }, {
            from: settings.compare.from.format(dateFormat),
            to: settings.compare.to.format(dateFormat),
          }),
          value: report.compare.autoLock || 0,
        });
      }
    }

    return <div>
      <PageHeader title={formatMessage({id: 'reports.locked.pageHeader'})} visible={true} right={<div className={styles.exportButton}>
        <button className={'btn btn-primary'} disabled={!report} onClick={this.export}><FormattedMessage id='reports.export' /></button>
        </div>} backButton={true} />
      <InnerForm run={this.props.getLockEventsReport} update={this.props.updateSettings} settings={this.props.settings} />
      {report ? <div className='row'>
        <div className='col-sm-6'>
          <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{formatMessage({id: 'reports.locked.manualTitle'})}</span></h3>}
            lightHeader={true}
            headerFontSize={16}
            minHeight={370}
            style={{marginBottom: 20}} >
            <div className='row'>
              <div className='col-sm-12'>
                <BarChart data={manualData} valueField={valueFields} />
                <TableResults header={<thead>
                  <tr>
                    <th><FormattedMessage id='reports.date' /></th>
                    <th className='text-right'><FormattedMessage id='reports.count' /></th>
                  </tr>
                  </thead>}>
                  <tr>
                    <td>
                      <FormattedMessage id='reports.locked.dateRange' values={{
                        from: settings.from.format(dateFormat),
                        to: settings.to.format(dateFormat),
                      }} />
                    </td>
                    <td className='text-right'>{report.manualLocked || 0}</td>
                  </tr>
                  {compare ? <tr>
                    <td>
                      <FormattedMessage id='reports.locked.dateRange' values={{
                        from: settings.compare.from.format(dateFormat),
                        to: settings.compare.to.format(dateFormat),
                      }} />
                    </td>
                    <td className='text-right'>{report.compare.manualLocked || 0}</td>
                  </tr> : null}
                </TableResults>
              </div>
            </div>
          </Card>
        </div>
        <div className='col-sm-6'>
          <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{formatMessage({id: 'reports.locked.autoTitle'})}</span></h3>}
            lightHeader={true}
            minHeight={370}
            headerFontSize={16}
            style={{marginBottom: 20}} >
            <div className='row'>
              <div className='col-sm-12'>
                <BarChart data={autoData} valueField={valueFields} />
                <TableResults header={<thead><tr>
                    <th><FormattedMessage id='reports.date' /></th>
                    <th className='text-right'><FormattedMessage id='reports.count' /></th>
                    </tr></thead>}>
                    <tr>
                    <td>
                      <FormattedMessage id='reports.locked.dateRange' values={{
                        from: settings.from.format(dateFormat),
                        to: settings.to.format(dateFormat),
                      }} />
                    </td>
                    <td className='text-right'>{report.autoLock || 0}</td>
                    </tr>
                    {compare ? <tr>
                      <td>
                        <FormattedMessage id='reports.locked.dateRange' values={{
                          from: settings.compare.from.format(dateFormat),
                          to: settings.compare.to.format(dateFormat),
                        }} />
                      </td>
                      <td className='text-right'>{report.compare.autoLock || 0}</td>
                    </tr> : null}
                </TableResults>
              </div>
            </div>
          </Card>
        </div>
      </div> : null}
    </div>;
  },
});

export default injectIntl(Locked);
