import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {browserHistory} from 'react-router';
import {List as ImmutableList} from 'immutable';

import path from 'path';

import {AppModel} from 'models';

import Icon from 'components/Icon/Icon';

import styles from './List.module.scss';

import {FormattedMessage} from 'react-intl';
import {getTranslatedName} from 'helpers/roles';

const List = createClass({
  displayName: 'List',
  mixins: [PureRenderMixin],
  propTypes: {
    activeApp: PropTypes.instanceOf(AppModel),
    addApp: PropTypes.func.isRequired,
    apps: ImmutablePropTypes.listOf(PropTypes.instanceOf(AppModel)),
    baseURL: PropTypes.string,
    rolesCopy: PropTypes.instanceOf(ImmutableList),
    locale: PropTypes.string,
  },

  getDefaultProps() {
    return {
      baseURL: '/apps',
    };
  },

  setApp(app) {
    return () => {
      let newPath = path.join(this.props.baseURL, app.id.toString());
      if (newPath[0] !== '/') {
        newPath = '/' + newPath;
      }
      browserHistory.push(newPath);
    };
  },

  isActive(app) {
    return this.props.activeApp && this.props.activeApp.id === app.id;
  },

  render() {
    let apps = this.props.apps;

    return <div>
      {apps.map(app => <div key={app._id}
                            className={styles.app + ' ' + (this.isActive(app) ? styles.active : '')}
                            onClick={this.setApp(app)}>
        {getTranslatedName(this.props.rolesCopy, this.props.locale, app.displayName)}
        {this.isActive(app) ? <Icon name='rightCaret' className={styles.rightCaret} /> : null}
      </div>)}

      <div className={styles.app + ' ' + styles.addApp} onClick={this.props.addApp}>
        <Icon name='add' />&nbsp;<FormattedMessage id='apps.addApp' />
      </div>
    </div>;
  },
});

export default List;
