import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import styles from './Password.module.scss';

const Password = createClass({
  displayName: 'Password',
  mixins: [PureRenderMixin],
  propTypes: {
    autoFocus: PropTypes.bool,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    score: PropTypes.number,
    value: PropTypes.string,
  },
  render() {
    let scores = [];
    if (this.props.score || this.props.score === 0) {
      for (let i = 0; i < 6; ++i) {
        let style = styles.bar;
        if (i <= this.props.score && this.props.score !== 0) {
          if (i < 2) {
            style += ' ' + styles.weak;
          } else if (i < 4) {
            style += ' ' + styles.medium;
          } else if (i < 6) {
            style += ' ' + styles.strong;
          }
        }
        scores.push(<div className={style} key={i} />);
      }
    }

    return <div>
      <input className={'form-control ' + styles.input} type='password' onChange={this.props.onChange} ref='underlying'
        value={this.props.value} defaultValue={this.props.defaultValue} autoFocus={this.props.autoFocus !== undefined && this.props.autoFocus !== false} />
      <div className={styles.scoreContainer}>
        {scores}
      </div>
    </div>;
  },
});

export default Password;
