import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';

import {GroupModel, UserModel} from 'models';

const EmailExists = createClass({
  displayName: 'EmailExists',
  mixins: [PureRenderMixin],
  propTypes: {
    dealer: PropTypes.instanceOf(GroupModel).isRequired,
    user: PropTypes.instanceOf(UserModel).isRequired,
    duplicate: PropTypes.instanceOf(GroupModel).isRequired,
    back: PropTypes.func.isRequired,
    tryAgain: PropTypes.func.isRequired,
    useExisting: PropTypes.func.isRequired,
  },

  back() {
    return this.props.back(this.props.dealer, this.props.user);
  },

  gotoProfile() {
    browserHistory.push('/user/' + this.props.duplicate.id);
  },

  render() {
    let user = this.props.duplicate;
    return <div>
      <h2>Email address, {user.email} already exists</h2>
      <div className='row'>
        <div className='col-md-4'>
          <div className='form-group'>
            <label>Name:</label>
            <div className='form-control-static'>{user.name}</div>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='form-group'>
            <label>Email:</label>
            <div className='form-control-static'>{user.email}</div>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='form-group'>
            <label>Mobile Phone:</label>
            <div className='form-control-static'>{user.mobilePhone}</div>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='form-group'>
            <label>Alternate Phone:</label>
            <div className='form-control-static'>{user.alternatePhone}</div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-xs-12'>
          <button className='btn btn-default pull-left' onClick={this.back}>
            Back
          </button>
          <button className='btn btn-default pull-left' onClick={this.props.tryAgain}>Try Again</button>


          <button className='btn btn-primary pull-right' onClick={this.props.useExisting}>
            Use This Profile
          </button>

          <button className='btn btn-default pull-right' onClick={this.gotoProfile}>
            View User Profile
          </button>

        </div>
      </div>
    </div>;
  },
});

export default EmailExists;
