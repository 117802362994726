// For the future developers who get here:
// https://github.com/ryanflorence/react-training/blob/gh-pages/lessons/05-wrapping-dom-libs.md

import React, {createClass, PropTypes} from 'react';
import {unmountComponentAtNode, findDOMNode} from 'react-dom';

import PureRenderMixin from 'react-addons-pure-render-mixin';

import 'amcharts/dist/amcharts/amcharts';
import 'amcharts/dist/amcharts/themes/light';

const AmCharts = window.AmCharts;

/**
 * Wrapper around AmCharts.makeChart
 */
const AmChart = createClass({
  displayName: 'AmChart',
  mixins: [PureRenderMixin],
  propTypes: {
    className: PropTypes.string,
    config: PropTypes.object.isRequired,
    height: PropTypes.number,
    noData: PropTypes.node,
  },

  getDefaultProps() {
    return {
      height: 300,
    };
  },

  componentDidMount() {
    this.node = findDOMNode(this);

    this.updateNode();
    this._chart = AmCharts.makeChart(this.node, this.props.config);
  },

  componentDidUpdate() {
    this.updateChart();
  },

  componentWillUnmount() {
    this._chart.clear();
    unmountComponentAtNode(this.node);
  },

  componentWillReceiveProps(nextProps) {
    this.updateNode(nextProps);
  },

  updateChart(props = this.props) {
    Object.assign(this._chart, props.config);

    this._chart.validateNow(true);

    // Certain chart types don't have this
    if (this._chart.zoomOut) {
      this._chart.zoomOut();
    }
  },

  updateNode(props = this.props) {
    this.node.className = props.className || '';
    this.node.style.height = props.height + 'px';
  },

  render() {
    return <div />;
  },
});

export default AmChart;
