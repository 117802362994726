import React from 'react';
import {ToastModel} from 'models';
import {addToast} from 'redux/actions/toasts';
import {FormattedMessage} from 'react-intl';

export function savingAppToast() {
  return addToast(new ToastModel({
    key: 'saving-app',
    message: <FormattedMessage id='toasts.app.saving' />,
    closeable: true,
  }));
}

export function savedAppToast() {
  return addToast(new ToastModel({
    clears: 'saving-app',
    key: 'saved-app-success',
    message: <FormattedMessage id='toasts.app.saved' />,
    ttl: 3000,
    closeable: true,
  }));
}

export function errorAppToast() {
  return addToast(new ToastModel({
    clears: 'saving-app',
    key: 'saved-app-failure',
    message: <FormattedMessage id='toasts.app.error' />,
    closeable: true,
  }));
}

export function deletingAppToast() {
  return addToast(new ToastModel({
    key: 'deleting-app',
    message: <FormattedMessage id='toasts.app.deleting' />,
    closeable: true,
    ttl: null,
  }));
}

export function deletedAppToast() {
  return addToast(new ToastModel({
    key: 'deleted-app',
    message: <FormattedMessage id='toasts.app.deleted' />,
    clears: 'deleting-app',
    closeable: true,
    ttl: 3000,
  }));
}
