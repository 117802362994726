import {connect} from 'react-redux';
import {getProfile, saveProfile} from 'api/profile';
import MyProfile from 'components/pages/Profile/Profile';
import {checkEmailUsed} from 'api/user';

import {setLocale} from '../../../locale/generateLocales.js!';

function mapStateToProps({data, locale}) {
  return {
    user: data.get('currentUser'),
    locale: locale.get('locale'),
    regions: locale.get('regions'),
    possibleLocales: locale.get('possibleLocales'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProfile: () => dispatch(getProfile()),
    saveProfile: (user) => dispatch(saveProfile(user)),
    checkEmailUsed: (...args) => dispatch(checkEmailUsed(...args)),
    setLocale: (...args) => dispatch(setLocale(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
