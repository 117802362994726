import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import {List} from 'immutable';

import {PromiseStoreMixin} from 'helpers/request';

import {AppModel} from 'models';

import AppTitle from '../../Apps/Title/Title';
import Card from 'components/layout/Card/Card';
import CardTable from 'components/layout/CardTable/CardTable';
import {Checkbox, NestedSortable} from 'components/forms';
import {Input as ReadOnlyInput} from 'components/forms/ReadOnly';

import Icon from 'components/Icon/Icon';

import path from 'path';

import styles from './View.module.scss';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const View = createClass({
  displayName: 'View',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    app: PropTypes.instanceOf(AppModel),
    assignGroup: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    rolesCopy: PropTypes.instanceOf(List),
    locale: PropTypes.string,
  },

  contextTypes: {
    canModifyApps: PropTypes.bool.isRequired,
  },


  getDefaultProps() {
    return {
      app: new AppModel({}),
    };
  },

  assignGroup() {
    this.props.assignGroup(this.props.app.id, this.refs.unassignedGroups.getValue());
  },

  groupToCard(g, indent) {
    return {
      id: g._id,
      horizontalIndent: indent,
      title: <div style={{padding: '5px 20px'}}>{g.displayName} | {g.name}</div>,
    };
  },

  addAttribute() {
    browserHistory.push(path.join(this.props.path, 'edit') + '?add-attribute');
  },

  addRole() {
    browserHistory.push(path.join(this.props.path, 'edit') + '?add-role');
  },

  addPermission() {
    browserHistory.push(path.join(this.props.path, 'edit') + '?add-permission');
  },

  groupsToCards(list, output = new List(), index = 0, indent = 0) {
    if (list.size > 0) {
      let group = list.get(index);

      let card = this.groupToCard(group, indent);
      output = output.push(card);
      if (group.children && group.children.size > 0) {
        output = this.groupsToCards(group.children, output, 0, indent + 1);
      }
      if (index < list.size - 1) {
        return this.groupsToCards(list, output, index + 1, indent);
      }
    }
    return output;
  },

  getPermissionsSection() {
    let app = this.props.app;

    let permissions = this.groupsToCards(app.permissions);

    return <Card style={{marginTop: 20}} header={<h3 style={{fontSize: 15, fontWeight: 600, padding: '10px 15px'}}>
        <FormattedMessage id='roles.tables.permissions' /></h3>}
        minHeight={0} noBorder={true} noPadding={true} noHeaderPadding={true} headerHeight='inherit' footer={this.context.canModifyApps ?
              <button className='btn btn-dashed' onClick={this.addPermission}>
                <Icon name='add' />&nbsp;<FormattedMessage id='roles.addPermission' />
              </button> : null
            }>
      <div className='row'>
        <div className='col-xs-12'>
          <NestedSortable striped={true} input={permissions} draggable={false} ref='groupsSortable'/>
        </div>
      </div>
    </Card>;
  },

  getRolesSection() {
    let roles = this.props.app.roles
      .map(x => {
        return <tr key={x._id}>
          <td><ReadOnlyInput minHeight={'auto'} value={x.displayName} ref={'roleDisplayName' + x._id}/></td>
          <td><ReadOnlyInput minHeight={'auto'} value={x.name} ref={'roleName' + x._id}/></td>
        </tr>;
      });

    return <CardTable
        header={<tr>
          <th style={{width: '30%'}}><FormattedMessage id='roles.tables.roles' /></th>
          <th style={{width: '54%'}}><FormattedMessage id='roles.tables.roleName' /></th>
        </tr>}
        footer={this.context.canModifyApps ? <button className='btn btn-dashed' onClick={this.addRole}>
          <Icon name='add' />&nbsp;Add Role
          </button> : null}>
      {roles}
    </CardTable>;
  },

  getAttributesSection() {
    let attrs = this.props.app.attributes
      .map(x => <tr key={x._id}>
        <td><ReadOnlyInput value={x.displayName} ref={'attributeDisplayName' + x._id} minHeight={'auto'} /></td>
        <td><ReadOnlyInput value={x.name} ref={'attributeName' + x._id} minHeight={'auto'} /></td>
        <td style={{textAlign: 'center'}}><Checkbox value={x.required} ref={'attributeRequired' + x._id} disabled={true}/></td>
      </tr>);
    return <CardTable
        header={
          <tr>
            <th style={{width: '42%'}}><FormattedMessage id='roles.tables.attributes' /></th>
            <th style={{width: '42%'}}><FormattedMessage id='roles.tables.attributeName' /></th>
            <th style={{textAlign: 'center'}}><FormattedMessage id='roles.tables.attributeRequired' /></th>
          </tr>
        }
        footer={this.context.canModifyApps ? <button className='btn btn-dashed' onClick={this.addAttribute}>
          <Icon name='add' />&nbsp;<FormattedMessage id='roles.addAttribute' />
          </button> : null}>
      {attrs}
    </CardTable>;
  },

  editRole(e) {
    e.preventDefault();
    browserHistory.push('/roles/' + this.props.app.id + '/edit');
  },

  render() {
    let {app} = this.props;

    return <div className={styles.showRole}>
      <AppTitle title={app.displayName} logo={app.logo} locale={this.props.locale} rolesCopy={this.props.rolesCopy} buttons={<div>
          <button className='btn'>{/*<Icon name='info' />*/}</button>
          {this.context.canModifyApps ?
            <button className='btn' onClick={this.editRole} title='Edit Roles &amp; Permissions'><Icon name='edit' /></button> : null}
      </div>} />

      {this.getPermissionsSection()}
      {this.getAttributesSection()}
      {this.getRolesSection()}
    </div>;
  },
});

export default injectIntl(View);
