import createRequest, {methodTypes} from 'helpers/request';
import {setRolesCopy} from 'redux/actions/locale';

export function getRoles() {
  return dispatch => {
    let req = createRequest('/api/Copy/Roles', {authorize: true, method: methodTypes.get});
    req
      .promise()
      .then(x => x.data)
      .then(roles => {
        dispatch(setRolesCopy(JSON.parse(roles)));
      });

    return req;
  };
}

export function saveRoles(roles) {
  return () => {
    let req = createRequest('/api/Copy/Roles', {
      authorize: true,
      method: methodTypes.post,
      body: {
        roles,
      },
    });

    req
      .promise()
      .then(x => x.data)
      .then(x => {
        console.log(x);
      });
  };
}
