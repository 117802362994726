import {connect} from 'react-redux';
import {getApps, assignGroup, removeGroup, saveApp, deleteApp} from 'api/app';
import {getGroups} from 'api/group';
import AppManagement from 'components/pages/Apps/Apps';
import {addToast} from 'redux/actions/toasts';
import {toggleModal} from 'redux/actions/modals';


import {List} from 'immutable';

function mapStateToProps({data, routing, locale, ui}) {
  return {
    apps: new List(data.get('apps').values()),
    groups: new List(data.get('groups').values()),
    deleteModalVisible: ui.getIn(['modals', 'deleteApp']),
    path: routing.locationBeforeTransitions.pathname,
    regions: locale.get('regions'),
    locale: locale.get('locale'),
    rolesCopy: locale.get('roles'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (...args) => dispatch(addToast(...args)),
    assignGroup: (...args) => dispatch(assignGroup(...args)),
    getApp: (...args) => dispatch(addToast(...args)),
    deleteApp: (id) => dispatch(deleteApp(id)),
    toggleAppDeleteModal: (...args) => dispatch(toggleModal('deleteApp', ...args)),
    getApps: () => dispatch(getApps()),
    getGroups: (...args) => dispatch(getGroups(...args)),
    removeGroup: (...args) => dispatch(removeGroup(...args)),
    saveApp: (...args) => dispatch(saveApp(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppManagement);
