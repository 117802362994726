import {connect} from 'react-redux';

import CreateDealerAdmin from 'components/pages/Dealers/CreateAdmin/CreateAdmin';

import {getGroup, saveNewDealerAdmin} from 'api/group';
import {checkEmailUsed} from 'api/user';
import {addToast} from 'redux/actions/toasts';

function mapStateToProps({data, routing, locale}, {params}) {
  return {
    dealer: data.getIn(['groups', parseInt(params.id)]),
    path: routing.locationBeforeTransitions.pathname,
    possibleLocales: locale.get('possibleLocales'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (...args) => dispatch(addToast(...args)),
    checkEmailUsed: (...args) => dispatch(checkEmailUsed(...args)),
    getDealer: id => dispatch(getGroup(id)),
    save: (dealer, user) => dispatch(saveNewDealerAdmin(dealer, user)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDealerAdmin);
