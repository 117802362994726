import {connect} from 'react-redux';
import Reports from 'components/pages/Reports/Reports';

import {setReportSettings} from 'redux/actions/reports';
import {toggleModal} from 'redux/actions/modals';

import {getDashboardReport, deleteInactiveUsers, deleteOrphanedUsers} from 'api/report';

function mapStateToProps({data, ui, locale}) {
  return {
    report: data.getIn(['reports', 'dashboard', 'data']),
    settings: data.getIn(['reports', 'dashboard', 'settings']),
    deleteInactiveUsersModalVisible: ui.getIn(['modals', 'deleteInactiveUsers']),
    deleteOrphanedUsersModalVisible: ui.getIn(['modals', 'deleteOrphanedUsers']),
    locale: locale.get('locale'),
    rolesCopy: locale.get('roles'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDashboardReport: (...args) => dispatch(getDashboardReport(...args)),
    updateSettings: (...args) => dispatch(setReportSettings('dashboard', ...args)),
    toggleDeleteInactiveUsersModal: (...args) => dispatch(toggleModal('deleteInactiveUsers', ...args)),
    toggleDeleteOrphanedUsersModal: (...args) => dispatch(toggleModal('deleteOrphanedUsers', ...args)),
    deleteInactiveUsers: (...args) => dispatch(deleteInactiveUsers(...args)),
    deleteOrphanedUsers: (...args) => dispatch(deleteOrphanedUsers(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
