import {connect} from 'react-redux';
import ReportsLogins from 'components/pages/Reports/Logins/Logins';
import {getLoginEventsReport} from 'api/report';
import {setReportSettings} from 'redux/actions/reports';

function mapStateToProps({data}) {
  return {
    report: data.getIn(['reports', 'loginEvents', 'data']),
    settings: data.getIn(['reports', 'loginEvents', 'settings']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLoginEventsReport: (...args) => dispatch(getLoginEventsReport(...args)),
    updateSettings: (...args) => dispatch(setReportSettings('loginEvents', ...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsLogins);
