import {connect} from 'react-redux';
import ReportsStatus from 'components/pages/Reports/Status/Status';
import {getAccountStatusReport} from 'api/report';
import {setReportSettings} from 'redux/actions/reports';

function mapStateToProps({data, locale}) {
  return {
    report: data.getIn(['reports', 'accountStatus', 'data']),
    settings: data.getIn(['reports', 'accountStatus', 'settings']),
    locale: locale.get('locale'),
    rolesCopy: locale.get('roles'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAccountStatusReport: (...args) => dispatch(getAccountStatusReport(...args)),
    updateSettings: (...args) => dispatch(setReportSettings('accountStatus', ...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsStatus);
