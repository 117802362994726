import {connect} from 'react-redux';
import ReportsUsers from 'components/pages/Reports/Users/Users';
import {setReportSettings} from 'redux/actions/reports';
import {getUsersReport} from 'api/report';

function mapStateToProps({data, locale}) {
  return {
    settings: data.getIn(['reports', 'users', 'settings']),
    report: data.getIn(['reports', 'users', 'data']),
    locale: locale.get('locale'),
    rolesCopy: locale.get('roles'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUsersReport: (...args) => dispatch(getUsersReport(...args)),
    updateSettings: (...args) => dispatch(setReportSettings('users', ...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsUsers);
