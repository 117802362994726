import generateModel from 'helpers/modelGenerator';

export default generateModel({
  id: 'ID',
  name: {value: 'Name', default: ''},
  displayName: {value: 'DisplayName', default: ''},
  value: {value: 'Value', default: ''},
  deleted: 'Deleted',
  required: {value: 'Required', default: false},
});
