import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import {Set} from 'immutable';
import Card from 'components/layout/Card/Card';
import styles from './Resets.module.scss';
import InnerForm from 'containers/layout/InnerForm';
import Results from 'components/layout/ReportsGenerics/LargeResults/LargeResults';
import TableResults from 'components/layout/ReportsGenerics/TableResults/TableResults';
import {PasswordResetsReport, ReportSettings} from 'models';

import {BarChart} from 'components/charts';
import {csvToUrl, textToCsv, arrayToCsv} from 'helpers/csv';

import {injectIntl, intlShape, FormattedMessage} from 'react-intl';

const Resets = createClass({
  displayName: 'Resets',
  mixins: [PureRenderMixin],
  propTypes: {
    report: PropTypes.instanceOf(PasswordResetsReport),
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    updateSettings: PropTypes.func.isRequired,
    getUsersReport: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  },

  contextTypes: {
    role: PropTypes.string.isRequired,
    canAccessAllDealers: PropTypes.bool.isRequired,
  },

  export() {
    let {formatMessage} = this.props.intl;
    let {settings, report} = this.props;

    let compare = report && report.compare && settings && settings.compare && settings.compare.from && settings.compare.to;

    let row = [];
    let header = [];

    header.push(formatMessage({id: 'reports.resets.title'}));
    row.push(report.totalReset);
    if (compare) {
      header.push(formatMessage({id: 'reports.filters.compare'}));
      row.push(report.compare.totalReset);
    }

    let output = [header, row];

    let csv = csvToUrl(textToCsv(arrayToCsv(output), 'export.csv'));
    window.open(csv);
  },

  render() {
    let {settings, report} = this.props;
    let {formatMessage} = this.props.intl;

    let compare = settings && report && settings.compare && report.compare && settings.compare.from && settings.compare.to;

    let summary, compareSummary;
    let data = [];
    let valueFields = ['value'];
    let dealerData = [];
    let branchData = [];
    let branches = new Set();
    let dealers = new Set();

    if (report) {
      summary = formatMessage({
        id: 'reports.resets.summary',
      }, {
        from: settings.from.format('ll'),
        to: settings.to.format('ll'),
      });

      if (compare) {
        compareSummary = formatMessage({
          id: 'reports.resets.summary',
        }, {
          from: settings.compare.from.format('ll'),
          to: settings.compare.to.format('ll'),
        });
      }

      dealers = new Set(report.resetsPerDealer.keySeq());
      branches = new Set(report.resetsPerStore.keySeq());

      data.push({
        name: formatMessage({
          id: 'reports.resets.dateRange',
        }, {
          from: settings.from.format('ll'),
          to: settings.to.format('ll'),
        }),
        value: report.totalReset,
      });
      if (!compare) {
        dealerData = report.resetsPerDealer.map((value, name) => {return {value, name};}).toArray();
        branchData = report.resetsPerStore.map((value, name) => {return {value, name};}).toArray();
      } else {
        dealers = dealers.union(new Set(report.compare.resetsPerDealer.keySeq()));
        branches = branches.union(new Set(report.compare.resetsPerStore.keySeq()));

        data.push({
          name: formatMessage({
            id: 'reports.resets.dateRange',
          }, {
            from: settings.compare.from.format('ll'),
            to: settings.compare.to.format('ll'),
          }),
          value: report.compare.totalReset,
        });

        dealerData = dealers.map(dealer => {return {value: report.resetsPerDealer.get(dealer),
            name: dealer, compare: report.compare.resetsPerDealer.get(dealer),
          };}).toArray();
        branchData = branches.map(branch => {return {value: report.resetsPerStore.get(branch),
            name: branch, compare: report.compare.resetsPerStore.get(branch),
          };}).toArray();
        valueFields.push('compare');
      }

      branchData = branchData.sort((x, y) => x.value > y.value ? -1 : 1);
      dealerData = dealerData.sort((x, y) => x.value > y.value ? -1 : 1);
    }

    return <div>
      <PageHeader title={formatMessage({id: 'reports.resets.pageHeader'})} visible={true} right={<div className={styles.exportButton}>
        <button className={'btn btn-primary'} disabled={!report} onClick={this.export}>Export</button>
        </div>} backButton={true} />
      <InnerForm run={this.props.getUsersReport} update={this.props.updateSettings} settings={this.props.settings} />
      {report ? <div>
        <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{formatMessage({id: 'reports.resets.title'})}</span></h3>}
          lightHeader={true}
          headerFontSize={16}
          style={{marginBottom: 20}} >
          <div className='row'>
            <div className='col-sm-12'>
              <BarChart data={data} />
              <div className='row'>
                <Results key={0} colSize={compare ? 'col-sm-6' : 'col-sm-12'} marginBottom={20}
                  light={false} text={summary} number={report.totalReset}
                  dealerAverage={report.totalReset / report.totalDealers} storeAverage={report.totalReset / report.totalStores}  />
                {compareSummary ? <Results key={1} colSize='col-sm-6' marginBottom={20}
                  light={false} text={compareSummary} number={report.compare.totalReset}
                  dealerAverage={report.compare.totalReset / report.compare.totalDealers}
                  storeAverage={report.compare.totalReset / report.compare.totalStores}  /> : null}
              </div>
            </div>
          </div>
        </Card>
        <div className='row'>
          {this.context.canAccessAllDealers ? <div className='col-sm-6'>
            <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{formatMessage({id: 'reports.resets.dealerTitle'})}</span></h3>}
              lightHeader={true}
              headerFontSize={16}
              minHeight={370}
              style={{marginBottom: 20}} >
              <div className='row'>
                <div className='col-sm-12'>
                  <BarChart data={dealerData.slice(0, 10)} valueField={valueFields} />
                  <TableResults header={<thead><tr>
                    <th><FormattedMessage id='reports.resets.dealer' /></th>
                    <th className='text-right'><FormattedMessage id='reports.count' />
                      <small>
                        <FormattedMessage id='reports.resets.dateRange' values={{
                          from: settings.from.format('ll'),
                          to: settings.to.format('ll'),
                        }} />
                      </small>
                    </th>
                    {compare ?
                      <th className='text-right'><FormattedMessage id='reports.count' />
                      <small>
                        <FormattedMessage id='reports.resets.dateRange' values={{
                          from: settings.compare.from.format('ll'),
                          to: settings.compare.to.format('ll'),
                        }} />
                      </small>
                      </th>
                       : null}
                     </tr></thead>}>
                      {dealerData.map(dealer => {
                        return <tr key={dealer.name}>
                          <td>{dealer.name}</td>
                          <td className='text-right'>{dealer.value || 0}</td>
                          {compare ? <td className='text-right'>{dealer.compare || 0}</td> : null}
                        </tr>;
                      })}
                  </TableResults>
                </div>
              </div>
            </Card>
          </div> : null}
          {this.context.role !== 'manager' ? <div className='col-sm-6'>
            <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{formatMessage({id: 'reports.resets.storeTitle'})}</span></h3>}
              lightHeader={true}
              headerFontSize={16}
              minHeight={370}
              style={{marginBottom: 20}} >
              <div className='row'>
                <div className='col-sm-12'>
                  <BarChart data={branchData.slice(0, 10)} valueField={valueFields} />
                  <TableResults header={<thead><tr>
                    <th><FormattedMessage id='reports.resets.store' /></th>
                    <th className='text-right'><FormattedMessage id='reports.count' />
                      <small>
                        <FormattedMessage id='reports.resets.dateRange' values={{
                          from: settings.from.format('ll'),
                          to: settings.to.format('ll'),
                        }} />
                      </small>
                    </th>
                    {compare ?
                      <th className='text-right'><FormattedMessage id='reports.count' />
                        <small>(\
                          <FormattedMessage id='reports.resets.dateRange' values={{
                            from: settings.compare.from.format('ll'),
                            to: settings.compare.to.format('ll'),
                          }} />
                        </small>
                      </th>
                       : null}
                     </tr></thead>}>
                      {branchData.map(store => {
                        return <tr key={store.name}>
                          <td>{store.name}</td>
                          <td className='text-right'>{store.value || 0}</td>
                          {compare ? <td className='text-right'>{store.compare || 0}</td> : null}
                        </tr>;
                      })}
                  </TableResults>
                </div>
              </div>
            </Card>
          </div> : null}
        </div>
      </div> : null}
    </div>;
  },
});

export default injectIntl(Resets);
