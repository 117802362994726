import {List} from 'immutable';
import {GroupModel} from 'models';
import {groupTypes} from 'helpers/constants';

/**
 * Gets users permissions in the specified app.
 * @param  {AppModel} app  App we are interested in.
 * @param  {UserModel} user User to lookup permissions.
 * @return {List}      List of permissions the user has in the app.
 */
export function getUsersPermissions(app, user) {
  let currentApp = user.apps.find(x => x.id === app.id);
  if (currentApp) {
    return currentApp.assignedPermissions;
  }
  return new List();
}

export function getBranchPermissions(user, app, branchID) {
  let currentApp = _findOrFail(user, 'apps', app);
  if (!currentApp) {
    return new List();
  }

  let branch = _findOrFail(currentApp, 'assigned', {id: branchID});
  if (!branch) {
    return new List();
  }

  return GroupModel.flatten(branch.children).filter(x => x.type === groupTypes.permission);
}

/**
 * Gets user's roles in the specified app.
 * @param  {AppModel} app  App we are interested in.
 * @param  {UserModel} user User we are interested in.
 * @return {List}      List of roles the user has in the app (should be 1).
 */
export function getUsersRoles(app, user) {
  let currentApp = user.apps.find(x => x.id === app.id);
  if (currentApp) {
    return currentApp.assignedRoles;
  }
  return new List();
}

/**
 * Gets the list of permissions for an app that a user can currently assign.
 * @param  {AppModel} app                The app we want the assignable permissions for.
 * @param  {List} currentPermissions List of permissions that the user currently has.
 * @param {bool} allPermissions Override for whether user has access to all permissions or just their own.
 * @return {List}                    List of permissions the user is allowed to assign.
 */
export function getAssignablePermissions(app, currentPermissions, allPermissions = false) {
  return allPermissions ?
    app.permissions.map(x => x.id) :
    currentPermissions
      .filter(x => x.transferable)
      .map(x => x.id);
}

/**
 * Get's the highest role a user has from a given list.
 * @param  {List} roles List of roles the user has.
 * @return {GroupModel}       The role with the highest priority from the list or a new GroupModel if the list is empty.
 */
export function getRole(roles) {
  return roles.sort((x, y) => x.priority > y.priority ? -1 : 1).first() || new GroupModel();
}

/**
 * Gets the highest role a user has in the given app.
 * @param  {AppModel} app  The app in question.
 * @param  {UserModel} user The user in question.
 * @return {GroupModel}      The highest role (or new GroupModel) the user has in the app.
 */
export function getUsersRole(app, user) {
  return getRole(getUsersRoles(app, user));
}


/**
 * Adds a role/branch/permission to a user under a certain app in the proper nested way.
 * @param {UserModel}    user  The user in question.
 * @param {AppModel}    app   The app the permission/branch/role should be assigned to.
 * @param {...GroupModel} chain Array of permissions/branches/roles that the user should be assigned to as a hierarchy.
 * @return {UserModel} The updated user with the proper nested structure assigned to it.
 */
export function addChild(user, app, ...chain) {
  let userApp = _findOrFallback(user, 'apps', app);
  chain = new List(chain);
  if (chain.size === 0) {
    userApp = app;
  } else {
    userApp = _addChain(userApp, chain);
  }
  user = user.update('apps', a => a.filter(x => x.id !== app.id).push(userApp));
  return user;
}

/**
 * Wraps recursive setting for chain to accomodate differences since an app is the parent which has a different structure.
 * @param {AppModel} app   App to set the chain on.
 * @param {List}   chain List of permissions, roles, and branches to nest under the app assigned.
 * @return {AppModel} The updated app with the nested version of chain merged into it.
 */
function _addChain(app, chain = new List()) {
  let first = chain.first();
  if (chain.size > 1) {
    first = _findOrFallback(app, 'assigned', first);
    chain = chain.shift();
  }
  let child = _setChain(chain, first);
  if (first !== child) {
    first = first.update('children', assigned => assigned.filter(x => x.id !== child.id).push(child));
  }
  app = app.update('assigned', assigned => assigned.filter(x => x.id !== first.id).push(first));
  return app;
}

/**
 * Recursively sets chain as a nested structure, looking at superParent to try and grab existing elements before taking it from the chain.
 * @param {List}   chain       List of permissions, branches, and roles to set on the user.
 * @param {GroupModel} superParent Element to look for the parent from before grabbing the first element of the chai.
 * @return {GroupModel} The group with nested properties if any were set.
 */
function _setChain(chain = new List(), superParent = undefined) {
  if (chain.size > 0) {
    let parent = _findOrFallback(superParent, 'children', chain.first());
    chain = chain.shift();
    let child = _setChain(chain, parent); // Recursively call _setChain to fetch children and set them in order.
    if (child !== parent) {
      parent = parent.update('children', children => children.filter(x => x.id !== child.id).push(child));
    }
    return parent;
  }
  return superParent; // Return super parent for empty list
}

/**
 * Looks for an element on the parent with matching id to object before falling back to object.
 * @param  {object} parent   A JS object to search for the property from (can be Immutable record like here).
 * @param  {string} property A string with the name of the property to search from parent.
 * @param  {object} object   A JS object with an ID property that should match against the parent[property]. Falls back to this if searching fails.
 * @return {object}          The matched object.
 */
function _findOrFallback(parent, property, object) {
  return parent && parent[property] ? (parent[property].find(x => x.id === object.id) || object) : object;
}

function _findOrFail(parent, property, object) {
  return parent && parent[property] ? (parent[property].find(x => x.id === object.id) || null) : null;
}

export function removeChild(user, app, ...chain) {
  let userApp = _findOrFail(user, 'apps', app);
  if (userApp !== null) {
    chain = new List(chain);
    if (chain.size === 0) { // No chain so remove app
      user = user.update('apps', a => a.filter(x => x.id !== app.id));
    } else { // Else update chain with removed node
      userApp = _removeChain(userApp, chain);
      user = user.update('apps', a => a.filter(x => x.id !== app.id).push(userApp));
    }
  }
  return user;
}

/**
 * Wraps recursive deleting of chain to accomodate differences since an app is the parent which has a different structure.
 * @param {AppModel} app   App to set the chain on.
 * @param {List}   chain List of permissions, roles, and branches to nest under the app assigned wtih at least 1 member.
 * @return {AppModel} The updated app with the nested version of chain merged into it.
 */
function _removeChain(app, chain = new List()) {
  let first = _findOrFail(app, 'assigned', chain.first());
  chain = chain.shift();

  if (first === null) { // First element not in app so don't need to worry
    return app;
  }

  if (chain.size === 0) { // No chain so filter out the first item (branch/role)
    app = app.update('assigned', assigned => assigned.filter(x => x.id !== first.id));
    return app;
  }

  if (chain.size === 1) {
    first = first.update('children', children => children.filter(x => x.id !== chain.first().id));
  } else {
    let child = _clearChain(chain, first);

    if (child === NOT_IN_CHAIN) {
      return app;
    }

    first = first.update('children', assigned => assigned.filter(x => x.id !== child.id).push(child));
  }

  app = app.update('assigned', assigned => assigned.filter(x => x.id !== first.id).push(first));
  return app;
}

const NOT_IN_CHAIN = 'NOT_IN_CHAIN';
const REMOVE_FROM_CHAIN = 'REMOVE_FROM_CHAIN';

/**
 * Recursively goes through chain and deletes the final method in chain if it's present, looking at superParent to try
 * and grab existing elements before taking it from the chain.
 * @param {List}   chain       List of permissions, branches, and roles to set on the user.
 * @param {GroupModel} superParent Element to look for the parent from before grabbing the first element of the chai.
 * @return {GroupModel} The group with nested properties if any were set.
 */
function _clearChain(chain = new List(), superParent = undefined) {
  if (chain.size === 0) {
    return REMOVE_FROM_CHAIN;
  }

  let parent = _findOrFail(superParent, 'children', chain.first());
  chain = chain.shift();

  if (parent === null) {
    return NOT_IN_CHAIN;
  }

  if (chain.size === 1) {
    parent = parent.update('children', children => children.filter(x => x.id !== chain.first().id));
    return parent;
  }

  let child = _clearChain(chain, parent); // Check children recursively

  // Propogate changes up
  if (child === NOT_IN_CHAIN) {
    return child;
  }

  parent = parent.update('children', children => children.filter(x => x.id !== chain.first().id)).push(child); // Save changes for deeper down

  return parent;
}

export function getUserBranches(app, stores, currentUser, allPermissions) {
  return stores.filter(store => {
    let currentUserBranchAssigned = false;
    let currentProvisioned = currentUser.apps.find(x => x.id === app.id);
    if (currentProvisioned) {
      currentUserBranchAssigned = currentProvisioned.assigned.map(x => x.id).includes(store.id);
    }

    return !(!currentUserBranchAssigned && !allPermissions);
  });
}
