import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import {PromiseStoreMixin} from 'helpers/request';
import {Map} from 'immutable';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import SearchBar from 'components/forms/SearchBar/SearchBar';
import PaginatedTable from 'components/forms/PaginatedTable/PaginatedTable';
import Modal from 'components/layout/Modal/Modal';
import {Input} from 'components/forms';

import ValidationGroup from 'helpers/validation';
import {ToastModel, UserModel} from 'models';

import Icon from 'components/Icon/Icon';

import styles from './Dealers.module.scss';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const Dealers = createClass({
  displayName: 'Dealers',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    addToast: PropTypes.func.isRequired,
    checkSoldTo: PropTypes.func.isRequired,
    clearDealerSearch: PropTypes.func.isRequired,
    clearDeleteDealer: PropTypes.func.isRequired,
    clearInlineDealer: PropTypes.func.isRequired,
    dealers: PropTypes.instanceOf(Map).isRequired,
    dealerToDelete: PropTypes.instanceOf(Map),
    deleteDealer: PropTypes.func.isRequired,
    deleteModalVisible: PropTypes.bool.isRequired,
    hasUserSearched: PropTypes.bool.isRequired,
    hideDeleteModal: PropTypes.func.isRequired,
    inlineDealer: PropTypes.instanceOf(Map),
    params: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    saveDealer: PropTypes.func.isRequired,
    searchDealers: PropTypes.func.isRequired,
    setDeleteDealer: PropTypes.func.isRequired,
    setInlineDealer: PropTypes.func.isRequired,
    showDeleteModal: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  },

  contextTypes: {
    currentUser: PropTypes.instanceOf(UserModel).isRequired,
    canAccessAllDealers: PropTypes.bool.isRequired,
    canCreateDealers: PropTypes.bool.isRequired,
    canViewDealers: PropTypes.bool.isRequired,
    canCreateDealerAdmin: PropTypes.bool.isRequired,
  },

  getInitialState: function() {
    return {
      currentColumn: {
        column: 'displayName',
        order: 'asc',
      },
    };
  },

  componentWillMount() {
    if (!this.context.canViewDealers && !this.context.canAccessAllDealers) {
      if (this.context.currentUser && this.context.currentUser.dealer && this.context.currentUser.dealer.id) {
        browserHistory.push('/dealer/' + this.context.currentUser.dealer.id);
      } else {
        browserHistory.push('/');
      }
    }

    this.fetchData(this.props);
    this.group = new ValidationGroup(this.saveInline);
  },

  componentWillUnmount() {
    this.props.clearInlineDealer();
    this.props.clearDealerSearch();
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.params.query !== nextProps.params.query || this.props.path !== nextProps.path) {
      this.fetchData(nextProps);
    }
  },

  fetchData(props) {
    if (props.params.query || props.path === '/dealers/') {
      this.promises.add(this.props.searchDealers(props.params.query ? decodeURIComponent(props.params.query) : ''));
    }
  },

  hideDeleteModal() {
    this.props.clearDeleteDealer();
    this.props.hideDeleteModal();
  },

  showDelete(dealer) {
    return () => {
      this.props.setDeleteDealer(dealer);
      this.props.showDeleteModal();
    };
  },

  runSearch(val) {
    browserHistory.replace('/dealers/' + encodeURIComponent(val));
  },

  editDealer(id) {
    return () => {
      browserHistory.push('/dealer/' + id);
    };
  },

  changePage(num) {
    return () => {
      let currentPage = parseInt(this.props.dealers.get('CurrentPage')) || 1;
      if (currentPage !== parseInt(num) && parseInt(num) > 0 && parseInt(num) <= this.props.dealers.get('TotalPages')) {
        this.promises.add(this.props.searchDealers(this.props.params.query ? decodeURIComponent(this.props.params.query) : '', num));
      }
    };
  },

  deleteConfirm() {
    let typed = this.refs.deleteConfirmation.getValue();

    let uppercaseName = '';
    if (this.props.dealerToDelete) {
      uppercaseName = this.props.dealerToDelete.get('DisplayName').toUpperCase();
    }

    if (typed === uppercaseName) {
      this.props.hideDeleteModal();
      this.promises.add(this.props.deleteDealer(this.props.dealerToDelete.get('ID')))
        .promise()
        .then(() => {
          this.props.clearDealerSearch();
          browserHistory.push('/dealers');
        });
    }
  },

  setInlineDealer(dealer) {
    return () => {
      this.props.setInlineDealer(dealer);
    };
  },

  addDealer() {
    browserHistory.push('/dealer/new');
  },

  saveInline() {
    let dealer = this.props.inlineDealer;
    if (dealer && this.refs.SoldTo && this.refs.DisplayName) {
      dealer = dealer.set('SoldTo', this.refs.SoldTo.getValue());
      dealer = dealer.set('DisplayName', this.refs.DisplayName.getValue());

      this.props.addToast(new ToastModel({
        key: 'saving-inline-dealer',
        ttl: null,
        message: <FormattedMessage id='toasts.dealer.saving' />,
        closeable: true,
      }));

      this.promises.add(this.props.saveDealer(dealer))
        .promise()
        .then(x => {
          if (x.success) {
            this.props.addToast(new ToastModel({
              key: 'saved-inline-dealer',
              clears: 'saving-inline-dealer',
              ttl: 3000,
              message: <FormattedMessage id='toasts.dealer.saved' />,
              closeable: true,
            }));
            this.props.clearInlineDealer();
            this.props.clearDealerSearch();
            browserHistory.push('/dealers');
          } else {
            console.error(x);
            this.props.addToast(new ToastModel({
              key: 'saved-inline-dealer',
              clears: 'saving-inline-dealer',
              message: <FormattedMessage id='toasts.dealer.error' />,
              ttl: null,
              closeable: true,
            }));
          }
        })
        .catch(err => {
          console.error(err);
          this.props.addToast(new ToastModel({
            key: 'saved-inline-dealer',
            clears: 'saving-inline-dealer',
            message: <FormattedMessage id='toasts.dealer.error' />,
            ttl: null,
            closeable: true,
          }));
        });
    }
  },

  columnActions(colName) {
    let order = 'asc';
    if (colName === this.state.currentColumn.column) {
      if (this.state.currentColumn.order === 'asc') {
        order = 'desc';
      } else {
        order = 'asc';
      }
    }
    this.setState({
      currentColumn: {
        column: colName,
        order: order,
      },
    });
    this.promises.add(this.props.searchDealers(this.props.params.query || '', this.props.dealers.get('CurrentPage') || 1, null, colName, order));
  },

  checkSoldTo(val) {
    if (val.length <= 20 && this.props.inlineDealer) {
      return this.props.checkSoldTo(val, this.props.inlineDealer.get('ID'))
        .promise()
        .then(data => {
          return {
            valid: data && !data.data,
            message: 'Sold To # already exists.',
          };
        });
    }
    return Promise.resolve(false);
  },

  render() {
    let dealerRows = this.props.dealers.get('Data').map(x => {
      if (!this.props.inlineDealer || x.get('ID') !== this.props.inlineDealer.get('ID') || !this.context.canAccessAllDealers) {
        return <tr key={x.get('ID')} className={styles.dealerRow}>
          <td onClick={this.editDealer(x.get('ID'))}>{x.get('DisplayName')}</td>
          <td onClick={this.editDealer(x.get('ID'))}>{x.get('SoldTo')}</td>
          <td> {this.context.canAccessAllDealers ?
            <div className='pull-right'>
              <button className='btn icon-button' onClick={this.setInlineDealer(x)}><Icon name='edit' /></button>
              <button className='btn icon-button' onClick={this.showDelete(x)}><Icon name='delete' /></button>
            </div> : null}
          </td>
        </tr>;
      }
      return <tr key={x.get('ID')} className={styles.dealerRow}>
        <td>
          <Input value={x.get('DisplayName')} ref='DisplayName' required={true} group={this.group} noLabel={true} noBottomMargin={true} />
        </td>
        <td>
          <Input value={x.get('SoldTo')} ref='SoldTo' required={true} group={this.group} validate={this.checkSoldTo} limit={10}
            validationMessage='Sold To # already exists.' noLabel={true} noBottomMargin={true} />
        </td>
        <td style={{verticalAlign: 'top'}}>
          <div className='pull-right'>
            <button className='btn btn-default' onClick={this.props.clearInlineDealer} style={{marginRight: 20, verticalAlign: 'top'}}>
              <FormattedMessage id='dealers.search.cancel' />
            </button>
            <button className='btn btn-primary' onClick={this.group.validate} style={{verticalAlign: 'top'}}>
              <FormattedMessage id='dealers.search.save' />
            </button>
          </div>
        </td>
      </tr>;
    });

    let results = this.props.dealers.get('Data').size === 0 ? <p><FormattedMessage id='dealers.search.noResults' /></p> :
    <PaginatedTable totalPages={this.props.dealers.get('TotalPages')} currentPage={this.props.dealers.get('CurrentPage')} spread={5} setPage={this.changePage}>
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th width='40%'>

              <span onClick={() => this.columnActions('displayName')} >
                <FormattedMessage id='dealers.search.name' /> {this.state.currentColumn.column === 'displayName' ?
                  <Icon name={this.state.currentColumn.order === 'asc' ? 'up' : 'down' } /> : null}
              </span>
            </th>
            <th width='40%'>
              <span onClick={() => this.columnActions('soldTo')}>
                <FormattedMessage id='dealers.search.soldTo' /> {this.state.currentColumn.column === 'soldTo' ?
                  <Icon name={this.state.currentColumn.order === 'asc' ? 'up' : 'down' } /> : null}
              </span>
            </th>
            <th width='10%' />
          </tr>
        </thead>
        <tbody>
          {dealerRows}
        </tbody>
      </table>
    </PaginatedTable>;

    let uppercaseName = '';
    if (this.props.dealerToDelete) {
      uppercaseName = this.props.dealerToDelete.get('DisplayName').toUpperCase();
    }

    let {formatMessage} = this.props.intl;

    let modalValues = {
      deleteTerm: <strong>{uppercaseName}</strong>,
    };

    return <div>
      <PageHeader title={formatMessage({id: 'dealers.pageHeader'})} backButton={false} right={<div>
        <button className='btn'>{/*<Icon name='info' />*/}</button>
        </div>}/>

      <Modal visible={this.props.deleteModalVisible} close={this.hideDeleteModal} id='dealer-delete-modal' size='md'
          header={<h3><FormattedMessage id='modals.deleteDealer.header' /></h3>}
          footer={<div>
            <Input colSize='col-sm-7' ref='deleteConfirmation' value='' autoFocus
              placeholder={formatMessage({id: 'modals.deleteDealer.placeholder', values: modalValues})} />
            <button className='btn btn-primary' onClick={this.deleteConfirm}><FormattedMessage id='modals.deleteDealer.submit' /></button></div>}>
        <div>
          <p><FormattedMessage id='modals.deleteDealer.warning' /></p>
          <p><FormattedMessage id='modals.deleteDealer.instructions' values={modalValues} /></p>
        </div>
      </Modal>

      <SearchBar buttonAction={this.addDealer} runSearch={this.runSearch} query={this.props.params.query}
        canAdd={this.context.canCreateDealers}
        placeholder={formatMessage({id: 'dealers.search.instructions'})} buttonLabel={formatMessage({id: 'dealers.addDealer'})}  />
      {this.props.hasUserSearched ? results : null}
      <div className='row'>
        <div className='col-xs-12'>
          {}
        </div>
      </div>
    </div>;
  },
});

export default injectIntl(Dealers);
