import {findDOMNode} from 'react-dom';
import {List} from 'immutable';

export function isValidEmail(email) {
  let valid = false;
  if (typeof email === 'string' && email.length > 0) {
    if (email.length > 50) {
      return {valid: false, message: 'Emails must be <= 50 chars long.'};
    }
    let split = email.split('@');
    if (split.length === 2 && split[0].length > 0 && split[1].length > 0) {
      let domain = split[1].split('.');
      if (domain.length >= 2 && domain.every(x => x.length > 0)) {
        valid = true;
      }
    }
  }
  return {
    valid,
    message: 'Please enter a valid email address.',
  };
}

export default class ValidateGroup {
  constructor(submit) {
    this._components = new List();
    this.submit = submit;
    this._keybind = this._keybind.bind(this);
    this.validate = this.validate.bind(this);

    this._first = true;

    this._keysBound = {};
  }

  addComponent(ref, bindKeys = true) {
    if (ref) {
      this._components = this._components.push(ref);

      if (bindKeys) {
        this._keysBound[ref] = true;
        findDOMNode(ref).addEventListener('keypress', this._keybind); // eslint-disable-line react/no-find-dom-node
      }
    }
  }

  removeComponent(ref) {
    if (this._keysBound[ref]) {
      findDOMNode(ref).removeEventListener('keypress', this._keybind); // eslint-disable-line react/no-find-dom-node
    }
    this._components = this._components.filter(x => x !== ref);
  }

  _keybind(e) {
    if (e.which === 13) {
      this.validate();
    }
  }

  get size() {
    return this._components.size;
  }

  get first() {
    if (this.size === 0 && this._first) {
      this._first = false;
      return true;
    }
    return false;
  }

  validate() {
    let allValid = [];
    for (let ref of this._components.toArray()) {
      if (ref && ref.validate) {
        let val = ref.validate();
        if (val instanceof Promise) {
          allValid.push(val);
        } else {
          allValid.push(Promise.resolve(val));
        }
      }
    }

    Promise.all(allValid)
      .then(x => {
        for (let i = 0; i < x.length; ++i) {
          let result = x[i];
          if (result && result.valid === false) {
            let ref = this._components.get(i);
            if (ref) {
              if (ref.focus) {
                ref.focus();
              }

              break;
            }
          }
        }
        if (x.reduce((acc, y) => y.valid && acc, true)) {
          this.submit();
        }
      });
  }
}
