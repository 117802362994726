import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {Set, List} from 'immutable';
import Card from 'components/layout/Card/Card';
import {Input} from 'components/forms';
import Select from 'components/forms/Select/Select';
import {PromiseStoreMixin} from 'helpers/request';
import phoneFormat from 'helpers/phoneFormat';
import {UserModel} from 'models';
import debounce from 'lodash.debounce';
import ValidationGroup, {isValidEmail} from 'helpers/validation';
import {createTokenManager} from 'redux-oidc';
import createTokenManagerConfig from 'helpers/tokenManager';

import path from 'path';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const Profile = createClass({
  displayName: 'Profile',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    checkEmailUsed: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    saveProfile: PropTypes.func.isRequired,
    regions: PropTypes.instanceOf(List).isRequired,
    setLocale: PropTypes.func.isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
    user: PropTypes.instanceOf(UserModel),
  },

  componentWillMount() {
    this.saveChanges = debounce(this._saveChanges, 500, {
      trailing: false,
      leading: true,
    });

    this.group = new ValidationGroup(this.saveChanges);
    this.fetchData(this.props);
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.region && this.props.user && this.props.user.region && this.props.user.region !== nextProps.user.region) {
      this.setSelectedRegion(nextProps.user.region);
    }
  },

  getInitialState() {
    return {
      selectedRegion: this.props.user ? this.props.user.region : 'US',
      initialEmail: this.props.user ? this.props.user.email : '',
    };
  },

  fetchData(props) {
    this.promises.add(props.getProfile(props.params.id));
  },

  getDefaultProps() {
    return {
      user: new UserModel({}),
      regions: new List(),
      possibleLocales: new List(),
    };
  },

  checkEmailUsed(email) {
    let user = this.getUser();
    user = user.set('email', email);
    let validEmail = isValidEmail(email);
    if (!validEmail.valid) {
      return Promise.resolve(validEmail);
    }
    return this.props.checkEmailUsed(user)
      .promise()
      .then(x => {
        if ((x && x.data && x.data.ID !== user.id) || !UserModel.getValidate('email')(email)) {
          return {valid: false, message: 'Email Address already used.'};
        }
        return {valid: true};
      })
      .catch(err => {
        console.error(err);
        return {valid: false, message: 'Network error.'};
      });
  },

  getUser() {
    let user = this.props.user;
    let keys = new Set(Object.keys(this.refs)).intersect(UserModel.keys);
    for (let key of keys) {
      user = user.set(key, this.refs[key].getValue());
    }
    return user;
  },

  _saveChanges() {
    let user = this.getUser();
    this.promises.add(this.props.saveProfile(user))
      .promise()
      .then(() => {
        if (user.email !== this.state.initialEmail) {
          // Logout if change your own email address
          let config = createTokenManagerConfig();
          let manager = createTokenManager(config);

          manager.removeToken();

          let logoutPath = 'https://' + path.join(window.idp.replace('https://', ''), '/connect/endsession');

          window.location.href = logoutPath;
        }
      });
    if (user.locale !== this.props.locale) {
      this.props.setLocale(user.locale);
    }
  },


  setSelectedRegion(region) {
    this.setState({selectedRegion: region});
  },

  render() {
    let user = this.props.user;
    let {formatMessage} = this.props.intl;

    let regionOptions = {};
    let locales = {};

    let regions = this.props.regions;
    let regionLocked = false;
    if (user && user.dealer && user.dealer.region) {
      regionLocked = true;
    }

    regionOptions = regions
    .reduce((acc, x) => {
      acc[x.get('region')] = x.get('label');
      return acc;
    }, {});

    locales = this.props.possibleLocales
      .filter(x => x.get('regions').includes(this.state.selectedRegion))
      .reduce((acc, x) => {
        acc[x.get('locale')] = x.get('labels').get(x.get('locale'));
        return acc;
      }, {});

    if (!Object.keys(locales).includes(user.locale)) {
      user = user.set('locale', Object.keys(locales).length > 0 ? Object.keys(locales)[0] : 'en-US');
    }

    return <div>
      <div className='row'>
        <div className='col-sm-12'>
          <Card header={formatMessage({id: 'profile.title'})}>
            <div className='row'>
              <div className='col-md-4'>
                <Input value={user.firstName} label={formatMessage({id: 'user.fields.firstName'}) + ':'}
                  ref='firstName' autoFocus
                  group={this.group}
                  required={UserModel.isRequired('firstName')}
                  validate={UserModel.getValidate('firstName')} />
              </div>
              <div className='col-md-4'>
                <Input value={user.lastName} label={formatMessage({id: 'user.fields.lastName'}) + ':'}
                  ref='lastName' required={UserModel.isRequired('lastName')}
                  validate={UserModel.getValidate('lastName')} group={this.group} />
              </div>
              <div className='col-md-4'>
                <Input value={user.email} label={formatMessage({id: 'user.fields.email'}) + ':'}
                  ref='email' required={UserModel.isRequired('email')}
                  validate={this.checkEmailUsed} group={this.group} />
              </div>
              <div className='col-md-4'>
                <Input value={user.mobilePhone} label={formatMessage({id: 'user.fields.mobilePhone'}) + ':'}
                    ref='mobilePhone' format={phoneFormat}
                    required={UserModel.isRequired('mobilePhone')} validate={UserModel.getValidate('mobilePhone')} group={this.group} />
              </div>
              <div className='col-md-4'>
                <Input value={user.alternatePhone} label={formatMessage({id: 'user.fields.alternatePhone'}) + ':'}
                    ref='alternatePhone' format={phoneFormat}
                    required={UserModel.isRequired('alternatePhone')} validate={UserModel.getValidate('alternatePhone')} group={this.group} />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <Select ref='region' label='Region:' value={user.region} options={regionOptions} onSelect={this.setSelectedRegion} disabled={regionLocked} />
              </div>
              <div className='col-md-4'>
                <Select ref='locale' label='Locale:' value={user.locale} options={locales} />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <button className='btn btn-primary pull-right' onClick={this.saveChanges} type='submit'><FormattedMessage id='profile.save' /></button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>;
  },
});

export default injectIntl(Profile);
