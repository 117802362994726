import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {browserHistory} from 'react-router';
import {Map, List} from 'immutable';

import {PromiseStoreMixin} from 'helpers/request';

import Card from 'components/layout/Card/Card';
import EditRole from './Edit/Edit';
import ViewRole from './View/View';
import ListApps from '../Apps/List/List';
import PageHeader from 'components/layout/PageHeader/PageHeader';

import {AppModel} from 'models';

import styles from './Roles.module.scss';

import {injectIntl, intlShape} from 'react-intl';

const Roles = createClass({
  displayName: 'Roles',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    addToast: PropTypes.func.isRequired,
    apps: ImmutablePropTypes.listOf(PropTypes.instanceOf(AppModel)),
    assignGroup: PropTypes.func.isRequired,
    clearAppDeleteSettings: PropTypes.func.isRequired,
    clearCurrentApp: PropTypes.func.isRequired,
    currentApp: PropTypes.instanceOf(AppModel).isRequired,
    deleteApp: PropTypes.func.isRequired,
    deleteAttributeModalVisible: PropTypes.bool.isRequired,
    deletePermissionModalVisible: PropTypes.bool.isRequired,
    deleteRoleModalVisible: PropTypes.bool.isRequired,
    deleteTargets: PropTypes.instanceOf(Map).isRequired,
    getApp: PropTypes.func.isRequired,
    getApps: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    search: PropTypes.string,
    saveApp: PropTypes.func.isRequired,
    setCurrentApp: PropTypes.func.isRequired,
    setDeleteAttribute: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    setDeletePermission: PropTypes.func.isRequired,
    setDeleteRole: PropTypes.func.isRequired,
    toggleAttributeDeleteModal: PropTypes.func.isRequired,
    togglePermissionDeleteModal: PropTypes.func.isRequired,
    toggleRoleDeleteModal: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string,
    rolesCopy: PropTypes.instanceOf(List),
  },

  getDefaultProps() {
    return {
      readOnly: false,
    };
  },

  componentWillMount() {
    this.fetchData(this.props);
    this.checkFirst(this.props);
  },


  componentWillReceiveProps(nextProps) {
    if (this.props.params.id !== nextProps.params.id) {
      this.fetchData(nextProps);
    }
    this.checkFirst(nextProps);
  },

  checkFirst(props) {
    if (!props.params.id && props.apps.size > 0) {
      browserHistory.replace('/roles/' + props.apps.first().id);
    }
  },

  fetchData(props) {
    this.promises.add(props.getApps())
      .promise()
      .then(appsRes => {
        if (props.params.id) {
          let app = AppModel.fromApiFormat(appsRes.data.find(x => x.ID === parseInt(props.params.id)));
          props.setCurrentApp(app);
        }
      });
  },

  addApp() {
    browserHistory.push('/apps/new/edit');
  },

  deleteApp(id) {
    return () => {
      this.props.deleteApp(id);
    };
  },

  deleteAttribute(id) {
    return () => {
      let attr = this.props.currentApp.attributes.find(x => x._id === id);
      if (attr.id) { // Only prompt modal for saved attributes
        this.props.setDeleteAttribute(attr);
        this.props.toggleAttributeDeleteModal(true);
      } else {
        let app = this.props.currentApp;
        app = app.update('attributes', a => a.filter(x => x._id !== id));
        this.props.setCurrentApp(app);
      }
    };
  },

  deleteRole(id) {
    return () => {
      let app = this.props.currentApp;
      let role = app.roles.find(x => x._id === id);
      if (role.id) {
        this.props.setDeleteRole(role);
        this.props.toggleRoleDeleteModal(true);
      } else {
        app = app.update('roles', r => r.filter(x => x._id !== id));
        this.props.setCurrentApp(app);
      }
    };
  },

  deletePermission(id) {
    return () => {
      let app = this.props.currentApp;
      let perm = app.permissions.find(x => x._id === id);
      if (perm.id) {
        this.props.setDeletePermission(perm);
        this.props.togglePermissionDeleteModal(true);
      } else {
        app = app.update('permissions', p => p.filter(x => x._id !== id));
        this.props.setCurrentApp(app);
      }
    };
  },

  closeModals() {
    this.props.toggleAttributeDeleteModal(false);
    this.props.togglePermissionDeleteModal(false);
    this.props.toggleRoleDeleteModal(false);
    this.props.clearAppDeleteSettings();
  },

  render() {
    let appID = this.props.params.id;

    let Inner = this.props.readOnly ? ViewRole : EditRole;

    return <div>
      <PageHeader title={this.props.intl.formatMessage({id: 'roles.pageHeader'})} backButton={false} />
      <Card header='Applications' noPadding={true} className={styles.manageGroups} style={{paddingBottom: 20}} >
        <div className='row'>
          <div className='col-sm-3'>
            <ListApps rolesCopy={this.props.rolesCopy} locale={this.props.locale}
                      apps={this.props.apps} addApp={this.addApp} activeApp={this.props.currentApp} baseURL='/roles' />
          </div>
          <div className='col-sm-9'>
            <Inner
              action={this.props.search}
              addToast={this.props.addToast}
              app={this.props.currentApp}
              assignGroup={this.props.assignGroup}
              closeModals={this.closeModals}
              deleteAttribute={this.deleteAttribute}
              deleteAttributeModalVisible={this.props.deleteAttributeModalVisible}
              deletePermission={this.deletePermission}
              deletePermissionModalVisible={this.props.deletePermissionModalVisible}
              deleteRole={this.deleteRole}
              deleteRoleModalVisible={this.props.deleteRoleModalVisible}
              deleteTargets={this.props.deleteTargets}
              new={appID === 'new'}
              saveApp={this.props.saveApp}
              updateApp={this.props.setCurrentApp}
              path={this.props.path}
              rolesCopy={this.props.rolesCopy}
              locale={this.props.locale}
            />
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(Roles);
