import React from 'react';
import {FormattedMessage} from 'react-intl';
import {ToastModel} from 'models';
import {addToast} from 'redux/actions/toasts';

export function savingProfileToast() {
  return addToast(new ToastModel({
      key: 'saving-user',
      message: <FormattedMessage id='toasts.user.saving' />,
      closeable: true,
      ttl: null,
    }));
}

export function savedProfileToast() {
  return addToast(new ToastModel({
    key: 'saved-user-success',
    message: <FormattedMessage id='toasts.user.saved' />,
    clears: 'saving-user',
    ttl: 3000,
    closeable: true,
  }));
}

export function errorProfileToast() {
  return addToast(new ToastModel({
    clears: 'saving-user',
    message: <FormattedMessage id='toasts.user.error' />,
    key: 'saved-user-failure',
  }));
}
