import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import styles from './SmallResults.module.scss';

const Result = createClass({
  displayName: 'Result',
  mixins: [PureRenderMixin],
  propTypes: {
    text: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    colSize: PropTypes.string,
  },

  getDefaultProps: function() {
    return {
      text: 'Total # of users',
      number: 0,
      colSize: 'col-sm-3',
    };
  },

  render() {
    return <div className={this.props.colSize}>
      <div className='row'>
        <div className={styles.result}>
          <div className='text-center'>
            <p className={styles.text}>
              {this.props.text}
            </p>
          </div>
          <div className='text-center'>
            <p className={styles.number}>
              {this.props.number}
            </p>
          </div>
        </div>
      </div>
    </div>;
  },
});

export default Result;
