import createRequest, {methodTypes} from 'helpers/request';
import {UserModel} from 'models';
import {setCurrentUser} from 'redux/actions/profile';
import {savingProfileToast, savedProfileToast, errorProfileToast} from './toasts/myprofile';

export function getProfile() {
  return dispatch => {
    let req = createRequest('/api/Profile', {authorize: true});
    req
      .promise()
      .then(userRes => {
        let user = UserModel.fromApiFormat(userRes.data);
        dispatch(setCurrentUser(user));
      });

    return req;
  };
}

export function saveProfile(user) {
  return dispatch => {
    dispatch(savingProfileToast());
    let req = createRequest('/api/Profile', {
      authorize: true,
      method: methodTypes.post,
      body: user.toApiFormat(),
    });

    req
      .promise()
      .then(userRes => {
        if (userRes.success) {
          dispatch(savedProfileToast());
          let newUser = UserModel.fromApiFormat(userRes.data);
          dispatch(setCurrentUser(newUser));
          return true;
        }
        return Promise.reject(userRes);
      }).catch(e => {
        console.error(e);
        dispatch(errorProfileToast());
      });

    return req;
  };
}
