import Immutable, {Map, List} from 'immutable';

import {createTokenManager} from 'redux-oidc';
import createTokenManagerConfig from 'helpers/tokenManager';

import {AppModel, GroupModel, UserModel, ReportSettings, makeCompareSettings} from 'models';

import {groupTypes} from 'helpers/constants';

import {LOCATION_CHANGE} from 'react-router-redux';

import {
  SET_APPS, SET_APP, UPDATE_TOKEN, INVALID_TOKEN, SEARCH_USERS, SET_USER, DELETE_USER, DELETE_APP,
  SAVING_APP, SAVE_APP_FAILURE, SAVE_APP_SUCCESS, CLEAR_APP_STATUS,
  SAVING_USER, SAVE_USER_SUCCESS, SAVE_USER_FAILURE, CLEAR_USER_STATUS, CLEAR_USER_SEARCH,
  SET_GROUPS, SET_GROUP, DELETE_GROUP,
  SET_ATTRIBUTE, SET_ATTRIBUTES,
  SET_DASHBOARD_INFO,
  CANCEL_USER_WIZARD, NAVIGATE_USER_WIZARD,
  SET_CURRENT_APP, CLEAR_CURRENT_APP,
  SET_DELETE_ATTRIBUTE, SET_DELETE_ROLE, SET_DELETE_PERMISSION, CLEAR_DELETE,
  SEARCH_DEALERS, CLEAR_DEALER_SEARCH,
  SET_DELETE_STORE, CLEAR_DELETE_STORE, NAVIGATE_DEALER_WIZARD,
  SET_DELETE_DEALER, CLEAR_DELETE_DEALER,
  SET_CURRENT_USER,
  SET_STORE_PROVISION_VISIBLE,
  SET_REPORT_DATA, SET_REPORT_SETTINGS,
  SET_TERMS_OF_USE, SET_PRIVACY_POLICY,
  SET_GROUP_USERS,
} from '../actions';

export default function dataReducer(state = Immutable.fromJS({
  apps: {},
  attributes: {},
  currentApp: new AppModel(), // App whose roles are currently being editted.
  currentUser: new UserModel(),
  dashboard: {
    UserAccounts: [],
  },
  delete: {
    attribute: null,
    dealer: null,
    permission: null,
    role: null,
    store: null,
  },
  groupUsers: new Map(),
  hasUserSearched: false,
  groups: {},
  status: {},
  users: {},
  userSearch: {
    TotalPages: 1,
    CurrentPage: 1,
    Data: [],
  },
  dealerSearch: {
    TotalPages: 1,
    CurrentPage: 1,
    Data: [],
  },
  dealerWizard: {
    dealer: new GroupModel({type: groupTypes.dealer}),
    user: new UserModel(),
    page: 0,
    duplicate: null,
    duplicateStore: null,
    duplicateUser: null,
  },
  userWizard: {
    user: new UserModel(),
    page: 0,
    duplicateUser: null,
  },
  userProvisioning: {
    visibleStores: {},
  },
  reports: {
    dashboard: {
      data: null,
      settings: new ReportSettings(),
    },
    users: {
      data: null,
      settings: makeCompareSettings(),
    },
    usersModified: {
      data: null,
      settings: makeCompareSettings(),
    },
    lockEvents: {
      data: null,
      settings: makeCompareSettings(),
    },
    accountStatus: {
      data: null,
      settings: makeCompareSettings(),
    },
    loginEvents: {
      data: null,
      settings: makeCompareSettings(),
    },
    passwordResets: {
      data: null,
      settings: makeCompareSettings(),
    },
    emails: {
      data: null,
      settings: makeCompareSettings(),
    },
  },
  previousPage: '',
  currentPage: '',
  info: {
    privacyPolicy: null,
    termsOfUse: null,
  },
}), action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      let newState = state.set('previousPage', state.get('currentPage'));
      newState = newState.set('currentPage', action.payload.pathname);
      return newState;
    }
    case SET_APPS: {
      let apps = new Map();
      for (let app of action.value) {
        apps = apps.set(app.id, app);
      }
      return state.mergeIn(['apps'],  apps);
    }
    case SET_CURRENT_USER: {
      return state.set('currentUser', action.value);
    }
    case SET_APP: {
      return state.setIn(['apps', parseInt(action.value.id)], action.value);
    }
    case DELETE_APP: {
      return state.deleteIn(['apps', parseInt(action.value)]);
    }
    case SAVING_APP: {
      return state.setIn(['status', 'app'], 'saving');
    }
    case SAVE_APP_SUCCESS: {
      return state.setIn(['status', 'app'], 'success');
    }
    case SAVE_APP_FAILURE: {
      return state.setIn(['status', 'app'], 'fail');
    }
    case CLEAR_APP_STATUS: {
      return state.setIn(['status', 'app'], null);
    }

    case SEARCH_USERS: {
      return state.set('userSearch', action.value).set('hasUserSearched', true);
    }
    case CLEAR_USER_SEARCH: {
      return state.set('userSearch', new Map({
        Data: new List(),
        CurrentPage: 1,
        TotalPages: 1,
      })).set('hasUserSearched', false);
    }

    case SEARCH_DEALERS: {
      return state.set('dealerSearch', action.value).set('hasUserSearched', true);
    }
    case CLEAR_DEALER_SEARCH: {
      return state.set('dealerSearch', new Map({
        Data: new List(),
        CurrentPage: 1,
        TotalPages: 1,
      })).set('hasUserSearched', false);
    }

    case SET_USER: {
      return state.setIn(['users', action.value.id], action.value);
    }
    case DELETE_USER: {
      return state.deleteIn(['users', action.value]);
    }
    case SAVING_USER: {
      return state.setIn(['status', 'user'], 'saving');
    }
    case SAVE_USER_SUCCESS: {
      return state.setIn(['status', 'user'], 'success');
    }
    case SAVE_USER_FAILURE: {
      return state.setIn(['status', 'user'], 'fail');
    }
    case CLEAR_USER_STATUS: {
      return state.setIn(['status', 'user'], null);
    }

    case SET_GROUPS: {
      let groups = new Map();
      for (let g of action.value) {
        groups = groups.set(g.id, g);
      }

      return state.setIn(['groups'], groups);
    }
    case SET_GROUP: {
      return state.setIn(['groups', action.value.id], action.value);
    }
    case DELETE_GROUP: {
      return state.deleteIn(['groups', action.value]);
    }

    case SET_ATTRIBUTES: {
      let attrs = new Map();
      for (let a of action.value) {
        attrs = attrs.set(a.id, a);
      }

      return state.setIn(['attributes'], attrs);
    }
    case SET_ATTRIBUTE: {
      return state.setIn(['attributes', action.value.id], action.value);
    }

    case SET_DASHBOARD_INFO: {
      return state.setIn(['dashboard'], action.value);
    }

    case NAVIGATE_USER_WIZARD: {
      return state.setIn(['userWizard', 'page'], action.value)
        .setIn(['userWizard', 'user'], action.user)
        .setIn(['userWizard', 'duplicateUser'], action.user);
    }

    case NAVIGATE_DEALER_WIZARD: {
      let newState = state.setIn(['dealerWizard', 'page'], action.value)
        .setIn(['dealerWizard', 'dealer'], action.dealer)
        .setIn(['dealerWizard', 'duplicate'], action.duplicate)
        .setIn(['dealerWizard', 'duplicateStore'], action.duplicateStore)
        .setIn(['dealerWizard', 'duplicateUser'], action.duplicateUser);

      if (action.user !== undefined) {
        newState = newState.setIn(['dealerWizard', 'user'], action.user);
      } else {
        newState = newState.setIn(['dealerWizard', 'user', 'locale'], action.dealer.locale)
          .setIn(['dealerWizard', 'user', 'region'], action.dealer.region);
      }
      return newState;
    }

    case CANCEL_USER_WIZARD: {
      return state.setIn(['userWizard', 'page'], 0)
        .setIn(['userWizard', 'user'], new UserModel());
    }

    case SET_CURRENT_APP: {
      return state.set('currentApp', action.value);
    }

    case CLEAR_CURRENT_APP: {
      return state.set('currentApp', new AppModel());
    }

    case SET_DELETE_ROLE: {
      return state.setIn(['delete', 'role'], action.value);
    }

    case SET_DELETE_ATTRIBUTE: {
      return state.setIn(['delete', 'attribute'], action.value);
    }

    case SET_DELETE_PERMISSION: {
      return state.setIn(['delete', 'permission'], action.value);
    }

    case SET_DELETE_DEALER: {
      return state.setIn(['delete', 'dealer'], action.value);
    }

    case CLEAR_DELETE_DEALER: {
      return state.setIn(['delete', 'dealer'], null);
    }

    case SET_DELETE_STORE: {
      return state.setIn(['delete', 'store'], action.value);
    }

    case CLEAR_DELETE_STORE: {
      return state.setIn(['delete', 'store'], null);
    }

    case CLEAR_DELETE: {
      return state.deleteIn(['delete', 'attribute'])
        .deleteIn(['delete', 'role'])
        .deleteIn(['delete', 'permission']);
    }

    case SET_STORE_PROVISION_VISIBLE: {
      return state.setIn(['userProvisioning', 'visibleStores', action.value], action.visible);
    }

    case SET_REPORT_DATA: {
      return state.setIn(['reports', action.report, 'data'], action.value);
    }

    case SET_REPORT_SETTINGS: {
      return state.setIn(['reports', action.report, 'settings'], action.value);
    }

    case SET_TERMS_OF_USE: {
      return state.setIn(['info', 'termsOfUse'], {__html: action.value});
    }

    case SET_PRIVACY_POLICY: {
      return state.setIn(['info', 'privacyPolicy'], {__html: action.value});
    }

    case SET_GROUP_USERS: {
      return state.setIn(['groupUsers', parseInt(action.groupID)], action.users);
    }

    default: {
      return state;
    }
  }
}

const manager = createTokenManager(createTokenManagerConfig());
export function tokenReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_TOKEN: {
      let newState = {
        idToken: manager['id_token'],
        valid: !manager.expired,
        profile: manager.profile,
      };
      return newState;
    }
    case INVALID_TOKEN: {
      let newState = {
        idToken: null,
        valid: false,
        profile: {},
      };
      return newState;
    }
    default: {
      return state;
    }
  }
}
