export function langMapMatcher(array, data) {
  let translatedDesc = {
      'en-US': null,
      'fr-CA': null,
    };
  array.forEach((x) => {
    for (let key in x) {
      if (!x.hasOwnProperty(key)) {
        continue;
      }

      if (x[key] === data) {
        translatedDesc = x;
      }
    }
  });
  return translatedDesc;
}

export function langImageMatcher(img, imgf, lang) {
  if (lang === 'fr-CA' || lang === 'fr') {
    if (imgf) {
      return imgf;
    }

    return img;
  }

  return img;
}
