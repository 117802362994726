import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import styles from './LargeResults.module.scss';
import {List} from 'immutable';

import {FormattedMessage} from 'react-intl';

const Result = createClass({
  displayName: 'Result',
  mixins: [PureRenderMixin],
  propTypes: {
    bottom: PropTypes.bool,
    textGray: PropTypes.bool,
    light: PropTypes.bool,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    minHeight: PropTypes.number,
    icon: PropTypes.string,
    text: PropTypes.string,
    number: PropTypes.number,
    footer: PropTypes.instanceOf(List),
    storeAverage: PropTypes.number,
    dealerAverage: PropTypes.number,
    colSize: PropTypes.string,
    fillBottomSpace: PropTypes.bool,
  },

  contextTypes: {
    role: PropTypes.string.isRequired,
    canAccessAllDealers: PropTypes.bool.isRequired,
  },

  getDefaultProps: function() {
    return {
      text: '',
      number: 0,
      footer: new List(),
      textGray: false,
      light: false,
    };
  },

  render() {
    let {number, text, footer} = this.props;

    let storeAverage = this.props.storeAverage !== null && this.props.storeAverage !== undefined
        && this.context.role !== 'manager' ? this.props.storeAverage : null;
    let dealerAverage = this.props.dealerAverage !== null && this.props.dealerAverage !== undefined
        && this.context.canAccessAllDealers ? this.props.dealerAverage : null;

    let bottomSection = null;
    if (footer.size === 0 && ((storeAverage !== null && storeAverage !== undefined) || (dealerAverage !== null && dealerAverage !== undefined))) {
      bottomSection = <div className='row'>
        <div className={styles.bottom + ' col-sm-12 text-center'}>
          <p>
            {dealerAverage !== null ?
              <span><FormattedMessage id='reports.averages.perDealer' /> :
                <strong>{Math.round(dealerAverage * 100) / 100}</strong></span> : null}
            {dealerAverage !== null && storeAverage !== null ?
              <span> | </span> :
              null}
            {storeAverage !== null ?
              <span><FormattedMessage id='reports.averages.perStore' /> :
                <strong>{Math.round(storeAverage * 100) / 100}</strong></span> : null}
          </p>
        </div>
      </div>;
    } else if (footer.size > 0) {
      bottomSection = <div className='row'>
        <div className={styles.bottom + ' col-sm-12 text-center'}>
          <p>{footer.map((item, i) => <span key={i}>{item.get('text')}: <strong>{item.get('number')}</strong></span>).toArray()}</p>
        </div>
      </div>;
    } else if (this.props.fillBottomSpace) {
      bottomSection = <div className='row' style={{border: 0}}>
        <div className={styles.bottom + ' col-sm-12 text-center'} style={{border: 0}}>
          <p>&nbsp;</p>
        </div>
      </div>;
    }

    return <div className={this.props.colSize} style={{marginTop: this.props.marginTop, marginBottom: this.props.marginBottom}}>
      <div className={this.props.light ? styles.light + ' row' : 'row'}>
        <div className={styles.result} style={{minHeight: this.props.minHeight}}>
          <div className='row'>
            <div className='col-sm-12 text-center'>
              <p className={styles.text}>
                {text}
              </p>
            </div>
          </div>
          <div className={(this.props.textGray ? styles.textGray : null) + ' row' }>
            <div className='col-sm-12 text-center'>
              <p className={styles.number}>
                {number}
              </p>
            </div>
          </div>
          {bottomSection}
        </div>
      </div>
    </div>;
  },
});

export default Result;
