import {Map} from 'immutable';

export function conditionalPushFooter(list, value, text) {
  let output = list;
  if (value !== undefined) {
    output = output.push(new Map({
      text,
      number: value,
    }));
  }

  return output;
}
