import {
  SET_APPS, SET_APP, DELETE_APP, SAVING_APP, SAVE_APP_FAILURE, SAVE_APP_SUCCESS, CLEAR_APP_STATUS,
  SET_CURRENT_APP, CLEAR_CURRENT_APP,
  SET_DELETE_ROLE, SET_DELETE_ATTRIBUTE, SET_DELETE_PERMISSION, CLEAR_DELETE,
} from './index';

export function setApps(apps) {
  return {
    type: SET_APPS,
    value: apps,
  };
}

export function setApp(app) {
  return {
    type: SET_APP,
    value: app,
  };
}

export function removeApp(id) {
  return {
    type: DELETE_APP,
    value: id,
  };
}

export function savingApp() {
  return {
    type: SAVING_APP,
  };
}

export function savedAppSuccess() {
  return {
    type: SAVE_APP_SUCCESS,
  };
}

export function savedAppFailure() {
  return {
    type: SAVE_APP_FAILURE,
  };
}

export function clearAppStatus() {
  return {
    type: CLEAR_APP_STATUS,
  };
}

export function setCurrentApp(app) {
  return {
    type: SET_CURRENT_APP,
    value: app,
  };
}

export function clearCurrentApp() {
  return {
    type: CLEAR_CURRENT_APP,
  };
}

export function clearAppDeleteSettings() {
  return {
    type: CLEAR_DELETE,
  };
}

export function setDeleteRole(role) {
  return {
    type: SET_DELETE_ROLE,
    value: role,
  };
}

export function setDeleteAttribute(attr) {
  return {
    type: SET_DELETE_ATTRIBUTE,
    value: attr,
  };
}

export function setDeletePermission(perm) {
  return {
    type: SET_DELETE_PERMISSION,
    value: perm,
  };
}
