import {createElement, cloneElement} from 'react';

export function wrapIfString(toTest, tag, className, props) {
  let newProps = Object.assign({}, className ? {className} : {}, props);

  if (typeof toTest === 'string') {
    return createElement(tag, newProps, toTest);
  }

  if (Object.keys(newProps).length && toTest) {
    return cloneElement(toTest, Object.assign({}, newProps, toTest.props || {}));
  }

  return toTest;
}
