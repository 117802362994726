/**
 * Generate Immutable-backed models from given config objects of the following form:
 * let config = {
 * 	prop1: 'Prop1', // api name is Prop1
 * 	prop2: {value: 'Prop2', type: DATE_TYPE, required: true} // api name is Prop2, it is a date, and a required field
 * 	prop3: {value: 'Prop3', default: 'hello', // api name is Prop3, the default value is 'hello' if not specified
 * 					validate: x => {valid: x.length === 5, message: 'Length must be 5.'}, // the value must be 5 characters long exactly to pass the validate function}
 * };
 */

import Immutable, {List, Set} from 'immutable';
import murmur from 'murmurhash';

export const REQUIRED_FIELD = 'REQUIRED_FIELD';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';

const identity = x => x;

let counter = 0;
const namespace = Math.round(Math.random() * 10000000);

function generateID() {
  counter += 1;
  // Fast non-cryptographic hash
  return murmur.v3(namespace.toString() + '.' + counter.toString());
}

export let Error = Immutable.Record({
  key: '',
  value: '',
  message: '',
  type: '',
});

/**
 * Return a model class for the data specified in the config variable.
 * @param  {object} config The configuration data summarizing the name, api formatted name, whether it is required,
 * the validation function and message, the type, and the default value of the model's properties.
 * @return {class}        The class generated by the configuration data with methods to convert to and from the api
 * format, clone the object, and validate it's properties.
 */
export default function generateClass(config) {
  // All config data is split up into separate objects so can look up necessary info in constant time.
  // TODO: look into potentially combining some of this data or just generating apiToJS and using config for the rest.
  let apiToJS = {};
  let jsToApi = {};
  let defaults = {
    _id: undefined,
  };
  let toWrappers = {};
  let required = {};
  let validationFunction = {};
  let types = {};

  let computed = {};

  let keys = Object.keys(config);
  for (let key of keys) {
    let prop = config[key];

    if (typeof prop === 'string') {
      apiToJS[prop] = key;
      jsToApi[key] = prop;
    } else if (typeof prop === 'function') {
      computed[key] = prop;
    } else {
      apiToJS[prop.value] = key;
      jsToApi[key] = prop.value;
      required[key] = prop.required;
      validationFunction[key] = prop.validate;

      types[key] = prop.type;
      toWrappers[key] = prop.toApiWrapper;
    }

    // Fill defaults object with the defaults or undefined since Immutable records must have default values
    defaults[key] = prop.default;

  }

  // Generated class extended off an Immutable record
  class Model extends Immutable.Record(defaults) {
    constructor(obj) {
      if (!obj) {
        obj = {};
      }
      if (!obj._id) {
        obj._id = generateID();
      }
      super(obj);
    }

    getRaw() {
      let output = this.toJS();
      delete output._id;
      return output;
    }

    /**
     * Transcomponents/forms the Immutable record into a plain javascript object with api formatted names.
     * @return {object} Javascript object of keys and values with the names formatted in the api format.
     */
    toApiFormat() {
      let apiOutput = {};
      for (let key of keys) {
        let val = this.get(key);
        let toWrapper = toWrappers[key];
        if (toWrapper) {
          val = toWrapper(val);
        }

        if (val instanceof List) {
          if (val.size > 0 && val.every(x => x.toApiFormat)) {
            apiOutput[jsToApi[key]] = val.map(x => x.toApiFormat()).toArray();
          } else {
            apiOutput[jsToApi[key]] = val.toArray();
          }
        } else if (val && val.toApiFormat) {
          apiOutput[jsToApi[key]] = val.toApiFormat();
        } else {
          apiOutput[jsToApi[key]] = val;
        }
      }

      return apiOutput;
    }

    /**
     * Return a cloned object.
     * @return {Model} The cloned version of the current instance.
     */
    clone() {
      return new Model(this);
    }

    static get keys() {
      return new Set(keys);
    }

    static isRequired(key) {
      return !!required[key];
    }

    static getValidate(key) {
      return validationFunction[key];
    }

    static validate(key) {
      return validationFunction[key];
    }

    /**
     * Return an Immutable list of errors for the current instance.
     * @return {ImmutableList} An Immutable List of errors for the any properties on the current instance that
     * don't pass their validation function or aren't specified but are required.
     */
    validate() {
      let errors = new List();

      for (let key of keys) {
        let val = this.get(key);
        let _validate = validationFunction[key];

        if (required[key] && !val) {
          errors = errors.push(Error({key: key, type: REQUIRED_FIELD}));
        } else if (_validate && !_validate(val, this).valid) {
          errors = errors.push(new Error({
            key: key,
            type: VALIDATION_ERROR,
            value: val.valid,
            message: val.message || '',
          }));
        }
      }

      return errors;
    }

    /**
     * Return a new instance of the Model transformed from an api formatted plain object.
     * @param  {object} apiFormatted An object with api formatted properties and values.
     * @return {Model}              A new instance with the converted data from apiFormatted.
     */
    static fromApiFormat(apiFormatted = {}) {
      let jsInput = {};

      let keys = Object.keys(apiFormatted || {});
      for (let key of keys) {
        let tempVal = apiFormatted[key];
        let jsKey = apiToJS[key];
        let objType = types[jsKey];
        let wrapper = objType ?
          (objType === 'self' ? Model.fromApiFormat : // Allow self-recursion
            (objType.fromApiFormat ? objType.fromApiFormat : objType) // Allow types generated by this without having to write fromApiFormat everywhere
          )
          : identity; // Do nothing with value

        if (tempVal instanceof Array) {
          tempVal = tempVal ? new List(tempVal.map(x => wrapper(x))) : new List();
        } else {
          tempVal = tempVal !== null && tempVal !== undefined ? wrapper(tempVal) : undefined;
        }
        // Escape null and undefined values with defaults so don't screw up inputs etc.
        jsInput[jsKey] = tempVal === undefined || tempVal === null ? defaults[jsKey] : tempVal;
      }

      return new Model(jsInput);
    }
  }

  // Set getters for computed properties; write function in config to use this
  for (let calc of Object.keys(computed)) {
    Object.defineProperty(Model.prototype, calc, {
      get: function() {
        return computed[calc](this);
      },
      enumerable: false,
    });
  }

  return Model;
}
