import React, {createClass, PropTypes} from 'react';

import {UserModel} from 'models';

import Icon from 'components/Icon/Icon';

import styles from './Unlock.module.scss';

import {FormattedMessage} from 'react-intl';

const Unlock = createClass({
  displayName: 'Unlock',
  propTypes: {
    unlockUser: PropTypes.func.isRequired,
    unlockable: PropTypes.bool,
    user: PropTypes.instanceOf(UserModel).isRequired,
  },

  getDefaultProps() {
    return {
      unlockable: false,
    };
  },

  render() {
    let user = this.props.user;

    // User is not locked out
    if (!user.isLocked) {
      return null;
    }

    return <div className={styles.unlockUser}>
      <div className='row'>
        <div className='col-xs-1'>
          <Icon name='unlock' className={styles.lockIcon} />
        </div>
        <div className='col-xs-4'>
          <h3 className={styles.lockedMessage} style={{marginTop: 14}}><FormattedMessage id='user.unlock.title' /></h3>
        </div>
        <div className='col-xs-4'>
          {!user.locked ? <div style={{marginTop: 14, textAlign: 'right'}}>
            <FormattedMessage id='user.unlock.when' values={{time: user.lockoutExpiry.local().fromNow()}} />
          </div> :
          <div style={{marginTop: 14, textAlign: 'right'}}><FormattedMessage id='user.unlock.permanent' /></div>}
        </div>
        <div className='col-xs-3'>
          {this.props.unlockable ?
            <button className={'btn btn-primary ' + styles.unlockButton} onClick={this.props.unlockUser}>
              <FormattedMessage id='user.unlock.submit' />
            </button> : null}
        </div>
      </div>
    </div>;
  },
});

export default Unlock;
