// This file must be written without React or JSX so it can be included in other projects.
var qwest = require('qwest');

const image = require('../components/Icon/icons/app.svg');
const ThinkificAPIHelper = require('./thinkificAPIHelper');


// Selectors
const SELECT = '#app-select';
const DROPDOWN = '#app-select-dropdown';

// Adds a set function which makes setting the values dynamically chainable so it's easier to use in ternary statements, etc.
function Style(object) {
  this._style = object || {};
}
Style.prototype.set = function(prop, value) {
  this._style[prop] = value;
  return this;
};

const copy = {
  login: {
    enUS: 'Login',
    frCA: 'Connexion',
  },
  logout: {
    enUS: 'Logout',
    frCA: 'Déconnexion',
  },
};

function getCopy(tag, locale = 'en-US') {
  const parsedLocale = locale.replace('-', '');

  return copy[tag][parsedLocale] || copy[tag].enUS || '';
}

const dropdownStyle = {
  backgroundColor: 'white',
  display: 'none',
  minWidth: 480,
  position: 'absolute',
  right: '-22px',
  top: 55,
  zIndex: '1000',
  border: '1px',
  borderStyle: 'solid',
  borderColor: '#96a1ab',
};

const selectStyle = {
  cursor: 'pointer',
  position: 'relative',
};

const arrowTop = {
  borderStyle: 'solid',
  display: 'block',
  height: 0,
  position: 'absolute',
  width: 0,
  borderTopColor: 'transparent',
  borderRightColor: 'transparent',
  borderBottomColor: '#fff',
  borderLeftColor: 'transparent',
  borderWidth: 14,
  right: '22px',
  top: -28,
};

const arrowBorder = {
  borderStyle: 'solid',
  display: 'block',
  height: 0,
  right: '21px',
  position: 'absolute',
  width: 0,
  borderTopColor: 'transparent',
  borderRightColor: 'transparent',
  borderBottomColor: '#333',
  borderLeftColor: 'transparent',
  borderWidth: 15,
  top: -30,
};

const appsHolder = new Style({
  paddingTop: '30px',
  paddingLeft: '20px',
  paddingRight: '0px',
  overflow: 'hidden',
});

const appItem = {
  display: 'grid',
  float: 'left',
  width: '209px',
  height: '54px',
  textAlign: 'left',
  marginRight: '20px',
  marginBottom: '20px',
};

const appsLink = {
  boxShadow: '0px 0px 4px #666',
  color: '#042061',
  display: 'block',
  fontWeight: '800',
  margin: 0,
  padding: 0,
  width: 'inherit',
  textDecoration: 'none'
};

const appsHeader = {
  color: '#0093d6',
  fontSize: '21px',
  paddingTop: '20px',
  paddingLeft: '5px',
  paddingBottom: '15px',
  margin: '0',
};

const logoutBtn = {
  padding: '0 25px 25px',
  fontSize: 16,
};

// Cache innerHTML at a global level so not creating in loop when switching locales
let initialInnerHTML = undefined;

/**
 * Inserts the app selector plugin to an element with the id app-select
 *
 * @param      {string}    idmUrl       The url to the idm version to use.
 * @param      {string}    firstName    Logged user first name.
 * @param      {string}    lastName     Logged user last name.
 * @param      {string}    email        Logged user email.
 * @param      {string}    provisioned  An array of app names the user is provisioned to (from the id_token).
 * @param      {string}    current      The name of the current app. Hides this app from the list of links.
 * @param      {Function}  signOut      An optional function to sign the user out. If this is present the signout button appears if signed in.
 * @param      {Function}  signIn       An optional function to sign the user in. If this is present the signin button appears if not signed in.
 * @param      {boolean}   tokenValid   Whether the token is validly signed or not (default false).
 * @param      {boolean}   addSelect    Whether to replace the div with the standard app select icon (default false).
 * @param      {string}    locale       Locale string; determines whether to show english or french logos.
 * @returns    {undefined}
 **/
function insertAppSelector(idmUrl = 'https://ssoadmin-preprod.mybioguard.com', provisioned = [],
    current = 'user-portal', signOut = null, signIn = null, tokenValid = false, addSelect = false, locale = 'en-US', region = "US", firstName = '', lastName = '', email = '') {
  let url = idmUrl + '/api/App/Metadata?appNames=' + encodeURIComponent((provisioned || []).join(','));
  // Need to cache true to elminate cors call since otherwise qwest adds cache control headers
  qwest.get(url, null, {cache: true})
    .then((xhr, res) => res)
    .then(sites => {
      const container = document.querySelector(SELECT);
      if (container && provisioned.filter(x => x !== current).length > 0) {
        if (!initialInnerHTML) {
          initialInnerHTML = container.innerHTML;
        }

        let inner = initialInnerHTML;
        if (addSelect) {
          inner = `<div><img src=${image} title="Apps" height="30" width="30"/></div>`;
        }
        let logoutButton = signOut && tokenValid ? `<div>
            <div class='clearfix' style=${cssToString(logoutBtn)}>
              <button role='button' id='app-select-logout-button' class='logout btn btn-primary col-xs-12'>${getCopy('logout', locale)}</button>
            </div>
          </div>` : '';

        let loginButton = signIn && !tokenValid ? `<div>
          <div class='clearfix' style=${cssToString(logoutBtn)}>
            <button role='button' id='app-select-login-button' class='logout btn btn-primary col-xs-12'>${getCopy('login', locale)}</button>
          </div>
        </div>` : '';
        let restrictedSites = sites.data.filter(x => provisioned.indexOf(x.name) !== -1)
          .filter(x => x.image)
          .filter(x => x.name !== current);

        restrictedSites = validateH2KnowAccess( restrictedSites, {firstName, lastName, email});

        container.innerHTML = `<div style=${cssToString(selectStyle)}>
<div id='app-select-inner'>
  ${inner}
</div>
<div id='app-select-dropdown' style=${cssToString(dropdownStyle)}>
  <div id='arrow-top' style=${cssToString(arrowBorder)}></div>
  <div id='arrow-border' style=${cssToString(arrowTop)}></div>
  <div style=${cssToString(restrictedSites.length > 0 ? appsHolder : appsHolder.set('paddingBottom', 30).set('textAlign', 'center'))}>
    ${restrictedSites.length > 0 ? restrictedSites
      .map(x => `<div style=${cssToString(appItem)}>
            <a style=${cssToString(appsLink)} href="${x.url}">
              ${x.image ? `<img style='width:auto;height:100%; max-height:100%' src="${(locale === 'fr-CA' || locale === 'fr') && !!x.imageF ? x.imageF : x.image}"/>` : ''}
              ${!x.image && x.displayName ? `<h2 style=${cssToString(appsHeader)}>${x.displayName}</h2>` : ''}
            </a>
        </div>`)
      .join('') : 'You do not have access to any other applications at this time.'}
  </div>`
  + `${logoutButton}
  ${loginButton}
</div>
</div>`;


        let inr = document.querySelector('#app-select-inner');
        if (inr) {
          document.querySelector('#app-select-inner').addEventListener('click', toggleDropdown);
          document.querySelector('body').addEventListener('click', outsideClick);

          if (signOut && tokenValid) {
            document.querySelector('#app-select-logout-button').addEventListener('click', () => {
              signOut();
              hideDropdown();
            });
          }
          if (signIn && !tokenValid) {
            document.querySelector('#app-select-login-button').addEventListener('click', () => {
              signIn();
              hideDropdown();
            });
          }
        }

      }
    })
    .catch(err => {
      console.error(err);
    });
}

function toggleDropdown(e) {
  e.stopPropagation();
  const dropdown = document.querySelector(DROPDOWN);
  if (dropdown.style.display === 'none') {
    dropdown.style.display = 'block';
  } else {
    dropdown.style.display = 'none';
  }
}

function hideDropdown() {
  const dropdown = document.querySelector(DROPDOWN);
  if (dropdown) {
    dropdown.style.display = 'none';
  }
}

function outsideClick(e) {
  const dropdown = document.querySelector(DROPDOWN);
  if (dropdown && dropdown.style.display !== 'none' && !e.target.closest(DROPDOWN)) {
    hideDropdown();
  }
}


// Helpers
function camelCaseToHyphen(xs) {
  return xs.replace(/([A-Z]{1})/g, '-$1');
}

function isNumber(xs) {
  let match = xs.match(/-?[0-9]+[.]{0,1}[0-9]+/);
  if (match && match[0] && match[0].length) {
    return match[0].length === xs.length;
  }
  return false;
}

function cssToString(_obj) {
  let obj = _obj && _obj._style ? _obj._style : _obj;
  let keys = Object.keys(obj);
  let output = '';
  for (let key of keys) {
    let formattedKey = camelCaseToHyphen(key).toLowerCase();

    let val = obj[key].toString();
    if (isNumber(val) && formattedKey !== 'z-index' && formattedKey !== 'font-weight') {
      val = val + 'px';
    }
    output += formattedKey + ':' + val + ';';
  }
  return '"' + output + '"';
}

function validateH2KnowAccess(sites,user) {
  const educationAppIndex = sites.findIndex(app => app.name === 'education');
  if(educationAppIndex >= 0) {
    //  ALEX-2511 provide H2Know app for all users that have education app
    const token = ThinkificAPIHelper.createThinkificToken({firstName: user.firstName, lastName: user.lastName, email: user.email});
    const thinkificURL = ThinkificAPIHelper.generateThinkificLoginURL(token, 'h2know');

    // Wildfire - 2021 Program Support C03 - Hide Education app connected to Bridge  and instead connect Thinkific to the Education app
    sites[educationAppIndex] = {
      ...sites[educationAppIndex],
      name: "h2know",
      url: thinkificURL
    };
  }
  return sites;
}

module.exports = insertAppSelector;
