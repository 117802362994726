import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import Immutable, {Map, List} from 'immutable';
import moment from 'moment';

import PageHeader from 'components/layout/PageHeader/PageHeader';
import Modal from 'components/layout/Modal/Modal';
import {Input} from 'components/forms';

/*Here are the sections for the general report dashboard*/
import GeneralForm from './Landing/GeneralForm/GeneralForm';
import Provisioned from './Landing/Provisioned/Provisioned';
import AccountStatus from './Landing/AccountStatus/AccountStatus';
import LockAccountA from './Landing/LockAccountA/LockAccountA';
import UserMods from './Landing/UserMods/UserMods';
import Logins from './Landing/Logins/Logins';
import LockAccountB from './Landing/LockAccountB/LockAccountB';
import Results from 'components/layout/ReportsGenerics/LargeResults/LargeResults';
import EmailsSent from './Landing/EmailsSent/EmailsSent';
import UsersExport from './Landing/UsersExport/UsersExport';
import UserCleanup from './Landing/UserCleanup/UserCleanup';

import {DashboardReport, ReportSettings} from 'models';

import {conditionalPushFooter} from 'helpers/reportHelpers';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {getTranslatedName} from 'helpers/roles';

import {getInactiveUsersCount, getOrphanedUsersCount} from 'api/report';

const userCleanupModalStates = {
  CLOSED: 0,
  LOADING: 1,
  DEFAULT: 2,
  NO_USERS: 3,
  DELETE_IN_PROGRESS: 4,
}

const Reports = createClass({
  displayName: 'Reports',
  mixins: [PureRenderMixin],
  propTypes: {
    report: PropTypes.instanceOf(DashboardReport),
    getDashboardReport: PropTypes.func.isRequired,
    updateSettings: PropTypes.func.isRequired,
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
    rolesCopy: PropTypes.instanceOf(List).isRequired,
    toggleDeleteInactiveUsersModal: PropTypes.func.isRequired,
    toggleDeleteOrpanedUsersModal: PropTypes.func.isRequired,
    deleteInactiveUsersModalVisible: PropTypes.bool.isRequired,
    deleteOrphanedUsersModalVisible: PropTypes.bool.isRequired,
    deleteInactiveUsers: PropTypes.func.isRequired,
    deleteOrphanedUsers: PropTypes.func.isRequired,
  },

  contextTypes: {
    canAccessAllDealers: PropTypes.bool.isRequired,
    canDeleteUsers: PropTypes.bool.isRequired,
    allPermissions: PropTypes.bool.isRequired,
    token: PropTypes.object.isRequired,
    role: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      fetchingInactiveUserCount: true,
      fetchingOrphanedUserCount: true,
      inactiveUserCount: 0,
      orphanedUserCount: 0,
      deleteInactiveUsersDate: moment().subtract(2, 'years'),
      deleteInProgress: false,
    };
  },

  hideDeleteModal() {
    if(this.props.deleteInactiveUsersModalVisible){
      this.props.toggleDeleteInactiveUsersModal(false);
    }else{
      this.props.toggleDeleteOrphanedUsersModal(false);
    }
  },

  onDeleteInactiveUsersPress(){
    this.setState({fetchingInactiveUserCount: true},
        // Open the modal
        () => this.props.toggleDeleteInactiveUsersModal(true)
      );

    // Make the preview call to get the number of users that will be deleted
    getInactiveUsersCount(this.state.deleteInactiveUsersDate.format())
      .then(inactiveUserCount => {
        this.setState({
          inactiveUserCount: inactiveUserCount.count,
          deleteInProgress: inactiveUserCount.deleteInProgress,
          fetchingInactiveUserCount: false,
        });
      })
      .catch(err => {
        console.error(err);
      });
  },

  onDeleteOrphanedUsersPress(){
    this.setState({fetchingInactiveUserCount: false}, () => this.props.toggleDeleteOrphanedUsersModal(true));
    // Open the modal
    getOrphanedUsersCount()
      .then(orphanedUserCount => {
        this.setState({
          orphanedUserCount: orphanedUserCount.count,
          deleteInProgress: orphanedUserCount.deleteInProgress,
          fetchingOrphanedUserCount: false,
        });
      })
      .catch(err => {
        console.error(err);
      });
  },

  deleteConfirm() {
    let typed = this.refs.deleteConfirmation.getValue();
    if (typed === 'DELETE') {
      if(this.props.deleteInactiveUsersModalVisible){
        this.props.deleteInactiveUsers(this.state.deleteInactiveUsersDate.format());
      }else{
        this.props.deleteOrphanedUsers();
      }
      this.hideDeleteModal();
    }
  },

  render() {
    const report = this.props.report;
    let {formatMessage} = this.props.intl;

    let lockedData, roleActiveInactive;
    let resultTiles = new List();
    if (report) {
      if (this.context.canAccessAllDealers) {
        resultTiles = conditionalPushFooter(resultTiles, report.totalDealers, formatMessage({id: 'reports.dashboard.totalDealers'}));
        resultTiles = conditionalPushFooter(resultTiles, Math.round(report.totalUsers / report.totalDealers * 100) / 100,
          formatMessage({id: 'reports.dashboard.avgUsersPerDealer'}));
      }
      if (this.context.role !== 'manager') {
        resultTiles = conditionalPushFooter(resultTiles, Math.round(report.totalUsers / report.totalStores * 100) / 100,
          formatMessage({id: 'reports.dashboard.avgUsersPerStore'}));
      }


      lockedData = Immutable.fromJS([
        {
          text: formatMessage({id: 'reports.dashboard.manualLock'}),
          number: report.manuallyLockedUsers,
        },
        {
          text: formatMessage({id: 'reports.dashboard.automaticLock'}),
          number: report.autoLockedUsers,
        },
      ]);

      let roles = report.activeUsersPerRole.keySeq();
      roleActiveInactive = roles.map(r => new Map({
        text: getTranslatedName(this.props.rolesCopy, this.props.locale, r),
        active: report.activeUsersPerRole.get(r),
        inactive: report.inactiveUsersPerRole.get(r),
      })).toList();
    }

    let deleteModalValues = {
      deleteTerm: 'DELETE',
    };

    // Determine what state the user cleanup modal is in
    var modalState;
    if(!this.props.deleteInactiveUsersModalVisible && !this.props.deleteOrphanedUsersModalVisible){
      // Modal isnt open
      modalState = userCleanupModalStates.CLOSED;
    } else if((this.props.deleteInactiveUsersModalVisible && this.state.fetchingInactiveUserCount) ||
              (this.props.deleteOrphanedUsersModalVisible && this.state.fetchingOrphanedUserCount)){
      // User count is loading
      modalState = userCleanupModalStates.LOADING;
    } else if((this.props.deleteInactiveUsersModalVisible && this.state.inactiveUserCount === 0) ||
              (this.props.deleteOrphanedUsersModalVisible && this.state.orphanedUserCount === 0)){
      // No users to delete
      modalState = userCleanupModalStates.NO_USERS;
    } else if(this.state.deleteInProgress){
      // A Delete is currently in progress
      modalState = userCleanupModalStates.DELETE_IN_PROGRESS;
    } else {
      modalState = userCleanupModalStates.DEFAULT;
    }

    return <div>
      <PageHeader title={formatMessage({id: 'reports.dashboard.pageHeader'})} visible={true} backButton={false} />
      <GeneralForm results={resultTiles} settings={this.props.settings} update={this.props.updateSettings} run={this.props.getDashboardReport} />
      {this.context.allPermissions &&
        <UsersExport />
      }
      {this.context.canDeleteUsers && this.context.canAccessAllDealers &&
        <UserCleanup
          dateValue={this.state.deleteInactiveUsersDate}
          onUpdateDate={date => this.setState({deleteInactiveUsersDate: date})}
          onDeleteInactiveUsersPress={this.onDeleteInactiveUsersPress}
          onDeleteOrphanedUsersPress={this.onDeleteOrphanedUsersPress}
        />
      }

      {/* User Cleanup Modal */}
      <Modal
        visible={modalState !== userCleanupModalStates.CLOSED}
        close={this.hideDeleteModal} id='user-cleanup-modal' size='md'
        header={<h3><FormattedMessage id={this.props.deleteInactiveUsersModalVisible ? 'modals.userCleanup.deleteInactiveHeader' : 'modals.userCleanup.deleteOrphanedHeader'} /></h3>}
        calloutFooter={modalState === userCleanupModalStates.DEFAULT}
        footer={modalState == userCleanupModalStates.DEFAULT && <div>
            <Input colSize='col-sm-8' ref='deleteConfirmation' value='' autoFocus
              placeholder={formatMessage({id: 'modals.userCleanup.placeholder'}, deleteModalValues)} />
            <button className='btn btn-primary' onClick={this.deleteConfirm}>
              <FormattedMessage id='modals.userCleanup.submit'/>
            </button>
          </div>
        }
      >
        {modalState === userCleanupModalStates.DEFAULT &&
          <div>
            <p><FormattedMessage id='modals.userCleanup.warning' values={{
              userCount: this.props.deleteInactiveUsersModalVisible?
                this.state.inactiveUserCount :
                this.state.orphanedUserCount
              }}/></p>
            <p><FormattedMessage id='modals.userCleanup.instructions' values={deleteModalValues} /></p>
          </div>
        }
        {modalState === userCleanupModalStates.NO_USERS &&
          <p><FormattedMessage id='modals.userCleanup.noUsers'/></p>
        }
        {modalState === userCleanupModalStates.DELETE_IN_PROGRESS &&
          <p><FormattedMessage id='modals.userCleanup.deleteInProgress'/></p>
        }
      </Modal>

      {report ?
      <div>
        <Provisioned report={report} />
        <div className='row'>
          <Results colSize='col-sm-6' marginBottom={20} light={true} text={formatMessage({id: 'reports.dashboard.accountsIn30Days'})}
            number={report.activeUsers}
            dealerAverage={report.averageActivePerDealer} storeAverage={report.averageActivePerStore} />
          <Results colSize='col-sm-6' marginBottom={20} light={true} text={formatMessage({id: 'reports.dashboard.accountsNotIn30Days'})}
            number={report.inactiveUsers}
            dealerAverage={report.averageInctivePerDealer} storeAverage={report.averageInctivePerStore} />
        </div>
        <div className='row'>
          {this.context.role !== 'manager' ? <div className='col-sm-6'>
            <AccountStatus accountStatus={roleActiveInactive} />
          </div> : null}
          <div className='col-sm-6'>
            <LockAccountA totalLocked={report.lockedUsers} accountsLocked={lockedData} full={report.lockedOverTime} />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <UserMods usersModified={report.modifiedUsers} full={report.usersModified} settings={this.props.settings} />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <Logins successful={report.totalSuccessfulLogins} failed={report.totalFailedLogins}
              full={report.loginsPerApp}
              numDealers={report.totalDealers}
              numStores={report.totalStores}
              avgSuccess={Math.round(report.successfulLoginsPerStore.reduce((acc, x) => acc + x, 0) / report.successfulLoginsPerStore.size)} />
          </div>
        </div>
        <div className='row'>
          {this.context.canAccessAllDealers ? <div className='col-sm-5'>
            <EmailsSent emailsSent={report.emailTypesSent} />
          </div> : null}
          <div className='col-sm-7'>
            <LockAccountB resets={report.passwordResets} full={report.resetsOverTime} settings={this.props.settings} />
          </div>
        </div>
      </div> : null}
    </div>;
  },
});

export default injectIntl(Reports);
