import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import debounce from 'lodash.debounce';

import Card from 'components/layout/Card/Card';
import PasswordInput from 'components/forms/Password/Password';

import {evaluateComplexity, passwordScore} from 'helpers/passwords';
import {PromiseStoreMixin} from 'helpers/request';

import path from 'path';

import {createTokenManager} from 'redux-oidc';
import createTokenManagerConfig from 'helpers/tokenManager';

import styles from './Password.module.scss';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const Password = createClass({
  displayName: 'Password',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    changePassword: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  },

  // Using state here since need as you type functionality without spamming redux actions
  getInitialState() {
    return {
      currentPassword: '',
      password: '',
      passwordConfirm: '',
    };
  },

  componentWillMount() {
    this.submitChange = debounce(this._submitChange, 500, {
      leading: true,
      trailing: false,
    });
  },

  _submitChange(e) {
    e.preventDefault();
    let {currentPassword, password, passwordConfirm} = this.state;
    if (evaluateComplexity(password, passwordConfirm).valid) {
      this.promises.add(this.props.changePassword(currentPassword, password))
        .promise()
        .then(locked => {
          if (locked && locked.data) { // If user is locked out from too many bad password change events logout them out
            let config = createTokenManagerConfig();
            let manager = createTokenManager(config);

            manager.removeToken();
            window.location.href = 'https://' + path.join(window.idp.replace('https://', ''), '/connect/endsession');
          }
          this.replaceState(this.getInitialState());
        });
    }
  },

  dataChanged(key) {
    return (e) => {
      let state = Object.assign({}, this.state);
      state[key] = e.target.value;
      this.setState(state);
    };
  },

  render() {
    let {formatMessage} = this.props.intl;
    let {password, passwordConfirm} = this.state;
    let {message} = evaluateComplexity(password, passwordConfirm);
    let score = passwordScore(password);
    let scoreConfirm = passwordScore(passwordConfirm);

    return <div className={styles.changePassword}>
      <Card header={formatMessage({id: 'changePassword.title'})} className={styles.changePasswordCard}>
        <form onSubmit={this.submitChange}>
          {message ? <div className='row'>
            <div className='col-xs-12'>
              {message}
            </div>
          </div> : null}
          <div className='row'>
            <div className='col-xs-12'>
              <label><FormattedMessage id='changePassword.current' />:
                <PasswordInput onChange={this.dataChanged('currentPassword')} value={this.state.currentPassword} autoFocus />
              </label>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='form-group'>
                <label><FormattedMessage id='changePassword.new' />:
                  <PasswordInput onChange={this.dataChanged('password')} value={password} score={score}/>
                </label>
                <label><FormattedMessage id='changePassword.confirm' />:
                  <PasswordInput onChange={this.dataChanged('passwordConfirm')} score={scoreConfirm} value={passwordConfirm} />
                </label>
              </div>
            </div>
            <div className='col-xs-12'>
              <button className='btn btn-primary' type='submit'><FormattedMessage id='changePassword.submit' /></button>
            </div>
          </div>
        </form>
      </Card>
    </div>;
  },
});

export default injectIntl(Password);
