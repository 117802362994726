import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import debounce from 'lodash.debounce';

const SearchField = createClass({
  displayName: 'SearchField',
  mixins: [PureRenderMixin],
  propTypes: {
    placeholder: PropTypes.string,
    query: PropTypes.string,
    search: PropTypes.func.isRequired,
  },

  getDefaultProps() {
    return {
      query: '',
    };
  },

  componentWillMount() {
    this.debouncedSearch = debounce(() => {
        if (this.refs.searchQuery) {
          let val = this.refs.searchQuery.value;
          if (val && val.length < 3) {
            return;
          }
          this.search();
        }
      }, 400);
  },

  componentDidMount() {
    if (this.refs.searchQuery) {
      let rawElement = this.refs.searchQuery;
      rawElement.addEventListener('keydown', this.keyBinding);

      // Sets caret to end of input on load; necessary for IE and Firefox
      if (this.props.query.length > 0) {
        rawElement.selectionStart = this.props.query.length;
      }
    }
  },

  componentWillUnmount() {
    if (this.refs.searchQuery) {
      this.refs.searchQuery.removeEventListener('keydown', this.keyBinding);
    }
  },

  search() {
    // Get field value here instead of passing into function so that it grabs value as late as possible
    this.props.search(this.refs.searchQuery.value || '');
  },

  keyBinding(e) {
    if (e.which === 13) {
      this.search();
    } else {
      this.debouncedSearch();
    }
  },


  render() {
    return <div>
      <input type='input' className='form-control' ref='searchQuery' placeholder={this.props.placeholder} defaultValue={this.props.query} autoFocus />
    </div>;
  },
});

export default SearchField;
