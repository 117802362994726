import React, {createClass, PropTypes} from 'react';
import {PureRenderMixin} from 'helpers/shallowCompare';
import {findDOMNode} from 'react-dom';
import {browserHistory} from 'react-router';
import {List, Set, Map} from 'immutable';

import debounce from 'lodash.debounce';

import {PromiseStoreMixin} from 'helpers/request';

import {GroupModel, UserModel} from 'models';

import Card from 'components/layout/Card/Card';
import {Checkbox, Input, Select} from 'components/forms';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import ProvisionApp from '../Provisioning/ProvisionApp/ProvisionApp';
import ShowProvision from '../Provisioning/ShowProvision/ShowProvision';
import phoneFormat from 'helpers/phoneFormat';
import ValidationGroup, {isValidEmail} from 'helpers/validation';
import {groupTypes} from 'helpers/constants';

import Icon from 'components/Icon/Icon';

import './Edit.module.scss';

import {intlShape, injectIntl} from 'react-intl';

import {generateSetBranch, generateSetRole, generateSetPermission, generateSetAttribute} from 'helpers/provisioning';

const Edit = createClass({
  displayName: 'Edit',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    addToast: PropTypes.func.isRequired,
    addUserToGroup: PropTypes.func.isRequired,
    apps: PropTypes.instanceOf(List),
    checkEmailUsed: PropTypes.func.isRequired,
    dealers: PropTypes.instanceOf(Map).isRequired,
    getApps: PropTypes.func.isRequired,
    getDealers: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    removeUserFromGroup: PropTypes.func.isRequired,
    saveUser: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    toggleStoreVisible: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(UserModel),
    visibleStores: PropTypes.instanceOf(Map).isRequired,
    locale: PropTypes.string.isRequired,
    rolesCopy: PropTypes.instanceOf(List).isRequired,
    regions: PropTypes.instanceOf(List).isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
    intl: intlShape.isRequired,
  },

  contextTypes: {
    allPermissions: PropTypes.bool.isRequired,
    canChangeDealer: PropTypes.bool.isRequired,
    currentUser: PropTypes.instanceOf(UserModel).isRequired,
    isSystemAdmin: PropTypes.bool.isRequired,
    canAssignPrimary: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      selectedRegion: this.props.user ? this.props.user.region : 'US',
      selectedDealer: this.props.user && this.props.user.dealer && this.props.user.dealer.id ? this.props.user.dealer.id : '',
      userWorkingCopy: this.props.user ? this.props.user.clone() : new UserModel({}),
    };
  },

  getDefaultProps() {
    return {
      possibleLocales: new List(),
      regions: new List(),
      groups: new List(),
      user: new UserModel({}),
    };
  },

  componentWillMount() {
    this.fetchData(this.props);

    this.saveChanges = debounce(this._saveChanges, 500, {
      leading: true,
      trailing: false,
    });

    this.group = new ValidationGroup(this.saveChanges);
    this.invalidAppProvisions = [];
  },

  componentDidMount() {
    this.matchHeights();
  },

  componentDidUpdate() {
    this.matchHeights();
  },

  matchHeights() {
    if (this.refs.accountPrefs && this.refs.mainPrefs) {
      let accountPrefs = findDOMNode(this.refs.accountPrefs);
      let mainPrefs = findDOMNode(this.refs.mainPrefs);

      let height = Math.max(accountPrefs.offsetHeight, mainPrefs.offsetHeight);
      accountPrefs.style.height = height + 'px';
      mainPrefs.style.height = height + 'px';
    }
  },

  componentWillReceiveProps(nextProps) {
    // Don't want an infinite loop here
    if (this.props.params.id !== nextProps.params.id) {
      this.fetchData(nextProps);
    }

    // Check if can save this user
    if (nextProps.user) {
      let saveable = this.context.allPermissions;
      if (!this.context.allPermissions) {
        let maximumRole = GroupModel.flatten(nextProps.user.apps.filter(x => x.name === 'user-portal').reduce((acc, x) => acc.concat(x.assigned), new List()))
          .filter(x => x.type === groupTypes.role)
          .sort((x, y) => x.priority > y.priority ? -1 : 1)
          .first();
        let currentRole = GroupModel.flatten(this.context.currentUser.apps.filter(x => x.name === 'user-portal')
            .reduce((acc, x) => acc.concat(x.assigned), new List()))
          .filter(x => x.type === groupTypes.role)
          .sort((x, y) => x.priority > y.priority ? -1 : 1)
          .first();
        if (currentRole && (!maximumRole || currentRole.id === maximumRole.id || currentRole.priority > maximumRole.priority)) {
          saveable = true;
        }
      }

      if (!saveable) {
        browserHistory.push('/');
      }
      this.setState({
        userWorkingCopy: nextProps.user,
      });
    }

    if (nextProps.user.dealer !== this.props.user.dealer) {
      this.setState({
        selectedDealer: nextProps.user.dealer && nextProps.user.dealer.id ? nextProps.user.dealer.id : '',
      });
    }
  },

  fetchData(props) {
    if (props.params.id !== 'new') {
      this.promises.cleanUp();
      this.promises.add(props.getUser(props.params.id));
      this.promises.add(this.props.getDealers());
    }
    this.promises.add(props.getApps());
  },

  setStoreVisible(id) {
    return () => {
      this.props.toggleStoreVisible(id, true);
    };
  },

  setSelectedRegion(region) {
    this.setState({selectedRegion: region});
  },

  checkEmailUsed(email) {
    let user = this.props.user; // Just use this since it's faster than grabbing the entire u
    user = user.set('email', email);
    let validEmail = isValidEmail(email);
    if (!validEmail.valid) {
      return Promise.resolve(validEmail);
    }
    return this.props.checkEmailUsed(user)
      .promise()
      .then(x => {
        if ((x && x.data && x.data.ID !== user.id) || !UserModel.getValidate('email')(email)) {
          return {valid: false, message: 'Email Address already used.'};
        }
        return {valid: true};
      })
      .catch(err => {
        console.error(err);
        return {valid: false, message: 'Network error.'};
      });
  },

  getUser() {
    let user = this.props.user;

    let keys = new Set(Object.keys(this.refs)).intersect(UserModel.keys).delete('dealer');
    for (let key of keys) {
      user = user.set(key, this.refs[key].getValue());
    }

    if (this.refs.dealer) {
      let val = this.refs.dealer.getValue();
      if (val) {
        user = user.set('dealer', this.props.dealers.get(parseInt(val)));
      } else {
        user = user.delete('dealer');
      }
    }
    return user;
  },

  _saveChanges() {
    let user = this.getUser();

    this.promises.add(this.props.saveUser(user))
      .promise()
      .then(userRes => {
        let userData = userRes.data;
        if (userRes.success && userData) {
          if (this.props.params.id === 'new' && userData.ID) {
            browserHistory.push('/users/' + userData.ID);
          } else {
            browserHistory.push(this.props.path.replace('/edit', ''));
          }
        }
      });
  },

  provisionUser(app) {
    let user = this.props.user;
    user = user.updateIn(['apps'], apps => apps.push(app));
    this.props.setUser(user);
  },

  deprovisionUser(app) {
    let user = this.props.user;
    // Not dealer admins and not bioguard employees
    if (!this.context.canCreateDealerAdmin && !this.context.allPermissions) {
      let currentApp = user.get('apps').find(x => x.id === app.id);
      let currentBranches = this.context.currentUser.idmBranchIDs;
      currentApp = currentApp.update('assigned', assigned => assigned.filter(x => x.type !== groupTypes.branch || !currentBranches.includes(x.id)));
      if (currentApp.assigned.size === 0) { // If removed all access remove completely
        user = user.update('apps', apps => apps.filter(x => x.id !== currentApp.id));
      } else { // Remove only branches user has access to
        user = user.setIn(['apps'], user.get('apps').filter(x => x.get('id') !== app.get('id')).push(currentApp));
      }
    } else {
      user = user.setIn(['apps'], user.get('apps').filter(x => x.get('id') !== app.get('id')));
    }
    this.props.setUser(user);
  },

  toggleStoreVisible(id) {
    return () => {
      this.props.toggleStoreVisible(id, !this.isStoreVisible(id));
    };
  },

  isStoreVisible(id) {
    return !!this.props.visibleStores.get(id);
  },

  haveValidUserAppProvision(user, appID) {
    let provisionApp = user.apps.find(x => x.id === appID);
    let haveRoleSelected = (provisionApp && provisionApp.assignedRoles && provisionApp.assignedRoles.size > 0) || false;
    let haveStoresSelected = (provisionApp && provisionApp.assignedBranches && provisionApp.assignedBranches.size > 0) || false;

    return haveRoleSelected === haveStoresSelected;
  },

  updateUser(user) {
    this.props.setUser(user);
    this.setState({
      userWorkingCopy: user,
    });
  },

  setBranch(...args) {
    return generateSetBranch(this.state.userWorkingCopy, this.props.apps, this.updateUser)(...args);
  },

  setRole(...args) {
    return generateSetRole(this.state.userWorkingCopy, this.props.apps, this.updateUser, this.context.currentUser, this.context.allPermissions)(...args);
  },

  setPermission(...args) {
    return generateSetPermission(this.state.userWorkingCopy, this.props.apps, this.updateUser)(...args);
  },

  setAttribute(...args) {
    return generateSetAttribute(this.state.userWorkingCopy, this.updateUser)(...args);
  },

  selectDealer(val) {
    if (val && this.props.dealers && this.props.dealers.size) {
      let dealerRegion = this.props.dealers.find(x => x.id === parseInt(val)).region;
      this.setState({
        selectedRegion: dealerRegion,
        selectedDealer: val,
      });
    } else {
      this.setState({
        selectedDealer: val,
      });
    }
  },

  getAppsSection() {
    let apps = this.props.apps.sort((a, b) => a.displayName < b.displayName ? -1 : 1);
    let user = this.state.userWorkingCopy;

    if (!this.context.allPermissions) {
      let provisionedApps = this.context.currentUser.apps.map(x => x.id);
      apps = apps.filter(x => provisionedApps.includes(x.id));
    }

    if (this.context.currentUser.id === user.id && !this.context.isSystemAdmin) {
      return <div>
        <h2>My Apps</h2>
        <Card noPadding={true}>
          {apps.map(x => <ShowProvision app={x} user={user} key={x._id} />)}
        </Card>
      </div>;
    }

    return <div>
      <h2>My Apps</h2>
      <Card noPadding={true}>
        {apps.map(x => <ProvisionApp
          group={this.group}
          app={x}
          deprovisionUser={this.deprovisionUser}
          dealer={user && user.dealer && user.dealer.id ? this.props.dealers.get(user.dealer.id) : null}
          key={x._id}
          provisionUser={this.provisionUser}
          ref={x._id}
          locale={this.props.locale}
          rolesCopy={this.props.rolesCopy}
          setBranch={this.setBranch}
          setPermission={this.setPermission}
          setAttribute={this.setAttribute}
          setStoreVisible={this.setStoreVisible}
          setRole={this.setRole}
          storeVisible={this.isStoreVisible}
          toggleStore={this.toggleStoreVisible}
          user={user}
          userStored = {this.props.user}
        />)}
      </Card>
    </div>;
  },

  cancel() {
    if (this.props.params.id === 'new') {
      browserHistory.push('/user');
    } else {
      browserHistory.replace(this.props.path.replace('/edit', ''));
    }
  },

  render() {
    let user = this.props.user;
    let dealer = this.state.selectedDealer && this.props.dealers && this.props.dealers.size ?
      this.props.dealers.find(x => x.id === parseInt(this.state.selectedDealer)) :
      null;
    let {formatMessage} = this.props.intl;
    let isNewUser = this.props.params.id === 'new';

    let appsSection = isNewUser ? null : this.getAppsSection();

    let dealerColumns = [];
    let options = {'': '(none)'};
    options = Object.assign(options, this.props.dealers.map(x => x.displayName + ' / ' + x.soldTo).sort((a, b) => a < b ? -1 : 1).toJS());
    dealerColumns.push(<div className='col-sm-4' key='dealername'>
      <Select label={formatMessage({id: 'user.fields.dealer'}) + ':'}
        onSelect={this.selectDealer}
        value={dealer && dealer.id ? dealer.id : ''} options={options} ref='dealer' />
    </div>);

    let regionOptions = {};
    let locales = {};

    let regions = this.props.regions;
    let regionLocked = false;
    if (this.state.selectedDealer && dealer) {
      regionLocked = true;
    }

    regionOptions = regions
    .reduce((acc, x) => {
      acc[x.get('region')] = x.get('label');
      return acc;
    }, {});

    locales = this.props.possibleLocales
      .filter(x => x.get('regions').includes(this.state.selectedRegion))
      .reduce((acc, x) => {
        acc[x.get('locale')] = x.get('labels').get(x.get('locale'));
        return acc;
      }, {});

    if (!Object.keys(locales).includes(user.locale)) {
      user = user.set('locale', Object.keys(locales).length > 0 ? Object.keys(locales)[0] : 'en-US');
    }

    return <div>
      <PageHeader title='Users: Profile' right={<div>
        <button className='btn' onClick={this.group.validate}><Icon name='check' /></button>
        <button className='btn' onClick={this.cancel} title='Cancel'><Icon name='close' /></button>
      </div>}/>

      <div className='row'>
        <div className='col-md-8'>
          <Card ref='mainPrefs'>
            <div className='row'>
              <div className='col-sm-6'>
                <Input value={user.firstName} label={formatMessage({id: 'user.fields.firstName'}) + ':'} group={this.group}
                  ref='firstName' autoFocus={isNewUser}
                  required={UserModel.isRequired('firstName')}
                  validate={UserModel.getValidate('firstName')} />
              </div>
              <div className='col-sm-6'>
                <Input value={user.lastName} label={formatMessage({id: 'user.fields.lastName'}) + ':'} ref='lastName'
                  required={UserModel.isRequired('lastName')} group={this.group}
                  validate={UserModel.getValidate('lastName')} />
              </div>
              <div className='col-sm-6'>
                <Input value={user.email} label={formatMessage({id: 'user.fields.email'}) + ':'} ref='email' required={UserModel.isRequired('email')}
                   group={this.group} validate={this.checkEmailUsed} />
              </div>
              <div className='col-sm-6'>
                <Input value={user.mobilePhone} label={formatMessage({id: 'user.fields.mobilePhone'}) + ':'} ref='mobilePhone'
                    format={phoneFormat} group={this.group}
                    required={UserModel.isRequired('mobilePhone')} validate={UserModel.getValidate('mobilePhone')} />
              </div>
              <div className='col-sm-6'>
                <Input value={user.alternatePhone} label={formatMessage({id: 'user.fields.alternatePhone'}) + ':'} ref='alternatePhone'
                    format={phoneFormat} group={this.group}
                    required={UserModel.isRequired('alternatePhone')} validate={UserModel.getValidate('alternatePhone')} />
              </div>
              {this.context.allPermissions && this.context.canChangeDealer ? dealerColumns : null}
            </div>
          </Card>
        </div>
        <div className='col-md-4'>
          <Card header={<h2>Account Preferences</h2>} ref='accountPrefs' headerBackground='none'>
            <div className='row'>
              {this.context.canAssignPrimary && user.idmRole === 'admin' ? <div className='col-sm-12'>
                <Checkbox label={formatMessage({id: 'user.fields.primaryAccount'}) + ':'} value={user.primary} ref='primary' />
              </div> : null}
              <div className='col-sm-12'>
                <Select ref='region' label={formatMessage({id: 'user.fields.region'}) + ':'} value={this.state.selectedRegion}
                  options={regionOptions} onSelect={this.setSelectedRegion} disabled={regionLocked} />
              </div>
              <div className='col-sm-12'>
                <Select ref='locale' label={formatMessage({id: 'user.fields.language'}) + ':'} value={user.locale} options={locales} />
              </div>
            </div>
          </Card>
        </div>
      </div>

      {appsSection}
    </div>;
  },
});

export default injectIntl(Edit);
