import React, {PropTypes} from 'react';
import AmChart from '../AmChart/AmChart';

import 'amcharts/dist/amcharts/serial';

/**
 * BarChart
 * @param {Array} data - [{name: something, value: something else}, ...] - Each item in the array represents a bar
 * @param {String} [titleField='name'] - the field used to title each bar
 * @param {Array} [valueField='value'] - the field used to get the numerical value of each pie slice
 * @returns {ReactElement} React HTML
 */
const BarChart = ({
  data, titleField = 'name', valueField = ['value'], fillColorsField = 'color', verticalAxisTitle='',
  colors = ['#52b9e9', '#7b8791', '#006fba', '#043673'],
}) => {
  if (!data || data.length === 0) {
    data = [];
    let rec = {};
    rec[titleField] = ' ';
    for (let vf in valueField) {
      rec[vf] = 0;
    }
    data.push(rec);
  }
  return <AmChart config={{
    type: 'serial',
    theme: 'light',
    zoomOutButtonImage: '',
    dataProvider: (data || []).map((d, i) => Object.assign({}, d, {color: colors[i % colors.length]})),
    categoryField: titleField,
    categoryAxis: {
      gridPosition: 'start',
      labelRotation: 30,
    },
    graphs: valueField.map(x => {
      return {
        type: 'column',
        valueField: x,
        fillColorsField: fillColorsField,
        fillAlphas: 1,
        lineAlpha: 0,
      };
    }),
    chartCursor: {
      categoryBalloonEnabled: false,
      cursorAlpha: 0,
      zoomable: false,
    },
    valueAxes: [
      {
        axisAlpha: 0,
        position: 'left',
        title: verticalAxisTitle,
      },
    ],
    startDuration: 0,
  }} />;
};

BarChart.propTypes = {
  titleField: PropTypes.string,
  valueField: PropTypes.array,
  fillColorsField: PropTypes.string,
  verticalAxisTitle: PropTypes.string,
  colors: PropTypes.array,
  data: PropTypes.array.isRequired,
};

module.exports = BarChart;
