import generateModel from 'helpers/modelGenerator';
import Immutable, {Map} from 'immutable';
import moment from 'moment';

const toImmutable = x => Immutable.fromJS(x);


export const DashboardReport = generateModel({
  totalUsers: {value: 'TotalUsers', default: 0},
  totalDealers: {value: 'TotalDealers', default: undefined},
  totalStores: {value: 'TotalStores', default: undefined},
  averageUsersPerDealer: {value: 'AverageUsersPerDealer', default: undefined},
  averageUsersPerStore: {value: 'AverageUsersPerStore', default: undefined},

  activeUsers: {value: 'ActiveUsers', default: 0},
  inactiveUsers: {value: 'InactiveUsers', default: 0},
  averageActivePerDealer: {value: 'AverageActivePerDealer', default: undefined},
  averageActivePerStore: {value: 'AverageActivePerStore', default: undefined},
  averageInactivePerDealer: {value: 'AverageInactivePerDealer', default: undefined},
  averageInactivePerStore: {value: 'AverageInactverPerStore', default: undefined},

  manuallyLockedUsers: {value: 'ManuallyLockedUsers', default: 0},
  autoLockedUsers: {value: 'AutoLockedUsers', default: 0},
  passwordResets: {value: 'PasswordResets', default: 0},
  totalSuccessfulLogins: {value: 'TotalSuccessfulLogins', default: 0},
  totalFailedLogins: {value: 'TotalFailedLogins', default: 0},
  lockedUsers: {value: 'LockedUsers', default: 0},
  modifiedUsers: {value: 'ModifiedUsers', default: 0},

  usersPerRole: {value: 'UsersPerRole', type: toImmutable, default: new Map()},
  usersPerRolePerStore: {value: 'UsersPerRolePerStore', type: toImmutable, default: new Map()},
  activeUsersPerRole: {value: 'ActiveUsersPerRole', type: toImmutable, default: new Map()},
  inactiveUsersPerRole: {value: 'InactiveUsersPerRole', type: toImmutable, default: new Map()},
  successfulLoginsPerStore: {value: 'SuccessfulLoginsPerStore', type: toImmutable, default: new Map()},
  usersPerStorePerRole: {value: 'UsersPerStorePerRole', type: toImmutable, default: new Map()},

  usersModified: {value: 'UsersModified', type: toImmutable, default: new Map()},
  loginsPerApp: {value: 'LoginsPerApp', type: toImmutable, default: new Map()},
  resetsOverTime: {value: 'ResetsOverTime', type: toImmutable, default: new Map()},
  lockedOverTime: {value: 'LockedOverTime', type: toImmutable, default: new Map()},

  emailTypesSent: {value: 'EmailTypesSent', default: new Map(), type: toImmutable},
});

export const ReportSettings = generateModel({
  from: {value: 'From', default: moment().date(1).month(0), toApiWrapper: date => date && date.format ? date.format() : date},
  to: {value: 'To', default: moment(), toApiWrapper: date => date && date.format ? date.format() : date},
  soldTo: {value: 'SoldTo', default: ''},
  shipTo: {value: 'ShipTo', default: ''},
  app: {value: 'App', default: ''},
  role: {value: 'Role', default: ''},
  user: {value: 'User', default: ''},

  compare: {value: 'Compare', type: 'self'},
});

export function makeCompareSettings() {
  let output = new ReportSettings().set('compare', new ReportSettings());
  output = output.setIn(['compare', 'to'], undefined);
  output = output.setIn(['compare', 'from'], undefined);
  return output;
}

export const UsersReport = generateModel({
  usersPerApp: {value: 'UsersPerApplication', type: toImmutable, default: new Map()},
  usersPerDealer: {value: 'UsersPerDealer', type: toImmutable, default: new Map()},
  usersPerStore: {value: 'UsersPerStore', type: toImmutable, default: new Map()},
  usersPerRolePerApp: {value: 'UsersPerRolePerApp', type: toImmutable, default: new Map()},
  usersPerRolePerDealer: {value: 'UsersPerRolePerDealer', type: toImmutable, default: new Map()},
  usersPerRolePerStore: {value: 'UsersPerRolePerStore', type: toImmutable, default: new Map()},

  compare: {value: 'Compare', type: 'self'},
});

export const UsersModifiedReport = generateModel({
  totalModified: {value: 'TotalModified', default: 0},
  totalDealers: {value: 'TotalDealers', default: undefined},
  totalStores: {value: 'TotalStores', default: undefined},
  rawData: {value: 'RawData', type: toImmutable, default: new Map()},

  compare: {value: 'Compare', type: 'self'},
});

export const EmailsReport = generateModel({
  emailsSent: {value: 'EmailsSent', default: 0},
  totalDealers: {value: 'TotalDealers', default: undefined},
  totalStores: {value: 'TotalStores', default: undefined},
  emailTypesSent: {value: 'EmailTypesSent', default: new Map(), type: toImmutable},

  compare: {value: 'Compare', type: 'self'},
});

export const AccountStatusReport = generateModel({
  totalLocked: {value: 'TotalLocked', default: 0},
  totalUnlocked: {value: 'TotalUnlocked', default: 0},
  lockedPerRole: {value: 'LockedPerRole', type: toImmutable, default: new Map()},
  unlockedPerRole: {value: 'UnlockedPerRole', type: toImmutable, default: new Map()},
  totalDealers: {value: 'TotalDealers', default: undefined},
  totalStores: {value: 'TotalStores', default: undefined},

  compare: {value: 'Compare', type: 'self'},
});

export const LockEventsReport = generateModel({
  manualLocked: {value: 'ManualLocked', default: 0},
  autoLocked: {value: 'AutoLocked', default: 0},
  rawManual: {value: 'RawManual', type: toImmutable, default: new Map()},
  rawAuto: {value: 'RawAuto', type: toImmutable, default: new Map()},

  compare: {value: 'Compare', type: 'self'},
});

export const LoginEventsReport = generateModel({
  successfulLogins: {value: 'SuccessfulLogins', default: 0},
  failedLogins: {value: 'FailedLogins', default: 0},
  totalDealers: {value: 'TotalDealers', default: undefined},
  totalStores: {value: 'TotalStores', default: undefined},
  successesPerApp: {value: 'SuccessesPerApp', type: toImmutable, default: new Map()},
  failuresPerApp: {value: 'FailuresPerApp', type: toImmutable, default: new Map()},
  successesPerBranch: {value: 'SuccessesPerBranch', type: toImmutable, default: new Map()},
  successesPerDealer: {value: 'SuccessesPerDealer', type: toImmutable, default: new Map()},

  compare: {value: 'Compare', type: 'self'},
});

export const PasswordResetsReport = generateModel({
  totalReset: {value: 'TotalResets', default: 0},
  rawReset: {value: 'RawResets', type: toImmutable, default: new Map()},
  totalDealers: {value: 'TotalDealers', default: undefined},
  totalStores: {value: 'TotalStores', default: undefined},
  resetsPerStore: {value: 'ResetsPerStore', type: toImmutable, default: new Map()},
  resetsPerDealer: {value: 'ResetsPerDealer', type: toImmutable, default: new Map()},

  compare: {value: 'Compare', type: 'self'},
});
