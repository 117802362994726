export const SET_APPS = 'SET_APPS';
export const SET_APP = 'SET_APP';
export const SAVING_APP = 'SAVING_APP';
export const DELETE_APP = 'DELETE_APP';
export const SAVE_APP_SUCCESS = 'SAVE_APP_SUCCESS';
export const SAVE_APP_FAILURE = 'SAVE_APP_FAILURE';
export const CLEAR_APP_STATUS = 'CLEAR_APP_STATUS';

export const INVALID_TOKEN = 'INVALID_TOKEN';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';

export const SEARCH_USERS = 'SEARCH_USERS';
export const CLEAR_USER_SEARCH = 'CLEAR_USER_SEARCH';
export const SET_USER = 'SET_USER';
export const DELETE_USER = 'DELETE_USER';
export const SAVING_USER = 'SAVING_USER';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';
export const CLEAR_USER_STATUS = 'CLEAR_USER_STATUS';

export const SET_GROUPS = 'SET_GROUPS';
export const SET_GROUP = 'SET_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';

export const SET_ATTRIBUTES = 'SET_ATTRIBUTES';
export const SET_ATTRIBUTE = 'SET_ATTRIBUTE';

export const SET_DASHBOARD_INFO = 'SET_DASHBOARD_INFO';

export const NAVIGATE_USER_WIZARD = 'NAVIGATE_USER_WIZARD';
export const CANCEL_USER_WIZARD = 'CANCEL_USER_WIZARD';

export const ADD_TOAST = 'ADD_TOAST';
export const CLEAR_TOAST = 'CLEAR_TOAST';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export const SET_CURRENT_APP = 'SET_CURRENT_APP';
export const CLEAR_CURRENT_APP = 'CLEAR_CURRENT_APP';


export const CLEAR_DELETE = 'CLEAR_DELETE';
export const SET_DELETE_ROLE = 'SET_DELETE_ROLE';
export const SET_DELETE_ATTRIBUTE = 'SET_DELETE_ATTRIBUTE';
export const SET_DELETE_PERMISSION = 'SET_DELETE_PERMISSION';

export const CLEAR_DEALER_SEARCH = 'CLEAR_DEALER_SEARCH';
export const SEARCH_DEALERS = 'SEARCH_DEALERS';


export const SET_DELETE_DEALER = 'SET_DELETE_DEALER';
export const CLEAR_DELETE_DEALER = 'CLEAR_DELETE_DEALER';

export const SET_DELETE_STORE = 'SET_DELETE_STORE';
export const CLEAR_DELETE_STORE = 'CLEAR_DELETE_STORE';

export const NAVIGATE_DEALER_WIZARD = 'NAVIGATE_DEALER_WIZARD';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const SET_STORE_PROVISION_VISIBLE = 'SET_STORE_PROVISION_VISIBLE';

export const SET_INLINE_DEALER = 'SET_INLINE_DEALER';
export const CLEAR_INLINE_DEALER = 'CLEAR_INLINE_DEALER';

export const SET_REPORT_DATA = 'SET_REPORT_DATA';
export const SET_REPORT_SETTINGS = 'SET_REPORT_SETTINGS';

export const SET_PRIVACY_POLICY = 'SET_PRIVACY_POLICY';
export const SET_TERMS_OF_USE = 'SET_TERMS_OF_USE';

export const SET_LOCALE = 'SET_LOCALE';
export const SET_ROLES_COPY = 'SET_ROLES_COPY';
export const ADD_ROLES_COPY = 'ADD_ROLES_COPY';
export const DELETE_ROLES_COPY = 'DELETE_ROLES_COPY';

export const SET_GROUP_USERS = 'SET_GROUP_USERS';
