import {connect} from 'react-redux';

import CreateStore from 'components/pages/Dealers/CreateStore/CreateStore';

import {getGroup, saveGroup, checkShipTo} from 'api/group';
import {addToast} from 'redux/actions/toasts';

function mapStateToProps({data, routing}, {params}) {
  return {
    dealer: data.getIn(['groups', parseInt(params.id)]),
    path: routing.locationBeforeTransitions.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (...args) => dispatch(addToast(...args)),
    checkShipTo: (...args) => dispatch(checkShipTo(...args)),
    getDealer: id => dispatch(getGroup(id)),
    save: dealer => dispatch(saveGroup(dealer)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateStore);
