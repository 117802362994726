import {List} from 'immutable';
import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {Link} from 'react-router';

import {GroupModel} from 'models';

import {Input as ReadOnlyInput} from 'components/forms/ReadOnly';

import Card from 'components/layout/Card/Card';
import styles from './ShowStore.module.scss';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const ShowStore = createClass({
  displayName: 'ShowStore',
  mixins: [PureRenderMixin],
  propTypes: {
    store: PropTypes.instanceOf(GroupModel).isRequired,
    intl: intlShape.isRequired,
    getGroupUsers: PropTypes.func.isRequired,
    users: PropTypes.instanceOf(List),
  },

  componentDidMount() {
    this.props.getGroupUsers(this.props.store.id);
  },

  render() {
    let users = (this.props.users || new List()).toArray().map(x =>
      <div key={x.id} className={styles.user + ' col-md-6'}>
        <Link to={'/user/' + x.id}>{x.firstName} {x.lastName}</Link>
      </div>);

    let {formatMessage} = this.props.intl;

    return <Card minHeight={0} >
      <h4 className={styles.storeTitle}>
        {this.props.store.displayName}
      </h4>
      <div className='row'>
        <div className='col-md-4'>
          <ReadOnlyInput label={formatMessage({id: 'dealer.storeFields.name'}) + ':'} value={this.props.store.displayName} />
          <ReadOnlyInput label={formatMessage({id: 'dealer.storeFields.shipTo'}) + ':'} value={this.props.store.shipTo} />
        </div>
        <div className='col-md-8'>
          <div>
            <label><FormattedMessage id='dealer.storeFields.users' /></label>
          </div>
          <div className='row'>
            {users}
          </div>
        </div>
      </div>
    </Card>;
  },
});

export default injectIntl(ShowStore);
