import generateModel from 'helpers/modelGenerator';
import {List} from 'immutable';

import AttributeModel from './attribute';
import GroupModel from './group';
import {groupTypes} from 'helpers/constants';


export default generateModel({
  autoProvisionRegions: {value: 'AutoProvisionRegions', default: new List()},
  autoProvisionRole: {value: 'AutoProvisionRole'},
  attributes: {value: 'Attributes', default: new List(), type: AttributeModel},
  assigned: {value: 'Assigned', default: new List(), type: GroupModel}, // Used only when nested under users; all nested permissions are assigned to user
  assignedRoles: (val) => GroupModel.flatten(val.assigned).filter(x => x.type === groupTypes.role),
  assignedPermissions: (val) => GroupModel.flatten(val.assigned).filter(x => x.type === groupTypes.permission),
  assignedBranches: (val) => GroupModel.flatten(val.assigned).filter(x => x.type === groupTypes.branch),
  clientID: {value: 'ClientID', default: '', required: true},
  colour: {value: 'Colour', default: '#96a1ab', validate: validateColour, required: true},
  deleted: {value: 'Deleted', default: false},
  displayName: {value: 'DisplayName', default: '', required: true},
  flow: {value: 'Flow', default: 0},
  groups: {value: 'Groups', default: new List(), type: GroupModel},
  permissions: {value: 'Permissions', default: new List(), type: GroupModel},
  roles: {value: 'Roles', default: new List(), type: GroupModel},
  id: 'ID',
  loginUrl: {value: 'LoginUrl', default: '', required: true},
  logo: { value: 'Logo', default: '' },
  logoF: {value: 'LogoF', default: ''},
  logoutUrl: {value: 'LogoutUrl', default: '', required: true},
  name: {value: 'Name', default: '', required: true},
  provisionType: {value: 'ProvisionType', default: 0},
  provisionForAllUsers: {value: 'ProvisionForAllUsers', default: false},
  hideInIDM: {value: 'HideInIDM', default: false},
  secret: {value: 'Secret', default: ''},
  url: {value: 'URL', default: '', required: true},
  dropdownImage: {value: 'DropdownImage', default: ''},
  description: {value: 'Description', default: ''},
});

function _validateColour(colour) {
  if (typeof colour !== 'string' ||
      colour.length === 0 ||
      colour[0] !== '#' ||
      (colour.length !== 4 && colour.length !== 7)) {
    return false;
  }

  const allowedChars = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'a', 'b', 'c', 'd', 'e', 'f', 'A', 'B', 'C', 'D', 'E', 'F'];

  for (let i = 1; i < colour.length; ++i) {
    let char = colour[i];
    if (!allowedChars.includes(char)) {
      return false;
    }
  }

  return true;
}

function validateColour(colour) {
  if (_validateColour(colour)) {
    return {valid: true};
  }
  return {valid: false, message: 'Please enter a valid colour.'};
}
