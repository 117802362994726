import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {List} from 'immutable';
import {Link} from 'react-router';

import Icon from 'components/Icon/Icon';
import Profile from '../Profile/Profile';
import LocaleSwitcher from '../LocaleSwitcher/LocaleSwitcher';

import {injectIntl, intlShape} from 'react-intl';

import styles from './NavBar.module.scss';
import logo from './logo.png';
import logoFR from './logoFR.png';

function composeCSS(...args) {
  return args.join(' ');
}

const NavBar = createClass({
  displayName: 'NavBar',
  mixins: [PureRenderMixin],

  propTypes: {
    activePage: PropTypes.string,
    intl: intlShape.isRequired,
    token: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    setLocale: PropTypes.func.isRequired,
    locales: PropTypes.instanceOf(List).isRequired,
  },

  getProfile() {
    if (this.props.token && this.props.token.profile && this.props.token.profile.email) {
      return <Profile activePage={this.props.activePage} token={this.props.token} />;
    }
    return '';
  },

  render() {
    let {formatMessage} = this.props.intl;
    let {locales, locale, setLocale} = this.props;

    return <div className={composeCSS('navbar', 'navbar-static-top', styles.navbar)}>
      <div className='navbar-header' style={{float: 'left'}}>
        <Link to='/'>
          <img src={locale === 'fr-CA' || locale === 'fr' ? logoFR : logo}
               height={60}
               style={{marginLeft: 14, marginTop: 14}}
               title={formatMessage({id: 'site.title'})} />
        </Link>
      </div>
      <div className={styles.navWrapper}>
        <div className='container'>
          <div className='pull-right'>
            <LocaleSwitcher locales={locales} locale={locale} setLocale={setLocale} />
            {this.getProfile()}
            <div id='app-select' className={styles.apps}>
              <button className={'btn icon-button ' + styles.waffleButton}>
                <Icon name='app' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>;
  },
});

export default injectIntl(NavBar);
