import {connect} from 'react-redux';
import {getRoles, saveRoles} from 'api/copy';
import Copy from 'components/pages/Copy/Copy';
import {addRolesCopy, deleteRolesCopy} from 'redux/actions/locale';

function mapStateToProps({locale}) {
  return {
    copy: locale.get('roles'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    get: (...args) => dispatch(getRoles(...args)),
    save: (...args) => dispatch(saveRoles(...args)),
    add: () => dispatch(addRolesCopy()),
    delete: id => dispatch(deleteRolesCopy(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Copy);
