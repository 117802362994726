import React from 'react';
import {FormattedMessage} from 'react-intl';
import createRequest, {methodTypes} from 'helpers/request';
import {
  DashboardReport, UsersReport, UsersModifiedReport, AccountStatusReport, LockEventsReport, LoginEventsReport, PasswordResetsReport, EmailsReport, ToastModel,
} from 'models';
import {setReportData} from 'redux/actions/reports';

import {addToast} from 'redux/actions/toasts';

const fetching = new ToastModel({
  key: 'getting-report',
  message: <FormattedMessage id='toasts.report.getting' />,
  ttl: 0,
});

const fetchSuccess = new ToastModel({
  key: 'got-report',
  message: <FormattedMessage id='toasts.report.got' />,
  ttl: 2000,
  clears: fetching.key,
});

const deletingUsers = new ToastModel({
  key: 'deleting-users',
  message: <FormattedMessage id='toasts.userCleanup.deleting' />,
  ttl: 0,
});

const deletedUsers = new ToastModel({
  key: 'deleted-users',
  message: <FormattedMessage id='toasts.userCleanup.success' />,
  ttl: 2000,
  clears: deletingUsers.key,
});

export function getDashboardReport(settings, dispatchToasts = true) {
  return dispatch => {
    let req = createRequest('/api/Report/Dashboard', {
      authorize: true,
      body: {
        settings: settings.toApiFormat(),
      },
      method: methodTypes.post,
    });
    req
      .promise()
      .then(data => {
        let report = DashboardReport.fromApiFormat(data.data);
        if (dispatchToasts) {
          dispatch(addToast(fetchSuccess));
        }
        dispatch(setReportData('dashboard', report));
      });

    if (dispatchToasts) {
      dispatch(addToast(fetching));
    }

    return req;
  };
}

export function getUsersReport(settings, dispatchToasts = true) {
  return dispatch => {
    let req = createRequest('/api/Report/Users', {
      authorize: true,
      body: {
        settings: settings.toApiFormat(),
      },
      method: methodTypes.post,
    });

    req
      .promise()
      .then(data => {
        let report = UsersReport.fromApiFormat(data.data);
        if (dispatchToasts) {
          dispatch(addToast(fetchSuccess));
        }
        dispatch(setReportData('users', report));
      });

    if (dispatchToasts) {
      dispatch(addToast(fetching));
    }
    return req;
  };
}

export function getEmailsReport(settings, dispatchToasts = true) {
  return dispatch => {
    let req = createRequest('/api/Report/Emails', {
      authorize: true,
      body: {
        settings: settings.toApiFormat(),
      },
      method: methodTypes.post,
    });

    req
      .promise()
      .then(data => {
        let report = EmailsReport.fromApiFormat(data.data);
        if (dispatchToasts) {
          dispatch(addToast(fetchSuccess));
        }
        dispatch(setReportData('emails', report));
      });

    if (dispatchToasts) {
      dispatch(addToast(fetching));
    }
    return req;
  };
}

export function getUsersModifiedReport(settings, dispatchToasts = true) {
  return dispatch => {
    let req = createRequest('/api/Report/UsersModified', {
      authorize: true,
      body: {
        settings: settings.toApiFormat(),
      },
      method: methodTypes.post,
    });

    req
      .promise()
      .then(data => {
        let report = UsersModifiedReport.fromApiFormat(data.data);
        if (dispatchToasts) {
          dispatch(addToast(fetchSuccess));
        }
        dispatch(setReportData('usersModified', report));
      });

    if (dispatchToasts) {
      dispatch(addToast(fetching));
    }
    return req;
  };
}

export function getAccountStatusReport(settings, dispatchToasts = true) {
  return dispatch => {
    let req = createRequest('/api/Report/AccountStatus', {
      authorize: true,
      body: {
        settings: settings.toApiFormat(),
      },
      method: methodTypes.post,
    });

    req
      .promise()
      .then(data => {
        let report = AccountStatusReport.fromApiFormat(data.data);
        if (dispatchToasts) {
          dispatch(addToast(fetchSuccess));
        }
        dispatch(setReportData('accountStatus', report));
      });

    if (dispatchToasts) {
      dispatch(addToast(fetching));
    }
    return req;
  };
}

export function getLockEventsReport(settings, dispatchToasts = true) {
  return dispatch => {
    let req = createRequest('/api/Report/LockEvents', {
      authorize: true,
      body: {
        settings: settings.toApiFormat(),
      },
      method: methodTypes.post,
    });

    req
      .promise()
      .then(data => {
        let report = LockEventsReport.fromApiFormat(data.data);
        if (dispatchToasts) {
          dispatch(addToast(fetchSuccess));
        }
        dispatch(setReportData('lockEvents', report));
      });

    if (dispatchToasts) {
      dispatch(addToast(fetching));
    }
    return req;
  };
}

export function getLoginEventsReport(settings, dispatchToasts = true) {
  return dispatch => {
    let req = createRequest('/api/Report/LoginEvents', {
      authorize: true,
      body: {
        settings: settings.toApiFormat(),
      },
      method: methodTypes.post,
    });

    req
      .promise()
      .then(data => {
        let report = LoginEventsReport.fromApiFormat(data.data);
        if (dispatchToasts) {
          dispatch(addToast(fetchSuccess));
        }
        dispatch(setReportData('loginEvents', report));
      });

    if (dispatchToasts) {
      dispatch(addToast(fetching));
    }
    return req;
  };
}

export function getPasswordResetsReport(settings, dispatchToasts = true) {
  return dispatch => {
    let req = createRequest('/api/Report/PasswordResets', {
      authorize: true,
      body: {
        settings: settings.toApiFormat(),
      },
      method: methodTypes.post,
    });

    req
      .promise()
      .then(data => {
        let report = PasswordResetsReport.fromApiFormat(data.data);
        if (dispatchToasts) {
          dispatch(addToast(fetchSuccess));
        }
        dispatch(setReportData('passwordResets', report));
      });

    if (dispatchToasts) {
      dispatch(addToast(fetching));
    }
    return req;
  };
}

export function getTemporaryToken() {
  return createRequest('/api/Report/Token', {
    authorize: true,
    method: methodTypes.post,
  })
    .promise()
    .then(x => x.data);
}

export function getInactiveUsersCount(date) {
    return createRequest(`/api/User/GetInactiveUsersCount?date=${date}`, {
      authorize: true,
      method: methodTypes.get,
    })
      .promise()
      .then(x => x.data);
}

export function deleteInactiveUsers(date) {
  return dispatch => {
    dispatch(addToast(deletingUsers));
    return createRequest('/api/User/DeleteInactiveUsers', {
      authorize: true,
      body: {
        date: date,
      },
      method: methodTypes.post,
    })
      .promise()
      .then(x => {
        dispatch(addToast(deletedUsers));
      });
  }
}

export function getOrphanedUsersCount() {
  return createRequest('/api/User/GetOrphanedUsersCount', {
    authorize: true,
    method: methodTypes.get,
  })
    .promise()
    .then(x => x.data);
}

export function deleteOrphanedUsers() {
  return dispatch => {
    dispatch(addToast(deletingUsers));
    return createRequest('/api/User/DeleteOrphanedUsers', {
      authorize: true,
      method: methodTypes.post,
    })
      .promise()
      .then(x => {
        dispatch(addToast(deletedUsers));
      });
  }
}

export function getUserCleanUpTemporaryToken() {
  return createRequest('/api/User/UserCleanupToken', {
    authorize: true,
    method: methodTypes.post,
  })
    .promise()
    .then(x => x.data);
}