import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import path from 'path';
import {browserHistory} from 'react-router';
import {createTokenManager} from 'redux-oidc';
import createTokenManagerConfig from 'helpers/tokenManager';
import {Link} from 'react-router';

import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon/Icon';

import styles from './Profile.module.scss';

const Profile = createClass({
  displayName: 'Profile',
  mixins: [PureRenderMixin],
  propTypes: {
    activePage: PropTypes.string,
    token: PropTypes.object.isRequired,
  },

  getInitialState: function() {
    return {
      dropdownVisible: false,
    };
  },

  toggleDropdown() {
    this.setState({dropdownVisible: !this.state.dropdownVisible});
  },

  componentDidMount() {
    document.querySelector('body').addEventListener('click', this.closeOutside);
  },

  componentWillUnmount() {
    document.querySelector('body').removeEventListener('click', this.closeOutside);
  },

  closeOutside(event) {
    const area = this.refs.openDropdown;
    if (!area.contains(event.target)) {
      this.setState({dropdownVisible: false });
    }
  },

  signOutUser() {
    // Built in logout function in token manager is broken
    let config = createTokenManagerConfig();
    let manager = createTokenManager(config);

    manager.removeToken();

    window.location.href = 'https://' + path.join(window.idp.replace('https://', ''), '/connect/endsession');
  },
  goToProfile(e) {
    e.preventDefault();
    this.setState({
      dropdownVisible: false,
    });
    browserHistory.push('/my-profile');
  },

  goToPasswordChange(e) {
    e.preventDefault();
    this.setState({
      dropdownVisible: false,
    });
    browserHistory.push('/change-password');
  },

  showDropdown() {
    if (this.state.dropdownVisible) {
      return (
        <div className={styles.dropdown}>
          <ul>
            <li>
              <div className={styles.circle}>
                <Icon name='profile' />
              </div>
              <div className={styles.name}>
                <a href='/my-profile' onClick={this.goToProfile}>
                  <FormattedMessage id='header.dropdown.myProfile' />
                </a>
              </div>
            </li>
            <li className={styles.navItem}>
              <div className='clearfix'>
                <button role='button' className='logout btn btn-primary col-xs-12' onClick={this.signOutUser}>
                  <FormattedMessage id='header.dropdown.logout' />
                </button>
              </div>
            </li>
            <li className={styles.navItem}>
              <Link to='/change-password' className={styles.link} onClick={this.goToPasswordChange}>
                <FormattedMessage id='header.dropdown.changePassword' />
              </Link>
            </li>
          </ul>
        </div>
      );
    }
    return '';
  },

  render() {
    return <div className={styles.profile} ref='openDropdown'>
      <div className={styles.ctaholder}  onClick={this.toggleDropdown}>
        <div className={styles.circle}>
          <Icon name='profile' />
        </div>
        <div className={styles.name}>{this.props.token.profile.name}</div>
        {this.state.dropdownVisible ? <i className='material-icons'>expand_less</i> : <i className='material-icons'>expand_more</i>}
      </div>
      {this.showDropdown()}
    </div>;
  },
});

export default Profile;
