import {connect} from 'react-redux';
import CreateUser from 'components/pages/Users/Create/Create';
import {saveUser, checkEmailUsed} from 'api/user';
import {cancelUserWizard, navigateUserWizard, setStoreProvision} from 'redux/actions/users';
import {getApps} from 'api/app';
import {getGroups} from 'api/group';
import {addToast} from 'redux/actions/toasts';

function mapStateToProps({data, locale}) {
  return {
    apps: data.get('apps').toList(),
    currentUser: data.get('currentUser'),
    dealers: data.get('groups').filter(x => x.type === 0),
    duplicateUser: data.getIn(['userWizard', 'duplicateUser']),
    page: data.getIn(['userWizard', 'page']),
    user: data.getIn(['userWizard', 'user']),
    visibleStores: data.getIn(['userProvisioning', 'visibleStores']),
    rolesCopy: locale.get('roles'),
    locale: locale.get('locale'),
    possibleLocales: locale.get('possibleLocales'),
    regions: locale.get('regions'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (...args) => dispatch(addToast(...args)),
    cancelUserWizard: (...args) => dispatch(cancelUserWizard(...args)),
    checkEmailUsed: (...args) => dispatch(checkEmailUsed(...args)),
    getDealers: () => dispatch(getGroups(0)),
    getApps: (...args) => dispatch(getApps(...args)),
    navigateUserWizard: (...args) => dispatch(navigateUserWizard(...args)),
    saveUser: (...args) => dispatch(saveUser(...args)),
    toggleStoreVisible: (...args) => dispatch(setStoreProvision(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
