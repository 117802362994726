import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import SearchField from './SearchField';
import styles from './SearchBar.module.scss';

import Icon from 'components/Icon/Icon';

const SearchBar = createClass({
  displayName: 'SearchBar',
  mixins: [PureRenderMixin],
  propTypes: {
    buttonAction: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    query: PropTypes.string,
    runSearch: PropTypes.func.isRequired,
    style: PropTypes.object,
    canAdd: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      canAdd: true,
      className: '',
      style: {},
      placeholder: '',
    };
  },

  render() {
    return <div className={styles.seachBox + ' row-fluid' + ' ' + this.props.className} style={this.props.style}>
      <div className='clearfix'>
        <div className='col-xs-5'>
          <div className={styles.searchHolder}>
            <SearchField search={this.props.runSearch} query={this.props.query} placeholder={this.props.placeholder} />
            <span className={styles.searchGlass}><Icon name='search' /></span>
          </div>
        </div>
        <div className='col-xs-2 pull-right'>
          {this.props.canAdd ?
            <button className='btn btn-primary pull-right' onClick={this.props.buttonAction}>
              <Icon name='add' verticalAlign='middle' />&nbsp;&nbsp;{this.props.buttonLabel}
            </button>
            : null}
        </div>
      </div>
    </div>;
  },
});

export default SearchBar;
