import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import Card from 'components/layout/Card/Card';
import TableResults from 'components/layout/ReportsGenerics/TableResults/TableResults';
import Results from 'components/layout/ReportsGenerics/LargeResults/LargeResults';
import {LoginEventsReport, ReportSettings} from 'models';
import {BarChart} from 'components/charts';

const dateFormat = 'll';

import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

const Failed = createClass({
  displayName: 'Failed',
  mixins: [PureRenderMixin],
  propTypes: {
    report: PropTypes.instanceOf(LoginEventsReport).isRequired,
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    intl: intlShape.isRequired,
  },

  render() {
    const {report, settings} = this.props;
    let {formatMessage} = this.props.intl;

    const compare = report && report.compare && settings && settings.compare && settings.compare.from && settings.compare.to;

    let summary = formatMessage({
      id: 'reports.failedLogins.summary',
    }, {
      from: settings.from.format(dateFormat),
      to: settings.to.format(dateFormat),
    });
    let compareSummary;

    if (compare) {
      compareSummary = formatMessage({
        id: 'reports.failedLogins.summary',
      }, {
        from: settings.compare.from.format(dateFormat),
        to: settings.compare.to.format(dateFormat),
      });
    }

    let data = [];
    let valueField = ['value'];
    if (!compare) {
      data = report.failuresPerApp.map((value, name) => {return {value, name};}).toArray();
    } else {
      data = report.failuresPerApp.map((value, name) => {return {value, name, compare: report.compare.failuresPerApp.get(name)};}).toArray();
      valueField.push('compare');
    }

    return <div>
      <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{formatMessage({id: 'reports.failedLogins.title'})}</span></h3>}
        lightHeader={true}
        headerFontSize={16}
        style={{marginBottom: 20}} >
        <div className='row'>
          <div className='col-sm-12'>
            <BarChart data={data} valueField={valueField} />
            <div className='row'>
              <Results colSize={compare ? 'col-sm-6' : 'col-sm-12'} marginBottom={20} light={false} text={summary} number={report.failedLogins} />
              {compareSummary ? <Results colSize='col-sm-6' marginBottom={20} light={false} bottom={false}
                text={compareSummary} number={report.compare.failedLogins} /> : null}
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                <TableResults header={<thead><tr>
                  <th><FormattedMessage id='reports.logins.app' /></th>
                  <th className='text-right'><FormattedMessage id='reports.count' />
                    <small>(
                      <FormattedMessage id='reports.logins.dateRange' values={{
                        from: settings.from.format(dateFormat),
                        to: settings.to.format(dateFormat),
                      }} />
                    )</small></th>
                  {compare ?
                    <th className='text-right'>COUNT
                      <small>(
                        <FormattedMessage id='reports.logins.dateRange' values={{
                          from: settings.compare.from.format(dateFormat),
                          to: settings.compare.to.format(dateFormat),
                        }} />
                      )</small></th>
                     : null}
                   </tr></thead>}>
                    {report.failuresPerApp.map((num, app) => {
                      return <tr key={app}>
                        <td>{app}</td>
                        <td className='text-right'>{num || 0}</td>
                        {compare ? <td className='text-right'>{report.compare.failuresPerApp.get(app) || 0}</td> : null}
                      </tr>;
                    }).toArray()}
                </TableResults>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(Failed);
