import {connect} from 'react-redux';
import ReportsModified from 'components/pages/Reports/Modified/Modified';

import {getUsersModifiedReport} from 'api/report';
import {setReportSettings} from 'redux/actions/reports';

function mapStateToProps({data}) {
  return {
    report: data.getIn(['reports', 'usersModified', 'data']),
    settings: data.getIn(['reports', 'usersModified', 'settings']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUsersModifiedReport: (...args) => dispatch(getUsersModifiedReport(...args)),
    updateSettings: (...args) => dispatch(setReportSettings('usersModified', ...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsModified);
