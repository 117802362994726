import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {List, Map} from 'immutable';

import CopyEditor from './CopyEditor';
import Card from 'components/layout/Card/Card';
import PageHeader from 'components/layout/PageHeader/PageHeader';

import {injectIntl, intlShape} from 'react-intl';

import murmur from 'murmurhash';

const RolesCopy = createClass({
  displayName: 'RolesCopy',
  mixins: [PureRenderMixin],
  propTypes: {
    copy: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
    save: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
    add: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
  },

  componentWillMount() {
    this.props.get();
  },

  save() {
    let {copy} = this.props;

    copy = copy || new List();

    for (let i = 0; i < copy.size; ++i) {
      if (this.refs[i] && this.refs[i].getCopy) {
        copy = copy.set(i, new Map(this.refs[i].getCopy()));
      }
    }
    this.props.save(JSON.stringify(copy.toJS()));
  },

  render() {
    let {copy} = this.props;
    copy = (copy || new List()).toJS();

    if (Object.keys(copy).length === 0) {
      this.props.add();
      return null;
    }

    let {formatMessage} = this.props.intl;
    let languages = [
      {
        locale: 'en-US',
        label: 'English',
      },
      {
        locale: 'fr-CA',
        label: 'French',
      },
    ];

    let keys = Object.keys(copy);
    let editorRows = [
      <CopyEditor readOnly={true} tag='h' languages={languages} style={{fontWeight: 700}}
      copy={languages.reduce((acc, x) => {acc[x.locale] = x.label + ' (' + x.locale + ')'; return acc;}, {})}
      key='header' />,
    ];

    for (let key of keys) {
      editorRows.push(<CopyEditor copy={copy[key]} tag={key} key={murmur.v3(Object.values(copy[key]).join('') || key)} languages={languages}
          delete={this.props.delete.bind(null, key)} ref={key} />);
    }

    return <div>
      <PageHeader title={formatMessage({id: 'sidebar.copy'})} />
      <Card footer={
        <div style={{marginBottom: 15}}>
          <button className='btn btn-primary pull-right' onClick={this.save}>Save</button>
          <div className='clearfix' />
        </div>}>
        <div className='row'>
          <div className='col-xs-12'>
            {editorRows}
          </div>
        </div>
      </Card>
      <button onClick={this.props.add} style={{
        position: 'fixed',
        bottom: 10,
        right: 20,
        border: 0,
        borderRadius: 300,
        padding: 10,
        color: 'white',
        backgroundColor: '#3498db',
        zIndex: 9999,
      }}><i className='material-icons' style={{fontSize: 50}}>add</i></button>
    </div>;
  },
});

export default injectIntl(RolesCopy);
