import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import Card from 'components/layout/Card/Card';
import {List, Map} from 'immutable';
import TableResults from 'components/layout/ReportsGenerics/TableResults/TableResults';
import {BarChart} from 'components/charts';

import styles from './LockAccountA.module.scss';

import {FormattedMessage} from 'react-intl';

const LockAccountA = createClass({
  displayName: 'LockAccountA',
  mixins: [PureRenderMixin],
  propTypes: {
    accountsLocked: PropTypes.instanceOf(List).isRequired,
    totalLocked: PropTypes.number.isRequired,
    full: PropTypes.instanceOf(Map).isRequired,
  },

  gotoPage() {
    browserHistory.push('/reports/locked');
  },

  render() {
    let chartData = this.props.accountsLocked.map(result => {
      return {
        name: result.get('text'),
        value: result.get('number'),
      };
    }).toArray();

    return <div>
      <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}><FormattedMessage id='reports.dashboard.lockedAccountCount' /></span>
          <button onClick={this.gotoPage} className={'btn btn-transparent pull-right'}><FormattedMessage id='reports.dashboard.fullReportLink' /></button></h3>}
        lightHeader={true}
        headerFontSize={16}
        minHeight={370}
        style={{marginBottom: 20}} >
        <div className='row'>
          <div className='col-sm-12'>
            <BarChart data={chartData} />
            <TableResults header={<thead><tr>
                <th><FormattedMessage id='reports.dashboard.lockOrigin' /></th>
                <th className='text-right'><FormattedMessage id='reports.dashboard.count' /></th>
                </tr></thead>}
                footer={<tfoot>
                  <tr style={{fontWeight: 'bold'}}>
                    <td style={{verticalAlign: 'bottom'}}><FormattedMessage id='reports.dashboard.totalLocked' /></td>
                    <td className={'text-right ' + styles.summaryNumber} style={{verticalAlign: 'bottom'}}>{this.props.totalLocked}</td>
                  </tr>
                </tfoot>}>
                {this.props.accountsLocked.map((result, index) => {
                  return <tr key={index}>
                    <td>{result.get('text')}</td>
                    <td className='text-right'>{result.get('number') || 0}</td>
                  </tr>;
                }).toArray()}
            </TableResults>
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default LockAccountA;
