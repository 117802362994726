/**
 * Formats a string as a phone number in the format (000) 000-0000
 * @param {string} num  The phone number to format.
 * @return {string}     The formatted phone number.
 **/
export default function formatStringAsPhone(num) {
  let onlyIntegers = num.split('').map(x => parseInt(x)).filter(x => !isNaN(x)).join('');
  let formatted = onlyIntegers;
  let addOne = false;
  if (onlyIntegers.length === 11 && onlyIntegers[0] === '1') {
    formatted = formatted.substr(1);
    addOne = true;
  }
  formatted = formatted.replace(/([0-9]{3})([0-9]{1})/, '($1) $2');

  if (onlyIntegers.length > 6) {
    formatted = formatted.substr(0, 9) + '-' + formatted.substr(9);
  }

  if (addOne) {
    formatted = '1 ' + formatted;
  }

  return formatted;
}
