import React, {PropTypes} from 'react';

import AmChart from '../AmChart/AmChart';

import 'amcharts/dist/amcharts/serial';

/**
 * MonthLinesChart - displays months on the x axis and values on the y axis
 * @param {Array} data - [{date: something, value: something else}, ...] - Each item in the array represents a point
 * @param {String} [titleField='date'] - the field used to title each bar
 * @param {[String]} [valueFields=['value']] - the fields used to get the numerical value of each line point
 * @returns {ReactElement} React HTML
 */
let MonthLinesChart = ({
  data, titleField = 'date', legendFields = [''], valueFields = ['value'], verticalAxisTitle='',
  colors = ['#52b9e9', '#043673'],
}) => (
  <AmChart config={{
    type: 'serial',
    theme: 'light',
    dataProvider: data.sort((a, b) => a.date < b.date ? -1 : 1),
    zoomOutButtonImage: '',
    categoryField: titleField,
    graphs: valueFields.map((valueField, i) => {
      return {
        valueField: valueField,
        lineColor: colors[i % colors.length],
        bullet: 'round',
        bulletBorderThickness: 1,
        fillAlphas: 0,
        title: legendFields[i % legendFields.length],
      };
    }),
    chartCursor: {
      categoryBalloonEnabled: false,
      cursorAlpha: 0,
      zoomable: false,
    },
    valueAxes: [
      {
        axisAlpha: 0,
        position: 'left',
        title: verticalAxisTitle,
      },
    ],
    categoryAxis: {
      parseDates: true,
      equalSpacing: true,
      boldPeriodBeginning: false,
      minPeriod: '1MM',
      labelRotation: 45,
    },
    legend: {
      useGraphSettings: true,
      valueFunction: (graphItem, formattedText) => {
        // Hide items with no value on the graph
        if (graphItem.x && graphItem.y) {
          return formattedText;
        }
        return '';
      },
    },
    startDuration: 0,
  }} />
);

MonthLinesChart.propTypes = {
  data: PropTypes.array.isRequired,
  titleField: PropTypes.string,
  legendFields: PropTypes.array,
  valueFields: PropTypes.array,
  verticalAxisTitle: PropTypes.string,
  colors: PropTypes.array,
};

module.exports = MonthLinesChart;
