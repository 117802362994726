import {connect} from 'react-redux';
import LoginPage from 'components/pages/Login/Login';

const mapDispatchToProps = () => {
  return {};
};
const mapStateToProps = ({
  token,
}) => {
  return {
    token: token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
