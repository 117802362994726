import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import {Set} from 'immutable';
import Card from 'components/layout/Card/Card';
import styles from './Emails.module.scss';
import {BarChart} from 'components/charts';
import InnerForm from 'containers/layout/InnerForm';
import Results from 'components/layout/ReportsGenerics/LargeResults/LargeResults';
import TableResults from 'components/layout/ReportsGenerics/TableResults/TableResults';
import {arrayToCsv, textToCsv, csvToUrl} from 'helpers/csv';

import {ReportSettings, EmailsReport} from 'models';

import {injectIntl, intlShape, FormattedMessage} from 'react-intl';

const Emails = createClass({
  displayName: 'Emails',
  mixins: [PureRenderMixin],
  propTypes: {
    report: PropTypes.instanceOf(EmailsReport).isRequired,
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    updateSettings: PropTypes.func.isRequired,
    getEmailsReport: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  },

  export() {
    let {report, settings} = this.props;
    let {formatMessage} = this.props.intl;
    let output = [];
    let showCompare = settings && settings.compare && settings.compare.to && settings.compare.from && report && report.compare;

    output.push([formatMessage({id: 'reports.email.type'}), formatMessage({id: 'reports.count'})]);

    if (showCompare) {
      output[0].push(formatMessage({id: 'reports.filters.compare'}));
    }

    let rows = report.emailTypesSent.keySeq().map(type => {
      let out = [type, report.emailTypesSent.get(type)];
      if (showCompare) {
        out.push(report.compare.emailTypesSent.get(type));
      }
      return out;
    }).toArray();

    output = output.concat(rows);

    let csv = csvToUrl(textToCsv(arrayToCsv(output)));
    window.open(csv);
  },

  render() {
    let {report, settings} = this.props;
    let {formatMessage} = this.props.intl;
    let showCompare = settings && settings.compare && settings.compare.to && settings.compare.from && report && report.compare;
    let compareText = '';

    let summaryText = formatMessage({
      id: 'reports.email.summary',
    }, {
      from: settings.from.format('ll'),
      start: settings.to.format('ll'),
    });

    if (showCompare) {
      compareText = formatMessage({
        id: 'reports.email.summary',
      }, {
        from: settings.compare.from.format('ll'),
        start: settings.compare.to.format('ll'),
      });
    }

    let emailTypes, chartData;
    let valueFields = new Set();
    valueFields = valueFields.add('value');
    if (report) {
      emailTypes = report.emailTypesSent.keySeq().map(type => {
        return <tr key={type}>
          <td>{type}</td>
          <td className='text-right'>{report.emailTypesSent.get(type)}</td>
          {showCompare ? <td className='text-right'>{report.compare.emailTypesSent.get(type)}</td> : null}
        </tr>;
      });

      chartData = report.emailTypesSent.keySeq().map(type => {
        let output = {
          name: type,
          value: report.emailTypesSent.get(type),
        };

        if (showCompare) {
          output.compare = report.compare.emailTypesSent.get(type);
          valueFields = valueFields.add('compare');
        }

        return output;
      }).toArray();
    }

    return <div>
      <PageHeader title={formatMessage({id: 'reports.email.pageHeader'})} visible={true} right={<div className={styles.exportButton}>
        <button className={'btn btn-primary'} onClick={this.export}><FormattedMessage id='reports.export' /></button>
        </div>} backButton={true} />
      <InnerForm settings={this.props.settings} run={this.props.getEmailsReport} update={this.props.updateSettings} />
      {this.props.report ? <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{formatMessage({id: 'reports.email.title'})}</span></h3>}
          lightHeader={true}
          headerFontSize={16}
          style={{marginBottom: 20}} >
          <div className='row'>
            <div className='col-sm-12'>
              <BarChart valueField={valueFields.toArray()} data={chartData} />
              <div className='row'>
                <Results key={0} colSize={showCompare ? 'col-sm-6' : 'col-sm-12'} marginBottom={20} light={false}
                  text={summaryText} number={report.emailsSent} />
                {showCompare ? <Results key={1} colSize='col-sm-6' marginBottom={20} light={false}
                   text={compareText} number={report.compare.emailsSent} /> : null}
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <TableResults header={<thead><tr>
                    <th><FormattedMessage id='reports.email.type' /></th>
                    <th className='text-right'><FormattedMessage id='reports.count' />
                      <small>
                        <FormattedMessage id='reports.email.dateRange' values={{from: settings.from.format('ll'), to: settings.to.format('ll')}} />
                      </small>
                    </th>
                    {showCompare ?
                      <th className='text-right'><FormattedMessage id='reports.count' />
                        <small>
                          (<FormattedMessage id='reports.email.dateRange'
                            values={{from: settings.compare.from.format('ll'), to: settings.compare.to.format('ll')}} />)
                        </small>
                      </th>
                       : null}
                     </tr></thead>}>
                      {emailTypes}
                  </TableResults>
                </div>
              </div>
            </div>
          </div>
        </Card> : null}
    </div>;
  },
});

export default injectIntl(Emails);
