import Immutable from 'immutable';

import {
  TOGGLE_MODAL,
  ADD_TOAST, CLEAR_TOAST,
  SET_DELETE_STORE, CLEAR_DELETE_STORE,
  SET_INLINE_DEALER, CLEAR_INLINE_DEALER,
} from '../actions';

const initialState = {
  inlineDealerEdit: null,
  modals: {
    deleteApp: false,
    deleteAttribute: false,
    deleteDealer: false,
    deletePermission: false,
    deleteRole: false,
    deleteStore: false,
    deleteUser: false,
    deleteInactiveUsers: false,
    deleteOrphanedUsers: false,
    disclaimer: false,
    privacy: false,
  },
  toasts: {},
};

export default function uiReducer(state = Immutable.fromJS(initialState), action) {
  switch (action.type) {
    case TOGGLE_MODAL: {
      return state.setIn(['modals', action.key], action.value);
    }

    case ADD_TOAST: {
      let newState = state.setIn(['toasts', action.value.key], action.value);
      if (action.value.clears) {
        return newState.deleteIn(['toasts', action.value.clears]);
      }
      return newState;
    }

    case CLEAR_TOAST: {
      return state.deleteIn(['toasts', action.value]);
    }

    case SET_DELETE_STORE: {
      return state.setIn(['modals', 'deleteStore'], true);
    }

    case CLEAR_DELETE_STORE: {
      return state.setIn(['modals', 'deleteStore'], false);
    }

    case SET_INLINE_DEALER: {
      return state.setIn(['inlineDealerEdit'], action.value);
    }

    case CLEAR_INLINE_DEALER: {
      return state.set('inlineDealerEdit', null);
    }

    default: {
      return state;
    }
  }
}
