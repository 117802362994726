export function arrayToCsv(xs) {
  let output = '';
  let isHeader = true;

  for (let ys of xs) {
    if (isHeader) {
      ys = ys.map(x => x ? x.toUpperCase() : x);
      isHeader = false;
    }

    output += ys.join(',') + '\n';
  }
  return output;
}

export function textToCsv(text, name) {
  let blob = new Blob([text], {type: 'text/csv'});
  let file = new File([blob], {name: name});
  return file;
}

export function csvToUrl(csv) {
  return URL.createObjectURL(csv);
}
