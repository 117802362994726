import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {Set, List} from 'immutable';
import {Link} from 'react-router';
import {GroupModel} from 'models';
import {Input} from 'components/forms';
import {Input as ReadOnlyInput} from 'components/forms/ReadOnly';
import Card from 'components/layout/Card/Card';
import ValidationGroup from 'helpers/validation';
import Icon from 'components/Icon/Icon';
import styles from './EditStore.module.scss';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const EditStore = createClass({
  displayName: 'EditStore',
  mixins: [PureRenderMixin],
  propTypes: {
    group: PropTypes.instanceOf(ValidationGroup),
    store: PropTypes.instanceOf(GroupModel).isRequired,
    promptDelete: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    getGroupUsers: PropTypes.func.isRequired,
    users: PropTypes.instanceOf(List),
  },

  contextTypes: {
    canAccessAllDealers: PropTypes.bool.isRequired,
    canDeleteStores: PropTypes.bool.isRequired,
  },

  componentDidMount() {
    this.props.getGroupUsers(this.props.store.id);
  },

  getStore() {
    let store = this.props.store;

    let keys = new Set(Object.keys(this.refs)).intersect(GroupModel.keys).toArray();
    for (let key of keys) {
      let ref = this.refs[key];
      if (ref && ref.getValue) {
        store = store.set(key, ref.getValue());
      }
    }

    return store;
  },

  render() {
    let {store, group} = this.props;

    let {formatMessage} = this.props.intl;

    let users = (this.props.users || new List()).toArray().map(x =>
      <div key={x.id} className={styles.user + ' col-md-6'}>
        <Link to={'/user/' + x.id}>{x.firstName} {x.lastName}</Link>
      </div>);

    return <Card minHeight={0}>
      <h4 className={styles.storeTitle}>
        {this.props.store.displayName}
        {this.context.canDeleteStores ? <button className='btn icon-button pull-right' onClick={this.props.promptDelete}><Icon name='delete' /></button> : null}
      </h4>
      <div className='row'>
        <div className='col-md-4'>
          <Input  label={formatMessage({id: 'dealer.storeFields.name'}) + ':'} ref='displayName' value={store.displayName}
            group={group} required={true} />
          {this.context.canAccessAllDealers ?
            <Input label={formatMessage({id: 'dealer.storeFields.shipTo'}) + ':'} ref='shipTo' limit={10} value={store.shipTo}
              group={group} required={true} />
            : <ReadOnlyInput label={formatMessage({id: 'dealer.storeFields.shipTo'}) + ':'} value={store.shipTo} />}
        </div>
        <div className='col-md-8'>
          <div>
            <label><FormattedMessage id='dealer.storeFields.users' /></label>
          </div>
          <div className='row'>
            {users}
          </div>
        </div>
      </div>
    </Card>;
  },
});

export default injectIntl(EditStore, {withRef: true});
