import React, {createClass, PropTypes} from 'react';

import {GroupModel} from 'models';
import ValidationGroup from 'helpers/validation';

import Icon from 'components/Icon/Icon';
import StoreCard from '../StoreCard/StoreCard';

import {FormattedMessage} from 'react-intl';

const CreateStore = createClass({
  displayName: 'CreateStore',
  // Mixins: [PureRenderMixin],
  propTypes: {
    addStore: PropTypes.func.isRequired,
    back: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    dealer: PropTypes.instanceOf(GroupModel).isRequired,
    deleteStore: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
  },

  componentWillMount() {
    this.group = new ValidationGroup(this.submit);
  },

  getDealer() {
    let dealer = this.props.dealer;
    dealer = dealer.update('children', branches => branches.map(store => {
      let ref = this.refs[store._id];
      if (ref) {
        return ref.getWrappedInstance().getStore();
      }
      return store;
    }));
    return dealer;
  },

  back() {
    return this.props.back(this.getDealer());
  },

  submit() {
    let dealer = this.getDealer();

    let stores = dealer.branches.map(x => {
      return {
        id: x.id,
        _id: x._id,
        shipTo: x.shipTo,
      };
    }).toArray();
    let shipToCounts = {};
    let error = false;
    for (let store of stores) {
      let field = this.refs[store._id] && this.refs[store._id].refs.shipTo ? this.refs[store._id].refs.shipTo : null;
      if (shipToCounts[store.shipTo] && field) {
        field.setState({
          error: true,
          errorMessage: 'Please enter a unique ship to.',
        });
        error = true;
      } else if (field && field.state.error && field.state.errorMessage === 'Please enter a unique ship to.') {
        field.setState({
          error: false,
          errorMessage: null,
        });
      }
      shipToCounts[store.shipTo] = 1;
    }
    if (!error) {
      return this.props.submit(this.getDealer());
    }
    return false;
  },

  addStore() {
    return this.props.addStore(this.getDealer(), true);
  },

  deleteStore(id) {
    return () => {
      return this.props.deleteStore(this.getDealer(), id);
    };
  },

  render() {
    let {dealer} = this.props;
    let stores = dealer.branches;

    const bottomSpace = {marginBottom: 20};

    stores = stores.map(x => {
      let canDelete = stores.size > 1;
      return <StoreCard store={x} key={x._id} ref={x._id} group={this.group} delete={this.deleteStore(x._id)} deleteEnabled={canDelete} />;
    });
    return <div>
      <h2><FormattedMessage id='createDealerStore.title' /></h2>
      {stores}
      <div className='row' style={bottomSpace}>
        <div className='col-xs-12'>
          <button className='btn btn-dashed full-width' onClick={this.addStore}>
            <Icon name='add' />&nbsp;<FormattedMessage id='createDealerStore.addStore' />
          </button>
        </div>
      </div>
      <div className='row button-row'>
        <div className='col-md-12'>
          <button className='btn btn-default pull-left' onClick={this.back}><FormattedMessage id='createDealer.back' /></button>
          <button className='btn btn-primary pull-right' onClick={this.group.validate}><FormattedMessage id='createDealer.continue' /></button>
          <button className='btn btn-default pull-right' onClick={this.props.cancel}><FormattedMessage id='createDealer.cancel' /></button>
        </div>
      </div>
    </div>;
  },
});

export default CreateStore;
