import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import {ToastModel} from 'models';

import styles from './Toast.module.scss';

const Toast = createClass({
  displayName: 'Toast',
  mixins: [PureRenderMixin],
  timeout: null,
  propTypes: {
    toast: PropTypes.instanceOf(ToastModel).isRequired,
    dismiss: PropTypes.func.isRequired,
  },

  componentWillMount() {
    let toast = this.props.toast;
    if (toast.ttl) {
      this.timeout = setTimeout(this.props.dismiss, toast.ttl);
    }
  },

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },

  render() {
    let toast = this.props.toast;
    return <div className={styles.toast}>
      {toast.message}
      {toast.button}
      <button className={'btn pull-right ' + styles.closeToastBtn} onClick={this.props.dismiss}>
        <i className='material-icons'>close</i></button>
    </div>;
  },
});

export default Toast;
