import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ToastModel } from 'models';
import { addToast } from 'redux/actions/toasts';

export function changingPasswordToast() {
  return addToast(new ToastModel({
    key: 'changing-password',
    message: <FormattedMessage id='toasts.user.changingPassword' />,
    ttl: null,
    closeable: true,
  }));
}

export function changedPasswordToast() {
  return addToast(new ToastModel({
    clears: 'changing-password',
    key: 'changed-password',
    message: <FormattedMessage id='toasts.user.changedPassword' />,
    ttl: 3000,
    closeable: true,
  }));
}

export function errorPasswordToast() {
  return addToast(new ToastModel({
    clears: 'changing-password',
    key: 'changed-password',
    message: <FormattedMessage id='toasts.user.changedPasswordError' />,
    ttl: null,
    closeable: true,
  }));
}

export function savingUserToast() {
  return addToast(new ToastModel({
    key: 'saving-user',
    message: <FormattedMessage id='toasts.user.saving' />,
    closeable: true,
    ttl: null,
  }));
}

export function savedUserToast() {
  return addToast(new ToastModel({
    key: 'saved-user-success',
    message: <FormattedMessage id='toasts.user.saved' />,
    clears: 'saving-user',
    ttl: 3000,
    closeable: true,
  }));
}

export function errorUserToast() {
  return addToast(new ToastModel({
    clears: 'saving-user',
    message: <FormattedMessage id='toasts.user.error' />,
    key: 'saved-user-failure',
  }));
}

export function saveUserPermissionsError() {
  return addToast(new ToastModel({
    clears: 'saving-user',
    message: <FormattedMessage id='toasts.user.permissionsError' />,
    key: 'saved-user-failure',
  }));
}

export function emailPermissionsSending() {
  return addToast(new ToastModel({
    message: <FormattedMessage id='toasts.email.permissionsSending' />,
    key: 'email-user-permissions-sending',
    ttl: null,
    closeable: true,
  }));
}

export function emailPermissionsSent() {
  return addToast(new ToastModel({
    message: <FormattedMessage id='toasts.email.permissionsSent' />,
    key: 'email-user-permissions-sent',
    ttl: 4000,
    closeable: true,
    clears: 'email-user-permissions-sending',
  }));
}

export function emailPermissiosError() {
  return addToast(new ToastModel({
    message: <FormattedMessage id='toasts.email.permissionsError' />,
    key: 'email-user-permissions-sent',
    ttl: 4000,
    closeable: true,
    clears: 'email-user-permissions-sending',
  }));
}
