import {SET_LOCALE, SET_ROLES_COPY, ADD_ROLES_COPY, DELETE_ROLES_COPY} from './index';

export function setLocale(val) {
  return {
    type: SET_LOCALE,
    value: val,
  };
}

export function setRolesCopy(val) {
  return {
    type: SET_ROLES_COPY,
    value: val,
  };
}

export function addRolesCopy() {
  return {
    type: ADD_ROLES_COPY,
  };
}

export function deleteRolesCopy(idx) {
  return {
    type: DELETE_ROLES_COPY,
    value: idx,
  };
}
