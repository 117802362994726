import {SET_ATTRIBUTE, SET_ATTRIBUTES} from './index';

export function setAttributes(attrs) {
  return {
    type: SET_ATTRIBUTES,
    value: attrs,
  };
}

export function setAttribute(attr) {
  return {
    type: SET_ATTRIBUTE,
    value: attr,
  };
}
