import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import Card from 'components/layout/Card/Card';
import {List} from 'immutable';
import TableResults from 'components/layout/ReportsGenerics/TableResults/TableResults';

import {FormattedMessage} from 'react-intl';

const statusLimit = 10;

const AccountStatus = createClass({
  displayName: 'AccountStatus',
  mixins: [PureRenderMixin],
  propTypes: {
    accountStatus: PropTypes.instanceOf(List).isRequired,
  },

  gotoPage() {
    browserHistory.push('/reports/status');
  },

  render() {
    return <div>
      <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}><FormattedMessage id='reports.dashboard.accountStatus' /></span>
        <button onClick={this.gotoPage} className={'btn btn-transparent pull-right'}>
          <FormattedMessage id='reports.dashboard.fullReportLink' /></button></h3>}
        lightHeader={true}
        headerFontSize={16}
        minHeight={370}
        style={{marginBottom: 20}} >
        <div className='row'>
          <div className='col-sm-12'>
            <TableResults header={<thead><tr>
                <th><FormattedMessage id='reports.dashboard.authentication' /></th>
                <th className='text-right'><FormattedMessage id='reports.dashboard.active' /></th>
                <th className='text-right'><FormattedMessage id='reports.dashboard.inactive' /></th>
                </tr></thead>}>
                {this.props.accountStatus.slice(0, Math.min(this.props.accountStatus.size, statusLimit)).map((result, index) => {
                  return <tr key={index}>
                    <td>{result.get('text')}</td>
                    <td className='text-right'>{result.get('active') || 0}</td>
                    <td className='text-right'>{result.get('inactive') || 0}</td>
                  </tr>;
                }).toArray()}
                {this.props.accountStatus.size > statusLimit ?
                  <tr>
                    <td colSpan={3}><FormattedMessage id='reports.dashboard.truncated' /></td>
                  </tr>
                  : null
                }
            </TableResults>
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default AccountStatus;
