import generateModel from 'helpers/modelGenerator';
import {List} from 'immutable';
import moment from 'moment';

import AppModel from './app';
import AttributeModel from './attribute';
import GroupModel from './group';
import {groupTypes} from 'helpers/constants';
import {isValidEmail} from 'helpers/validation';

function getDeepPermissions(xs = new List(), type = groupTypes.permission, output = new List()) {
  if (xs.size > 0) {
    let val = xs.first();
    let input = xs.shift();

    if (val.type === type) {
      output = output.push(val);
    }

    if (val.children && val.children.size > 0) {
      input = input.concat(val.children);
    }

    if (input.size > 0) {
      return getDeepPermissions(input, type, output);
    }
  }
  return output;
}

const UserModel = generateModel({
  apps: {value: 'Apps', default: new List(), type: AppModel},
  alternatePhone: {value: 'AlternatePhone', default: ''},
  attributes: {value: 'Attributes', default: new List(), type: AttributeModel},
  branches: val => getDeepPermissions(val.apps.reduce((acc, x) => acc.concat(x.assigned), new List()), groupTypes.branch),
  dealer: {value: 'Dealer', type: GroupModel},
  deleted: 'Deleted',
  email: {value: 'Email', default: '', required: true, validate: isValidEmail},
  firstName: {value: 'FirstName', default: '', required: true},
  groups: {value: 'Groups', default: new List(), type: GroupModel},
  id: 'ID',
  isLocked: usr => usr.locked || (usr.lockoutExpiry && usr.lockoutExpiry.isAfter(moment())),
  lastName: {value: 'LastName', default: '', required: true},
  locked: 'Locked',
  lockoutExpiry: {value: 'LockoutExpiry', type: moment.utc},
  mobilePhone: {value: 'MobilePhone', default: ''},
  name: val => val.firstName + ' ' + val.lastName,
  permissions: val => getDeepPermissions(val.apps.reduce((acc, x) => acc.concat(x.assigned), new List())),
  roles: val => getDeepPermissions(val.apps.reduce((acc, x) => acc.concat(x.assigned), new List()), groupTypes.role),
  idmBranchIDs: {value: 'IDMBranchIDs', default: new List()},
  primary: {value: 'Primary', default: false},
  idmRole: val => {
    let idm = val.apps.find(x => x.clientID === window.clientID);
    if (!idm || !idm.assigned) {
      return undefined;
    }
    let roles = getDeepPermissions(idm.assigned.toList(), groupTypes.role);
    if (!roles || roles.size === 0) {
      return undefined;
    }

    return roles.first().name;
  },
  region: {value: 'Region', default: 'US'},
  locale: {value: 'Locale', default: 'en-US'},
});

export default UserModel;
