import {connect} from 'react-redux';
import {getApps, deleteApp, assignGroup, saveApp} from 'api/app';
import {getGroups} from 'api/group';
import RoleManagement from 'components/pages/Roles/Roles';
import {toggleModal} from 'redux/actions/modals';
import {addToast} from 'redux/actions/toasts';
import readOnly from 'helpers/readOnly';

import {setCurrentApp, clearCurrentApp, clearAppDeleteSettings, setDeleteRole, setDeleteAttribute, setDeletePermission} from 'redux/actions/apps';


import {List} from 'immutable';

function mapStateToProps({data, ui, routing, locale}) {
  return {
    apps: new List(data.get('apps').values()),
    currentApp: data.get('currentApp'),
    deleteTargets: data.get('delete'),
    deleteAttributeModalVisible: ui.getIn(['modals', 'deleteAttribute']),
    deletePermissionModalVisible: ui.getIn(['modals', 'deletePermission']),
    deleteRoleModalVisible: ui.getIn(['modals', 'deleteRole']),
    groups: new List(data.get('groups').values()),
    search: routing.locationBeforeTransitions.search,
    path: routing.locationBeforeTransitions.pathname,
    locale: locale.get('locale'),
    rolesCopy: locale.get('roles'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (...args) => dispatch(addToast(...args)),
    assignGroup: (...args) => dispatch(assignGroup(...args)),
    clearAppDeleteSettings: () => dispatch(clearAppDeleteSettings()),
    clearCurrentApp: () => dispatch(clearCurrentApp()),
    deleteApp: (id) => dispatch(deleteApp(id)),
    getApp: (...args) => dispatch(addToast(...args)),
    getApps: () => dispatch(getApps()),
    getGroups: (...args) => dispatch(getGroups(...args)),
    saveApp: (...args) => dispatch(saveApp(...args)),
    setCurrentApp: (app) => dispatch(setCurrentApp(app)),
    setDeleteAttribute: (...args) => dispatch(setDeleteAttribute(...args)),
    setDeletePermission: (...args) => dispatch(setDeletePermission(...args)),
    setDeleteRole: (...args) => dispatch(setDeleteRole(...args)),
    toggleAttributeDeleteModal: (...args) => dispatch(toggleModal('deleteAttribute', ...args)),
    togglePermissionDeleteModal: (...args) => dispatch(toggleModal('deletePermission', ...args)),
    toggleRoleDeleteModal: (...args) => dispatch(toggleModal('deleteRole', ...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(readOnly(RoleManagement));
