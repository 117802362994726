import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import styles from './Users.module.scss';
import {List} from 'immutable';

/*Here are the sections for the users provisioned report*/
import InnerForm from 'containers/layout/InnerForm';
import PerApp from './PerApp/PerApp';
import PerDealer from './PerDealer/PerDealer';
import PerStore from './PerStore/PerStore';

import {UsersReport, ReportSettings} from 'models';

import {injectIntl, FormattedMessage, intlShape} from 'react-intl';
import {getTranslatedName} from 'helpers/roles';
import {csvToUrl, textToCsv, arrayToCsv} from 'helpers/csv';

const Users = createClass({
  displayName: 'Users',
  mixins: [PureRenderMixin],
  propTypes: {
    report: PropTypes.instanceOf(UsersReport),
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    getUsersReport: PropTypes.func.isRequired,
    updateSettings: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    rolesCopy: PropTypes.instanceOf(List).isRequired,
    locale: PropTypes.string.isRequired,
  },

  contextTypes: {
    canAccessAllDealers: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
  },

  export() {
    let {formatMessage} = this.props.intl;
    let {report, settings} = this.props;
    const compare = report && report.compare && settings && settings.compare && settings.compare.from && settings.compare.to;


    let output = [[formatMessage({id: 'reports.logins.app'}), formatMessage({id: 'reports.count'})]];
    if (compare) {
      output[0].push(formatMessage({id: 'reports.filters.compare'}));
    }

    let rows = report.usersPerApp.keySeq().map(name => {
      let out = [name, report.usersPerApp.get(name)];
      if (compare) {
        out.push(report.compare.usersPerApp.get(name) || 0);
      }
      return out;
    }).toArray();

    output = output.concat(rows);

    let csv = csvToUrl(textToCsv(arrayToCsv(output), 'export.csv'));

    window.open(csv);
  },

  render() {
    let {report, settings, rolesCopy, locale} = this.props;
    let {formatMessage} = this.props.intl;

    let getRoleName = getTranslatedName.bind(null, rolesCopy, locale);

    return <div className={styles.usersReport}>
      <PageHeader title={formatMessage({id: 'reports.users.pageHeader'})} visible={true} right={<div className={styles.exportButton}>
        <button className={'btn btn-primary'} disabled={!report} onClick={this.export}><FormattedMessage id='reports.export' /></button>
      </div>} backButton={true} />
      <InnerForm run={this.props.getUsersReport} settings={settings} update={this.props.updateSettings} />
      {report ? <div>
        <PerApp report={report} settings={settings} getRoleName={getRoleName} />
        {this.context.canAccessAllDealers ? <PerDealer report={report} settings={settings} getRoleName={getRoleName} /> : null}
        {this.context.role !== 'manager' || this.context.canAccessAllDealers ? <PerStore report={report}
          settings={settings} getRoleName={getRoleName} /> : null}
      </div> : null}
    </div>;
  },
});

export default injectIntl(Users);
