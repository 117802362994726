import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import Card from 'components/layout/Card/Card';
import Icon from 'components/Icon/Icon';
import styles from './ViewDetails.module.scss';
import {BarChart} from 'components/charts';

const ViewDetails = createClass({
  displayName: 'ViewDetails',
  mixins: [PureRenderMixin],
  propTypes: {
    header: PropTypes.any,
    footer: PropTypes.any,
    children: PropTypes.any,
    data: PropTypes.array.isRequired,
    valueFields: PropTypes.array,
  },

  getInitialState() {
    return {
      isOpen: false,
    };
  },

  toggleCollapse: function() {
    this.setState({isOpen: !this.state.isOpen});
  },

  render() {
    return <div>
      <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{this.props.header}</span></h3>}
        headerFontSize={16}
        footer={<button className='btn btn-dashed full-body-button' onClick={this.toggleCollapse}>
        {this.props.footer} <Icon name='downArrow' />
        </button>}
        lightHeader={true}
        style={{marginBottom: 20}} >
        <div className='row'>
          <div className='col-sm-12'>
            <BarChart data={this.props.data} valueField={this.props.valueFields} />
          </div>
        </div>
      </Card>
      <div className={this.state.isOpen ? styles.opened : styles.closed}>
        <div className='row'>
          {this.props.children}
        </div>
      </div>
    </div>;
  },
});

export default ViewDetails;
