import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';

import {PromiseStoreMixin} from 'helpers/request';

import Card from 'components/layout/Card/Card';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import SearchBar from 'components/forms/SearchBar/SearchBar';

import {List} from 'immutable';
import {groupTypes} from 'helpers/constants';
import {GroupModel, UserModel} from 'models';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {getTranslatedName} from 'helpers/roles';

import styles from './Home.module.scss';

const Home = createClass({
  displayName: 'Home',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    user: PropTypes.instanceOf(UserModel).isRequired,
    getUser: PropTypes.func.isRequired,
    getDashboardInfo: PropTypes.func.isRequired,
    userAccounts: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
    roles: PropTypes.instanceOf(List).isRequired,
  },

  contextTypes: {
    canCreateUsers: PropTypes.bool.isRequired,
    canViewUsers: PropTypes.bool.isRequired,
    token: PropTypes.object.isRequired,
  },

  createUser() {
    browserHistory.push('/user/new');
  },

  componentWillMount() {
    this.promises.add(this.props.getDashboardInfo());
    this.promises.add(this.props.getUser());
  },

  search(val) {
    browserHistory.push(`/users/${val}`);
  },

  render() {
    let {formatMessage} = this.props.intl;

    let titles = [
      formatMessage({id: 'dashboard.userAccount.totalUsers'}),
      formatMessage({id: 'dashboard.userAccount.totalStores'}),
      formatMessage({id: 'dashboard.userAccount.activeUsers'}),
      formatMessage({id: 'dashboard.userAccount.lockedPerm'}),
      formatMessage({id: 'dashboard.userAccount.lockedTemp'}),
    ];

    let accountRows = this.props.userAccounts.map((x, i) => <tr key={i}>
      <td>{titles[i]}:</td>
      <td>{x.get('Value')}</td>
    </tr>);

    let user = this.props.user;
    let dealer = user.dealer;
    let role = '';
    let dealerName = '';
    let soldTo = '';
    let apps = 0;

    if (user) {
      if (dealer) {
        dealerName = dealer.displayName;
        soldTo = dealer.soldTo;
      }

      apps = user.apps.size;

      if (apps && apps < 10) {
        apps = '0' + apps;
      }

      let idm = user.apps.find(x => x.clientID === window.clientID);
      if (idm) {
        // Pick role with most permissions
        let currentRole = GroupModel.flatten(idm.assigned)
          .filter(x => x.type === groupTypes.role)
          .sort((x, y) => x.children > y.children ? -1 : 1)
          .first();
        if (currentRole) {
          role = getTranslatedName(this.props.roles, this.props.locale, currentRole.displayName);
        }
      }
    }

    return <div>
      <PageHeader title={this.props.intl.formatMessage({id: 'dashboard.pageHeader'})} visible={false} />
      {this.context.canViewUsers ? <div>
        <SearchBar buttonAction={this.createUser} style={{marginTop: 40}} buttonLabel={formatMessage({id: 'dashboard.search.createUser'})}
          placeholder={formatMessage({id: 'dashboard.search.findUser'})} runSearch={this.search} query=''
          canAdd={this.context.canCreateUser} />

        <div className={styles.cards}>
          <Card header={formatMessage({id: 'dashboard.userAccount.title'})} className={styles.tile} minHeight={275}>
            <table className='table'>
              {/* Overrides some bootstrap style that affects production */}
              <tbody style={{border: 'none'}}>
                {accountRows}
              </tbody>
            </table>
          </Card>

          <Card header={formatMessage({id: 'dashboard.myAccount.title'})} className={styles.tile} minHeight={275}>
            <table className='table'>
              <tbody style={{border: 'none'}}>
                <tr><td><FormattedMessage id='dashboard.myAccount.userRole' />:</td><td>{role}</td></tr>
                <tr><td><FormattedMessage id='dashboard.myAccount.dealerName' />:</td><td>{dealerName}</td></tr>
                <tr><td><FormattedMessage id='dashboard.myAccount.soldTo' />:</td><td>{soldTo}</td></tr>
                <tr><td><FormattedMessage id='dashboard.myAccount.appAccess' />:</td><td>{apps}</td></tr>
              </tbody>
            </table>
          </Card>
          <div className='clearfix' />
        </div>
      </div> : <div>You do not have access to this page.</div>}
    </div>;
  },
});

export default injectIntl(Home);
