import {Record} from 'immutable';


export default new Record({
  key: '',
  message: '',
  ttl: null,
  closeable: true,
  button: null,
  clears: '',
});
