import React, {createClass, PropTypes} from 'react';
import Card from 'components/layout/Card/Card';

import styles from './UserCleanup.module.scss';

import {injectIntl, FormattedMessage, intlShape} from 'react-intl';
import DatePicker from '../../../../forms/DatePicker/DatePicker';
import {getUserCleanUpTemporaryToken} from 'api/report';

const UserCleanup = createClass({
  displayName: 'UserCleanup',
  propTypes: {
    intl: intlShape.isRequired,
    onDeleteInactiveUsersPress: PropTypes.func.isRequired,
    onDeleteOrphanedUsersPress: PropTypes.func.isRequired,
    onUpdateDate: PropTypes.func.isRequired,
    dateValue: PropTypes.any.isRequired,
  },

  exportInactiveUsers() {
    getUserCleanUpTemporaryToken()
      .then(token => {
        return window.open(`/api/User/ExportInactiveUsers?token=${token}&date=${this.props.dateValue.format()}`);
      })
      .catch(err => {
        console.error(err);
      })
  },

  exportOrphanedUsers() {
    getUserCleanUpTemporaryToken()
      .then(token => {
        return window.open(`/api/User/ExportOrphanedUsers?token=${token}`);
      })
      .catch(err => {
        console.error(err);
      })
  },

  render() {
    const { formatMessage } = this.props.intl;
    const { onDeleteInactiveUsersPress, onDeleteOrphanedUsersPress, dateValue, onUpdateDate } = this.props;
    return <div>
      <Card
        minHeight={0}
        header={<h3><span style={{display: 'inline-block', marginTop: 6}}
      ><FormattedMessage id='reports.dashboard.userCleanup' /></span></h3>}
        headerFontSize={16}
        lightHeader={true}
        style={{marginBottom: 20}}>
          <div className={styles.inactiveUsersRow + ' row'}>
            <DatePicker ref='deleteBefore' label={formatMessage({id: 'reports.userCleanup.deleteInactiveUsersBefore'}) + ':'} colSize='col-sm-3'
              value={dateValue}
              onChange={value => {onUpdateDate(value)}}
            />
            <div className={styles.warningText + ' col-sm-5 pull-left'}>
              <FormattedMessage id='reports.userCleanup.takesLong'/>
            </div>
            <div className='col-sm-3 pull-right'>
              <button onClick={this.exportInactiveUsers} className={'btn btn-default pull-right'}><FormattedMessage id='reports.userCleanup.exportInactiveUsers' /></button>
              <button onClick={() => onDeleteInactiveUsersPress()} className={styles.submitButton + ' btn btn-primary pull-right'}><FormattedMessage id='reports.userCleanup.deleteInactiveUsers' /></button>
            </div>
          </div>
          <div className={styles.orphanedRow + ' row'}>
            <div className={styles.warningText + ' col-sm-8'}>
              <FormattedMessage id='reports.userCleanup.deleteOrphanedInfo'/>
            </div>
            <div className='col-sm-3 pull-right'>
              <button onClick={this.exportOrphanedUsers} className={'btn btn-default pull-right'}><FormattedMessage id='reports.userCleanup.exportOrphanedUsers' /></button>
              <button onClick={() => onDeleteOrphanedUsersPress()} className={styles.submitButton + ' btn btn-primary pull-right'}><FormattedMessage id='reports.userCleanup.deleteOrphanedUsers' /></button>
            </div>
          </div>
      </Card>
    </div>;
  },
});

export default injectIntl(UserCleanup);
