import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/main.module.scss';

import React from 'react';

import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
import dataReducer, {tokenReducer} from 'redux/reducers/reducers';
import uiReducer from 'redux/reducers/uiReducer';
import localeReducer from 'redux/reducers/localeReducer';
import {Router, Route, browserHistory, IndexRoute} from 'react-router';
import {syncHistoryWithStore, routerReducer, routerMiddleware, LOCATION_CHANGE} from 'react-router-redux';
import thunk from 'redux-thunk';

import IntlProvider from 'containers/layout/IntlProvider';

// Manage openid connect tokens and integrate with redux
import createTokenMiddleware, {createTokenManager} from 'redux-oidc';
import createTokenManagerConfig from 'helpers/tokenManager';

import {invalidateToken} from 'redux/actions/tokens';

import App from 'containers/layout/App';

// Pages to route
import AppListing from 'containers/pages/Apps/View';
import AppManagement from 'containers/pages/Apps/Apps';
import MyProfile from 'containers/pages/Profile/Profile';
import ChangePassword from 'containers/pages/Profile/Password/Password';
import CreateDealer from 'containers/pages/Dealers/Create/Create';
import CreateStore from 'containers/pages/Dealers/CreateStore/CreateStore';
import CreateUser from 'containers/pages/Users/Create/Create';
import DealerManagement from 'containers/pages/Dealers/Dealers';
import EditDealer from 'containers/pages/Dealers/Edit/Edit';
import ShowDealer from 'containers/pages/Dealers/View/View';
import EditUser from 'containers/pages/Users/Edit/Edit';
import ShowUser from 'containers/pages/Users/View/View';
import Home from 'containers/pages/Home/Home';
import LoginNeeded from 'components/pages/Login/LoginNeeded';
import LoginPage from 'containers/pages/Login/Login';
import LogoutPage from 'components/pages/Logout/Logout';
import RoleManagement from 'containers/pages/Roles/Roles';
import RoleListing from 'containers/pages/Roles/List';
import UserManagement from 'containers/pages/Users/Users';
import Reports from 'containers/pages/Reports/Reports';
import ReportsUsers from 'containers/pages/Reports/Users/Users';
import ReportsStatus from 'containers/pages/Reports/Status/Status';
import ReportsLocked from 'containers/pages/Reports/Locked/Locked';
import ReportsModified from 'containers/pages/Reports/Modified/Modified';
import ReportsLogins from 'containers/pages/Reports/Logins/Logins';
import ReportsEmails from 'containers/pages/Reports/Emails/Emails';
import ReportsResets from 'containers/pages/Reports/Resets/Resets';
import CreateDealerAdmin from 'containers/pages/Dealers/CreateAdmin/CreateAdmin';
import RolesCopy from 'containers/pages/Copy/Copy';

import 'element-closest'; // Element.closest polyfill for IE

let initialState = {};
const manager = createTokenManager(createTokenManagerConfig());

if (!manager.expired) {
  initialState = {
    token: {
      idToken: manager['id_token'],
      valid: !manager.expired,
      profile: manager.profile,
    },
  };
}

// Block token from trying to validate on login related routes so that don't get stuck in login loop
let blockedRoutes = [
  'login',
  'logout',
  'login-needed',
  '/login-needed',
  '/login',
  '/logout',
  '/',
];
function validateToken(state, action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (blockedRoutes.indexOf(action.payload) !== -1 || blockedRoutes.indexOf(action.payload.pathname) !== -1) {
        return false;
      }
      return true;
    }
    default: {
      return true;
    }
  }
}

const store = createStore(
  combineReducers({
    token: tokenReducer,
    routing: routerReducer,
    data: dataReducer,
    ui: uiReducer,
    locale: localeReducer,
  }),
  initialState,
  compose(
    applyMiddleware(createTokenMiddleware(createTokenManagerConfig(), validateToken, invalidateToken())),
    applyMiddleware(routerMiddleware(browserHistory)),
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

const history = syncHistoryWithStore(browserHistory, store);

// Function to require authentication on a router. Use onEnter={requireAuth} hook to force logged in.
function requireAuth(...permissions) {
  return (nextState, replace) => {
    if (!manager['id_token'] || manager.expired) {
      replace({
        pathname: '/login-needed',
        state: { nextPathname: nextState.location.pathname },
      });
    }

    let token = store && store.getState ? store.getState().token : null;
    let rawPerms = token && token.profile && token.profile.permission ? token.profile.permission : [];

    if (!rawPerms instanceof Array) {
      rawPerms = [rawPerms];
    }

    // TODO: implement this on backend so that roles match up and I don't get kicked out of everything.
    for (let perm of permissions) {
      if (!rawPerms.find(x => x === perm)) {
        console.warn('Invalid permissions for page.');
        // TODO: should add a toast here saying no permissions
        replace({
          pathname: '/',
          state: { nextPathname: nextState.location.pathname },
        });
      }
    }
  };
}

const permissions = {
  viewApps: 'user-portal-view-apps',
  editApps: 'user-portal-modify-apps',
  createApps: 'user-portal-create-apps',
  deleteApps: 'user-portal-delete-apps',
  viewUsers: 'user-portal-view-users',
  editUsers: 'user-portal-modify-users',
  createUsers: 'user-portal-create-users',
  deleteUsers: 'user-portal-delete-users',

  viewDealers: 'user-portal-view-dealers',
  editDealers: 'user-portal-modify-dealers',
  createDealers: 'user-portal-create-dealers',
  deleteDealers: 'user-portal-delete-dealers',

  createDealerAdmin: 'user-portal-create-dealer-admin',
};

render(
  <Provider store={store}>
    <IntlProvider>
      <Router history={history}>
        <Route path='/' component={App}>
          <IndexRoute component={Home} onEnter={requireAuth()} />

          <Route path='login-needed' component={LoginNeeded} />
          <Route path='login' component={LoginPage} />
          <Route path='logout' component={LogoutPage} />

          <Route path='apps' component={AppListing} onEnter={requireAuth(permissions.viewApps)} />
          <Route path='apps/:id' component={AppListing} onEnter={requireAuth(permissions.viewApps)} />
          <Route path='apps/:id/edit' component={AppManagement} onEnter={requireAuth(permissions.viewApps, permissions.editApps)} />
          <Route path='my-profile' component={MyProfile} onEnter={requireAuth()} />
          <Route path='change-password' component={ChangePassword} onEnter={requireAuth()} />
          <Route path='dealer/new' component={CreateDealer} onEnter={requireAuth(permissions.createDealers)} />
          <Route path='dealer/:id' component={ShowDealer} onEnter={requireAuth()} />
          <Route path='dealer/:id/edit' component={EditDealer} onEnter={requireAuth(permissions.editDealers)} />
          <Route path='dealer/:id/new-store' component={CreateStore} onEnter={requireAuth(permissions.editDealers)} />
          <Route path='dealer/:id/new-dealer-admin' component={CreateDealerAdmin} onEnter={requireAuth(permissions.createDealerAdmin)} />
          <Route path='dealers' component={DealerManagement} onEnter={requireAuth()} />
          <Route path='dealers/:query' component={DealerManagement} onEnter={requireAuth()} />
          <Route path='roles' component={RoleListing} onEnter={requireAuth(permissions.viewApps)} />
          <Route path='roles/:id' component={RoleListing} onEnter={requireAuth(permissions.viewApps)} />
          <Route path='roles/:id/edit' component={RoleManagement} onEnter={requireAuth(permissions.viewApps, permissions.editApps)} />
          <Route path='user/new' component={CreateUser} onEnter={requireAuth(permissions.createUsers)} />
          <Route path='user/:id' component={ShowUser} onEnter={requireAuth(permissions.viewUsers)} />
          <Route path='user/:id/edit' component={EditUser} onEnter={requireAuth(permissions.editUsers, permissions.viewUsers)} />
          <Route path='users' component={UserManagement} onEnter={requireAuth(permissions.viewUsers)} />
          <Route path='users/:query' component={UserManagement} onEnter={requireAuth(permissions.viewUsers)} />
          <Route path='reports' component={Reports} onEnter={requireAuth()} />
          <Route path='reports/users' component={ReportsUsers} onEnter={requireAuth()} />
          <Route path='reports/status' component={ReportsStatus} onEnter={requireAuth()} />
          <Route path='reports/locked' component={ReportsLocked} onEnter={requireAuth()} />
          <Route path='reports/modified' component={ReportsModified} onEnter={requireAuth()} />
          <Route path='reports/logins' component={ReportsLogins} onEnter={requireAuth()} />
          <Route path='reports/emails' component={ReportsEmails} onEnter={requireAuth()} />
          <Route path='reports/resets' component={ReportsResets} onEnter={requireAuth()} />
          <Route path='copy' component={RolesCopy} onEnter={requireAuth()} />
        </Route>
      </Router>
    </IntlProvider>
  </Provider>,
  document.getElementById('app-container')
);
