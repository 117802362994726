import {connect} from 'react-redux';
import InnerForm from 'components/layout/ReportsGenerics/InnerForm/InnerForm';
import {getApps} from 'api/app';
import {getGroups} from 'api/group';
import {injectIntl} from 'react-intl';

import {List} from 'immutable';

function mapStateToProps({data}) {
  return {
    apps: new List(data.get('apps').values()),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getApps: (...args) => dispatch(getApps(...args)),
    getGroups: (...args) => dispatch(getGroups(...args)),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(InnerForm));
