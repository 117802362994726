import {List} from 'immutable';
import {addChild, removeChild, getUserBranches} from 'components/pages/Users/Provisioning/ProvisionApp/provisionHelpers';
import {groupTypes} from './constants';

export function generateSetRole(user, apps, updateUser, currentUser, allPermissions) {
  return function setRole(appID) {
    return roleName => {
      let app = user.apps.find(x => x.id === appID) || apps.find(a => a.id === appID);
      if (!app) {
        return;
      }

      // Removing all global permisisons (needed to update roles for no dealer and in case user changed from no dealer to having a dealer)
      app = app.update('assigned', assigned => assigned.filter(x => x.type !== groupTypes.permission));
      user = addChild(user, app);

      let role = app.roles.find(x => x.name === roleName);
      let branches = app.assigned.filter(x => x.type === groupTypes.branch).toArray();

      if (role) {
        app = app.update('assigned', assigned => assigned.filter(x => x.type !== groupTypes.role)); // Remove other roles.
        user = addChild(user, app);

        let perms = role.children.toArray();
        role = role.set('children', new List()); // Remove existing permissions from role so don't inherit

        for (let branch of branches) {
          branch = branch.set('children', new List()); // Clear existing permissions from branch
          user = addChild(user, app, branch);

          for (let perm of perms) {
            user = addChild(user, app, branch, perm); // Add permissions to all provisioned branches
          }
        }

        if (!user.dealer) { // If no dealer set permissions on role setting so they get it right away
          for (let perm of perms) {
            user = addChild(user, app, perm);
          }
        } else {
          let dealerBranches = getUserBranches(app, user.dealer.branches, currentUser, allPermissions);
          if (dealerBranches.size === 1) {
            if (perms.length > 0) {
              for (let perm of perms) {
                user = addChild(user, app, dealerBranches.first(), perm);
              }
            } else {
              user = addChild(user, app, dealerBranches.first());
            }
          }
        }

        user = addChild(user, app, role);
      } else { // If no role then filter out all roles to unselect
        app = app.update('assigned', assigned => assigned.filter(x => x.type !== groupTypes.role));
        user = addChild(user, app);

        for (let branch of branches) {
          branch = branch.set('children', new List()); // Remove all permissions when select no role
          user = addChild(user, app, branch);
        }
      }

      updateUser(user, app.id);
    };
  };
}

export function generateSetPermission(user, apps, updateUser) {
  return function setPermission(appID, branchID, roleName, permissionID) {
    return val => {
      let app = apps.find(a => a.id === appID);
      let branch = user && user.dealer && user.dealer.branches ? user.dealer.branches.find(x => x.id === branchID) : null;
      let permission = app.permissions.find(x => x.id === permissionID);

      if (app && branch && permission) {
        if (val) {
          user = addChild(user, app, branch, permission);
        } else {
          user = removeChild(user, app, branch, permission);
        }
        updateUser(user, app.id);
      } else if (app && permission) {
        if (val) {
          user = addChild(user, app, permission);
        } else {
          user = removeChild(user, app, permission);
        }
        updateUser(user, app.id);
      }
    };
  };
}

export function generateSetBranch(user, apps, updateUser) {
  return function setBranch(appID, branchID) {
    return val => {
      let branch = user.dealer.branches.find(x => x.id === branchID);
      let app = apps.find(a => a.id === appID);
      if (!app || !branch) {
        return;
      }
      if (val) {
        let userApp = user.apps.find(a => a.id === appID);
        if (!userApp) {
          user = addChild(user, app, branch); // Add branch and nothing else if no role
          updateUser(user, app.id);
          return;
        }

        let role = (userApp.assignedRoles || new List()).first();
        if (!role) {
          user = addChild(user, app, branch); // To show validatation notify
          updateUser(user, app.id);
          return;
        }

        let fullRole = app.roles.find(x => x.id === role.id);
        let permissions = fullRole.children.toArray();

        for (let perm of permissions) {
          user = addChild(user, app, branch, perm); // Set permissions on branch to equal that of role
        }

        // Add branch if no permissions
        if (permissions.length === 0) {
          user = addChild(user, app, branch);
        }
      } else {
        user = removeChild(user, app, branch); // Remove branch
      }

      updateUser(user, app.id);
    };
  };
}

export function generateSetAttribute(user, updateUser) {
  return function setAttribute(attr, appID) {
    return val => {
      if (val) {
        attr = attr.set('value', val);
        user = user.update('attributes', attrs => attrs.filter(x => x.id !== attr.id).push(attr));
      } else {
        user = user.update('attributes', attrs => attrs.filter(x => x.id !== attr.id));
      }

      updateUser(user, appID);
    };
  };
}
