import {connect} from 'react-redux';
import {getUser, lockUser, deleteUser, resendWelcomeEmail} from 'api/user';
import ShowUser from 'components/pages/Users/View/View';
import {getApps} from 'api/app';
import {setUser} from 'redux/actions/users';
import {toggleModal} from 'redux/actions/modals';


function mapStateToProps({data, ui, locale}, ownProps) {
  return {
    apps: data.get('apps').toList(),
    deleteModalVisible: ui.getIn(['modals', 'deleteUser']),
    user: data.getIn(['users', parseInt(ownProps.params.id)]),
    loadData: data.get('currentPage') !== data.get('previousPage').replace('/edit', ''),
    roles: locale.get('roles'),
    locale: locale.get('locale'),
    possibleLocales: locale.get('possibleLocales'),
    regions: locale.get('regions'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getApps: (...args) => dispatch(getApps(...args)),
    getUser: (id) => dispatch(getUser(id)),
    setUser: (...args) => dispatch(setUser(...args)),
    lockUser: (id, lockState) => dispatch(lockUser(id, lockState)),
    deleteUser: (...args) => dispatch(deleteUser(...args)),
    toggleUserDeleteModal: (...args) => dispatch(toggleModal('deleteUser', ...args)),
    resendWelcomeEmail: (...args) => dispatch(resendWelcomeEmail(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowUser);
