import React, {createClass, PropTypes} from 'react';
import {PureRenderMixin} from 'helpers/shallowCompare';

import Card from 'components/layout/Card/Card';
import DatePicker from 'components/forms/DatePicker/DatePicker';
import styles from './InnerForm.module.scss';
import {Input} from 'components/forms';
import Select from 'components/forms/Select/Select';
import {ReportSettings, GroupModel} from 'models';

import {groupTypes} from 'helpers/constants';

import moment from 'moment';

import {Set, List} from 'immutable';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const InnerForm = createClass({
  displayName: 'InnerForm',
  mixins: [PureRenderMixin],
  propTypes: {
    run: PropTypes.func.isRequired,
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    update: PropTypes.func.isRequired,
    apps: PropTypes.instanceOf(List),
    groups: PropTypes.instanceOf(List),
    getApps: PropTypes.func.isRequired,
    getGroups: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  },

  getDefaultProps() {
    return {
      apps: new List(),
    };
  },

  contextTypes: {
    canAccessAllDealers: PropTypes.bool.isRequired,
    currentUser: PropTypes.any.isRequired,
    role: PropTypes.string.isRequired,
  },

  componentWillMount() {
    this.props.getApps();
    this.props.getGroups(groupTypes.branch);
  },

  getSettings() {
    let settings = this.props.settings;
    let keys = new Set(Object.keys(this.refs)).intersect(ReportSettings.keys);
    for (let key of keys) {
      settings = settings.set(key, this.refs[key].getValue());
    }

    return settings;
  },

  run() {
    if (this.props.run && this.props.update) {
      let settings = this.getSettings();
      this.props.update(settings);
      this.props.run(settings);
    }
  },

  updateDate(key) {
    return value => {
      if (this.props.update) {
        let settings = this.getSettings();
        settings = settings.setIn(key, value);

        this.props.update(settings);
      }
    };
  },

  render() {
    let {settings} = this.props;
    let {formatMessage} = this.props.intl;

    const all = formatMessage({id: 'reports.filters.all'});

    let appOptions = {'': all};
    let apps = this.props.apps.toArray();
    for (let app of apps) {
      appOptions[app.name] = app.displayName;
    }

    let roleOptions = {};
    if (settings.app) {
      let app = this.props.apps.find(x => x.name === settings.app);
      let userApp = this.currentUser.apps.find(x => x.id === app.id);
      let userRole;
      if (userApp) {
        let uroles = GroupModel.flatten(userApp.assigned).filter(x => x.type === groupTypes.role).sort((a, b) => a.priority > b.priority ? -1 : 1);
        if (uroles.size > 0) {
          userRole = uroles.first();
        }
      }

      let roles = app.roles.filter(x => !x.priority || (userRole && x.priority <= userRole.priority));
      for (let role of roles) {
        roleOptions[role.name] = role.displayName;
      }
    }
    roleOptions[''] = all;


    let idm = this.context.currentUser.get('apps').find(x => x.clientID === window.clientID);


    let branchOptions = {};
    if (this.context.role === 'admin' && idm) {
      let currentBranches = idm.getIn(['assigned']).filter(x => x.type === groupTypes.branch).toArray();

      for (let branch of currentBranches) {
        branchOptions[branch.shipTo] = branch.shipTo;
      }
    }
    branchOptions[''] = all;

    let shipToDisabled = false;
    let soldToDisabled = false;

    if (!this.context.canAccessAllDealers) {
      console.log(this.context.currentUser.toJS(), this.context.currentUser.getIn(['dealer', 'soldTo']));
      soldToDisabled = true;
      settings = settings.set('soldTo', this.context.currentUser.getIn(['dealer', 'soldTo']));

      if (this.context.role === 'manager') {
        shipToDisabled = true;
        if (idm) {
          let currentBranch = idm.getIn(['assigned']).filter(x => x.type === groupTypes.branch).first();
          if (currentBranch) {
            settings = settings.set('shipTo', currentBranch.shipTo);
          }
        }
      }
    }

    return <div>
      <Card minHeight={0} style={{marginBottom: 20}} noPadding={true} >
        <div className='row'>
          <div className={'clearfix ' + styles.box}>
            <DatePicker ref='startDate' label={formatMessage({id: 'reports.filters.from'}) + ':'} colSize='col-sm-2'
              value={settings.from} onChange={this.updateDate(['from'])}
              maxDate={settings.to} />
            <DatePicker ref='endDate' label={formatMessage({id: 'reports.filters.to'}) + ':'} colSize='col-sm-2' value={settings.to} minDate={settings.from}
              maxDate={moment()} onChange={this.updateDate(['to'])}  />
            <div className='col-sm-4 text-center' style={{marginTop: 25}}>
              <span><strong><FormattedMessage id='reports.filters.compare' /></strong></span>
            </div>
            <DatePicker ref='compStartDate' label={formatMessage({id: 'reports.filters.from'}) + ':'} colSize='col-sm-2' value={settings.compare.from}
              maxDate={settings.compare.to} onChange={this.updateDate(['compare', 'from'])}  />
            <DatePicker ref='compEndDate' label={formatMessage({id: 'reports.filters.to'}) + ':'} colSize='col-sm-2' value={settings.compare.to}
              maxDate={moment()} minDate={settings.compare.from} onChange={this.updateDate(['compare', 'to'])}  />
          </div>
        </div>
        <div className='row'>
          <div className={styles.boxBottom + ' clearfix ' + styles.box}>
            <div className='row'>
              <div className='col-sm-3'>
                <h4><FormattedMessage id='reports.filters.dealer' /></h4>
              </div>
              <div className='col-sm-3'>
                <h4><FormattedMessage id='reports.filters.provisioning' /></h4>
              </div>
              <div className='col-sm-3'>
                <h4><FormattedMessage id='reports.filters.user' /></h4>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-3'>
                <Input label={formatMessage({id: 'reports.filters.soldTo'}) + ':'} ref='soldTo' value={settings.soldTo} disabled={soldToDisabled} />
              </div>
              <div className='col-sm-3'>
                <Select label={formatMessage({id: 'reports.filters.app'}) + ':'} ref='app' value={settings.app}
                  options={appOptions} onSelect={this.updateDate(['app'])} />
              </div>
              <div className='col-sm-3'>
                <Input label={formatMessage({id: 'reports.filters.email'}) + ':'} ref='user' value={settings.user} />
              </div>
              <div className='col-sm-3 text-right'>
                <button className={styles.submitButton + ' btn btn-primary'} onClick={this.run}><FormattedMessage id='reports.filters.run' /></button>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-3'>
                {this.context.role === 'admin' ? <Select label={formatMessage({id: 'reports.filters.shipTo'}) + ':'} ref='shipTo'
                  value={settings.shipTo} options={branchOptions} />
                 : <Input label={formatMessage({id: 'reports.filters.shipTo'}) + ':'}
                    ref='shipTo' value={settings.shipTo} disabled={shipToDisabled} />}
              </div>
              <div className='col-sm-3'>
                <Select label={formatMessage({id: 'reports.filters.role'}) + ':'}
                  ref='role' value={settings.role} options={roleOptions} disabled={!settings.app} />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(InnerForm);
