import Footer from 'components/layout/Footer/Footer';
import {connect} from 'react-redux';
import {getPrivacyPolicy, getTermsOfUse} from 'api/info';

import {injectIntl} from 'react-intl';

function mapStateToProps({data, locale}) {
  return {
    locale: locale.get('locale'),
    privacyPolicy: data.getIn(['info', 'privacyPolicy']),
    termsOfUse: data.getIn(['info', 'termsOfUse']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPrivacyPolicy: (...args) => dispatch(getPrivacyPolicy(...args)),
    getTermsOfUse: (...args) => dispatch(getTermsOfUse(...args)),
  };
}

// Need to wrap all connected components that aren't rendered directly in React Router with injectIntl so it updates on the first language change properly.
// This is currently the only component where it is necessary however.
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Footer));
