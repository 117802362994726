import generateModel from 'helpers/modelGenerator';
import {List} from 'immutable';

import {groupTypes} from 'helpers/constants';

import {UserSummary} from './userSummary';

const GroupModel = generateModel({
  id: 'ID',
  name: {value: 'Name', default: '', required: true},
  displayName: {value: 'DisplayName', default: '', required: true},
  deleted: 'Deleted',
  transferable: {value: 'Transferable', default: false},
  children: {value: 'Children', default: new List(), type: 'self'},
  type: {value: 'Type', default: 3},
  shipTo: {value: 'ShipTo', default: ''},
  soldTo: {value: 'SoldTo', default: '',
    validate: st => {
      if (st.length >= 10) {
        return {valid: false, message: 'Must be 10 characters or less.'};
      }
      return {valid: true};
    },
  },
  branches: group => group.children.filter(x => x.type === groupTypes.branch),
  users: {value: 'Users', default: new List(), type: UserSummary},
  dsmName: {value: 'DSMName', default: ''},
  rmNumber: {value: 'RMNumber', default: ''},
  platinumStatus: {value: 'PlatinumStatus', default: '', validate: val => {return {valid: !val || val.length <= 2, message: 'Must be 2 characters or less.'};}},
  primaryAdmins: {value: 'DealerAdmins', default: new List(), type: UserSummary},
  priority: {value: 'Priority', default: 0},
  roles: val => GroupModel.flatten(val.children).filter(x => x.type === groupTypes.role),
  permissions: val => GroupModel.flatten(val.children).filter(x => x.type === groupTypes.permission),
  region: {value: 'Region', default: 'US'},
  locale: {value: 'Locale', default: 'en-US'},
});

GroupModel.flatten = function flattenGroups(xs, output = new List()) {
  if (xs.size === 0) {
    return output;
  }
  let x = xs.last();
  xs = xs.pop();
  let children = x.children;
  x = x.set('children', new List());
  output = output.push(x);
  output = flattenGroups(children, output);
  return flattenGroups(xs, output);
};

export default GroupModel;
