import createRequest, {methodTypes} from 'helpers/request';
import {GroupModel, UserModel, AttributeModel} from 'models';
import Immutable, {List} from 'immutable';
import {setGroupUsers} from 'redux/actions/dealers';

import {setGroups, setGroup, deleteGroup, dealerSearch} from 'redux/actions/groups';
import {setUser} from 'redux/actions/users';
import {setAttribute} from 'redux/actions/attributes';

import {groupTypes} from 'helpers/constants';

export function getGroups(type = -1) {
  return dispatch => {
    let req = createRequest(`/api/Group/All?type=${type}`, {
      authorize: true,
    });

    req
      .promise()
      .then(groupsRes => {
        dispatch(setGroups(groupsRes.data.map(g => GroupModel.fromApiFormat(g))));
      });

    return req;
  };
}

export function searchDealers(query, page = 1, pageSize = 15, sort = '', order = 'asc') {
  return dispatch => {
    let request = createRequest(`/api/Group/Search?type=${groupTypes.dealer}&query=${query}&page=${page}&pageSize=${pageSize}&sort=${sort}&order=${order}`,
      {authorize: true});

    request
      .promise()
      .then(dealerRes => {
        let groups = Immutable.fromJS(dealerRes.data);
        dispatch(dealerSearch(groups));
      });

    return request;
  };
}

export function getGroupUsers(groupID) {
  return dispatch => {
    let request = createRequest(`/api/Group/Users?groupID=${groupID}`,
      {authorize: true});

    request
      .promise()
      .then(res => {
        const users =  new List(res.data);

        dispatch(setGroupUsers(groupID, users));
        return users;
      });

    return request;
  }
}

export function getGroup(id) {
  return dispatch => {
    let req = createRequest(`/api/Group?id=${id}`, {
      authorize: true,
    });

    req
      .promise()
      .then(groupRes => {
        let group = GroupModel.fromApiFormat(groupRes.data);

        dispatch(setGroup(group));
      });

    return req;
  };
}

export function saveGroup(group) {
  return dispatch => {
    let req = createRequest('/api/Group', {
      method: methodTypes.post,
      authorize: true,
      body: group && group.toApiFormat ? group.toApiFormat() : group && group.toJS() ? group.toJS() : group,
    });

    req
      .promise()
      .then(groupRes => {
        let updated = GroupModel.fromApiFormat(groupRes.data);
        dispatch(setGroup(updated));
      });

    return req;
  };
}

export function removeGroup(groupID) {
  return dispatch => {
    let req = createRequest(`/api/Group/Delete?groupID=${groupID}`, {authorize: true, method: methodTypes.post});

    req
      .promise()
      .then(() => {
        dispatch(deleteGroup());
      });

    return req;
  };
}

export function assignAttribute(groupID, attrID) {
  return dispatch => {
    let req = createRequest(`/api/Group/AssignAttribute?groupID=${groupID}&attrID=${attrID}`, {
      authorize: true,
      method: methodTypes.post,
    });

    req
      .promise()
      .then(groupRes => {
        let updated = GroupModel.fromApiFormat(groupRes.data);
        dispatch(setGroup(updated));
      });

    return req;
  };
}

export function removeAttribute(groupID, attrID) {
  return dispatch => {
    let req = createRequest(`/api/Group/RemoveAttribute?groupID=${groupID}&attrID=${attrID}`, {
      authorize: true,
      method: methodTypes.post,
    });

    req
      .promise()
      .then(groupRes => {
        let updated = GroupModel.fromApiFormat(groupRes.data);
        dispatch(setGroup(updated));
      });

    return req;
  };
}

export function createAttribute(attr, groupID) {
  return dispatch => {
    let req = createRequest(`/api/Group/CreateAttribute?groupID=${groupID}`, {
      authorize: true,
      body: attr.toApiFormat(),
      method: methodTypes.post,
    });

    req
      .promise()
      .then(({attribute, group}) => {
        let newGroup = GroupModel.fromApiFormat(group);
        let newAttr = AttributeModel.fromApiFormat(attribute);

        dispatch(setGroup(newGroup));
        dispatch(setAttribute(newAttr));
      });

    return req;
  };
}

export function saveNewDealer(dealer, user) {
  return dispatch => {
    let req = createRequest('/api/Dealer', {
      authorize: true,
      body: {
        dealer: dealer.toApiFormat(),
        user: user.toApiFormat(),
      },
      method: methodTypes.post,
    });

    req
      .promise()
      .then(newDealer => {
        let d = GroupModel.fromApiFormat(newDealer.data);

        dispatch(setGroup(d));
      });

    return req;
  };
}

export function saveNewDealerAdmin(dealer, user) {
  return dispatch => {
    let req = createRequest('/api/Dealer/Admin', {
      authorize: true,
      body: {
        dealer: dealer.toApiFormat(),
        user: user.toApiFormat(),
      },
      method: methodTypes.post,
    });

    req
      .promise()
      .then(newUser => {
        let u = UserModel.fromApiFormat(newUser.data);

        dispatch(setUser(u));
      });

    return req;
  };
}

export function checkSoldTo(soldTo, id) {
  return () => {
    let req = createRequest('/api/Group/SoldToUsed', {
      authorize: true,
      body: {
        id: id,
        soldTo: soldTo,
      },
      method: methodTypes.get,
    });

    req
      .promise()
      .then(checkRes => {
        if (checkRes.data) {
          let duplicate = GroupModel.fromApiFormat(checkRes.data);

          return duplicate;
        }
        return checkRes.data;
      });

    return req;
  };
}

export function checkShipTo(shipTo, id) {
  return () => {
    let req = createRequest('/api/Group/ShipToUsed', {
      authorize: true,
      body: {
        id: id,
        shipTo: shipTo,
      },
      method: methodTypes.post,
    });

    req
      .promise()
      .then(checkRes => {
        if (checkRes.data) {
          let duplicate = GroupModel.fromApiFormat(checkRes.data);

          return duplicate;
        }
        return checkRes.data;
      });

    return req;
  };
}
