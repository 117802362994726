export const flowTypes = {
  code: 0,
  implicit: 1,
};

export const flowNames = {
  0: 'Code',
  1: 'Implicit',
};

export const provisionTypes = {
  dealerBased: 0,
  roleBased: 1,
};

export const groupTypes = {
  dealer: 0,
  branch: 1,
  role: 2,
  permission: 3,
};

export const groupTypeNames = {
  0: 'Dealer',
  1: 'Branch',
  2: 'Role',
  3: 'Other',
};

export const showMaintenanceMessage = false;
