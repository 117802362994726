import {
  SET_DELETE_STORE, CLEAR_DELETE_STORE,
  NAVIGATE_DEALER_WIZARD,
  SET_DELETE_DEALER, CLEAR_DELETE_DEALER,
  SET_INLINE_DEALER, CLEAR_INLINE_DEALER,
  SET_GROUP_USERS,
} from './index';

import {GroupModel, UserModel} from 'models';

import {groupTypes} from 'helpers/constants';

export function setDeleteStore(store) {
  return {
    type: SET_DELETE_STORE,
    value: store,
  };
}

export function clearDeleteStore() {
  return {
    type: CLEAR_DELETE_STORE,
  };
}

export function setDeleteDealer(dealer) {
  return {
    type: SET_DELETE_DEALER,
    value: dealer,
  };
}

export function clearDeleteDealer() {
  return {
    type: CLEAR_DELETE_DEALER,
  };
}

export function navigateDealerWizard(page, dealer, user, duplicate, duplicateStore, duplicateUser) {
  return {
    type: NAVIGATE_DEALER_WIZARD,
    value: page,
    dealer: dealer,
    user: user,
    duplicate: duplicate,
    duplicateStore: duplicateStore,
    duplicateUser: duplicateUser,
  };
}

export function clearDealerWizard() {
  return {
    type: NAVIGATE_DEALER_WIZARD,
    value: 0,
    dealer: new GroupModel({type: groupTypes.dealer}),
    user: new UserModel(),
    duplicate: null,
    duplicateStore: null,
    duplicateUser: null,
  };
}

export function setInlineDealer(dealer) {
  return {
    type: SET_INLINE_DEALER,
    value: dealer,
  };
}

export function clearInlineDealer() {
  return {
    type: CLEAR_INLINE_DEALER,
  };
}

export function setGroupUsers(groupID, users) {
  return {
    type: SET_GROUP_USERS,
    groupID,
    users,
  };
}
