import React, {createClass, PropTypes} from 'react';
import {PureRenderMixin} from 'helpers/shallowCompare';
import {List} from 'immutable';

import {AppModel, GroupModel, UserModel} from 'models';

import {Checkbox} from 'components/forms';
import {Input} from 'components/forms';
import Select from 'components/forms/Select/Select';

import ValidationGroup from 'helpers/validation';

import Icon from 'components/Icon/Icon';

import styles from './ProvisionApp.module.scss';

import {getUsersPermissions, getUsersRole, getBranchPermissions, getAssignablePermissions} from './provisionHelpers';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {getTranslatedName} from 'helpers/roles';


const ProvisionApp = createClass({
  displayName: 'ProvisionApp',
  mixin: [PureRenderMixin],
  propTypes: {
    app: PropTypes.instanceOf(AppModel).isRequired,
    dealer: PropTypes.instanceOf(GroupModel).isRequired,
    deprovisionUser: PropTypes.func.isRequired,
    provisionUser: PropTypes.func.isRequired,
    setAttribute: PropTypes.func.isRequired,
    setBranch: PropTypes.func.isRequired,
    setPermission: PropTypes.func.isRequired,
    setRole: PropTypes.func.isRequired,
    storeVisible: PropTypes.func.isRequired,
    toggleStore: PropTypes.func.isRequired,
    setStoreVisible: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(UserModel).isRequired,
    intl: intlShape.isRequired,
    rolesCopy: PropTypes.instanceOf(List).isRequired,
    locale: PropTypes.string.isRequired,
    userStored: PropTypes.instanceOf(UserModel).isRequired,
    group: PropTypes.instanceOf(ValidationGroup),
  },

  contextTypes: {
    currentUser: PropTypes.instanceOf(UserModel).isRequired,
    allPermissions: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      roleProvision: { isValid: true, message: '' },
    };
  },

  componentWillMount() {
    this.props.group.addComponent(this, false);
  },

  componentWillUnmount() {
    this.props.group.removeComponent(this);
  },

  focus() {
    if (this.inner) {
      this.inner.scrollIntoView();
    }
  },

  validate() {
    if (this.props.user && !this.props.user.dealer) {
      return {valid: true};
    }

    return {valid: this.validateProvisioning(this.props.user, this.props.app)};
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.app) {
      this.validateProvisioning(nextProps.user, nextProps.app);
    }
  },

  validateProvisioning(user, app) {
    if (user && !user.dealer) {
      this.setState({
        roleProision: {isValid: true, message: ''},
      });

      return true;
    }

    let provisionApp = user.apps.find(x => x.id === app.id);
    let haveRoleSelected = (provisionApp && provisionApp.assignedRoles && provisionApp.assignedRoles.size > 0) || false;
    let haveStoresSelected = (provisionApp && provisionApp.assignedBranches && provisionApp.assignedBranches.size > 0) || false;

    if (haveRoleSelected && !haveStoresSelected) {
      this.setState({
        roleProvision: { isValid: false, message: 'Please select one or more stores for the selected role'},
      });
      return false;
    }

    if (!haveRoleSelected && haveStoresSelected) {
      this.setState({
        roleProvision: { isValid: false, message: 'Please select a role for the selected store(s)'},
      });

      return false;
    }

    if (!haveRoleSelected && !haveStoresSelected) {
      this.setState({
        roleProvision: { isValid: true, message: '', isEmpty: true},
      });

      return true;
    }

    this.setState({
      roleProvision: { isValid: true, message: ''},
    });

    return true;
  },

  // For users who have dealers on apps that support dealers
  getDealerType() {
    let dealer = this.props.dealer;
    let stores = dealer && dealer.branches ? dealer.branches : new List();
    let user = this.props.user;
    let app = this.props.app;

    let storeHeader = <div className={styles.header}>
      <div className='row'>
        <div className='col-xs-1' />
        <div className='col-xs-5'><FormattedMessage id='user.provisioning.store' /></div>
        <div className='col-xs-5'><FormattedMessage id='user.provisioning.shipTo' />'</div>
        <div className='col-xs-1' />
      </div>
    </div>;

    let storeRows = stores.map(store => {
      let button = null;
      if (this.props.app.permissions.size > 0) {
        button = <button className='btn icon-button' onClick={this.props.toggleStore(this.props.app._id + '.' + store._id)}>
          <Icon name={this.props.storeVisible(this.props.app._id + '.' + store._id) ? 'up' : 'down'} />
        </button>;
      }

      // Is assigned to branch?
      let branchAssigned = false;
      let provisioned = user.apps.find(x => x.id === app.id);
      if (provisioned) {
        branchAssigned = !!provisioned.assigned.find(x => x.id === store.id);
      }

      // Is user who's trying edit assigned to that branch?
      let currentUserBranchAssigned = false;
      let currentProvisioned = this.context.currentUser.apps.find(x => x.id === app.id);
      if (currentProvisioned) {
        currentUserBranchAssigned = currentProvisioned.assigned.map(x => x.id).includes(store.id);
      }

      // Block if no permissions for this branch
      if (!currentUserBranchAssigned && !this.context.allPermissions) {
        return null;
      }

      return <div key={store._id} className={styles.provisionRow + ' ' + (this.props.storeVisible(this.props.app._id + '.' + store._id) ? styles.active : '')}>
        <div className='row'>
          <div className='col-xs-1'>
            <Checkbox className='pull-right' ref={this.props.app._id + '.' + store._id} value={branchAssigned}
              onChange={(val) => {this.props.setBranch(this.props.app.id, store.id)(val);}}
              style={{marginTop: 0, marginBottom: 0}}/>
          </div>
          <div className='col-xs-5'>{store.displayName}</div>
          <div className='col-xs-5'>{store.shipTo}</div>
          <div className='col-xs-1'>{button}</div>
        </div>
          {this.props.storeVisible(this.props.app._id + '.' + store._id) ?
            <div className={styles.branchProvision}>{this.getProvisionArea(store._id, store.id)}</div>
          : null}

      </div>;
    }).toArray();

    let attributes = app.attributes.map(attr => {
      let userAttr = user.attributes.find(x => x.id === attr.id);

      return <div key={attr._id} style={{marginTop: 20}}>
        <Input value={userAttr ? userAttr.value : ''} required={attr.required} type='text' ref={attr._id} label={attr.displayName}
          group={this.group} onChange={this.props.setAttribute(attr, this.props.app.id)} />
      </div>;
    });

    return <div>
      {storeRows.length > 0 ? storeHeader : null}
      {storeRows}

      {app.attributes.size > 0 ? <div>{attributes}</div> : null}
    </div>;
  },

  getRoleName(branchID) {
    let key = branchID ? branchID + '.role' : 'role';
    let ref = this.refs[key];
    if (ref) {
      return ref.getValue();
    }
    return '';
  },

  // Custom permissions area for users with no dealers or apps that don't support dealers (in the future)
  getProvisionArea(prefix = '', branchID) {
    let edittingUser = this.props.user;
    let currentUser = this.context.currentUser;
    let app = this.props.app;


    let currentPermissions;
    let edittingPermissions;
    let attributes;
    if (branchID) {
      currentPermissions = getBranchPermissions(currentUser, app, branchID);
      edittingPermissions = getBranchPermissions(edittingUser, app, branchID).map(x => x.id).toArray();
    } else {
      currentPermissions = getUsersPermissions(app, currentUser);
      edittingPermissions = getUsersPermissions(app, edittingUser).map(x => x.id).toArray();

      attributes = app.attributes.map(attr => {
        let userAttr = edittingUser.attributes.find(x => x.id === attr.id);

        return <div key={attr._id}>
          <Input value={userAttr ? userAttr.value : ''} required={attr.required} type='text' ref={attr._id} label={attr.displayName}
            group={this.group} onChange={this.props.setAttribute(attr)} />
        </div>;
      });
    }
    let edittingRole = getUsersRole(app, edittingUser);

    let assignablePermissions = getAssignablePermissions(app, currentPermissions, this.context.allPermissions);
    if (assignablePermissions.size === 0) {
      return null;
    }

    let permissions = app.permissions
    .sort((a, b) => {
      let assignA = assignablePermissions.includes(a.id);
      let assignB = assignablePermissions.includes(b.id);

      if (assignA !== assignB) {
        return assignA ? -1 : 1;
      }
      return a.name < b.name ? -1 : 1;
    })
    .map(x => {
      let ref = prefix ? prefix + '.' + x._id : prefix;

      if (assignablePermissions.includes(x.id)) {
        return <div key={x._id} className='col-md-3'>
          <Checkbox label={x.displayName} ref={ref} value={edittingPermissions.includes(x.id)}
            onChange={this.props.setPermission(app.id, branchID, edittingRole.name, x.id)} />
        </div>;
      }
      return null;
    });

    return <div className='row'>
      <div className='col-md-12'><label><FormattedMessage id='user.provisioning.permissions' />:</label></div>
      {permissions}
      {attributes && app.attributes.size > 0 ? <div className='col-xs-12'>{attributes}</div> : null}
    </div>;
  },

  getRoleType() {
    return this.getProvisionArea();
  },

  provision() {
    this.props.provisionUser(this.props.app);
  },

  deprovision() {
    this.props.deprovisionUser(this.props.app);
  },

  render() {
    let app = this.props.app;
    let user = this.props.user;
    let userStored = this.props.userStored;
    let {formatMessage} = this.props.intl;
    let locale = this.props.locale;

    let currentRole = getUsersRole(app, this.context.currentUser);
    let edittingRole = getUsersRole(app, user);


    let provision;
    if (!user.dealer || !this.props.dealer) {
      provision = this.getRoleType();
    } else {
      provision = this.getDealerType();
    }

    let options = {'': formatMessage({id: 'user.provisioning.selectRole'})};

    // Filter roles where current role + all roles user can assign
    let roles = this.context.allPermissions ?
      app.roles.toArray() :
      app.roles
        .filter(x => !x.priority || x.name === currentRole.name || x.priority <= currentRole.priority)
        .toArray();
    roles = roles.sort((x, y) => x.priority > y.priority ? -1 : 1);
    for (let i = 0; i < roles.length; ++i) {
      options[roles[i].name] = getTranslatedName(this.props.rolesCopy, this.props.locale, roles[i].displayName);
    }

    return <div className={styles.provisionApp} ref={r => this.inner = r}>
      <h3 className={styles.heading}>
        {app.logo ?
          <img src={locale === 'fr-CA' || locale === 'fr' && app.logoF ? app.logoF : app.logo}
               height={40}
               style={{verticalAlign: 'middle', marginRight: 20}} /> :
          null}
        {getTranslatedName(this.props.rolesCopy, this.props.locale, app.displayName)}
        <button className='btn btn-primary pull-right'
                onClick={userStored.apps.map(x => x.id).includes(app.id) ? this.deprovision : this.provision}
                disabled={(!this.state.roleProvision.isValid || this.state.roleProvision) && !userStored.apps.map(x => x.id).includes(app.id)}>
          {userStored.apps.map(x => x.id).includes(app.id) ?
            formatMessage({id: 'user.provisioning.deProvision'}) :
            formatMessage({id: 'user.provisioning.provision'})}
        </button>
      </h3>
      <div className='row'>
        <div className='col-md-12'>
          <Select options={options} value={edittingRole.name} error={!this.state.roleProvision.isValid} errorMessage={this.state.roleProvision.message}
            onSelect={this.props.setRole(this.props.app.id)} ref='role' label={formatMessage({id: 'user.provisioning.role'}) + ':'} />
        </div>
      </div>
      {provision}
      <div className='clearfix' />
    </div>;
  },
});

export default injectIntl(ProvisionApp);
