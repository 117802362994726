import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import ValidationGroup from 'helpers/validation';
import {GroupModel} from 'models';

import {Input} from 'components/forms';

import Icon from 'components/Icon/Icon';

import {Set} from 'immutable';

import {injectIntl, intlShape} from 'react-intl';

const StoreCard = createClass({
  displayName: 'StoreCard',
  mixins: [PureRenderMixin],
  propTypes: {
    group: PropTypes.instanceOf(ValidationGroup),
    store: PropTypes.instanceOf(GroupModel).isRequired,
    delete: PropTypes.func.isRequired,
    deleteEnabled: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
  },

  getStore() {
    let store = this.props.store;

    let keys = new Set(Object.keys(this.refs)).intersect(GroupModel.keys).toArray();
    for (let key of keys) {
      let ref = this.refs[key];
      if (ref && ref.getValue) {
        store = store.set(key, ref.getValue());
      }
    }

    return store;
  },

  render() {
    let {store} = this.props;
    let autoFocus = this.props.group ? this.props.group.first : false;
    let {formatMessage} = this.props.intl;

    return <div>
      <div className='row'>
        <div className='col-md-4'>
          <Input ref='displayName' value={store.displayName} label={formatMessage({id: 'createDealerStore.fields.name'}) + ':'}
            required={true} group={this.props.group} autoFocus={autoFocus} />
        </div>

        <div className='col-md-4'>
          <Input ref='shipTo' limit={10} value={store.shipTo} label={formatMessage({id: 'createDealerStore.fields.shipTo'}) + ':'}
            required={true} group={this.props.group} />
        </div>

        <div className='col-md-4'>
          <button className='btn icon-button' onClick={this.props.delete} disabled={!this.props.deleteEnabled} style={{display: 'inline-block', marginTop: 22}}>
            <Icon name='delete' />
          </button>
        </div>
      </div>
    </div>;
  },
});

export default injectIntl(StoreCard, {withRef: true});
