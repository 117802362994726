import React, {createClass, PropTypes} from 'react';
import {PureRenderMixin} from 'helpers/shallowCompare';
import {Map, List} from 'immutable';

import {UserModel} from 'models';

import {Select} from 'components/forms';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const AssignDealer = createClass({
  displayName: 'AssignDealer',
  mixins: [PureRenderMixin],
  propTypes: {
    back: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    dealers: PropTypes.instanceOf(Map).isRequired,
    submit: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(UserModel).isRequired,
    intl: intlShape.isRequired,
    regions: PropTypes.instanceOf(List).isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
  },

  contextTypes: {
    canChangeDealer: PropTypes.bool.isRequired,
    currentUser: PropTypes.instanceOf(UserModel).isRequired,
  },

  getInitialState() {
    let {user} = this.props;
    return {
      selectedDealer: user && user.dealer && user.dealer.id ? user.dealer.id : '',
      selectedRegion: this.props.user ? this.props.user.region : 'US',
      selectedLocale: user && user.locale ? user.locale : 'en-US',
    };
  },

  setSelectedRegion(region) {
    this.setState({selectedRegion: region});
  },

  setSelectedLocale(locale) {
    this.setState({selectedLocale: locale});
  },

  componentWillMount() {
    if (this.context.currentUser && !this.context.canChangeDealer) { // Auto submit if no permissions to change dealer
      let user = this.props.user;
      user = user.set('dealer', this.context.currentUser.dealer);
      user = user.set('region', this.context.currentUser.dealer.region); // Default to dealer region
      this.props.submit(user);
    }
  },

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextContext.currentUser && !nextContext.canChangeDealer) {
      let user = nextProps.user;
      user = user.set('dealer', nextContext.currentUser.dealer);
      nextProps.submit(user);
    }
  },

  getUser() {
    let user = this.props.user;

    if (this.refs.dealer) {
      let dealerID = parseInt(this.refs.dealer.getValue() || '-1');
      let dealer = this.props.dealers.get(dealerID);
      if (dealer && dealerID !== '-1') {
        user = user.set('dealer', dealer);
      } else {
        user = user.delete('dealer');
      }
    }

    if (this.context.canChangeDealer) {
      user = user.set('region', this.state.selectedRegion);
      user = user.set('locale', this.state.selectedLocale);
    }

    return user;
  },

  changeDealer(val) {
    if (val && this.props.dealers && this.props.dealers.size) {
      let dealer = this.props.dealers.find(x => x.id === parseInt(val));
      this.setState({
        selectedRegion: dealer.region,
        selectedDealer: val,
        selectedLocale: dealer.locale,
      });
    } else {
      this.setState({
        selectedDealer: val,
      });
    }
  },

  submit() {
    return this.props.submit(this.getUser());
  },

  back() {
    return this.props.back(this.getUser());
  },

  render() {
    let user = this.props.user;
    let {formatMessage} = this.props.intl;
    let dealers = this.props.dealers;

    let regionOptions = {};
    let locales = {};
    let regionLocked = false;

    if (this.context.canChangeDealer) {
      let dealer = this.state.selectedDealer ? this.props.dealers.find(x => x.id === parseInt(this.state.selectedDealer)) : null;
      let regions = this.props.regions;
      if (this.state.selectedDealer && dealer) {
        regionLocked = true;
      }

      regionOptions = regions
      .reduce((acc, x) => {
        acc[x.get('region')] = x.get('label');
        return acc;
      }, {});

      locales = this.props.possibleLocales
        .filter(x => x.get('regions').includes(this.state.selectedRegion))
        .reduce((acc, x) => {
          acc[x.get('locale')] = x.get('labels').get(x.get('locale'));
          return acc;
        }, {});

      if (!Object.keys(locales).includes(user.locale)) {
        user = user.set('locale', Object.keys(locales).length > 0 ? Object.keys(locales)[0] : 'en-US');
      }
    }



    let options = {'': '(none)'};
    options = Object.assign(options, dealers.map(x => x.displayName).sort((a, b) => a < b ? -1 : 1).toJS());

    return <div>
      <h2><FormattedMessage id='user.wizard.assignDealer' /></h2>
      <div className='row'>
        <div className='col-md-12'>
          <FormattedMessage id='user.wizard.dealerInstructions' value={{name: user.name}}/>
        </div>
        <div className='col-md-4'>
          <Select options={options} label={formatMessage({id: 'user.wizard.dealer'})} onSelect={this.changeDealer}
            value={this.state.selectedDealer} ref='dealer' />
        </div>
        {this.context.canChangeDealer ?
          <div className='col-md-4'>
            <Select ref='region' label={formatMessage({id: 'user.fields.region'}) + ':'} onSelect={this.setSelectedRegion}
              value={this.state.selectedRegion} options={regionOptions} disabled={regionLocked} />
          </div> : null}
        {this.context.canChangeDealer ?
          <div className='col-md-4'>
            <Select ref='locale' label={formatMessage({id: 'user.fields.locale'}) + ':'} value={this.state.selectedLocale} options={locales}
              onSelect={this.setSelectedLocale} />
          </div> : null}
      </div>
      <div className='row'>
        <div className={'col-xs-12'}>
          <button className='btn btn-default pull-left' onClick={this.back}><FormattedMessage id='user.wizard.back' /></button>
          <button className='btn btn-primary pull-right' onClick={this.submit}><FormattedMessage id='user.wizard.assign' /></button>
          <button className='btn btn-default pull-right' onClick={this.props.cancel}><FormattedMessage id='user.wizard.cancel' /></button>
        </div>
      </div>
    </div>;
  },
});

export default injectIntl(AssignDealer);
