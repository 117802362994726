import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import Card from 'components/layout/Card/Card';
import {Map} from 'immutable';
import styles from './LockAccountB.module.scss';
import MonthLinesChart from 'components/charts/MonthLinesChart/MonthLinesChart';
import moment from 'moment';
import {ReportSettings} from'models/reports';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const LockAccountB = createClass({
  displayName: 'LockAccountB',
  mixins: [PureRenderMixin],
  propTypes: {
    resets: PropTypes.number.isRequired,
    full: PropTypes.instanceOf(Map).isRequired,
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    intl: intlShape.isRequired,
  },

  gotoPage() {
    browserHistory.push('/reports/resets');
  },


  render() {
    let {settings} = this.props;
    let {formatMessage} = this.props.intl;
    let data = this.props.full.map((value, date) => {return {value, date};}).toArray();
    if (data.length === 0) {
      data.push({date: moment().format('YYYY-MM-DD'), value: 0});
    }
    return <div>
      <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}><FormattedMessage id='reports.dashboard.selfServeReset' /></span>
          <button onClick={this.gotoPage} className={'btn btn-transparent pull-right'}><FormattedMessage id='reports.dashboard.fullReportLink' /></button></h3>}
        lightHeader={true}
        headerFontSize={16}
        minHeight={340}
        style={{marginBottom: 20}} >
        <div className='row'>
          <div className='col-sm-12'>
            <MonthLinesChart data={data} legendFields={[
              formatMessage({
                id: 'reports.graphs.dateLegend',
              }, {
                  from: settings.from.format('MMM D, YYYY'),
                  to: settings.to.format('MMM D, YYYY'),
                }
              ),
            ]} />
            <div className='row'>
              <div className='col-sm-12'>
                <p className='text-center' style={{fontWeight: 800, marginBottom: 0}}>
                  <FormattedMessage id='reports.dashboard.totalResets' />:
                  <span style={{verticalAlign: 'middle'}} className={styles.bigNumber}> {this.props.resets}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(LockAccountB);
