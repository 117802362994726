import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {List} from 'immutable';
import {Input} from 'components/forms';
import SortPermission from './SortPermission';
import Icon from 'components/Icon/Icon';
import styles from './CollapseRow.module.scss';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const CollapseRow = createClass({
  displayName: 'CollapseRow',
  mixins: [PureRenderMixin],
  propTypes: {
    role: PropTypes.object.isRequired,
    deleteRole: PropTypes.func.isRequired,
    permissions: PropTypes.instanceOf(List).isRequired,
    intl: intlShape.isRequired,
  },

  getInitialState() {
    return {
      isOpen: false,
    };
  },

  toggleCollapse: function() {
    this.setState({isOpen: !this.state.isOpen});
  },

  render() {
    let role = this.props.role;
    let {formatMessage} = this.props.intl;
    return <tr>
      <td colSpan='5' style={{padding: '0px 0px'}}>
      <table className={styles.table + ' table'} style={{width: '100%', padding: 10}}>
        <tbody>
        <tr>
          <td width='25%'><Input value={role.displayName} ref={'roleDisplayName' + role._id}
            placeholder={formatMessage({id: 'roles.fields.roleDisplayName'})} /></td>
          <td><Input value={role.name} ref={'roleName' + role._id}
            placeholder={formatMessage({id: 'roles.fields.roleName'})} /></td>
          <td width='25%'><Input value={role.priority} ref={'rolePriority' + role._id}
            placeholder={formatMessage({id: 'roles.fields.rolePriority'})} /></td>
          <td width='25%' style={{textAlign: 'right'}}>
            <button className='btn icon-button' onClick={this.props.deleteRole(role._id)}>
                <Icon name='delete' />
            </button>
            <button className={'btn icon-button accordion-toggle ' + styles.toggleVisible} onClick={this.toggleCollapse}>
              <Icon name={ this.state.isOpen ? 'up' : 'down' } />
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <table className={styles.table + ' table'} style={{width: '100%'}}>
        <tbody>
          <tr>
            <td className={this.state.isOpen ? styles.opened : styles.closed } colSpan='5'>
              <h5 className={styles.selectTitle}><FormattedMessage id='roles.role.expandedTitle' /></h5>
              <div id={'col-' + role._id} className={styles.permission}>
                <div id={'col-' + role._id}>
                  <SortPermission input={this.props.permissions} ref='permissions' />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </td>
    </tr>;
  },
});

export default injectIntl(CollapseRow, {withRef: true});
