import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {List, Map} from 'immutable';

import {UserModel} from 'models';
import {groupTypes} from 'helpers/constants';
import ProvisionApp from '../../Provisioning/ProvisionApp/ProvisionApp';

import {addChild, removeChild} from '../../Provisioning/ProvisionApp/provisionHelpers';

import {generateSetBranch, generateSetRole, generateSetPermission, generateSetAttribute} from 'helpers/provisioning';
import ValidationGroup from 'helpers/validation';

import {FormattedMessage} from 'react-intl';

const AssignPermissions = createClass({
  displayName: 'AssignPermissions',
  mixins: [PureRenderMixin],
  propTypes: {
    apps: PropTypes.instanceOf(List).isRequired,
    dealers: PropTypes.instanceOf(Map).isRequired,
    back: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    toggleStoreVisible: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(UserModel).isRequired,
    visibleStores: PropTypes.instanceOf(Map).isRequired,
    locale: PropTypes.string.isRequired,
    rolesCopy: PropTypes.instanceOf(List).isRequired,
  },

  contextTypes: {
    currentUser: PropTypes.instanceOf(UserModel).isRequired,
    allPermissions: PropTypes.bool,
  },

  componentWillMount() {
    this.invalidAppProvisions = [];

    this.group = new ValidationGroup(() => this.props.submit(this.props.user));
  },

  getInitialState() {
    return {
      userWorkingCopy: this.props.user ? this.props.user.clone() : new UserModel({}),
    };
  },

  back() {
    return this.props.back(this.props.user);
  },

  haveValidUserAppProvision(user, appID) {
    let provisionApp = user.apps.find(x => x.id === appID);
    let haveRoleSelected =   (provisionApp && provisionApp.assignedRoles && provisionApp.assignedRoles.size > 0) || false;
    let haveStoresSelected = (provisionApp && provisionApp.assignedBranches && provisionApp.assignedBranches.size > 0) || false;

    return haveStoresSelected === haveRoleSelected;
  },

  checkForEmptyAppProvision(user, appID) {
    let provisionApp = user.apps.find(x => x.id === appID);

    if (provisionApp && provisionApp.assigned && provisionApp.assigned.filter(x => x.type === groupTypes.role || x.type === groupTypes.branch).size === 0) {
      this.deprovisionUser(provisionApp);
    }
  },

  updateUser(user, appID) {
    let validAppProvision = this.haveValidUserAppProvision(user, appID);
    let invalidAppProvisionIndex = this.invalidAppProvisions.indexOf(appID);

    if (validAppProvision) {
      if (invalidAppProvisionIndex > -1) {
        this.invalidAppProvisions.splice(invalidAppProvisionIndex,1);
      }
      this.props.updateUser(user);
      this.checkForEmptyAppProvision(user, appID);
    }else if (invalidAppProvisionIndex < 0) {
      this.invalidAppProvisions.push(appID);
    }
    this.setState({
        userWorkingCopy: user,
      });
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({
        userWorkingCopy: nextProps.user,
      });
    }
  },

  provisionUser(app) {
    let user = this.props.user;
    user = addChild(user, app);
    this.props.updateUser(user);
  },

  deprovisionUser(app) {
    let user = this.props.user;
    // Not dealer admins and not bioguard employees
    if (!this.context.canCreateDealerAdmin && !this.context.allPermissions) {
      let currentApp = user.get('apps').find(x => x.id === app.id);
      let currentBranches = this.context.currentUser.idmBranchIDs;
      currentApp = currentApp.update('assigned', assigned => assigned.filter(x => x.type !== groupTypes.branch || !currentBranches.includes(x.id)));
      user = user.setIn(['apps'], user.get('apps').filter(x => x.get('id') !== app.get('id')).push(currentApp));
    } else {
      user = removeChild(user, app);
    }
    this.props.updateUser(user);
  },

  toggleStoreVisible(id) {
    return () => {
      this.props.toggleStoreVisible(id, !this.isStoreVisible(id));
    };
  },

  setStoreVisible(id) {
    return () => {
      this.props.toggleStoreVisible(id, true);
    };
  },

  isStoreVisible(id) {
    return !!this.props.visibleStores.get(id);
  },

  setBranch(...args) {
    return generateSetBranch(this.state.userWorkingCopy, this.props.apps, this.updateUser)(...args);
  },

  setRole(...args) {
    return generateSetRole(this.state.userWorkingCopy, this.props.apps, this.updateUser, this.context.currentUser, this.context.allPermissions)(...args);
  },

  setPermission(...args) {
    return generateSetPermission(this.state.userWorkingCopy, this.props.apps, this.updateUser)(...args);
  },

  setAttribute(...args) {
    return generateSetAttribute(this.state.userWorkingCopy, this.updateUser)(...args);
  },

  render() {
    let user = this.state.userWorkingCopy;
    let apps = this.props.apps;

    if (!this.context.allPermissions) {
      let provisionedApps = this.context.currentUser.apps.map(x => x.id);
      apps = apps.filter(x => provisionedApps.includes(x.id));
    }

    return <div>
      <h2><FormattedMessage id='user.provisioning.title' /></h2>
      {apps.map(x => <ProvisionApp
        app={x}
        dealer={user.dealer && user.dealer.id ? this.props.dealers.get(user.dealer.id) : null}
        deprovisionUser={this.deprovisionUser}
        key={x._id}
        provisionUser={this.provisionUser}
        ref={x._id}
        group={this.group}
        setStoreVisible={this.setStoreVisible}
        setAttribute={this.setAttribute}
        setBranch={this.setBranch}
        locale={this.props.locale}
        rolesCopy={this.props.rolesCopy}
        setPermission={this.setPermission}
        setRole={this.setRole}
        storeVisible={this.isStoreVisible}
        toggleStore={this.toggleStoreVisible}
        user={user}
        userStored={this.props.user}
      />)}
      <div className='row'>
        <div className={'col-xs-12'}>
          <button className='btn btn-default pull-left' onClick={this.back}><FormattedMessage id='user.wizard.back' /></button>
          <button className='btn btn-primary pull-right' onClick={this.group.validate}><FormattedMessage id='user.wizard.complete' /></button>
          <button className='btn btn-default pull-right' onClick={this.props.cancel}><FormattedMessage id='user.wizard.cancel' /></button>
        </div>
      </div>
    </div>;
  },
});

export default AssignPermissions;
