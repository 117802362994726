import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import {PromiseStoreMixin} from 'helpers/request';
import {Map, List} from 'immutable';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import SearchBar from 'components/forms/SearchBar/SearchBar';
import PaginatedTable from 'components/forms/PaginatedTable/PaginatedTable';

import Icon from 'components/Icon/Icon';

import styles from './Users.module.scss';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {getTranslatedName} from 'helpers/roles';

const UserManagement = createClass({
  displayName: 'UserManagement',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    apps: PropTypes.instanceOf(List).isRequired,
    clearUserSearch: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    hasUserSearched: PropTypes.bool.isRequired,
    getApps: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    searchUsers: PropTypes.func.isRequired,
    users: PropTypes.instanceOf(Map).isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
    rolesCopy: PropTypes.instanceOf(List).isRequired,
  },

  contextTypes: {
    canCreateUsers: PropTypes.bool.isRequired,
  },

  getInitialState: function() {
    return {
      currentColumn: {
        column: 'lastName',
        order: 'asc',
      },
    };
  },

  componentWillMount() {
    this.fetchData(this.props);
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.params.query !== nextProps.params.query || this.props.path !== nextProps.path) {
      this.fetchData(nextProps);
    }
  },

  fetchData(props) {
    // Page maps to /users by default; I am using /users/ to redirect from dashboard for empty search to populate results
    if (props.params.query || props.path === '/users/') {
      this.promises.add(this.props.searchUsers(props.params.query ? decodeURIComponent(props.params.query) : ''));
      this.promises.add(this.props.getApps());
    }
  },

  componentWillUnmount() {
    this.props.clearUserSearch();
  },

  runSearch(val) {
    browserHistory.replace('/users/' + encodeURIComponent(val));
  },

  editUser(id) {
    return () => {
      browserHistory.push('/user/' + id);
    };
  },

  changePage(num) {
    return () => {
      let currentPage = parseInt(this.props.users.get('CurrentPage')) || 1;
      if (currentPage !== parseInt(num) && parseInt(num) > 0 && parseInt(num) <= this.props.users.get('TotalPages')) {
        this.promises.add(this.props.searchUsers(this.props.params.query ? decodeURIComponent(this.props.params.query) : '', num));
      }
    };
  },

  addUser() {
    browserHistory.push('/user/new');
  },

  columnActions(colName) {
    let order = 'asc';
    if (colName === this.state.currentColumn.column) {
      if (this.state.currentColumn.order === 'asc') {
        order = 'desc';
      } else {
        order = 'asc';
      }
    }
    this.setState({
      currentColumn: {
        column: colName,
        order: order,
      },
    });
    this.promises.add(this.props.searchUsers(this.props.params.query || '', this.props.users.get('CurrentPage') || 1, null, colName, order));
  },

  getColour(id) {
    let apps = this.props.apps;
    let app = apps.find(x => x.id === id);
    if (app) {
      return app.colour;
    }
    return '#96a1ab';
  },

  render() {
    let {formatMessage} = this.props.intl;

    let userRows = this.props.users.get('Data').map(x => {
      return <tr key={x.get('ID')} className={styles.userRow} onClick={this.editUser(x.get('ID'))}>
        <td>{x.get('Name')}</td>
        <td>{x.get('Email')}</td>
        <td>
          {x.get('Apps')
            .filter(y => {
              let app = this.props.apps.find(a => a.get('id') === y.get('ID'));

              return app && !app.deleted;
            })
            .map(y => (
              <span className='badge'
                    key={y.get('ID')}
                    style={{backgroundColor: this.getColour(y.get('ID'))}}>
                {getTranslatedName(this.props.rolesCopy, this.props.locale, y.get('DisplayName'))}
              </span>))}
        </td>
      </tr>;
    });

    let results = this.props.users.get('Data').size === 0 ? <p><FormattedMessage id='users.search.noResults' /></p> :
    <PaginatedTable totalPages={this.props.users.get('TotalPages')} currentPage={this.props.users.get('CurrentPage')} spread={5} setPage={this.changePage}>
      <table className='table table-striped table-hover'>
        <thead className={styles.tablehead}>
          <tr>
            <th width='25%'>
              <span onClick={() => this.columnActions('lastName')} >
                <FormattedMessage id='users.search.name' /> {this.state.currentColumn.column === 'lastName' ?
                        <Icon name={this.state.currentColumn.order === 'asc' ? 'up' : 'down' } /> : null}
              </span>
            </th>
            <th width='30%'>
              <span onClick={() => this.columnActions('email')}>
                <FormattedMessage id='users.search.email' /> {this.state.currentColumn.column === 'email' ?
                        <Icon name={this.state.currentColumn.order === 'asc' ? 'up' : 'down' } /> : null}
              </span>
            </th>
            <th width='45%'><FormattedMessage id='users.search.apps' /></th>
          </tr>
        </thead>
        <tbody>
          {userRows}
        </tbody>
      </table>
    </PaginatedTable>;

    return <div>
    <PageHeader title={formatMessage({id: 'users.pageHeader'})} backButton={false} right={<div>
      <button className='btn'>{/*<Icon name='info' />*/}</button>
      </div>} />
    <SearchBar buttonAction={this.addUser} buttonLabel={formatMessage({id: 'users.search.createUser'})}
      placeholder={formatMessage({id: 'users.search.instructions'})} runSearch={this.runSearch} query={this.props.params.query}
      canAdd={this.context.canCreateUsers} />
      {this.props.hasUserSearched ? results : null}
    </div>;
  },
});

export default injectIntl(UserManagement);
