import {SET_GROUP, SET_GROUPS, DELETE_GROUP, CLEAR_DEALER_SEARCH, SEARCH_DEALERS} from './index';

export function setGroups(groups) {
  return {
    type: SET_GROUPS,
    value: groups,
  };
}

export function setGroup(group) {
  return {
    type: SET_GROUP,
    value: group,
  };
}

export function deleteGroup(id) {
  return {
    type: DELETE_GROUP,
    value: id,
  };
}

export function clearDealerSearch() {
  return {
    type: CLEAR_DEALER_SEARCH,
  };
}

export function dealerSearch(dealers) {
  return {
    type: SEARCH_DEALERS,
    value: dealers,
  };
}
