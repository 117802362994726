import React, {createClass, PropTypes} from 'react';
import {Set, List} from 'immutable';

import {UserModel, GroupModel} from 'models';
import ValidationGroup from 'helpers/validation';

import {Input, Select} from 'components/forms';

import phoneFormat from 'helpers/phoneFormat';

import styles from './CreateAdmin.module.scss';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const CreateAdmin = createClass({
  displayName: 'CreateAdmin',
  // Mixins: [PureRenderMixin],
  propTypes: {
    dealer: PropTypes.instanceOf(GroupModel).isRequired,
    user: PropTypes.instanceOf(UserModel).isRequired,
    back: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    regions: PropTypes.instanceOf(List).isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
  },

  componentWillMount() {
    this.group = new ValidationGroup(this.submit);
  },

  getUser() {
    let user = this.props.user;

    let keys = new Set(Object.keys(this.refs)).intersect(UserModel.keys).toArray();
    for (let key of keys) {
      let ref = this.refs[key];
      if (ref) {
        user = user.set(key, ref.getValue());
      }
    }

    return user;
  },

  back() {
    return this.props.back(this.props.dealer, this.getUser());
  },

  submit() {
    return this.props.submit(this.props.dealer, this.getUser());
  },

  render() {
    let {user, dealer} = this.props;
    let {formatMessage} = this.props.intl;

    let localeOptions = this.props.possibleLocales
      .filter(x => x.get('regions').includes(dealer.region))
      .reduce((acc, x) => {
        acc[x.get('locale')] = x.get('labels').get(x.get('locale'));
        return acc;
      }, {});

    return <div>
      <div className='row'>
        <div className='col-md-4'>
          <Input value={user.firstName} label={formatMessage({id: 'addDealerAdmin.fields.firstName'}) + ':'}
            ref='firstName' autoFocus group={this.group}
            required={UserModel.isRequired('firstName')}
            validate={UserModel.getValidate('firstName')} />
        </div>
        <div className='col-md-4'>
          <Input value={user.lastName} label={formatMessage({id: 'addDealerAdmin.fields.lastName'}) + ':'}
            ref='lastName' required={UserModel.isRequired('lastName')} group={this.group}
            validate={UserModel.getValidate('lastName')} />
        </div>
        <div className='col-md-4'>
          <Input value={user.email} label={formatMessage({id: 'addDealerAdmin.fields.email'}) + ':'}
            ref='email' required={UserModel.isRequired('email')} type='email' group={this.group} />
        </div>
        <div className='col-md-4'>
          <Input value={user.mobilePhone} label={formatMessage({id: 'addDealerAdmin.fields.mobilePhone'}) + ':'}
              ref='mobilePhone' format={phoneFormat} group={this.group}
              required={UserModel.isRequired('mobilePhone')} validate={UserModel.getValidate('mobilePhone')} />
        </div>
        <div className='col-md-4'>
          <Input value={user.AlternatePhone} label={formatMessage({id: 'addDealerAdmin.fields.alternatePhone'}) + ':'}
              ref='alternatePhone' format={phoneFormat} group={this.group}
              required={UserModel.isRequired('alternatePhone')} validate={UserModel.getValidate('alternatePhone')} />
        </div>
        <div className='col-md-4'>
          <Select label={formatMessage({id: 'addDealerAdmin.fields.language'}) + ':'} value={user.locale} ref='locale'
            options={localeOptions} />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          <p className={styles.disclaimer}>
            <FormattedMessage id='createDealerAdmin.disclaimer' />
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <button className='btn btn-default pull-left' onClick={this.back}><FormattedMessage id='createDealer.back' /></button>
          <button className='btn btn-primary pull-right' onClick={this.group.validate}><FormattedMessage id='createDealer.continue' /></button>
          <button className='btn btn-default pull-right' onClick={this.props.cancel}><FormattedMessage id='createDealer.cancel' /></button>
        </div>
      </div>
    </div>;
  },
});

export default injectIntl(CreateAdmin);
