import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import {List, Map} from 'immutable';

import debounce from 'lodash.debounce';

import {PromiseStoreMixin} from 'helpers/request';

import {UserModel} from 'models';

import Card from 'components/layout/Card/Card';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import UserWizard from './UserWizard/UserWizard';

import AssignDealer from './AssignDealer/AssignDealer';
import AssignPermissions from './AssignPermissions/AssignPermissions';
import UserCreation from './UserCreation/UserCreation';

import styles from './Create.module.scss';

import {injectIntl, intlShape} from 'react-intl';

const Create = createClass({
  displayName: 'Create',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    addToast: PropTypes.func.isRequired,
    apps: PropTypes.instanceOf(List).isRequired,
    cancelUserWizard: PropTypes.func.isRequired,
    checkEmailUsed: PropTypes.func.isRequired,
    currentUser: PropTypes.instanceOf(UserModel).isRequired,
    dealers: PropTypes.instanceOf(Map).isRequired,
    duplicateUser: PropTypes.instanceOf(UserModel),
    getApps: PropTypes.func.isRequired,
    getDealers: PropTypes.func.isRequired,
    navigateUserWizard: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    saveUser: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(UserModel).isRequired,
    toggleStoreVisible: PropTypes.func.isRequired,
    visibleStores: PropTypes.instanceOf(Map).isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
    rolesCopy: PropTypes.instanceOf(List).isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
    regions: PropTypes.instanceOf(List).isRequired,
  },

  contextTypes: {
    allPermissions: PropTypes.bool.isRequired,
  },

  componentWillMount() {
    this.props.getApps();
    this.props.getDealers();

    this.finishWizard = debounce((...args) => this._finishWizard(...args), 500, {
      leading: true,
      trailing: false,
    });
  },

  componentWillUnmount() {
    this.props.cancelUserWizard();
  },

  cancel() {
    this.props.cancelUserWizard();
    browserHistory.push('/users');
  },

  tryAgain() {
    this.props.cancelUserWizard();
  },

  assignDealer() {
    let user = this.props.user;
    if (this.context.allPermissions) {
      let dealer = this.props.currentUser.dealer;
      if (dealer) {
        user = user.set('dealer', dealer);
        user = user.set('region', dealer.region);
      }
    } else if (this.refs.dealer) {
      let val = this.refs.dealer.getValue();
      if (val !== '') {
        user = user.set('dealer', this.props.dealers.get(parseInt(val)));
      }
    }

    let nextPage = this.props.page + 1;
    this.props.navigateUserWizard(nextPage, user);
  },

  continue(forward = true) {
    return (user) => {
      if (forward && this.props.page === 0) {
        this.promises.add(this.props.checkEmailUsed(user));
      } else if (!forward && !this.context.allPermissions && this.props.page === 2) {
        this.props.navigateUserWizard(0, user);
      } else {
        let nextPage = forward ? this.props.page + 1 : this.props.page - 1;
        this.props.navigateUserWizard(nextPage, user);
      }
    };
  },

  _finishWizard(user) {
    this.promises.add(this.props.saveUser(user))
      .promise()
      .then(userRes => {
        let userData = userRes.data;
        if (userRes && userData) {
          browserHistory.push('/user/' + userRes.data.ID);
          return true;
        }
        return Promise.reject(userRes);
      })
      .catch(e => {
        console.error(e);
      });
  },

  getFirstPage() {
    return <UserCreation submit={this.continue()} cancel={this.cancel} user={this.props.user}
      possibleLocales={this.props.possibleLocales} currentUser={this.props.currentUser} />;
  },

  getSecondPage() {
    return <AssignDealer submit={this.continue()} cancel={this.cancel} back={this.continue(false)} user={this.props.user} dealers={this.props.dealers}
      regions={this.props.regions} possibleLocales={this.props.possibleLocales} />;
  },

  getThirdPage() {
    return <AssignPermissions
      apps={this.props.apps}
      back={this.continue(false)}
      cancel={this.cancel}
      submit={this.finishWizard}
      dealers={this.props.dealers}
      toggleStoreVisible={this.props.toggleStoreVisible}
      updateUser={user => this.props.navigateUserWizard(this.props.page, user)}
      user={this.props.user}
      visibleStores={this.props.visibleStores}
      rolesCopy={this.props.rolesCopy}
      locale={this.props.locale}
    />;
  },


  getEmailUsedPage() {
    let duplicateUser = this.props.duplicateUser;

    let toProfile = () => {
      browserHistory.push('/user/' + duplicateUser.id);
    };

    return <div>
      <h2>Email address <em>{this.props.user.email}</em> already exists</h2>
      <div className='well'>
        <div className='row'>
          <div className='col-xs-4'>
            <div className='form-group'>
              <label>Name</label>
              <div className='form-control-static'>{duplicateUser.name}</div>
            </div>
          </div>

          <div className='col-xs-4'>
            <div className='form-group'>
              <label>Email Address</label>
              <div className='form-control-static'>{duplicateUser.email}</div>
            </div>
          </div>

          <div className='col-xs-4'>
            <div className='form-group'>
              <label>Mobile Phone</label>
              <div className='form-control-static'>{duplicateUser.mobilePhone}</div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className={'col-xs-12 ' + styles.btnRow}>
            <button className='btn-primary btn pull-right' onClick={toProfile}>User Profile</button>
            <button className='btn-primary btn pull-right' onClick={this.tryAgain}>Try Again</button>
            <button className='btn-default btn pull-right' onClick={this.cancel}>Cancel</button>
          </div>
        </div>
      </div>
    </div>;
  },

  render() {
    let {formatMessage} = this.props.intl;

    let page;
    switch (this.props.page) {
      case 0: {
        page = this.getFirstPage();
        break;
      }
      case 1: {
        page = this.getSecondPage();
        break;
      }
      case 2: {
        page = this.getThirdPage();
        break;
      }
      case -1: {
        page = this.getEmailUsedPage();
        break;
      }
      default: {
        page = null;
        break;
      }
    }

    let header = <UserWizard active={this.props.page} />;

    return <div>
      <PageHeader title={formatMessage({id: 'user.wizard.pageHeader'})} backButton={false} />
      <Card header={header} noHeaderPadding={true} headerHeight={40}>
        {page}
        <div  style={{marginBottom: 10}} />
      </Card>
    </div>;
  },
});

export default injectIntl(Create);
