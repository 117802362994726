import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import {List} from 'immutable';

import {getTranslatedName} from 'helpers/roles';
import {PromiseStoreMixin} from 'helpers/request';
import {langImageMatcher} from 'helpers/translationHelper';

import {AppModel} from 'models';

import AppTitle from '../Title/Title';
import CardTable from 'components/layout/CardTable/CardTable';
import {Input as ReadOnlyInput} from 'components/forms/ReadOnly';
import {TextArea} from 'components/forms/Readonly';
import {Input} from 'components/forms';
import Modal from 'components/layout/Modal/Modal';

import {colsToRows} from 'helpers/tables';
import ValidationGroup from 'helpers/validation';

import Icon from 'components/Icon/Icon';

import {injectIntl, intlShape, FormattedMessage} from 'react-intl';

import styles from './View.module.scss';

const View = createClass({
  displayName: 'View',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    app: PropTypes.instanceOf(AppModel),
    groups: PropTypes.object.isRequired,
    new: PropTypes.bool.isRequired,
    deleteApp: PropTypes.func.isRequired,
    locale: PropTypes.string,
    deleteModalVisible: PropTypes.bool.isRequired,
    hideDeleteModal: PropTypes.func.isRequired,
    openDeleteModal: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    rolesCopy: PropTypes.instanceOf(List),
  },

  contextTypes: {
    canDeleteApps: PropTypes.bool.isRequired,
    canModifyApps: PropTypes.bool.isRequired,
  },

  componentWillMount() {
    this.group = new ValidationGroup(this.save);
  },

  getDefaultProps() {
    return {
      app: new AppModel({}),
    };
  },

  getGroupsSection() {
    let app = this.props.app;

    let rows = colsToRows(
      app.roles.map(x => x.displayName).toJS(),
      app.attributes.map(x => x.name).toJS(),
      app.permissions.map(x => x.displayName).toJS()
    );

    return <CardTable style={{marginTop: 20}} stripedColumns={true} stripedRows={false} header={
        <tr>
          <th><FormattedMessage id='apps.table.roles' /></th>
          <th><FormattedMessage id='apps.table.attributes' /></th>
          <th><FormattedMessage id='apps.table.permissions' /></th>
        </tr>
      }>
      {rows}
    </CardTable>;
  },


  deleteConfirm() {
    let app = this.props.app;
    if (app.displayName.toUpperCase() === this.refs.deleteConfirmation.getValue()) {
      this.props.deleteApp(app.id)
        .promise()
        .then(() => {
          browserHistory.push('/apps');
        });
      this.props.hideDeleteModal();
    }
  },

  editApp(e) {
    e.preventDefault();
    browserHistory.push('/apps/' + this.props.app.id + '/edit');
  },

  render() {
    let groupsSection = this.getGroupsSection();
    let app = this.props.app;
    let options = {1: 'Implicit', 0: 'Code', 2: 'Hybrid'};

    let uppercaseName = app.displayName.toUpperCase();

    let {formatMessage} = this.props.intl;
    let appValues = {
      deleteTerm: uppercaseName,
    };

    return <div className={styles.editApp}>
      <AppTitle locale={this.props.locale} rolesCopy={this.props.rolesCopy}
                title={app.displayName} logo={langImageMatcher(app.logo, app.logoF, this.props.locale)} buttons={<div>
          <button className='btn'>{/*<Icon name='info' />*/}</button>
          {!this.props.new && this.context.canDeleteApps ?
            <button className='btn' onClick={this.props.openDeleteModal} title='Delete App'><Icon name='delete' /></button> : null}
          {this.context.canModifyApps ? <button className='btn' onClick={this.editApp} title='Edit App'><Icon name='edit' /></button> : null}
      </div>} />


      <Modal visible={this.props.deleteModalVisible} close={this.props.hideDeleteModal} id='app-delete-modal' size='md'
          header={<h3><FormattedMessage id='modals.deleteApp.header' /></h3>}
          footer={<div>
            <Input colSize='col-sm-7' ref='deleteConfirmation' value='' autoFocus
              placeholder={formatMessage({id: 'modals.deleteApp.placeholder'}, appValues)} />
            <button className='btn btn-primary' onClick={this.deleteConfirm}><FormattedMessage id='modals.deleteApp.submit' /></button></div>}>
        <div>
          <p><FormattedMessage id='modals.deleteApp.warning' /></p>
          <p><FormattedMessage id='modals.deleteApp.instructions' values={appValues} /></p>
        </div>
      </Modal>


      <div className='row'>
        <div className='col-md-4'>
          <ReadOnlyInput label={formatMessage({id: 'apps.fields.name'}) + ':'} value={app.name} />
        </div>
        <div className='col-md-4'>
          <ReadOnlyInput label={formatMessage({id: 'apps.fields.displayName'}) + ':'}
                         value={getTranslatedName(this.props.rolesCopy, this.props.locale, app.displayName)} />
        </div>
        <div className='col-md-4'>
          <ReadOnlyInput label={formatMessage({id: 'apps.fields.clientID'}) + ':'}  value={app.clientID} />
        </div>
        <div className='col-md-4'>
          <ReadOnlyInput label={formatMessage({id: 'apps.fields.url'}) + ':'}  value={app.url} />
        </div>
        <div className='col-md-4'>
          <ReadOnlyInput label={formatMessage({id: 'apps.fields.secret'}) + ':'}  value={app.secret} />
        </div>
        <div className='col-md-4'>
          <ReadOnlyInput label={formatMessage({id: 'apps.fields.loginURL'}) + ':'} value={app.loginUrl} />
        </div>
        <div className='col-md-4'>
          <ReadOnlyInput label={formatMessage({id: 'apps.fields.logoutURL'}) + ':'}  value={app.logoutUrl}/>
        </div>
        <div className='col-md-4'>
          <ReadOnlyInput label={formatMessage({id: 'apps.fields.flow'}) + ':'}  value={options[app.flow]} />
        </div>
        <div className='col-md-4'>
          <ReadOnlyInput label={formatMessage({id: 'apps.fields.colour'}) + ':'}  value={app.colour} />
        </div>
        <div className='col-md-12'>
          <TextArea label={formatMessage({id: 'apps.fields.description'}) + ':'}
                    value={getTranslatedName(this.props.rolesCopy, this.props.locale, app.description)} />
        </div>

      </div>

      {groupsSection}
    </div>;
  },
});

export default injectIntl(View);
