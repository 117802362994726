import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import {Set, List} from 'immutable';

import debounce from 'lodash.debounce';

import {PromiseStoreMixin} from 'helpers/request';
import {langImageMatcher} from '../../../../helpers/translationHelper';

import {AppModel} from 'models';

import AppTitle from '../Title/Title';
import CardTable from 'components/layout/CardTable/CardTable';
import {Input, TextArea, Select, FileUpload, Checkbox} from 'components/forms';

import {colsToRows} from 'helpers/tables';
import ValidationGroup from 'helpers/validation';

import Icon from 'components/Icon/Icon';

import styles from './Edit.module.scss';

import {injectIntl, intlShape} from 'react-intl';

const Edit = createClass({
  displayName: 'Edit',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    app: PropTypes.instanceOf(AppModel),
    assignGroup: PropTypes.func.isRequired,
    groups: PropTypes.object.isRequired,
    new: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    removeGroup: PropTypes.func.isRequired,
    saveApp: PropTypes.func.isRequired,
    locale: PropTypes.string,
    intl: intlShape.isRequired,
    rolesCopy: PropTypes.instanceOf(List),
    regions: PropTypes.instanceOf(List).isRequired,
  },

  getInitialState() {
    return {
      autoProvision: this.props.app.autoProvisionRegions.reduce((acc, x) => {
        acc[x] = true;
        return acc;
      }, {}),
    };
  },

  onCheck(region) {
    return val => {
      let autoProvision = this.state.autoProvision;
      if (val) {
        autoProvision[region] = true;
      } else {
        delete autoProvision[region];
      }
    };
  },

  componentWillMount() {
    this.save = debounce(this._save, 500, {
      trailing: false,
      leading: true,
    });
    this.group = new ValidationGroup(this.save);
  },

  componentWillReceiveProps(nextProps) {
    this.setState({
      autoProvision: nextProps.app.autoProvisionRegions.reduce((acc, x) => {
        acc[x] = true;
        return acc;
      }, {}),
    });
  },

  getDefaultProps() {
    return {
      app: new AppModel({}),
    };
  },

  assignGroup() {
    this.props.assignGroup(this.props.app.id, this.refs.unassignedGroups.getValue());
  },

  removeGroup(id) {
    return () => {
      this.props.removeGroup(this.props.app.id, id);
    };
  },

  _save() {
    let app = this.props.app;
    let keys = new Set(Object.keys(this.refs)).intersect(AppModel.keys);
    for (var ref of keys) {
      if (this.refs[ref] && this.refs[ref].getValue) {
        app = app.set(ref, this.refs[ref].getValue());
      }
    }

    app = app.set('autoProvisionRegions', new List(Object.keys(this.state.autoProvision)));

    let fileArray = [];
    fileArray.push({ data: this.refs.logo.getFiles(), name: 'logo' });
    fileArray.push({ data: this.refs.logoF.getFiles(), name: 'logoF' });

    let errors = app.validate();
    if (errors.size === 0) {
      this.promises.add(this.props.saveApp(app, fileArray, false)) // Don't save children here, just the top level properties could have changed
        .promise()
        .then(appRes => {
          let appData = appRes.data;
          if (appRes.success) {
            browserHistory.push('/apps/' + appData.ID);
          }
        });
    } else {
      let elem = keys.intersect(errors.map(x => x.key)).first();
      if (elem) {
        this.refs[elem].focus();
      }
    }
  },

  getGroupsSection() {
    let app = this.props.app;

    let rows = colsToRows(
      app.roles.map(x => x.displayName).toJS(),
      app.attributes.map(x => x.name).toJS(),
      app.permissions.map(x => x.displayName).toJS()
    );

    return <CardTable style={{marginTop: 20}} stripedColumns={true} stripedRows={false} header={
        <tr>
          <th>Roles</th>
          <th>Attributes</th>
          <th>Permissions</th>
        </tr>
      }>
      {rows}
    </CardTable>;
  },

  editRoles(event) {
    event.preventDefault();
    browserHistory.push('/roles/' + this.props.app.id);
  },

  cancel() {
    if (this.props.new) {
      browserHistory.push('/apps');
    } else {
      browserHistory.push(this.props.path.replace('/edit', ''));
    }
  },

  render() {
    let groupsSection = !this.props.new ? this.getGroupsSection() : null;
    let app = this.props.app;
    let {formatMessage} = this.props.intl;

    let roleOptions = app.roles.reduce((acc, x) => {
            acc[x.id] = x.displayName;
            return acc;
          }, {});

    roleOptions[''] = 'Select a Role';

    return <div className={styles.editApp}>
      <AppTitle locale={this.props.locale} rolesCopy={this.props.rolesCopy}
                title={app.displayName} logo={langImageMatcher(app.logo, app.logoF, this.props.locale)} buttons={<div>
          <button className='btn'>{/*<Icon name='info' />*/}</button>
          <button className='btn' onClick={this.group.validate} title='Save Changes'><Icon name='check' /></button>
          <button className='btn' onClick={this.cancel} title='Cancel'><Icon name='close' /></button>
      </div>} />

      <div className='row'>
        <div className='col-md-4'>
          <Input label={formatMessage({id: 'apps.fields.name'}) + ':'} value={app.name} ref='name' autoFocus={this.props.new} group={this.group}
            required={AppModel.isRequired('name')} validate={AppModel.getValidate('name')} />
        </div>
        <div className='col-md-4'>
          <Input label={formatMessage({id: 'apps.fields.displayName'}) + ':'} value={app.displayName} ref='displayName' group={this.group}
            required={AppModel.isRequired('displayName')} validate={AppModel.getValidate('displayName')} />
        </div>
        <div className='col-md-4'>
          <Input label={formatMessage({id: 'apps.fields.clientID'}) + ':'} value={app.clientID} ref='clientID' group={this.group}
            required={AppModel.isRequired('clientID')} validate={AppModel.getValidate('clientID')} />
        </div>
        <div className='col-md-4'>
          <Input label={formatMessage({id: 'apps.fields.url'}) + ':'} value={app.url} ref='url' validate={AppModel.getValidate('url')} group={this.group}
              required={AppModel.isRequired('url')} />
        </div>
        <div className='col-md-4'>
          <Input label={formatMessage({id: 'apps.fields.secret'}) + ':'} value={app.secret}
            ref='secret' required={AppModel.isRequired('secret')} group={this.group}
            validate={AppModel.getValidate('secret')} />
        </div>
        <div className='col-md-4'>
          <Input label={formatMessage({id: 'apps.fields.loginURL'}) + ':'} value={app.loginUrl} ref='loginUrl'
            required={AppModel.isRequired('loginUrl')} group={this.group}
            validate={AppModel.getValidate('loginUrl')} />
        </div>
        <div className='col-md-4'>
          <Input label={formatMessage({id: 'apps.fields.logoutURL'}) + ':'} value={app.logoutUrl} ref='logoutUrl' group={this.group}
            required={AppModel.isRequired('logoutUrl')} validate={AppModel.getValidate('loginUrl')} />
        </div>
        <div className='col-md-4'>
          <Select label={formatMessage({id: 'apps.fields.flow'}) + ':'} value={app.flow} ref='flow' options={{1: 'Implicit', 0: 'Code', 2: 'Hybrid'}} />
        </div>
        <div className='col-md-4'>
          <Input label={formatMessage({id: 'apps.fields.colour'}) + ':'} value={app.colour} ref='colour' group={this.group}
            required={AppModel.isRequired('colour')} validate={AppModel.getValidate('colour')} />
        </div>
        <div className='col-md-12'>
          <TextArea label={formatMessage({id: 'apps.fields.description'}) + ':'} value={app.description} ref='description' limit={500} />
        </div>

        <div className='col-md-12 bottom-pad'>
          <FileUpload
            ref='logo'
            label={formatMessage({id: 'apps.fields.logo'}) + ':'}
            dropAreaCol='col-md-6'
            innerText={formatMessage({id: 'apps.fields.logoInstructions'})}
            previewAreaCol='col-md-6'
            previewLabel='Save changes to finish uploading. ' />
        </div>
        <div className='col-md-12'>
          <FileUpload
            ref='logoF'
            label={formatMessage({id: 'apps.fields.logoF'}) + ':'}
            dropAreaCol='col-md-6'
            innerText={formatMessage({id: 'apps.fields.logoInstructions'})}
            previewAreaCol='col-md-6'
            previewLabel='Save changes to finish uploading. ' />
        </div>
      </div>
      <div className='row' style={{marginTop: 20}}>
        <div className='col-md-4'>
          <Select label='Auto Provision Role' value={app.autoProvisionRole} options={roleOptions} ref='autoProvisionRole' />
        </div>
        <div className='clearfix' />
        <div className='col-md-12'>
          <label>Auto Provision For Admins In Region:</label>
        </div>

        {this.props.regions.map(x => <div className='col-md-3'><Checkbox label={x.get('label')} value={this.state.autoProvision[x.get('region')]}
          onChange={this.onCheck(x.get('region'))} /></div>)}
      </div>

      {groupsSection}
    </div>;
  },
});

export default injectIntl(Edit);
