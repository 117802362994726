import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import {Set, List, Map} from 'immutable';
import debounce from 'lodash.debounce';

import {PromiseStoreMixin} from 'helpers/request';

import {AppModel, AttributeModel, GroupModel} from 'models';

import AppTitle from '../../Apps/Title/Title';
import Card from 'components/layout/Card/Card';
import CardTable from 'components/layout/CardTable/CardTable';
import {Checkbox, Input, NestedSortable} from 'components/forms';
import Modal from 'components/layout/Modal/Modal';

import Icon from 'components/Icon/Icon';
import CollapseRow from './CollapseRow';

import {groupTypes} from 'helpers/constants';

import styles from './Edit.module.scss';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

function identity(...args) {
  return args;
}

function getValues(term) {
  return {
    deleteTerm: <strong>{term}</strong>,
  };
}

const Edit = createClass({
  displayName: 'Edit',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    action: PropTypes.string,
    app: PropTypes.instanceOf(AppModel),
    assignGroup: PropTypes.func.isRequired,
    closeModals: PropTypes.func.isRequired,
    deleteAttribute: PropTypes.func.isRequired,
    deleteAttributeModalVisible: PropTypes.bool,
    deletePermission: PropTypes.func.isRequired,
    deletePermissionModalVisible: PropTypes.bool,
    deleteRole: PropTypes.func.isRequired,
    deleteRoleModalVisible: PropTypes.bool,
    deleteTargets: PropTypes.instanceOf(Map).isRequired,
    new: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    saveApp: PropTypes.func.isRequired,
    updateApp: PropTypes.func.isRequired,
    rolesCopy: PropTypes.instanceOf(List).isRequired,
    locale: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
  },

  getDefaultProps() {
    return {
      app: new AppModel({}),
    };
  },

  componentWillMount() {
    this.save = debounce(this._save, 500, {
      trailing: false,
      leading: true,
    });
  },

  componentDidUpdate(prevProps) {
    if (!prevProps.app.id && this.props.app.id && this.props.action) {
      if (this.props.action.includes('add-attribute')) {
        this.addAttribute();
      }
      if (this.props.action.includes('add-permission')) {
        this.addPermission();
      }
      if (this.props.action.includes('add-role')) {
        this.addRole();
      }
    }
  },

  cardToGroup(groups, card) {
    let group = groups.find(x => x._id === card.id);
    group = group.set('name', this.refs['permissionName' + group._id].getValue());
    group = group.set('displayName', this.refs['permissionDisplayName' + group._id].getValue());
    group = group.set('transferable', this.refs['permissionTransferable' + group._id].getValue());

    if (card.children && card.children.length > 0) {
      for (let child of card.children) {
        group = group.set('children', new List());
        group = group.updateIn(['children'], c => c.push(this.cardToGroup(groups, child)));
      }
    }
    return group;
  },

  groupToCard(g, indent) {
    let {formatMessage} = this.props.intl;
    return {
      id: g._id,
      horizontalIndent: indent,
      title: <div>
        <Icon name='dragHandle' style={{marginRight: 10, marginLeft: 10, fontSize: '1.3em'}}/>
        <Input value={g.displayName} inline={true} ref={'permissionDisplayName' + g._id}
          placeholder={formatMessage({id: 'roles.fields.permissionDisplayName'})} />
        <Input value={g.name} inline={true} ref={'permissionName' + g._id}
          placeholder={formatMessage({id: 'roles.fields.permissionName'})} />
        <Checkbox inline={true} value={g.transferable} label='Can Grant' ref={'permissionTransferable' + g._id} />
        <button className='btn icon-button pull-right' onClick={this.props.deletePermission(g._id)}>
          <Icon name='delete' />
        </button>
      </div>,
    };
  },

  groupsToCards(list, output = new List(), index = 0, indent = 0) {
    if (list.size > 0) {
      let group = list.get(index);

      let card;
      card = this.groupToCard(group, indent);
      output = output.push(card);
      if (group.children && group.children.size > 0) {
        output = this.groupsToCards(group.children, output, 0, indent + 1);
      }
      if (index < list.size - 1) {
        return this.groupsToCards(list, output, index + 1, indent);
      }
    }
    return output;
  },

  getUpdatedPermissions() {
    let permissions = this.props.app.permissions;
    if (this.refs.groupsSortable) {
      let cards = this.refs.groupsSortable.getCards();
      let updatedPermissions = new List(cards.map(x => this.cardToGroup(GroupModel.flatten(permissions), x)));
      return updatedPermissions;
    }
    return new List();
  },

  getUpdatedAttributes() {
    let app = this.props.app;

    let attributes = app.attributes;
    attributes = attributes.map(attr => {
      let id = attr._id;
      attr = attr.set('name', this.refs['attributeName' + id].getValue());
      attr = attr.set('displayName', this.refs['attributeDisplayName' + id].getValue());
      attr = attr.set('required', this.refs['attributeRequired' + id].getValue());
      return attr;
    });
    return attributes;
  },

  getUpdatedRoles() {
    let app = this.props.app;
    let roles = app.roles.map(role => {
      role = role.set('name', this.refs['role_' + role._id].getWrappedInstance().refs['roleName' + role._id].getValue());
      role = role.set('displayName', this.refs['role_' + role._id].getWrappedInstance().refs['roleDisplayName' + role._id].getValue());
      role = role.set('priority', this.refs['role_' + role._id].getWrappedInstance().refs['rolePriority' + role._id].getValue());
      let permissions = GroupModel.flatten(app.permissions);
      let children = new List();
      for (let permission of permissions.filter(x => x.id)) {
        if (this.refs['permission_' + role._id + '_' + permission._id].getValue()) {
          children = children.push(permission);
        }
      }
      role = role.set('children', children);
      return role;
    });
    return roles;
  },

  assignGroup() {
    this.props.assignGroup(this.props.app.id, this.refs.unassignedGroups.getValue());
  },

  _save() {
    let app = this.props.app;
    let keys = new Set(Object.keys(this.refs)).intersect(AppModel.keys);
    for (var ref of keys) {
      app = app.set(ref, this.refs[ref].getValue());
    }

    app = app.set('roles', this.getUpdatedRoles().filter(x => x.name && x.displayName));
    app = app.set('permissions', this.getUpdatedPermissions().filter(x => x.name && x.displayName));
    app = app.set('attributes', this.getUpdatedAttributes().filter(x => x.name));

    let errors = app.validate();
    if (errors.size === 0) {
      this.promises.add(this.props.saveApp(app))
        .promise()
        .then(appRes => {
          let appData = appRes.data;
          if (appRes.success && appData) {
            browserHistory.push('/roles/' + appData.ID);
            return true;
          }
          return Promise.reject(appRes);
        });
    } else {
      let elem = keys.intersect(errors.map(x => x.key)).first();
      if (elem) {
        this.refs[elem].focus();
      }
    }
  },

  addPermission() {
    let app = this.props.app;
    app = app.update('permissions', p => p.push(new GroupModel({type: groupTypes.permission})));
    this.props.updateApp(app);
  },

  addRole() {
    let app = this.props.app;
    app = app.update('roles', p => p.push(new GroupModel({type: groupTypes.role})));
    this.props.updateApp(app);
  },

  addAttribute() {
    let app = this.props.app;
    app = app.update('attributes', p => p.push(new AttributeModel()));
    this.props.updateApp(app);
  },

  removeAttribute(attrID) {
    return () => {
      let app = this.props.app;
      app = app.update('attributes', a => a.filter(x => x.id !== attrID));
      this.props.updateApp(app);
    };
  },

  removeRole(id) {
    return () => {
      let app = this.props.app;
      app = app.update('roles', r => r.filter(x => x.id !== id));
      this.props.updateApp(app);
    };
  },

  removePermission(id) {
    return () => {
      let app = this.props.app;
      app = app.update('permissions', p => p.filter(x => x.id !== id));
      this.props.updateApp(app);
    };
  },

  getPermissionsSection() {
    let app = this.props.app;
    let permissions = this.groupsToCards(app.permissions);
    return <Card style={{marginTop: 20}} header={<h3 style={{fontSize: 15, fontWeight: 600, padding: '10px 15px'}}>
      <FormattedMessage id='roles.tables.permissions' /></h3>}
        minHeight={0} noBorder={true} noPadding={true} noHeaderPadding={true} headerHeight='inherit'
        footer={
          <button className='btn btn-dashed' onClick={this.addPermission}>
            <Icon name='add' />&nbsp;<FormattedMessage id='roles.addPermission' />
          </button>
        }
      >
      <div className='row'>
        <div className='col-xs-12'>
          <NestedSortable input={permissions} ref='groupsSortable' striped={true} />
        </div>
      </div>
    </Card>;
  },

  permissionToRole(p, r, indent) {
    return {
      id: p._id,
      horizontalIndent: indent,
      title:  <div style={{padding: '5px 0px', position: 'relative'}}>
                <Checkbox value={r.children.find(obj => obj.get('id') === p.id) !== undefined}
                  ref={'permission_' + r._id + '_' + p._id}
                  label={p.displayName + ' | ' + p.name} />
              </div>,
    };
  },

  permissionsToRole(role, list, output = new List(), index = 0, indent = 0) {
    if (list.size > 0) {
      let permission = list.get(index);
      let card;
      card = this.permissionToRole(permission, role, indent);
      output = output.push(card);
      if (permission.children && permission.children.size > 0) {
        output = this.permissionsToRole(role, permission.children, output, 0, indent + 1);
      }
      if (index < list.size - 1) {
        return this.permissionsToRole(role, list, output, index + 1, indent);
      }
    }
    return output;
  },

  getRolesSection() {
    let app = this.props.app;
    let roles = app.roles
      .map(role => {
        let permissions = this.permissionsToRole(role, app.permissions);
        return <CollapseRow role={role} key={role._id} ref={'role_' + role._id} deleteRole={this.props.deleteRole} permissions={permissions}></CollapseRow>;
      });

    return <CardTable        header={<tr>

          <th width='25%'><FormattedMessage id='roles.tables.roles' /></th>
          <th width='25%'><FormattedMessage id='roles.tables.roleName' /></th>
          <th width='25%'><FormattedMessage id='roles.tables.roleHierarchy' /></th>
          <th />
        </tr>}
        footer={<button className='btn btn-dashed' onClick={this.addRole}>
          <Icon name='add' />&nbsp;<FormattedMessage id='roles.addRole' />
          </button>}>
      {roles}
    </CardTable>;
  },

  getAttributesSection() {
    let {formatMessage} = this.props.intl;
    let attrs = this.props.app.attributes
      .map(x => <tr key={x._id}>
        <td><Input value={x.displayName} ref={'attributeDisplayName' + x._id}
          placeholder={formatMessage({id: 'roles.fields.permissionDisplayName'})} /></td>
        <td><Input value={x.name} ref={'attributeName' + x._id} placeholder={formatMessage({id: 'roles.fields.permissionName'})} /></td>
        <td><Checkbox value={x.required} ref={'attributeRequired' + x._id} /></td>
        <td>
          <button className='btn icon-button' onClick={this.props.deleteAttribute(x._id)}>
            <Icon name='delete' />
          </button>
        </td>
      </tr>);
    return <CardTable
        header={
          <tr>
            <th><FormattedMessage id='roles.tables.attributes' /></th>
            <th><FormattedMessage id='roles.tables.attributeName' /></th>
            <th><FormattedMessage id='roles.tables.attributeRequired' /></th>
            <th />
          </tr>
        }
        footer={<button className='btn btn-dashed' onClick={this.addAttribute}>
          <Icon name='add' />&nbsp;<FormattedMessage id='roles.addAttribute' />
          </button>}>
      {attrs}
    </CardTable>;
  },

  verifyText(getElement, text, callback) {
    return () => {
      let element = getElement();
      if (element && element.getValue) {
        let value = element.getValue();
        if (value === text) {
          callback();
          this.props.closeModals();
        }
      }
    };
  },

  cancel() {
    if (this.props.new) {
      browserHistory.push('/roles');
    } else {
      browserHistory.push(this.props.path.replace('/edit', ''));
    }
  },

  render() {
    let {app, deleteTargets} = this.props;

    let deleteAttribute = '';
    let attributeCallback = identity;

    let deletePermission = '';
    let permissionCallback = identity;

    let deleteRole = '';
    let roleCallback = identity;

    if (deleteTargets.get('permission')) {
      deletePermission = deleteTargets.get('permission').name.toUpperCase();
      permissionCallback = this.verifyText(() => this.refs.deletePermissionConfirmation, deletePermission,
        this.removePermission(deleteTargets.get('permission').id)).bind(this);
    }

    if (deleteTargets.get('attribute')) {
      deleteAttribute = deleteTargets.get('attribute').name.toUpperCase();
      attributeCallback = this.verifyText(() => this.refs.deleteAttributeConfirmation, deleteAttribute,
        this.removeAttribute(deleteTargets.get('attribute').id)).bind(this);
    }

    if (deleteTargets.get('role')) {
      deleteRole = deleteTargets.get('role').name.toUpperCase();
      roleCallback = this.verifyText(() => this.refs.deleteRoleConfirmation, deleteRole,
        this.removeRole(deleteTargets.get('role').id)).bind(this);
    }

    let {formatMessage} = this.props.intl;

    return <div className={styles.editRole}>
      <AppTitle title={app.displayName} locale={this.props.locale} rolesCopy={this.props.rolesCopy} logo={app.logo} buttons={<div>
          <button className='btn'>{/*<Icon name='info' />*/}</button>
          <button className='btn' onClick={this.save} title={formatMessage({id: 'roles.tooltips.saveRole'})}><Icon name='check' /></button>
          <button className='btn' onClick={this.cancel} title={formatMessage({id: 'roles.tooltips.cancel'})}><Icon name='close' /></button>
      </div>} />

      <Modal visible={this.props.deleteRoleModalVisible} close={this.props.closeModals} id='role-delete-modal' size='md'
          header={<h3><FormattedMessage id='modals.deleteRole.header' /></h3>}
          footer={<div>
            <button className='btn btn-primary' onClick={roleCallback}><FormattedMessage id='modals.deleteRole.submit' /></button>
              <Input colSize='col-sm-7' ref='deleteRoleConfirmation' value='' autoFocus
                placeholder={formatMessage({id: 'modals.deleteRole.placeholder', values: getValues(deleteRole)})} /></div>}>
        <div>
          <p><FormattedMessage id='modals.deleteRole.warning' /></p>
          <p><FormattedMessage id='modals.deleteRole.instructions' values={getValues(deleteRole)} /></p>
        </div>
      </Modal>

      <Modal visible={this.props.deletePermissionModalVisible} close={this.props.closeModals} id='permission-delete-modal' size='md'
          header={<h3><FormattedMessage id='modals.deletePermission.header' /></h3>}
          footer={<div>
            <button className='btn btn-primary' onClick={permissionCallback}><FormattedMessage id='modals.deletePermission.submit' /></button>
              <Input colSize='col-sm-6' ref='deletePermissionConfirmation' value=''
                autoFocus placeholder={formatMessage({id: 'modals.deletePermission.placeholder', values: getValues(deletePermission)})} /></div>}>
        <div>
          <p><FormattedMessage id='modals.deletePermission.warning' /></p>
          <p><FormattedMessage id='modals.deletePermission.instructions' values={getValues(deletePermission)} /></p>
        </div>
      </Modal>

      <Modal visible={this.props.deleteAttributeModalVisible} close={this.props.closeModals} id='attribute-delete-modal' size='md'
          header={<h3><FormattedMessage id='modals.deleteAttribute.header' /></h3>}
          footer={<div>
            <button className='btn btn-primary' onClick={attributeCallback}><FormattedMessage id='modals.deleteAttribute.submit' /></button>
              <Input colSize='col-sm-7' ref='deleteAttributeConfirmation'
                value='' autoFocus placeholder={formatMessage({id: 'modals.deleteAttribute.placeholder', values: getValues(deleteAttribute)})} /></div>}>
        <div>
          <p><FormattedMessage id='modals.deleteAttribute.warning' /></p>
          <p><FormattedMessage id='modals.deleteAttribute.body' values={getValues(deleteAttribute)} /></p>
        </div>
      </Modal>

      {this.getPermissionsSection()}
      {this.getAttributesSection()}
      {this.getRolesSection()}
    </div>;
  },
});

export default injectIntl(Edit);
