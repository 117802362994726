import React, {createClass, PropTypes} from 'react';
import {PureRenderMixin} from 'helpers/shallowCompare';

import WizardProgress from 'components/layout/WizardProgress/WizardProgress';

import {injectIntl, intlShape} from 'react-intl';

const UserWizard = createClass({
  displayName: 'UserWizard',
  mixins: [PureRenderMixin],
  propTypes: {
    active: PropTypes.number.isRequired,
    intl: intlShape.isRequired,
  },

  contextTypes: {
    canChangeDealer: PropTypes.bool.isRequired,
  },

  render() {
    let {formatMessage} = this.props.intl;

    let steps = {0: formatMessage({id: 'user.wizard.createUser'}), 2: formatMessage({id: 'user.wizard.assignPermissions'})};
    if (this.context.canChangeDealer) {
      steps = [
        formatMessage({id: 'user.wizard.createUser'}),
        formatMessage({id: 'user.wizard.assignDealer'}),
        formatMessage({id: 'user.wizard.assignPermissions'}),
      ];
    }
    return <WizardProgress steps={steps} active={this.props.active} />;
  },
});

export default injectIntl(UserWizard);
