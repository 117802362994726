import createRequest, {methodTypes} from 'helpers/request';
import {List} from 'immutable';
import {AppModel} from 'models';

import {setApps, setApp, removeApp} from 'redux/actions/apps';
import {savingAppToast, savedAppToast, errorAppToast, deletingAppToast, deletedAppToast} from './toasts/apps';

export function getApps() {
  return dispatch => {
    let request = createRequest('/api/App/All', {authorize: true});

    request
    .promise()
    .then(appsRes => {
      let apps = new List(appsRes.data.map(x => AppModel.fromApiFormat(x)));
      dispatch(setApps(apps));
    });

    return request;
  };
}

export function getApp(id) {
  return dispatch => {
    let request = createRequest('/api/App?id=' + id, {authorize: true});

    request
    .promise()
    .then(appRes => {
      let app = AppModel.fromApiFormat(appRes.data);
      dispatch(setApp(app));
    });

    return request;
  };
}

export function saveApp(app, files, saveChildren = true) {
  return dispatch => {
    let body = new FormData();
    body.append('app', JSON.stringify(app.toApiFormat()));
    body.append('saveChildren', saveChildren);

    if (files && files.length > 0) {
      files.forEach((file) => {
        if (file.data && file.data.length > 0) {
          body.append(file.name, file.data[0]);
        }
      });
    }

    dispatch(savingAppToast());
    let request = createRequest('/api/App', {
      authorize: true,
      body: body,
      dataType: 'formdata',
      method: methodTypes.post,
    });

    request
    .promise()
    .then(appRes => {
      if (appRes.success) {
        dispatch(savedAppToast());
        let newApp = AppModel.fromApiFormat(appRes.data);
        dispatch(setApp(newApp));
        return true;
      }
      return Promise.reject(appRes);
    }).catch(e => {
      console.error(JSON.stringify(e, null, 2));
      dispatch(errorAppToast());
    });

    return request;
  };
}

export function deleteApp(id) {
  return dispatch => {
    let request = createRequest(`/api/App/Delete?id=${id}`, {
      authorize: true,
      method: 'POST',
    });

    dispatch(deletingAppToast());

    request
      .promise()
      .then(() => {
        dispatch(removeApp(id));
        dispatch(deletedAppToast());
      });

    return request;
  };
}
