import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {Set, List} from 'immutable';
import {browserHistory} from 'react-router';

import {UserModel, GroupModel, ToastModel} from 'models';
import {groupTypes} from 'helpers/constants';

import phoneFormat from 'helpers/phoneFormat';

import Card from 'components/layout/Card/Card';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import {Input, Select} from 'components/forms';

import ValidationGroup, {isValidEmail} from 'helpers/validation';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const CreateAdmin = createClass({
  displayName: 'CreateAdmin',
  mixins: [PureRenderMixin],
  propTypes: {
    addToast: PropTypes.func.isRequired,
    checkEmailUsed: PropTypes.func.isRequired,
    dealer: PropTypes.instanceOf(GroupModel),
    getDealer: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    save: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
  },

  getDefaultProps() {
    return {
      dealer: new GroupModel({type: groupTypes.dealer}),
    };
  },

  fetchData(props) {
    props.getDealer(props.params.id);
  },

  componentWillMount() {
    this.fetchData(this.props);
    this.group = new ValidationGroup(this.save);
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.id !== this.props.params.id) {
      this.fetchData(nextProps);
    }
  },


  checkEmailUsed(email) {
    let user = this.getUser();
    user = user.set('email', email);
    let validEmail = isValidEmail(email);
    if (!validEmail.valid) {
      return Promise.resolve(validEmail);
    }
    return this.props.checkEmailUsed(user)
      .promise()
      .then(x => {
        if ((x && x.data && x.data.ID !== user.id) || !UserModel.getValidate('email')(email)) {
          return {valid: false, message: 'Email Address already used.'};
        }
        return {valid: true};
      })
      .catch(err => {
        console.error(err);
        return {valid: false, message: 'Network error.'};
      });
  },

  cancel() {
    browserHistory.push(this.props.path.replace('/new-dealer-admin', ''));
  },

  getUser() {
    let user = new UserModel();
    let keys = new Set(Object.keys(this.refs)).intersect(UserModel.keys).toArray();
    for (let key of keys) {
      let ref = this.refs[key];
      if (ref && ref.getValue) {
        user = user.set(key, ref.getValue());
      }
    }
    return user;
  },

  save() {
    let user = this.getUser();
    let dealer = this.props.dealer;

    this.props.addToast(new ToastModel({
      key: 'saving-dealer-admin',
      message: <FormattedMessage id='toasts.dealerAdmin.saving' />,
      ttl: null,
      closeable: true,
    }));

    this.props.save(dealer, user)
    .promise()
    .then(() => {
      this.props.addToast(new ToastModel({
        key: 'saved-dealer-admin',
        message: <FormattedMessage id='toasts.dealerAdmin.saved' />,
        ttl: 4000,
        closeable: true,
        clears: 'saving-dealer-admin',
      }));

      this.cancel();
    })
    .catch(err => {
      console.error(err);
      this.props.addToast(new ToastModel({
        key: 'saved-dealer-admin',
        messaged: <FormattedMessage id='toasts.dealerAdmin.error' />,
        clears: 'saving-dealer-admin',
        ttl: null,
        closeable: true,
      }));
    });
  },

  render() {
    let dealer = this.props.dealer;
    let {formatMessage} = this.props.intl;

    let localeOptions = this.props.possibleLocales
      .filter(x => x.get('regions').includes(dealer.region))
      .reduce((acc, x) => {
        acc[x.get('locale')] = x.get('labels').get(x.get('locale'));
        return acc;
      }, {});

    return <div>
      <PageHeader title={formatMessage({id: 'addDealerAdmin.pageHeader'}) + ': ' + dealer.displayName} />
      <Card>
        <div>
          <h2><FormattedMessage id='addDealerAdmin.title' /></h2>
          <div className='row'>
            <div className='col-md-4'>
              <Input value='' label={formatMessage({id: 'addDealerAdmin.fields.firstName'}) + ':'}
                ref='firstName' autoFocus group={this.group}
                required={UserModel.isRequired('firstName')}
                validate={UserModel.getValidate('firstName')} />
            </div>
            <div className='col-md-4'>
              <Input value='' label={formatMessage({id: 'addDealerAdmin.fields.lastName'}) + ':'}
                ref='lastName' required={UserModel.isRequired('lastName')} group={this.group}
                validate={UserModel.getValidate('lastName')} />
            </div>
            <div className='col-md-4'>
              <Input value='' label={formatMessage({id: 'addDealerAdmin.fields.email'}) + ':'}
                ref='email' required={UserModel.isRequired('email')} type='email' group={this.group}
                validate={this.checkEmailUsed} />
            </div>
            <div className='col-md-4'>
              <Input value='' label={formatMessage({id: 'addDealerAdmin.fields.mobilePhone'}) + ':'}
                  ref='mobilePhone' format={phoneFormat} group={this.group}
                  required={UserModel.isRequired('mobilePhone')} validate={UserModel.getValidate('mobilePhone')} />
            </div>
            <div className='col-md-4'>
              <Input value='' label={formatMessage({id: 'addDealerAdmin.fields.alternatePhone'}) + ':'} ref='alternatePhone'
                  format={phoneFormat} group={this.group}
                  required={UserModel.isRequired('alternatePhone')} validate={UserModel.getValidate('alternatePhone')} />
            </div>
            <div className='col-md-4'>
              <Select label={formatMessage({id: 'addDealerAdmin.fields.language'}) + ':'} value={dealer.locale} ref='locale'
                options={localeOptions} />
            </div>
          </div>
          <div className='row' style={{marginBottom: 20}}>
            <div className='col-md-12'>
              <button className='pull-left btn btn-default' onClick={this.cancel}><FormattedMessage id='addDealerAdmin.cancel' /></button>
              <button className='pull-right btn btn-primary' onClick={this.group.validate}><FormattedMessage id='addDealerAdmin.confirm' /></button>
            </div>
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(CreateAdmin);
