import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import styles from './Checkbox.module.scss';

const Checkbox = createClass({
  displayName: 'Checkbox',
  mixins: [PureRenderMixin],
  propTypes: {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
    topLabel: PropTypes.string,
    value: PropTypes.bool,
  },

  getInitialState() {
    return {
      value: false,
    };
  },

  getDefaultProps() {
    return {
      className: '',
      inline: false,
      label: '',
      style: {},
      value: false,
    };
  },

  componentWillMount() {
    this.setState({
      value: this.props.value,
    });
  },

  componentWillReceiveProps(nextProps) {
    if (!Object.keys(nextProps).map(x => nextProps[x] === this.props[x]).every(x => x === true) && nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  },

  dataChanged(e) {
    if (this.props.onChange) {
      this.props.onChange(e.target.checked);
    }
    this.setState({
      value: e.target.checked,
    });
  },

  getValue() {
    return this.refs.input.checked;
  },

  focus() {
    this.refs.input.focus();
  },

  render() {
    let topLabel = this.props.topLabel ? <label>{this.props.topLabel}</label> : null;

    let inline = '';
    if (this.props.inline) {
      inline = ' ' + styles.inline;
    }

    return <div className={'form-group ' + this.props.className + ' ' + styles.editableCheckbox + inline} style={this.props.style}>
      {topLabel}
      <label className={styles.inlineLabel}>
        <input type='checkbox' ref='input' checked={this.state.value} onChange={this.dataChanged} disabled={this.props.disabled ? true : false} />
        {' ' + this.props.label}
      </label>

    </div>;
  },
});

export default Checkbox;
