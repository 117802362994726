import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import {List} from 'immutable';
import Card from 'components/layout/Card/Card';
import Results from 'components/layout/ReportsGenerics/LargeResults/LargeResults';
import styles from './Provisioned.module.scss';
import {DashboardReport} from 'models';

import {conditionalPushFooter} from 'helpers/reportHelpers';

import {injectIntl, intlShape, FormattedMessage} from 'react-intl';

const Provisioned = createClass({
  displayName: 'Provisioned',
  mixins: [PureRenderMixin],
  propTypes: {
    report: PropTypes.instanceOf(DashboardReport).isRequired,
    intl: intlShape.isRequired,
  },
  gotoPage() {
    browserHistory.push('/reports/users');
  },

  render() {
    let {report} = this.props;
    let {formatMessage} = this.props.intl;

    let resultTiles = new List();
    resultTiles = conditionalPushFooter(resultTiles, report.totalUsers, formatMessage({id: 'reports.dashboard.totalUsers'}));

    return <div>
      <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}><FormattedMessage id='reports.dashboard.provisioned' /></span>
          <button onClick={this.gotoPage} className={'btn btn-transparent pull-right'}><FormattedMessage id='reports.dashboard.fullReportLink' /></button></h3>}
        className={styles.reportsTitle}
        headerFontSize={16}
        lightHeader={true}
        style={{marginBottom: 20}} >
        <div className='row'>
          <div className='col-xs-12'>
            {resultTiles.map((result, index) => {
              return <Results colSize={'col-sm-' + 12 / resultTiles} text={result.get('text')} key={index} number={result.get('number')} />;
            }).toArray()}
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(Provisioned);
