import React, {createClass, PropTypes, cloneElement} from 'react';
import {List} from 'immutable';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import {wrapIfString} from 'helpers/strings';

import styles from './Title.module.scss';
import {getTranslatedName} from 'helpers/roles';

const Title = createClass({
  displayName: 'Title',
  propTypes: {
    logo: PropTypes.string,
    title: PropTypes.any.isRequired,
    buttons: PropTypes.element,
    locale: PropTypes.string,
    rolesCopy: PropTypes.instanceOf(List),
  },

  render() {
    let title = wrapIfString(getTranslatedName(this.props.rolesCopy, this.props.locale, this.props.title), 'h2', styles.title);

    return <div>
      {this.props.logo ?
        <img src={this.props.logo}
             height={40}
             style={{verticalAlign: 'top', marginRight: 20}} /> :
        null}
      {title}
      {this.props.buttons ? cloneElement(this.props.buttons, {className: styles.buttons}) : null}
      <div className='clearfix' />
    </div>;
  },
});

export default Title;
