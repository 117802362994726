import React from 'react';
import {FormattedMessage} from 'react-intl';

const conditions = [
  {condition: (val, val2) => val === val2, messageID: 'password.match'},
  {condition: val => val && val.length >= 8, messageID: 'password.length'},
  {condition: val => val.toLowerCase().match(/[a-z]{1}/) !== null, messageID: 'password.letter'},
  {
    condition: val => {
      let test = val.toLowerCase().match(/[a-z]{1}/g);
      return test && test.length < val.length;
    },
    messageID: 'password.nonLetter',
  },
];

/**
 * Evaluate whether the password meets the minimum requirements.
 * @param  {string} pass1 The password to consider.
 * @param  {string} pass2 Confirmation that the user knows the password.
 * @return {bool}       True iff password meets minimum requirements.
 */
export function evaluateComplexity(pass1, pass2) {
  let valid = true;
  let message = null;

  let messages = [];

  for (let cond of conditions) {
    let validity = cond.condition(pass1, pass2);
    valid = valid && validity;
    messages.push(<li key={cond.messageID}><i className='material-icons' style={{fontSize: '1.3em'}}>
      {validity ? 'check' : 'close'}</i>&nbsp;<FormattedMessage id={cond.messageID} /></li>);
  }

  if (messages.length > 0) {
    message = <div><FormattedMessage id='password.requirements' />:
      <ul style={{listStyleType: 'none', paddingLeft: 15}}>
        {messages}
      </ul>
    </div>;
  }

  return {valid, message};
}

/**
 * Return a score between 0 and 6 for how secure a password is.
 * @param  {string} phrase The password to evaluate.
 * @return {number}          The score of how secure the supplied password is.
 */
export function passwordScore(phrase) {
  // Add 1 to score for each of these conditions
  let phraseFactors = [
    phrase.length > 8, // Phrase > 8 characters (minimum)
    phrase.length > 13, // Phrase >= 14 characters
    phrase.match(/[a-z]{1}/) !== null, // Contains at least one lower case
    phrase.match(/[A-Z]{1}/) !== null, // Contains at least one capital letter
    phrase.match(/[0-9]{1}/) !== null, // Contains at least one number
    (() => { // Contains at least one symbol
      let test = phrase.match(/[a-zA-Z0-9]{1}/g);
      return test && test.length < phrase.length;
    })(),
  ];

  return phraseFactors.reduce((acc, x) => acc + (x ? 1 : 0), 0);
}
