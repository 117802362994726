import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import ViewDetails from '../ViewDetails/ViewDetails';
import Card from 'components/layout/Card/Card';
import TableResults from 'components/layout/ReportsGenerics/TableResults/TableResults';

import {ReportSettings, UsersReport} from 'models';

import {injectIntl, FormattedMessage, intlShape} from 'react-intl';

const PerDealer = createClass({
  displayName: 'PerDealer',
  mixins: [PureRenderMixin],
  propTypes: {
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    report: PropTypes.instanceOf(UsersReport).isRequired,
    intl: intlShape.isRequired,
    getRoleName: PropTypes.func.isRequired,
  },

  render() {
    let {report, settings, getRoleName} = this.props;
    let {formatMessage} = this.props.intl;

    let data = [];
    let valueFields = ['value'];
    let compare = settings.compare && report.compare;

    if (!compare) {
      data = report.usersPerDealer.map((num, app) => {return {name: app, value: num};}).toArray();
    } else {
      valueFields = ['value', 'compare'];
      data = report.usersPerDealer.map((num, app) => {return {name: app, value: num, compare: report.compare.usersPerDealer.get(app)};}).toArray();
    }

    return <div>
      <ViewDetails
        valueFields={valueFields}
        data={data}
        header={formatMessage({id: 'reports.usersPerDealer.title'})}
        footer={formatMessage({id: 'reports.usersPerDealer.footer'})}>
        {report.usersPerRolePerDealer.map((usersPerRole, dealer) => {
          let compareApp = null;
          let totals = [];
          totals.push(usersPerRole.reduce((acc, x) => acc + x, 0));
          if (compare) {
            compareApp = report.compare.usersPerRolePerDealer.get(dealer);
            totals.push(compareApp.reduce((acc, x) => acc + x, 0));
          }

          return <div key={dealer} className='col-sm-6'>
            <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{dealer}</span></h3>}
              lightHeader={true}
              headerFontSize={16}
              style={{marginBottom: 20}} >
              <div className='row'>
                <div className='col-sm-12'>
                  <TableResults totals={totals} header={<thead><tr>
                    <th><FormattedMessage id='reports.users.authorization' /></th>
                    <th className='text-right'><FormattedMessage id='reports.count' />
                      <small>(
                        <FormattedMessage id='reports.users.dateRange' values={{
                          from: settings.from.format('ll'),
                          to: settings.to.format('ll'),
                        }} />
                      )</small>
                    </th>
                    {compare ?
                      <th className='text-right'><FormattedMessage id='reports.count' />
                        <small>(
                          <FormattedMessage id='reports.users.dateRange' values={{
                            from: settings.compare.from.format('ll'),
                            to: settings.compare.to.format('ll'),
                          }} />
                        )</small>
                      </th> : null}
                     </tr></thead>}>
                      {usersPerRole.map((numUsers, role) => {
                        let compareCount = 0;
                        if (compare && compareApp) {
                          compareCount = compareApp.get(role);
                        }
                        return <tr key={role}>
                          <td>{getRoleName(role)}</td>
                          <td className='text-right'>{numUsers || 0}</td>
                          {compare ? <td className='text-right'>{compareCount || 0}</td> : null}
                        </tr>;
                      })}
                  </TableResults>
                </div>
              </div>
            </Card>
          </div>;
        }).toArray()}
      </ViewDetails>
    </div>;
  },
});

export default injectIntl(PerDealer);
