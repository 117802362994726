import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import {groupTypes} from 'helpers/constants';

import styles from './ShowProvision.module.scss';

import {GroupModel, UserModel, AppModel} from 'models';
import {List} from 'immutable';

import {getUsersRole} from '../ProvisionApp/provisionHelpers';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {getTranslatedName} from 'helpers/roles';

const ShowProvision = createClass({
  displayName: 'ShowProvision',
  mixins: [PureRenderMixin],
  propTypes: {
    app: PropTypes.instanceOf(AppModel).isRequired,
    user: PropTypes.instanceOf(UserModel).isRequired,
    intl: intlShape.isRequired,
    roles: PropTypes.instanceOf(List).isRequired,
    locale: PropTypes.string.isRequired,
  },

  contextTypes: {
    allPermissions: PropTypes.bool.isRequired,
    currentUser: PropTypes.instanceOf(UserModel).isRequired,
  },

  getRoleType() {
    let user = this.props.user;
    let app = this.props.app;

    let provisioned = user.apps.find(x => x.id === app.id);

    let currentRole = '';
    let permissions = [];
    let attributes;
    if (provisioned) {
      let rawPerms = GroupModel.flatten(provisioned.assigned);
      let currentRoles = rawPerms.filter(x => x.type === groupTypes.role);
      if (currentRoles.size > 0) {
        currentRole = getTranslatedName(this.props.roles, this.props.locale, currentRoles.first().displayName);
      }

      permissions = rawPerms.filter(x => x.type === groupTypes.permission).map(x => <div key={x._id} className='col-md-3'>{x.displayName}</div>);

      attributes = app.attributes.map(attr => {
        let userAttr = user.attributes.find(x => x.id === attr.id);

        return <div key={attr._id}>
          <div className='form-group'>
            <label>{attr.displayName}</label>
            <p>{userAttr ? userAttr.value : '(none)'}</p>
          </div>
        </div>;
      });
    }



    return <div>
      <div className='row'>
        <div className='col-md-12'>
          <label><FormattedMessage id='user.provisioning.role' />:</label>
          <div className='form-control-static'>
            {currentRole || '(none)'}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          <label><FormattedMessage id='user.provisioning.permissions' />:</label>
        </div>
        {permissions}
      </div>
      {attributes ? <div className='row'>
        <div className='col-xs-12'>
          {attributes}
        </div>
      </div> : null}
    </div>;
  },

  getDealerType() {
    let dealer = this.props.user.dealer;
    let stores = dealer && dealer.branches ? dealer.branches : new List();
    let user = this.props.user;
    let app = this.props.app;

    let storeRows = stores.map(store => {
      let branchAssigned = false;
      let provisioned = user.apps.find(x => x.id === app.id);
      if (provisioned) {
        branchAssigned = provisioned.assigned.find(x => x.id === store.id);
      }

      let currentUserBranchAssigned = false;
      let currentProvisioned = this.context.currentUser.apps.find(x => x.id === app.id);
      if (currentProvisioned) {
        currentUserBranchAssigned = currentProvisioned.assigned.map(x => x.id).includes(store.id);
      }

      if (!currentUserBranchAssigned && !this.context.allPermissions) {
        return null;
      }

      let permissions = '';
      if (branchAssigned && branchAssigned.children.size > 0) {
        let branchChildren = GroupModel.flatten(branchAssigned.children);
        permissions = branchChildren.filter(x => x.type === groupTypes.permission)
          .map(x => x.displayName)
          .join(', ');
      }


      return <tr key={store._id}>
        <td>{branchAssigned ? 'Yes' : 'No'}</td>
        <td>{store.displayName}</td>
        <td>{store.shipTo}</td>
        <td>{permissions}</td>
      </tr>;
    }).toArray();

    let currentRole = getUsersRole(app, user);
    if (!currentRole) {
      currentRole = '(none)';
    } else {
      currentRole = getTranslatedName(this.props.roles, this.props.locale, currentRole.displayName);
    }


    let attributes = app.attributes.map(attr => {
      let userAttr = user.attributes.find(x => x.id === attr.id);

      return <div key={attr._id} style={{marginTop: 20}}>
        <div className='form-group'>
          <label>{attr.displayName}</label>
          <p>{userAttr ? userAttr.value : '(none)'}</p>
        </div>
      </div>;
    });

    return <div>
      <div><label><FormattedMessage id='user.provisioning.role' />:&nbsp;</label>{currentRole}</div>
      <table className='table-striped table'>
        <thead>
          <tr className={styles.header}>
            <th style={{width: '15%'}}><FormattedMessage id='user.provisioning.status' /></th>
            <th style={{width: '15%'}}><FormattedMessage id='user.provisioning.store' /></th>
            <th style={{width: '15%'}}><FormattedMessage id='user.provisioning.shipTo' /></th>
            <th style={{width: '40%'}}><FormattedMessage id='user.provisioning.permissions' /></th>
          </tr>
        </thead>
        <tbody>
          {storeRows}
        </tbody>
      </table>
      <div style={{marginTop: 20}}>{attributes}</div>
    </div>;
  },

  render() {
    let {app, user, locale} = this.props;

    let provision;
    if (!user.dealer) {
      provision = this.getRoleType();
    } else {
      provision = this.getDealerType();
    }

    return <div className={styles.provisionApp}>
      <h3 className={styles.heading}>
        {app.logo ?
          <img src={locale === 'fr-CA' || locale === 'fr' && app.logoF ? app.logoF : app.logo}
                         height={40}
                         style={{verticalAlign: 'middle', marginRight: 20}} /> :
          null}
        {getTranslatedName(this.props.roles, this.props.locale, app.displayName)}
      </h3>
      {provision}
    </div>;
  },
});

export default injectIntl(ShowProvision);
