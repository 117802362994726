import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import styles from './TableResults.module.scss';

import {FormattedMessage} from 'react-intl';

const TableResults = createClass({
  displayName: 'TableResults',
  mixins: [PureRenderMixin],
  propTypes: {
    header: PropTypes.any,
    footer: PropTypes.any,
    totals: PropTypes.array,
    children: PropTypes.any,
  },

  getDefaultProps() {
    return {
      totals: [],
    };
  },

  render() {
    return <div>
      <table className={'table table-striped ' + styles.table}>
        {this.props.header}
        <tbody>
          {this.props.children}
        </tbody>
        <tfoot>
          {this.props.totals.length > 0 ? <tr style={{fontWeight: 'bold'}}><td>
            <FormattedMessage id='reports.total' />
          </td>{this.props.totals.map((x, i) => <td className='text-right' key={i}>{x}</td>)}</tr> : null}
        </tfoot>
        {this.props.footer}
      </table>
    </div>;
  },
});

export default TableResults;
