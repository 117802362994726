import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import Icon from 'components/Icon/Icon';

import styles from './Modal.module.scss';

const Modal = createClass({
  displayName: 'Modal',
  mixins: [PureRenderMixin],
  propTypes: {
    calloutFooter: PropTypes.bool,
    children: PropTypes.element.isRequired,
    close: PropTypes.func.isRequired,
    header: PropTypes.any.isRequired,
    footer: PropTypes.any.isRequired,
    id: PropTypes.string.isRequired, // Unique key to identify for close modal click handlers
    visible: PropTypes.bool,
    size: PropTypes.string,
  },

  getDefaultProps() {
    return {
      calloutFooter: true,
      size: 'lg',
      visible: false,
    };
  },

  componentDidMount() {
    document.getElementById('app-container').addEventListener('click', this.clickHandler);
    document.querySelector('body').addEventListener('keydown', this.keyHandler);
  },

  componentWillUnmount() {
    document.getElementById('app-container').removeEventListener('click', this.clickHandler);
    document.querySelector('body').removeEventListener('keydown', this.keyHandler);
  },

  clickHandler(e) {
    if (this.props.visible && !e.target.closest('#' + this.props.id)) {
      this.props.close();
    }
  },

  keyHandler(e) {
    if (this.props.visible && e.keyCode === 27) {
      this.props.close();
    }
  },

  render() {
    if (!this.props.visible) {
      return null;
    }
    return <div>
      <div className={'modal-backdrop ' + styles.backdrop} />
      <div className={'modal ' + styles.modal} role='dialog'>
        <div className={'modal-dialog modal-' + this.props.size + ' ' + styles.modalDialog} id={this.props.id} role='document'>
          <div className={'modal-content ' + styles.modalContent}>
            <div className={'modal-header ' + styles.modalHeader}>
              {this.props.header}
              <button className={styles.closeButton} onClick={this.props.close}>
                <Icon name='close' />
              </button>
            </div>
            <div className={'modal-body ' + styles.modalBody}>
              {this.props.children}
            </div>
            <div className={'modal-footer ' + styles.modalFooter + (this.props.calloutFooter ? ' ' + styles.footerBox : '')}>
              {this.props.footer}
            </div>
          </div>
        </div>
      </div>
    </div>;
  },
});

export default Modal;
