import React, {createClass, PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import {DragSource, DropTarget} from 'react-dnd';

const type = 'SORTABLE_CARD';

const indentSize = 50;

import styles from './SortableCard.module.scss';

const SortableCard = createClass({
  displayName: 'SortableCard',
  mixins: [PureRenderMixin],
  propTypes: {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    draggable: PropTypes.bool.isRequired,
    horizontalIndent: PropTypes.number.isRequired,
    previousIndent: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    moveCard: PropTypes.func.isRequired,
    title: PropTypes.any.isRequired,
  },

  render() {
    let {connectDragSource, connectDropTarget} = this.props;

    // Need horizontal indent to be on inner object so doesn't changing while dragging doesn't glitch and increase very quickly
    let cardStyle = {
      marginLeft: indentSize * this.props.horizontalIndent,
    };

    let cardClass = styles.outerCard;
    if (this.props.isDragging) {
      cardClass += ' ' + styles.draggingCard;
    }

    let output = <div className={cardClass} style={cardStyle}>
      {this.props.title}
    </div>;

    if (this.props.draggable) {
      return connectDropTarget(connectDragSource(output));
    }
    return output;

  },
});

const source = {
  beginDrag: (props) => {
    return {
      id: props.id,
      index: props.index,
      horizontalIndent: props.horizontalIndent,
    };
  },
};
const target = {
  hover: (props, monitor, component) => {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    const currentIndent = monitor.getItem().horizontalIndent;

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect(); // eslint-disable-line react/no-find-dom-node

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    let rawIndent = (monitor.getSourceClientOffset().x + props.horizontalIndent * indentSize - hoverBoundingRect.left) / indentSize;

    // Changed to floor instead of round since otherwise cannot drag very front of card
    let newIndent = Math.floor(rawIndent);
    newIndent = Math.max(0, newIndent);
    newIndent = Math.min(props.previousIndent + 1, newIndent);


    // Don't update if not 50% moved vertically or indent isn't changed
    if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
      (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) ||
      (dragIndex === hoverIndex && newIndent === currentIndent)
    ) {
      return;
    }

    // Move card
    props.moveCard(dragIndex, hoverIndex, newIndent);

    // Mutate monitor since otherwise only updates when drop, not as hovering.
    monitor.getItem().index = hoverIndex;
    monitor.getItem().horizontalIndent = newIndent;
  },
};

function targetPropsInjector(connect) {
  return {
    connectDragSource: connect.dropTarget(),
  };
}
function sourcePropsInjector(connect, monitor) {
  return {
    connectDropTarget: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}


export default DropTarget(type, target, targetPropsInjector)(
  DragSource(type, source, sourcePropsInjector)(SortableCard)
);
