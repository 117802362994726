import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {Set, List} from 'immutable';

import {UserModel} from 'models';

import {Input, Select} from 'components/forms';

import phoneFormat from 'helpers/phoneFormat';
import ValidateGroup from 'helpers/validation';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const UserCreation = createClass({
  displayName: 'UserCreation',
  mixins: [PureRenderMixin],
  group: null,
  propTypes: {
    user: PropTypes.instanceOf(UserModel).isRequired,
    submit: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
    currentUser: PropTypes.instanceOf(UserModel).isRequired,
  },

  contextTypes: {
    canChangeDealer: PropTypes.bool.isRequired,
  },

  getInitialState() {
    let selectedLocale = this.props.user.locale;
    if (this.props.currentUser && this.props.currentUser.dealer) {
      selectedLocale = this.props.currentUser.dealer.locale;
    }

    return {selectedLocale};
  },

  componentWillMount() {
    this.group = new ValidateGroup(this.submit);
  },

  submit() {
    let user = this.props.user;

    let keys = new Set(Object.keys(this.refs)).intersect(UserModel.keys);
    for (let key of keys) {
      user = user.set(key, this.refs[key].getValue());
    }

    return this.props.submit(user);
  },

  setSelectedLocale(selectedLocale) {
    this.setState({selectedLocale});
  },

  render() {
    let user = this.props.user;
    let {formatMessage} = this.props.intl;
    let localeOptions = {};

    if (!this.context.canChangeDealer && this.props.currentUser && this.props.currentUser.dealer) {
      localeOptions = this.props.possibleLocales
        .filter(x => x.get('regions').includes(this.props.currentUser.dealer.region))
        .reduce((acc, x) => {
          acc[x.get('locale')] = x.get('labels').get(x.get('locale'));
          return acc;
        }, {});

      if (!Object.keys(localeOptions).includes(user.locale)) {
        user = user.set('locale', Object.keys(localeOptions).length > 0 ? Object.keys(localeOptions)[0] : 'en-US');
      }
    }


    return <div>
      <h2><FormattedMessage id='user.wizard.information' /></h2>
      <div className='row'>
        <div className='col-md-4'>
          <Input value={user.firstName} label={formatMessage({id: 'user.fields.firstName'}) + ':'}
            ref='firstName' autoFocus group={this.group}
            required={UserModel.isRequired('firstName')}
            validate={UserModel.getValidate('firstName')} />
        </div>
        <div className='col-md-4'>
          <Input value={user.lastName} label={formatMessage({id: 'user.fields.lastName'}) + ':'}
            ref='lastName' required={UserModel.isRequired('lastName')} group={this.group}
            validate={UserModel.getValidate('lastName')} />
        </div>
        <div className='col-md-4'>
          <Input value={user.email} label={formatMessage({id: 'user.fields.email'}) + ':'}
            ref='email' required={UserModel.isRequired('email')} type='email' group={this.group}
            validate={UserModel.getValidate('email')} validationMessage='Please enter a valid email address.' />
        </div>
        <div className='col-md-4'>
          <Input value={user.mobilePhone} label={formatMessage({id: 'user.fields.mobilePhone'}) + ':'}
              ref='mobilePhone' format={phoneFormat} group={this.group}
              required={UserModel.isRequired('mobilePhone')} validate={UserModel.getValidate('mobilePhone')} />
        </div>
        <div className='col-md-4'>
          <Input value={user.alternatePhone} label={formatMessage({id: 'user.fields.alternatePhone'}) + ':'}
              ref='alternatePhone' format={phoneFormat} group={this.group}
              required={UserModel.isRequired('alternatePhone')} validate={UserModel.getValidate('alternatePhone')} />
        </div>
        {/* If can't change dealer just show the language stuff here then*/}
        {!this.context.canChangeDealer ? <div className='col-md-4'>
          <Select value={this.state.selectedLocale} label={formatMessage({id: 'user.fields.locale'}) + ':'}
            ref='locale' options={localeOptions} onSelect={this.setSelectedLocale} />
        </div> : null}
      </div>

      <div className='row'>
        <div className='col-xs-12'>
          <button className='btn btn-default pull-left' onClick={this.props.cancel}><FormattedMessage id='user.wizard.cancel' /></button>
          <button className='btn btn-primary pull-right' onClick={this.group.validate}><FormattedMessage id='user.wizard.continue' /></button>
        </div>
      </div>
    </div>;
  },
});

export default injectIntl(UserCreation);
