import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import Card from 'components/layout/Card/Card';
import {BarChart} from 'components/charts';
import {Map} from 'immutable';
import Results from 'components/layout/ReportsGenerics/LargeResults/LargeResults';

import {injectIntl, FormattedMessage, intlShape} from 'react-intl';

const Logins = createClass({
  displayName: 'Logins',
  mixins: [PureRenderMixin],
  propTypes: {
    successful: PropTypes.number.isRequired,
    failed: PropTypes.number.isRequired,
    avgSuccess: PropTypes.number.isRequired,
    full: PropTypes.instanceOf(Map).isRequired,
    numStores: PropTypes.number.isRequired,
    numDealers: PropTypes.number.isRequired,
    intl: intlShape.isRequired,
  },

  contextTypes: {
    role: PropTypes.bool.isRequired,
    canAccessAllDealers: PropTypes.bool.isRequired,
  },

  gotoPage() {
    browserHistory.push('/reports/logins');
  },

  render() {
    let {formatMessage} = this.props.intl;

    return <div>
      <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}><FormattedMessage id='reports.dashboard.loginsOverTime' /></span>
          <button onClick={this.gotoPage} className={'btn btn-transparent pull-right'}><FormattedMessage id='reports.dashboard.fullReportLink' /></button></h3>}
        lightHeader={true}
        headerFontSize={16}
        style={{marginBottom: 20}} >
        <div className='row'>
          <div className='col-sm-12'>
            <Results key={0} colSize={!this.context.canAccessAllDealers ? 'col-sm-12' : 'col-sm-6'}
              marginBottom={20} light={false} storeAverage={this.props.successful / this.props.numStores}
              dealerAverage={this.props.successful / this.props.numDealers}
              text={formatMessage({id: 'reports.dashboard.totalSuccessLogins'})} number={this.props.successful} />
            {this.context.canAccessAllDealers ? <Results key={1} colSize='col-sm-6' marginBottom={20} light={false} textGray={true} bottom={false}
              text={formatMessage({id: 'reports.dashboard.totalFailedLogins'})} number={this.props.failed} fillBottomSpace={true} /> : null}
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <BarChart data={this.props.full.map((num, app) => {return {value: num, name: app};}).toArray()} />
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(Logins);
