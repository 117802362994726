import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import styles from './TextArea.module.scss';

import ValidateGroup from 'helpers/validation';
import {PromiseStoreMixin, makeCancelable} from 'helpers/request';

import {FormattedMessage} from 'react-intl';

const TextArea = createClass({
  displayName: 'TextArea',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    autoFocus: PropTypes.bool,
    colSize: PropTypes.string,
    error: PropTypes.bool,
    format: PropTypes.func,
    group: PropTypes.instanceOf(ValidateGroup),
    inline: PropTypes.bool,
    label: PropTypes.string,
    limit: PropTypes.number,
    noBottomMargin: PropTypes.bool,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    validate: PropTypes.func,
    value: PropTypes.string.isRequired,
  },

  getDefaultProps() {
    return {
      autoFocus: false,
      error: false,
      inline: false,
      readOnly: false,
      required: false,
      noBottomMargin: false,
    };
  },

  getInitialState() {
    return {
      error: false,
      errorMessage: null,
      requiredWarning: false,
      val: '',
    };
  },

  componentWillMount() {
    this.setState({
      val: this.props.value || '',
    });
  },

  componentDidMount() {
    if (this.props.group) {
      this.props.group.addComponent(this);
    }
  },

  componentWillUnmount() {
    if (this.props.group) {
      this.props.group.removeComponent(this);
    }
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      let val = nextProps.value;
      if (nextProps.format) {
        val = nextProps.format(val);
      }
      this.setState({
        val: val,
      });
    }
  },

  getValue() {
    return this.state.val;
  },

  focus() {
    this.refs.input.focus();
  },

  validate() {
    if (this.props.required && !this.state.val) {
      this.setState({
        requiredWarning: true,
      });
      console.log('Field is required');
      return {valid: false};
    }
    if (this.props.validate) {
      let validateResult = this.props.validate(this.state.val);
      if (validateResult.valid === false) {
        this.setState({
          error: true,
          errorMessage: validateResult.message,
        });
        console.log('Validation failed');
        return {valid: false};
      }

      if (validateResult instanceof Promise) {
        return validateResult.then(x => {
          if (!x.valid) {
            this.setState({
              error: true,
              errorMessage: x.message,
            });
            console.log('Validation failed');
            return {valid: false};
          }
          return {valid: true};
        });
      }
      return {valid: true};
    }
    return {valid: true};
  },

  dataChanged(newValue) {
    let nextValue = newValue.target.value;

    if (this.props.limit && nextValue.length > this.props.limit && nextValue.length > this.state.val.length) {
      return;
    }

    if (this.props.format) {
      nextValue = this.props.format(nextValue);
    }
    let requiredWarning = this.state.requiredWarning ? !nextValue : false;
    let error = this.state.error;
    let potentialError = {valid: true, message: null};
    if (error && this.props.validate) {
      potentialError = this.props.validate(nextValue);
      let errorPromise;
      if (potentialError instanceof Promise) {
        errorPromise = potentialError;
      } else {
        errorPromise = Promise.resolve(potentialError);
      }
      errorPromise = makeCancelable(errorPromise);
      this.promises.add(errorPromise)
        .promise()
        .then(x => {
          this.setState({
            error: !x.valid,
            errorMessage: x.message || null,
          });
        });
    }
    this.setState({
      val: nextValue,
      requiredWarning,
    });
  },

  render() {
    let error = this.state.error || this.state.requiredWarning ? ' ' + styles.error : '';

    let inline = '';
    if (this.props.inline) {
      inline = ' ' + styles.inline;
    }

    let input = <textarea className={'form-control' + error + inline} value={this.state.val} onBlur={this.props.onBlur}
        onChange={this.dataChanged} placeholder={this.props.placeholder} autoFocus={this.props.autoFocus} ref='input' />;

    if (this.props.readOnly) {
      input = <span className={styles.readOnly}>{this.props.value}</span>;
    }

    return <div className={(this.props.colSize ? this.props.colSize : '')  + ' form-group' + inline}
                style={this.props.noBottomMargin ? {marginBottom: 0}: null}>
      {this.props.label ? <label>{this.props.label}{this.props.required ? <span className={styles.required}>*</span> : null}</label> : null}
      {input}
      {this.props.group ? <span>
        {this.state.requiredWarning ? <FormattedMessage id='fields.required' /> : null}
        {this.state.error && !this.state.requiredWarning && this.state.errorMessage  ? this.state.errorMessage : null}
        &nbsp;
      </span> : null}
    </div>;
  },
});

export default TextArea;
