import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import {Input} from 'components/forms';
import {Input as ReadOnlyInput} from 'components/forms/ReadOnly';
import Icon from 'components/Icon/Icon';

const CopyEditor = createClass({
  displayName: 'CopyEditor',
  mixins: [PureRenderMixin],
  propTypes: {
    languages: PropTypes.array.isRequired,
    copy: PropTypes.object.isRequired,
    tag: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    style: PropTypes.object,
    delete: PropTypes.func.isRequired,
  },

  getDefaultProps() {
    return {
      copy: {},
      languages: [],
      readOnly: false,
      style: {},
    };
  },

  getInitialState() {
    return {
      copy: this.props.copy,
    };
  },

  getCopy() {
    return this.state.copy;
  },

  onChange(key) {
    return val => {
      let copy = Object.assign({}, this.state.copy);
      copy[key] = val;
      this.setState({copy});
    };
  },

  render() {
    let {languages, readOnly} = this.props;
    let {copy} = this.state;
    let fields = [];

    let WrappedInput = readOnly ? ReadOnlyInput : Input;

    let width = Math.floor(100 / (languages.length > 0 ? languages.length : 1)) - 2;
    let style = {
      width: width + '%',
      display: 'inline-block',
      marginRight: 5,
    };

    for (let lang of languages) {
      fields.push(<WrappedInput dataKey={lang.locale} key={lang.locale} value={copy[lang.locale]} onChange={this.onChange(lang.locale)} style={style} />);
    }

    return <div style={this.props.style}>
      {fields}
      {!readOnly ? <button className='icon-button btn' onClick={this.props.delete}><Icon name='delete' /></button> : null}
    </div>;
  },
});

export default CopyEditor;
