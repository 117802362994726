import {connect} from 'react-redux';

import {clearDealerWizard, navigateDealerWizard} from 'redux/actions/dealers';
import {addToast} from 'redux/actions/toasts';

import {saveNewDealer, checkSoldTo, checkShipTo} from 'api/group';
import {checkDealerEmailUsed} from 'api/user';

import CreateDealer from 'components/pages/Dealers/Create/Create';

function mapStateToProps({data, locale}) {
  return {
    dealer: data.getIn(['dealerWizard', 'dealer']),
    duplicate: data.getIn(['dealerWizard', 'duplicate']),
    duplicateStore: data.getIn(['dealerWizard', 'duplicateStore']),
    duplicateUser: data.getIn(['dealerWizard', 'duplicateUser']),
    user: data.getIn(['dealerWizard', 'user']),
    wizardPage: data.getIn(['dealerWizard', 'page']),
    regions: locale.get('regions'),
    possibleLocales: locale.get('possibleLocales'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (...args) => dispatch(addToast(...args)),
    clearWizard: () => dispatch(clearDealerWizard()),
    navigateDealerWizard: (...args) => dispatch(navigateDealerWizard(...args)),
    save: (...args) => dispatch(saveNewDealer(...args)),
    checkSoldTo: (...args) => dispatch(checkSoldTo(...args)),
    checkShipTo: (...args) => dispatch(checkShipTo(...args)),
    checkEmail: (...args) => dispatch(checkDealerEmailUsed(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDealer);
