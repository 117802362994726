import {connect} from 'react-redux';
import ReportsEmails from 'components/pages/Reports/Emails/Emails';
import {getEmailsReport} from 'api/report';
import {setReportSettings} from 'redux/actions/reports';

function mapStateToProps({data}) {
  return {
    settings: data.getIn(['reports', 'emails', 'settings']),
    report: data.getIn(['reports', 'emails', 'data']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEmailsReport: (...args) => dispatch(getEmailsReport(...args)),
    updateSettings: (...args) => dispatch(setReportSettings('emails', ...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsEmails);
