import {showMaintenanceMessage} from 'helpers/constants';
import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {List, Map} from 'immutable';

import NavBar from '../NavBar/NavBar';
import Toast from '../../misc/Toast/Toast';
import SideBar from '../SideBar/SideBar';
import Footer from 'containers/layout/Footer';

import {UserModel} from 'models';
import insertAppSelector from 'helpers/appSelect';

import styles from './App.module.scss';

const App = createClass({
  displayName: 'App',
  mixins: [PureRenderMixin],
  propTypes: {
    addToast: PropTypes.func.isRequired,
    children: PropTypes.any,
    clearAppStatus: PropTypes.func.isRequired,
    clearToast: PropTypes.func.isRequired,
    clearUserStatus: PropTypes.func.isRequired,
    currentUser: PropTypes.instanceOf(UserModel).isRequired,
    disclaimerModalVisible: PropTypes.bool.isRequired,
    getCurrentUser: PropTypes.func.isRequired,
    getRoles: PropTypes.func.isRequired,
    loc: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
    privacyModalVisible: PropTypes.bool.isRequired,
    setLocale: PropTypes.func.isRequired,
    status: PropTypes.object,
    toasts: PropTypes.instanceOf(List).isRequired,
    toggleDisclaimerModal: PropTypes.func.isRequired,
    togglePrivacyModal: PropTypes.func.isRequired,
    token: PropTypes.object,
    initialLoad: PropTypes.bool.isRequired,
  },

  getChildContext: function() {
    let permission = this.props.token && this.props.token.profile && this.props.token.profile.permission ? this.props.token.profile.permission : [];
    if (!(permission instanceof Array)) {
      permission = [permission];
    }
    let role = this.props.token && this.props.token.profile && this.props.token.profile.role ? this.props.token.profile.role : null;

    let mapping = {
      canCreateApps: 'user-portal-create-apps',
      canDeleteApps: 'user-portal-delete-apps',
      canViewApps: 'user-portal-view-apps',
      canModifyApps: 'user-portal-modify-apps',

      canCreatePermmissions: 'user-portal-create-permissions',
      canModifyPermissions: 'user-portal-modify-permissions',
      canDeletePermissions: 'user-portal-delete-permissions',

      canCreateRoles: 'user-portal-create-roles',
      canModifyRoles: 'user-portal-modify-roles',
      canDeleteRoles: 'user-portal-delete-roles',

      canCreateDealers: 'user-portal-create-dealers',
      canDeleteDealers: 'user-portal-delete-dealers',
      canViewDealers: 'user-portal-view-dealers',
      canModifyDealers: 'user-portal-modify-dealers',
      canDeleteStores: 'user-portal-delete-stores',
      canModifyStores: 'user-portal-modify-stores',
      canCreateStores: 'user-portal-create-stores',

      canCreateUsers: 'user-portal-create-users',
      canModifyUsers: 'user-portal-modify-users',
      canDeleteUsers: 'user-portal-delete-users',
      canViewUsers: 'user-portal-view-users',
      canLockUsers: 'user-portal-lock-users',
      canProvisionApps: 'user-portal-provision-apps',
      canDeProvisionApps: 'user-portal-deprovision-apps',
      canCreateDealerAdmin: 'user-portal-create-dealer-admin',

      canViewReports: 'user-portal-view-reports',

      canAccessAllDealers: 'user-portal-access-all-dealers',

      canChangeDealer: 'user-portal-change-dealer', // Change which dealer a user is provisioned to
      // canViewReports: 'user-portal-view-reports',

      allPermissions: 'user-portal-all-permissions',
      canAssignPrimary: 'user-portal-assign-primary',
      canModifyPrimary: 'user-portal-modify-primary',
    };

    let output = {
      currentUser: this.props.currentUser || new UserModel(),
      isSystemAdmin: role && (role === 'system_admin' || role === 'biolab_admin'),
      token: this.props.token,
      role: role,
    };

    let keys = Object.keys(mapping);
    for (let key of keys) {
      output[key] = !!permission.find(x => x === mapping[key]);
    }

    if (!output.allPermissions) {
      output.allPermissions = permission.includes('user-portal-all-permisions');
    }

    return output;
  },

  childContextTypes: {
    token: PropTypes.object,
    currentUser: PropTypes.instanceOf(UserModel),
    isSystemAdmin: PropTypes.bool,

    canCreateApps: PropTypes.bool,
    canDeleteApps: PropTypes.bool,
    canViewApps: PropTypes.bool,
    canModifyApps: PropTypes.bool,

    canCreatePermmissions: PropTypes.bool,
    canModifyPermissions: PropTypes.bool,
    canDeletePermissions: PropTypes.bool,

    canCreateRoles: PropTypes.bool,
    canModifyRoles: PropTypes.bool,
    canDeleteRoles: PropTypes.bool,

    canCreateDealers: PropTypes.bool,
    canDeleteDealers: PropTypes.bool,
    canViewDealers: PropTypes.bool,
    canModifyDealers: PropTypes.bool,
    canDeleteStores: PropTypes.bool,
    canModifyStores: PropTypes.bool,
    canCreateStores: PropTypes.bool,

    canCreateUsers: PropTypes.bool,
    canModifyUsers: PropTypes.bool,
    canDeleteUsers: PropTypes.bool,
    canViewUsers: PropTypes.bool,
    canLockUsers: PropTypes.bool,
    canProvisionApps: PropTypes.bool,
    canDeProvisionApps: PropTypes.bool,
    canCreateDealerAdmin: PropTypes.bool,

    canAccessAllDealers: PropTypes.bool,
    canChangeDealer: PropTypes.bool,
    canViewReports: PropTypes.bool,
    allPermissions: PropTypes.bool,
    canAssignPrimary: PropTypes.bool,
    canModifyPrimary: PropTypes.bool,

    role: PropTypes.string,
  },

  componentWillMount() {
    let {locale, possibleLocales, setLocale, initialLoad} = this.props;
    this.props.getCurrentUser();
    this.props.getRoles();

    let fullLocale = possibleLocales.find(x => x.get('locale') === locale);
    let newLocale = possibleLocales.find(x => x.get('locale') === window.navigator.language || x.get('shortLocale') === window.navigator.language);

    if (initialLoad && fullLocale && newLocale && fullLocale !== newLocale) {
      setLocale(newLocale.get('locale'));
    }
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.locale !== this.props.locale || nextProps.token !== this.props.token) {
      this.bindAppSelector(nextProps);
    }
  },

  componentDidMount() {
    this.bindAppSelector(this.props);

    if (this.props.token && this.props.token.profile && this.props.token.profile.locale && this.props.token.profile.locale !== this.props.locale) {
      this.props.setLocale(this.props.token.profile.locale);
    }
  },

  bindAppSelector(props) {
    const {token} = props;
    const {profile} = token

    let appKeys = [];
    if (token && profile && profile.app) {
      if (profile.app instanceof Array) {
        appKeys = profile.app;
      } else {
        appKeys.push(profile.app);
      }
    }
    insertAppSelector(window.clientAddress, appKeys, 'user-portal', null, null, false, false, props.locale,
      profile && profile.address ? profile.address.country || 'US' : 'US',
      profile && profile.given_name ? profile.given_name : '',
      profile && profile.family_name ? profile.family_name : '',
      profile && profile.email ? profile.email : '');
  },

  dismissToast(key) {
    return () => {
      this.props.clearToast(key);
    };
  },

  hideDisclaimerModal() {
    return this.props.toggleDisclaimerModal(false);
  },

  openDisclaimerModal() {
    return this.props.toggleDisclaimerModal(true);
  },

  hidePrivacyModal() {
    return this.props.togglePrivacyModal(false);
  },

  openPrivacyModal() {
    return this.props.togglePrivacyModal(true);
  },

  render() {
    let toasts = this.props.toasts.map(t => <Toast toast={t} dismiss={this.dismissToast(t.key)} key={t.key} />);

    return <div className={styles.wrapper} >
      <NavBar token={this.props.token} title='User Portal' activePage={window.location.hash} locale={this.props.locale} setLocale={this.props.setLocale}
        locales={this.props.possibleLocales} />
      {showMaintenanceMessage ? <div className={styles.maintenanceNotice}>
          We are undergoing maintenance. Some functionality may be missing or disabled until finished.
        </div> : null}
      <div className={styles.toastContainer}>
        {toasts}
      </div>
      <SideBar token={this.props.token} loc={this.props.loc} />
      <div className={styles.mainContent}>
        <div className='container'>
          {this.props.children}
        </div>
      </div>
      <div className={styles.pushFooter} />
      <Footer
        disclaimerModalVisible={this.props.disclaimerModalVisible}
        hideDisclaimerModal={this.hideDisclaimerModal}
        openDisclaimerModal={this.openDisclaimerModal}
        locale={this.props.locale}
        privacyModalVisible={this.props.privacyModalVisible}
        hidePrivacyModal={this.hidePrivacyModal}
        openPrivacyModal={this.openPrivacyModal}
        />
    </div>;
  },
});

export default App;
