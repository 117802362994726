import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import Card from 'components/layout/Card/Card';
import TableResults from 'components/layout/ReportsGenerics/TableResults/TableResults';
import {BarChart} from 'components/charts';
import {LoginEventsReport, ReportSettings} from 'models';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const Successful = createClass({
  displayName: 'Successful',
  mixins: [PureRenderMixin],
  propTypes: {
    report: PropTypes.instanceOf(LoginEventsReport).isRequired,
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    intl: intlShape.isRequired,
  },

  contextTypes: {
    role: PropTypes.string.isRequired,
    canAccessAllDealers: PropTypes.bool.isRequired,
  },

  render() {
    const {report, settings} = this.props;
    let {formatMessage} = this.props.intl;

    const compare = report && report.compare && settings && settings.compare && settings.compare.from && settings.compare.to;

    let data = [];
    let branchTotals = [report.successesPerBranch.reduce((acc, x) => acc + x, 0)];
    let dealerTotals = [report.successesPerDealer.reduce((acc, x) => acc + x, 0)];
    let valueField = ['value'];
    if (!compare) {
      data = report.successesPerApp.map((value, name) => {return {value, name};}).toArray();
    } else {
      branchTotals.push(report.compare.successesPerBranch.reduce((acc, x) => acc + x, 0));
      dealerTotals.push(report.compare.successesPerDealer.reduce((acc, x) => acc + x, 0));

      data = report.successesPerApp.map((value, name) => {return {value, name, compare: report.compare.successesPerApp.get(name)};}).toArray();
      valueField.push('compare');
    }

    return <div>
      <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{formatMessage({id: 'reports.successLogins.title'})}</span></h3>}
        lightHeader={true}
        style={{marginBottom: 20}}>
        <div className='row'>
          <div className='col-sm-12'>
            <BarChart data={data} valueField={valueField} />
          </div>
        </div>
      </Card>
      <div className='row'>
        {this.context.canAccessAllDealers ? <div className='col-sm-6'>
          <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{formatMessage({id: 'reports.successLogins.dealerAverage'})}</span></h3>}
            headerFontSize={16} lightHeader={true} minHeight={370} style={{marginBottom: 20}} >
            <div className='row'>
              <div className='col-sm-12'>
                <TableResults totals={dealerTotals} header={<thead><tr>
                  <th><FormattedMessage id='reports.logins.app' /></th>
                  <th className='text-right'><FormattedMessage id='reports.count' />
                    <small>(
                      <FormattedMessage id='reports.logins.dateRange' values={{
                        from: settings.from.format('lll'),
                        to: settings.to.format('lll'),
                      }} />
                    )</small>
                  </th>
                  {compare ?
                    <th className='text-right'><FormattedMessage id='reports.count' />
                      <small>({settings.compare.from.format('MMM D, YYYY')} to {settings.compare.to.format('MMM D, YYYY')})</small></th>
                     : null}
                   </tr></thead>}>
                    {report.successesPerDealer.map((num, dealer) => {
                      return <tr key={dealer}>
                        <td>{dealer}</td>
                        <td className='text-right'>{num}</td>
                        {compare ? <td>{report.compare.successesPerDealer.get(dealer) || 0}</td> : null}
                      </tr>;
                    }).toArray()}
                </TableResults>
              </div>
            </div>
          </Card>
        </div> : null}
        {this.context.role !== 'manager' ? <div className='col-sm-6'>
          <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}>{formatMessage({id: 'reports.successLogins.storeAverage'})}</span></h3>}
            headerFontSize={16} lightHeader={true} minHeight={370} style={{marginBottom: 20}} >
            <div className='row'>
              <div className='col-sm-12'>
                <TableResults totals={branchTotals} header={<thead><tr>
                  <th><FormattedMessage id='reports.logins.app' /></th>
                  <th className='text-right'><FormattedMessage id='reports.count' />
                    <small>(
                      <FormattedMessage id='reports.logins.dateRange' values={{
                        from: settings.from.format('lll'),
                        to: settings.to.format('lll'),
                      }} />
                    )</small>
                  </th>
                  {compare ?
                    <th className='text-right'><FormattedMessage id='reports.count' />
                      <small>(
                        <FormattedMessage id='reports.logins.dateRange' values={{
                          from: settings.compare.from.format('lll'),
                          to: settings.compare.to.format('lll'),
                        }} />
                      )</small>
                    </th>
                     : null}
                   </tr></thead>}>
                    {report.successesPerBranch.map((num ,branch) => {
                      return <tr key={branch}>
                        <td>{branch}</td>
                        <td className='text-right'>{num || 0}</td>
                        {compare ? <td className='text-right'>{report.compare.successesPerBranch.get(branch) || 0}</td> : null}
                      </tr>;
                    }).toArray()}
                </TableResults>
              </div>
            </div>
          </Card>
        </div> : null}
      </div>
    </div>;
  },
});

export default injectIntl(Successful);
