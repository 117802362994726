import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {browserHistory} from 'react-router';
import {List} from 'immutable';

import {PromiseStoreMixin} from 'helpers/request';

import Card from 'components/layout/Card/Card';
import EditApp from './Edit/Edit';
import ViewApp from './View/View';
import ListApps from './List/List';
import PageHeader from 'components/layout/PageHeader/PageHeader';

import {AppModel} from 'models';

import styles from './Apps.module.scss';

import {injectIntl, intlShape} from 'react-intl';

const Apps = createClass({
  displayName: 'Apps',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    addToast: PropTypes.func.isRequired,
    apps: ImmutablePropTypes.listOf(PropTypes.instanceOf(AppModel)),
    assignGroup: PropTypes.func.isRequired,
    getApp: PropTypes.func.isRequired,
    getApps: PropTypes.func.isRequired,
    getGroups: PropTypes.func.isRequired,
    deleteApp: PropTypes.func.isRequired,
    toggleAppDeleteModal: PropTypes.func.isRequired,
    deleteModalVisible: PropTypes.bool.isRequired,
    groups: PropTypes.instanceOf(List).isRequired,
    path: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    removeGroup: PropTypes.func.isRequired,
    saveApp: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    intl: intlShape.isRequired,
    locale: PropTypes.string,
    rolesCopy: PropTypes.instanceOf(List),
    regions: PropTypes.instanceOf(List).isRequired,
  },

  componentWillMount() {
    this.fetchData(this.props);
    this.checkFirst(this.props);
  },

  getDefaultProps() {
    return {
      readOnly: false,
    };
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.params.id !== nextProps.params.id) {
      this.fetchData(nextProps);
    }
    this.checkFirst(nextProps);
  },

  checkFirst(props) {
    if ((!props.params || !props.params.id) && props.apps.size > 0) {
      browserHistory.replace('/apps/' + props.apps.first().id);
    }
  },

  fetchData(props) {
    this.promises.add(props.getApps());
    this.promises.add(props.getGroups());
  },

  addApp() {
    browserHistory.push('/apps/new/edit');
  },

  hideDeleteModal() {
    this.props.toggleAppDeleteModal(false);
  },

  showDeleteModal() {
    this.props.toggleAppDeleteModal(true);
  },

  render() {
    let appID = this.props.params.id;
    let {formatMessage} = this.props.intl;
    let app = appID && appID !== 'new' ? this.props.apps.find(x => x.id === parseInt(appID)) : new AppModel();

    let Inner = this.props.readOnly ? ViewApp : EditApp;
    return <div>
      <PageHeader title={formatMessage({id: 'apps.pageHeader'})} backButton={false} />
      <Card header='Applications' noPadding={true} className={styles.manageApps} style={{paddingBottom: 20}}>
        <div className='row'>
          <div className='col-sm-3'>
            <ListApps apps={this.props.apps} rolesCopy={this.props.rolesCopy} locale={this.props.locale} addApp={this.addApp} activeApp={app} />
          </div>
          <div className='col-sm-9'>
            <Inner
              addToast={this.props.addToast}
              app={app}
              deleteModalVisible={this.props.deleteModalVisible}
              deleteApp={this.props.deleteApp}
              hideDeleteModal={this.hideDeleteModal}
              openDeleteModal={this.showDeleteModal}
              regions={this.props.regions}
              assignGroup={this.props.assignGroup}
              groups={this.props.groups}
              new={appID === 'new'}
              path={this.props.path}
              rolesCopy={this.props.rolesCopy}
              locale={this.props.locale}
              removeGroup={this.props.removeGroup}
              saveApp={this.props.saveApp}
            />
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(Apps);
