import {Input, Select} from 'components/forms';
import {Input as ReadOnlyInput} from 'components/forms/ReadOnly';
import Icon from 'components/Icon/Icon';
import Card from 'components/layout/Card/Card';
import Modal from 'components/layout/Modal/Modal';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import {PromiseStoreMixin} from 'helpers/request';
import {List, Set} from 'immutable';
import {GroupModel} from 'models';
import path from 'path';
import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {browserHistory, Link} from 'react-router';
import ShowStore from './ShowStore/ShowStore';


const View = createClass({
  displayName: 'View',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    dealer: PropTypes.instanceOf(GroupModel),
    getGroup: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    deleteDealer: PropTypes.func.isRequired,
    deleteModalVisible: PropTypes.bool.isRequired,
    emailUserPermissions: PropTypes.func.isRequired,
    showDeleteModal: PropTypes.func.isRequired,
    hideDeleteModal: PropTypes.func.isRequired,
    loadData: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    regions: PropTypes.instanceOf(List).isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
    getGroupUsers: PropTypes.func.isRequired,
    dealerUsers: PropTypes.instanceOf(List),
    usersForGroup: PropTypes.func.isRequired,
  },

  contextTypes: {
    canCreateUsers: PropTypes.bool.isRequired,
    canCreateDealers: PropTypes.bool.isRequired,
    canDeleteDealers: PropTypes.bool.isRequired,
    canModifyDealers: PropTypes.bool.isRequired,
    canCreateStores: PropTypes.bool.isRequired,
    canCreateDealerAdmin: PropTypes.bool.isRequired,
    isSystemAdmin: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      emailPermissionsUserID: 0,
      emailUserPermissionsOptions: [],
    };
  },

  componentWillMount() {
    if (this.props.loadData) {
      this.fetchData(this.props);
    }
  },

  createAdmin() {
    browserHistory.push(path.join(this.props.path, '/new-dealer-admin'));
  },

  addStore() {
    browserHistory.push(path.join(this.props.path, 'new-store'));
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.params.id !== nextProps.params.id) {
      this.fetchData(nextProps);
    }

    const dealerUsers = (this.props.dealerUsers || new List()).toArray();
    if(dealerUsers.length){
      this.initEmailPermissionsState(dealerUsers);
    }
  },

  initEmailPermissionsState(users) {
    let emailUserPermissionsOptions = users
    .reduce((acc, x) => {
      acc[x.id] = `${x.firstName || ''} ${x.lastName || ''}`.trim();
      return acc;
    }, {});

    this.setState({
      emailUserPermissionsOptions,
      emailPermissionsUserID: emailUserPermissionsOptions ? Object.keys(emailUserPermissionsOptions)[0] : 0,
    });
  },

  fetchData(props) {
    this.promises.add(props.getGroupUsers(props.params.id)).promise()
    .then((users) => {
      if (!users.data) {
        return;
      }
      this.initEmailPermissionsState(users);
    });

    this.promises.add(props.getGroup(props.params.id))
    .promise()
    .then(data => {
      if (!data || !data.data) {
        throw new Error(data.message);
      }
      }).catch(err => {
      console.error(err);
    });
  },

  deleteConfirm() {
    let typed = this.refs.deleteConfirmation.getValue();
    if (typed === this.props.dealer.displayName.toUpperCase()) {
      this.props.hideDeleteModal();
      this.promises.add(this.props.deleteDealer(this.props.dealer.id))
        .promise()
        .then(() => {
          browserHistory.push('/dealers');
        });
    }
  },

  editApp(e) {
    e.preventDefault();
    browserHistory.push('/dealer/' + this.props.dealer.id + '/edit');
  },

  setEmailPermissionsUserID(id) {
    this.setState({emailPermissionsUserID: id});
  },

  sendUserPermissionsEmail() {
    this.props.emailUserPermissions(this.props.dealer.id, this.state.emailPermissionsUserID);
  },


  render() {
    let {dealer} = this.props;
    dealer = dealer || new GroupModel();

    let uppercaseName = dealer.displayName.toUpperCase();

    let {formatMessage} = this.props.intl;
    let modalValues = {
      deleteTerm: uppercaseName,
    };

    const dealerUsers = Set(this.props.dealerUsers || new List());
    const primaryAdmins = dealerUsers.toArray().filter(x => x.primary);
    const assignedStoreUsers =  dealer.branches.map(branch => Set(this.props.usersForGroup(branch.id) || new List())).flatten();
    const unAssignedUsers =  (dealerUsers.filter(
      (set => a => !set.has(a.id))(Set(assignedStoreUsers.map(b => b.id)))
    ) || new List()).toArray();

    let region = this.props.regions.find(x => x.get('region') === dealer.region) || this.props.regions.first();

    let locale = this.props.possibleLocales.find(
      x => x.get('locale') === dealer.locale) || this.props.possibleLocales.first();


    let {emailPermissionsUserID, emailUserPermissionsOptions} = this.state;


    return <div>
      <PageHeader title={'Dealer: ' + (dealer ? dealer.displayName : '')} navTitle='Show Dealer' right={<div>
        <button className='btn'>{/*<Icon name='info' />*/}</button>
        {this.context.canDeleteDealers && dealer && dealer.id  ?
          <button className='btn' onClick={this.props.showDeleteModal}><Icon name='delete'/></button> : null}
        {this.context.canModifyDealers && dealer && dealer.id  ?
          <button className='btn' onClick={this.editApp}><Icon name='edit'/></button> : null}
      </div>}/>

      <Modal visible={this.props.deleteModalVisible} close={this.hideDeleteModal} id='dealer-delete-modal' size='md'
        header={<h3><FormattedMessage id='modals.deleteDealer.header'/></h3>}
        footer={<div>
          <Input colSize='col-sm-7' ref='deleteConfirmation' value='' autoFocus
            placeholder={formatMessage({id: 'modals.deleteDealer.placeholder'}, modalValues)}/>
          <button className='btn btn-primary' onClick={this.deleteConfirm}><FormattedMessage
            id='modals.deleteDealer.submit'/></button>
        </div>}>
        <div>
          <p><FormattedMessage id='modals.deleteDealer.warning'/></p>
          <p><FormattedMessage id='modals.deleteDealer.instructions' values={modalValues}/></p>
        </div>
      </Modal>

      <Card minHeight={0}>
        <div className='row'>
          <div className='col-md-2'>
            <ReadOnlyInput value={dealer.displayName} ref='displayName'
              label={formatMessage({id: 'dealer.fields.name'}) + ':'}/>
          </div>
          <div className='col-md-2'>
            <ReadOnlyInput value={dealer.soldTo} ref='soldTo'
              label={formatMessage({id: 'dealer.fields.soldTo'}) + ':'}/>
          </div>
          <div className='col-md-2'>
            <ReadOnlyInput value={region.get('label')} ref='region'
              label={formatMessage({id: 'dealer.fields.region'}) + ':'}/>
          </div>
          <div className='col-md-1'>
            <ReadOnlyInput value={locale.get('labels').get(locale.get('locale'))} ref='locale'
              label={formatMessage({id: 'dealer.fields.locale'}) + ':'}/>
          </div>
          <div className='col-md-2'>
            <div className='form-group'>
              <label><FormattedMessage id='dealer.fields.dealerAdmin'/>:
                <div className='form-control-static'>
                  {primaryAdmins.map(x => <div key={x.id}><Link to={'/user/' + x.id}>{x.firstName} {x.lastName}</Link></div>)}
                </div>
              </label>
            </div>
          </div>
          {this.context.canCreateDealerAdmin && dealer && dealer.id ?
            <div className='col-md-3'>
              <div className='form-group'>
                <label> </label>
                <div className='form-control-static'>
                  <button className='btn btn-primary' onClick={this.createAdmin}><FormattedMessage
                    id='dealer.addDealerAdmin'/></button>
                </div>
              </div>
            </div> : null}
        </div>
      </Card>

      {dealer.branches && dealer.branches.length ? <h2><FormattedMessage id='dealer.stores'/></h2> : null}

      {dealer.branches.map(branch => <ShowStore store={branch} key={branch._id} getGroupUsers={this.props.getGroupUsers}
        users={this.props.usersForGroup(branch.id)}/>)}

      {this.context.canCreateStores ?
        <button style={{marginTop: 20}} className='btn btn-dashed full-width' onClick={this.addStore}>
          <Icon name='add'/>&nbsp;<FormattedMessage id='dealer.addStore'/>
        </button> : null}

        {this.context.isSystemAdmin && unAssignedUsers && Object.keys(unAssignedUsers).length ?
        <div>
          <h2><FormattedMessage id='dealer.disabled.users'/></h2>
          <Card minHeight={0}>
            <div className='row'>
                {unAssignedUsers.map(x =>
                  <div key={x.id} className={'col-md-4'}>
                    <Link to={'/user/' + x.id}>{x.firstName} {x.lastName}</Link>
                  </div>)}
            </div>
          </Card>
        </div> : null}

      {this.context.canCreateUsers && this.context.canCreateDealers &&
        emailUserPermissionsOptions && Object.keys(emailUserPermissionsOptions).length ?
        <div>
          <h2><FormattedMessage id='dealer.user-permissions.section'/></h2>
          <Card minHeight={0}>
            <div className='row'>
              <div className='col-md-4'>
                <Select ref='userpermissions'
                  label={formatMessage({id: 'dealer.user-permissions.user'}) + ':'}
                  value={emailPermissionsUserID}
                  options={emailUserPermissionsOptions}
                  onSelect={this.setEmailPermissionsUserID}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <button className='btn btn-primary pull-left' onClick={this.sendUserPermissionsEmail}><FormattedMessage
                  id='dealer.user-permissions.email'/></button>
              </div>
            </div>
          </Card>
        </div> : null}

       

    </div>;
  },
});

export default injectIntl(View);
