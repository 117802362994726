import React from 'react';

/**
 * Converts columns to rows for a table to make it easier to enter inherently columnar data.
 * @param {string[]} cols An array of columns with the data for the table.
 * @return {element[]}         An array of rows with the data for the table wrapped in tr and td components.
 **/
export function colsToRows(...cols) {
  let rows = [];
  let numCols = cols.length;
  let maxLength = cols.map(c => c.length).reduce((acc, x) => acc >= x ? acc : x, 0);
  for (let i = 0; i < maxLength; ++i) {
    let row = [];
    for (let j = 0; j < numCols; ++j) {
      if (i < cols[j].length) {
        row.push(<td key={'cell' + j + i}>{cols[j][i]}</td>);
      } else {
        row.push(<td key={'cell' + j + i} />);
      }
    }
    rows.push(<tr key={'row' + i}>{row}</tr>);
  }

  return rows;
}
