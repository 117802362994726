import {
  SEARCH_USERS, SET_USER, DELETE_USER, SAVE_USER_SUCCESS, SAVE_USER_FAILURE, SAVING_USER, CLEAR_USER_STATUS, CLEAR_USER_SEARCH,
  NAVIGATE_USER_WIZARD, CANCEL_USER_WIZARD,
  SET_STORE_PROVISION_VISIBLE,
} from './index';

export function searchUsers(results) {
  return {
    type: SEARCH_USERS,
    value: results,
  };
}

export function clearUserSearch() {
  return {
    type: CLEAR_USER_SEARCH,
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    value: user,
  };
}

export function removeUser(id) {
  return {
    type: DELETE_USER,
    value: id,
  };
}

export function savingUser() {
  return {
    type: SAVING_USER,
  };
}

export function saveUserSuccess() {
  return {
    type: SAVE_USER_SUCCESS,
  };
}

export function saveUserFail() {
  return {
    type: SAVE_USER_FAILURE,
  };
}

export function clearUserStatus() {
  return {
    type: CLEAR_USER_STATUS,
  };
}

export function cancelUserWizard() {
  return {
    type: CANCEL_USER_WIZARD,
  };
}

export function navigateUserWizard(page, user) {
  return {
    type: NAVIGATE_USER_WIZARD,
    value: page,
    user: user,
  };
}

export function setStoreProvision(id, visible) {
  return {
    type: SET_STORE_PROVISION_VISIBLE,
    value: id,
    visible,
  };
}
