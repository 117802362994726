import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {Link} from 'react-router';

import {injectIntl, FormattedMessage, intlShape} from 'react-intl';

import Icon from 'components/Icon/Icon';

import styles from './SideBar.module.scss';

const navItems = [
  {
    id: 'sidebar.dashboard',
    title: 'Dashboard',
    link: '/',
    name: 'dashboard',
    icon: 'dashboard',
    pages: ['/'],
    reqUser: true,
  },
  {
    id: 'sidebar.apps',
    link: '/apps',
    name: 'apps',
    icon: 'appManagement',
    pages: ['/apps', '/app'],
    reqUser: true,
    reqRole: ctx => ctx.canViewApps,
  },
  {
    id: 'sidebar.roles',
    link: '/roles',
    name: 'groups',
    icon: 'roleManagement',
    pages: ['/role', '/roles'],
    reqUser: true,
    reqRole: ctx => ctx.canViewApps,
  },
  {
    id: 'sidebar.dealers',
    link: '/dealers',
    name: 'dealers',
    icon: 'dealerManagement',
    pages: ['/dealer', '/dealers'],
    reqUser: true,
    reqRole: ctx => ctx.canViewDealers || ctx.canCreateDealerAdmin || ctx.canAccessAllDealers,
  },
  {
    id: 'sidebar.users',
    link: '/users',
    name: 'users',
    icon: 'userManagement',
    pages: ['/users', '/user'],
    reqUser: true,
    reqRole: ctx => ctx.canViewUsers,
  },
  {
    id: 'sidebar.reports',
    link: '/reports',
    name: 'reports',
    icon: 'repsAdmin',
    pages: ['/reports', '/reports/users'],
    reqUser: true,
    reqRole: ctx => ctx.canViewReports,
  },
  {
    id: 'sidebar.copy',
    title: 'Copy',
    link: '/copy',
    name: 'copy',
    icon: 'language',
    pages: ['/copy'],
    reqUser: true,
    reqRole: ctx => ctx.isSystemAdmin,
  },
];

const SideBar = createClass({
  displayName: 'SideBar',
  mixins: [PureRenderMixin],
  propTypes: {
    token: PropTypes.any,
    loc: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
  },
  contextTypes: {
    canViewApps: PropTypes.bool.isRequired,
    canViewUsers: PropTypes.bool.isRequired,
    canViewDealers: PropTypes.bool.isRequired,
    canCreateDealerAdmin: PropTypes.bool.isRequired,
    canAccessAllDealers: PropTypes.bool.isRequired,
    canViewReports: PropTypes.bool.isRequired,
    isSystemAdmin: PropTypes.bool.isRequired,
  },
  render() {
    let {formatMessage} = this.props.intl;

    let navItemElements = navItems.map((item, index) => {
      let split = this.props.loc.split('/');
      let itemIsActive = false;
      let joined = split.slice(0, Math.min(2, split.length)).join('/');

      if (item.pages.indexOf(joined) !== -1) {
        itemIsActive = true;
      }

      if ((!this.props.token || !this.props.token.valid) && item.reqUser) {
        return null;
      }
      if (this.props.token && item.reqPermissions) {
        return null;
      }
      if (item.reqRole && !item.reqRole(this.context)) {
        return null;
      }
      return (
        <li className={itemIsActive ? styles.active : ''} key={index}>
          <Link to={item.link}>
            <div className={styles.itemIconSection}>
              <Icon name={item.icon} title={formatMessage({id: item.id})} />
              <div><FormattedMessage id={item.id} /></div>
            </div>
          </Link>
        </li>
      );
    });

    return <div className={styles.sidebar}>
        <ul className={styles.navItemList}>
          {navItemElements}
        </ul>
        <div className={styles.remaining}>
          <div className={styles.remainingIcons}></div>
          <div className={styles.remainingExpanded}></div>
        </div>
      </div>;
  },
});

export default injectIntl(SideBar);
