import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {List, Set} from 'immutable';

import {GroupModel} from 'models';

import {Input} from 'components/forms';
import Select from 'components/forms/Select/Select';

import ValidateGroup from 'helpers/validation';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const DealerInformation = createClass({
  displayName: 'DealerInformation',
  mixins: [PureRenderMixin],
  validateGroup: null,
  propTypes: {
    addStore: PropTypes.func.isRequired,
    dealer: PropTypes.instanceOf(GroupModel).isRequired,
    submit: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    regions: PropTypes.instanceOf(List).isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
  },

  getInitialState() {
    return {
      selectedRegion: 'US',
    };
  },

  setSelectedRegion(region) {
    this.setState({selectedRegion: region});
  },

  getDealer() {
    let dealer = this.props.dealer;

    let keys = new Set(Object.keys(this.refs)).intersect(GroupModel.keys).toArray();
    for (let key of keys) {
      let ref = this.refs[key];
      if (ref) {
        dealer = dealer.set(key, ref.getValue());
      }
    }

    return dealer;
  },

  submit() {
    let dealer = this.getDealer();
    if (dealer.children.size === 0) {
      dealer = this.props.addStore(dealer);
    }
    return this.props.submit(dealer);
  },

  componentWillMount() {
    this.validateGroup = new ValidateGroup(this.submit);
  },

  render() {
    let {dealer} = this.props;
    let {formatMessage} = this.props.intl;

    let regionOptions = {};
    let localeOptions = {};

    let regions = this.props.regions;

    regionOptions = regions
    .reduce((acc, x) => {
      acc[x.get('region')] = x.get('label');
      return acc;
    }, {});

    localeOptions = this.props.possibleLocales
      .filter(x => x.get('regions').includes(this.state.selectedRegion))
      .reduce((acc, x) => {
        acc[x.get('locale')] = x.get('labels').get(x.get('locale'));
        return acc;
      }, {});

    return <div>
      <h2><FormattedMessage id='createDealer.title' /></h2>
        <div>
          <div className='row'>
            <div className='col-md-3'>
              <Input label={formatMessage({id: 'createDealer.fields.name'}) + ':'}
                value={dealer.displayName} ref='displayName' autoFocus required={true} group={this.validateGroup} />
            </div>
            <div className='col-md-3'>
              <Input label={formatMessage({id: 'createDealer.fields.soldTo'}) + ':'}
                limit={10} value={dealer.soldTo} ref='soldTo' required={true} group={this.validateGroup} />
            </div>
            <div className='col-md-3'>
              <Select label={formatMessage({id: 'createDealer.fields.region'}) + ':'} value={dealer.region} ref='region'
                options={regionOptions} onSelect={this.setSelectedRegion} />
            </div>
            <div className='col-md-3'>
              <Select label={formatMessage({id: 'createDealer.fields.locale'}) + ':'} value={dealer.locale} ref='locale'
                options={localeOptions} />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-3'>
              <Input label={formatMessage({id: 'createDealer.fields.dsmName'}) + ':'}
                value={dealer.dsmName} ref='dsmName' required={false} group={this.validateGroup} />
            </div>
            <div className='col-md-3'>
              <Input label={formatMessage({id: 'createDealer.fields.rmNumber'}) + ':'}
                type='number' value={dealer.rmNumber} ref='rmNumber' required={false} group={this.validateGroup} />
            </div>
            <div className='col-md-3'>
              <Select label={formatMessage({id: 'createDealer.fields.platinumStatus'}) + ':'}
                value={dealer.platinumStatus} ref='platinumStatus' options={{
                NP: formatMessage({id: 'createDealer.fields.nonPlatinum'}),
                PL: formatMessage({id: 'createDealer.fields.platinum'}),
              }} />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <button onClick={this.props.cancel} className='btn btn-default pull-left'><FormattedMessage id='createDealer.cancel' /></button>
              <button onClick={this.validateGroup ? this.validateGroup.validate : null} className='btn btn-primary pull-right'>
                <FormattedMessage id='createDealer.continue' /></button>
            </div>
          </div>
        </div>
    </div>;
  },
});

export default injectIntl(DealerInformation);
