import createRequest from 'helpers/request';
import {setPrivacyPolicy, setTermsOfUse} from 'redux/actions/info';

export function getPrivacyPolicy(locale) {
  return dispatch => {
    let req = createRequest(window.alexURL + '/Info/PrivacyPolicy?locale=' + locale, { cors: false, dataType: 'plain' });
    req.promise().then(x => {
      dispatch(setPrivacyPolicy(x.html));
    });

    return req;
  };
}

export function getTermsOfUse(locale) {
  return dispatch => {
    let req = createRequest(window.alexURL + '/Info/TermsOfUse?locale=' + locale, {cors: false, dataType: 'plain'});
    req.promise().then(x => {
      dispatch(setTermsOfUse(x.html));
    });

    return req;
  };
}
