import React, {createClass, PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import {List} from 'immutable';
import {PromiseStoreMixin} from 'helpers/request';
import {UserModel, GroupModel} from 'models';
import {groupTypes} from 'helpers/constants';
import classNames from 'classnames';

import Card from 'components/layout/Card/Card';
import Modal from 'components/layout/Modal/Modal';

import {Input as ReadOnlyInput} from 'components/forms/ReadOnly';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import ShowProvision from '../Provisioning/ShowProvision/ShowProvision';
import Icon from 'components/Icon/Icon';
import {Input} from 'components/forms';
import UnlockUser from '../Unlock/Unlock';
import styles from './View.module.scss';

import {injectIntl, intlShape, FormattedMessage} from 'react-intl';

const View = createClass({
  displayName: 'View',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    apps: PropTypes.instanceOf(List),
    deleteModalVisible: PropTypes.bool.isRequired,
    deleteUser: PropTypes.func.isRequired,
    getApps: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    lockUser: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    resendWelcomeEmail: PropTypes.func.isRequired,
    toggleUserDeleteModal: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(UserModel),
    loadData: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
    roles: PropTypes.instanceOf(List).isRequired,
    regions: PropTypes.instanceOf(List).isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
  },

  contextTypes: {
    canLockUsers: PropTypes.bool.isRequired,
    allPermissions: PropTypes.bool.isRequired,
    currentUser: PropTypes.instanceOf(UserModel).isRequired,
    canModifyUsers: PropTypes.bool.isRequired,
    canDeleteUsers: PropTypes.bool.isRequired,
    canAssignPrimary: PropTypes.bool.isRequired,
    canModifyPrimary: PropTypes.bool.isRequired,
  },

  componentWillMount() {
    if (this.props.loadData) {
      this.fetchData(this.props);
    }
  },

  componentWillReceiveProps(nextProps) {
    // Don't want an infinite loop here
    if (this.props.params.id !== nextProps.params.id) {
      this.fetchData(nextProps);
    }
  },

  componentDidMount() {
    this.matchHeights();
  },

  componentDidUpdate() {
    this.matchHeights();
  },

  matchHeights() {
    if (this.refs.accountPrefs && this.refs.mainPrefs) {
      let accountPrefs = findDOMNode(this.refs.accountPrefs);
      let mainPrefs = findDOMNode(this.refs.mainPrefs);

      let height = Math.max(accountPrefs.offsetHeight, mainPrefs.offsetHeight);
      accountPrefs.style.height = height + 'px';
      mainPrefs.style.height = height + 'px';
    }
  },

  fetchData(props) {
    this.promises.cleanUp();
    this.promises.add(props.getUser(props.params.id));
    this.promises.add(props.getApps());
  },

  getDefaultProps() {
    return {
      groups: new List(),
      user: new UserModel({}),
    };
  },

  lock() {
    this.promises.add(this.props.lockUser(this.props.user.id, true));
  },

  unlock() {
    this.promises.add(this.props.lockUser(this.props.user.id, false));
  },

  getAppsSection() {
    let user = this.props.user;
    let apps = user.apps.sort((a, b) => a.displayName < b.displayName ? -1 : 1);

    if (!this.context.allPermissions) {
      let provisionedApps = this.context.currentUser.apps.map(x => x.id);
      apps = apps.filter(x => provisionedApps.includes(x.id));
    }

    // Want to grab information from the actual app not the one on the user model
    apps = apps.map(x => this.props.apps.find(y => y.id === x.id)).filter(x => !!x);

    if (apps.size === 0) {
      return null;
    }

    return <div>
      <h2>My Apps</h2>
      <Card noPadding={true}>
        {apps.map(x => <ShowProvision app={x} user={user} key={x._id} roles={this.props.roles} locale={this.props.locale} />)}
      </Card>
    </div>;
  },

  editApp(e) {
    e.preventDefault();
    browserHistory.replace('/user/' + this.props.user.id + '/edit');
  },


  hideDeleteModal() {
    this.props.toggleUserDeleteModal(false);
  },

  showDeleteModal() {
    this.props.toggleUserDeleteModal(true);
  },

  deleteConfirm() {
    let typed = this.refs.deleteConfirmation.getValue();
    if (typed === 'DELETE') {
      this.hideDeleteModal();
      this.promises.add(this.props.deleteUser(this.props.user.id))
        .promise()
        .then(() => {
          browserHistory.push('/users');
        });
    }
  },

  resendWelcomeEmail() {
    this.props.resendWelcomeEmail(this.props.user.id);
  },

  render() {
    let user = this.props.user;
    let {formatMessage} = this.props.intl;
    let appsSection = this.getAppsSection();

    let region = this.props.regions.find(x => x.get('region') === user.region);
    if (!region) {
      region = this.props.regions.first();
    }

    let locale = this.props.possibleLocales.find(x => x.get('locale') === user.locale);
    if (!locale) {
      locale = this.props.possibleLocales.first();
    }

    let dealerColumns = [];
    if (user.dealer) {
      dealerColumns.push(<div className='col-sm-6' key='dealername'><ReadOnlyInput label={formatMessage({id: 'user.fields.dealerName'}) + ':'}
        value={user.dealer.displayName} /></div>);
      dealerColumns.push(<div className='col-sm-6' key='soldto'>
        <ReadOnlyInput label={formatMessage({id: 'user.fields.soldTo'}) + ':'} value={user.dealer.soldTo} /></div>);
    }

    // Hide edit buttons if user has more permissions for every app, or if branches not same for any app
    let saveable = this.context.allPermissions;
    if (!this.context.allPermissions) {
      let maximumRole = GroupModel.flatten(this.props.user.apps.filter(x => x.name === 'user-portal').reduce((acc, x) => acc.concat(x.assigned), new List()))
        .filter(x => x.type === groupTypes.role)
        .sort((x, y) => x.priority > y.priority ? -1 : 1)
        .first();
      let currentRole = GroupModel.flatten(this.context.currentUser.apps.filter(x => x.name === 'user-portal')
          .reduce((acc, x) => acc.concat(x.assigned), new List()))
        .filter(x => x.type === groupTypes.role)
        .sort((x, y) => x.priority > y.priority ? -1 : 1)
        .first();
      if (currentRole && (!maximumRole || currentRole.id === maximumRole.id || currentRole.priority > maximumRole.priority)) {
        saveable = true;
      }
      if (!this.context.canModifyPrimary && user.primary) {
        saveable = false;
      }
    }

    let modalValues = {
      deleteTerm: 'DELETE',
    };

    let isSelf = this.context.currentUser.id === user.id;

    return <div>
      <PageHeader title='Users: Profile' right={<div>
        {/*<button className='btn'><Icon name='info' /></button>*/}
        {/* On the user-portal-view-users permission which you need to be on this page anyway */}
        <button className='btn' onClick={this.resendWelcomeEmail}><Icon name='resendEmail' /></button>
        {!isSelf && this.context.canLockUsers && saveable ?
          <button className='btn' onClick={this.lock} disabled={user.isLocked}><Icon name='unlock' /></button> : null}
        {!isSelf && this.context.canDeleteUsers && saveable ? <button className='btn' onClick={this.showDeleteModal}><Icon name='delete' /></button> : null}
        {this.context.canModifyUsers && saveable ? <button className='btn' onClick={this.editApp}><Icon name='edit' /></button> : null}
      </div>}/>

      <Modal
        visible={this.props.deleteModalVisible}
        close={this.hideDeleteModal} id='user-delete-modal' size='md'
        header={<h3><FormattedMessage id='modals.deleteUser.header' /></h3>}
        footer={<div>
          <Input colSize='col-sm-8' ref='deleteConfirmation' value='' autoFocus
            placeholder={formatMessage({id: 'modals.deleteUser.placeholder'}, modalValues)} />
          <button className='btn btn-primary' onClick={this.deleteConfirm}>
            <FormattedMessage id='modals.deleteUser.submit'/>
          </button></div>}>
          <div>
            <p><FormattedMessage id='modals.deleteUser.warning' /></p>
            <p><FormattedMessage id='modals.deleteUser.instructions' values={modalValues} /></p>
          </div>
      </Modal>

      <UnlockUser user={user} unlockUser={this.unlock} unlockable={this.context.canLockUsers && saveable} />

      <div className='row'>
        <div className='col-md-8'>
          <Card minHeight={150} ref='mainPrefs'>
            <div className={classNames('row', styles.headerGrid)}>
              <div className='col-sm-6'>
                <ReadOnlyInput value={user.firstName + ' ' + user.lastName} label={formatMessage({id: 'user.fields.name'}) + ':'} />
              </div>
              <div className='col-sm-6'>
                <ReadOnlyInput value={user.email} label={formatMessage({id: 'user.fields.email'}) + ':'} />
              </div>
              <div className='col-sm-6'>
                <ReadOnlyInput value={user.mobilePhone} label={formatMessage({id: 'user.fields.mobilePhone'}) + ':'} />
              </div>
              <div className='col-sm-6'>
                <ReadOnlyInput value={user.alternatePhone} label={formatMessage({id: 'user.fields.alternatePhone'}) + ':'} />
              </div>
              {dealerColumns}
            </div>
          </Card>
        </div>

        <div className='col-md-4'>
          <Card header={<h2>Account Preferences</h2>} ref='accountPrefs' headerBackground='none'>
            <div className='row'>
              <div className='col-sm-12'>
                {this.context.canAssignPrimary && user.idmRole === 'admin' ? <ReadOnlyInput
                  value={user.primary ? <FormattedMessage id='user.fields.primaryAccount' /> : <FormattedMessage id='user.fields.regularAccount' />} /> : null}
              </div>
              <div className='col-sm-12'>
                <ReadOnlyInput ref='region' label={formatMessage({id: 'user.fields.region'}) + ':'} value={region.get('label')} />
              </div>
              <div className='col-sm-12'>
                <ReadOnlyInput ref='locale' label={formatMessage({id: 'user.fields.language'}) + ':'} value={locale.get('labels').get(locale.get('locale'))} />
              </div>
            </div>
          </Card>
        </div>
      </div>

      {appsSection}
    </div>;
  },
});

export default injectIntl(View);
