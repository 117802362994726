import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import styles from './Select.module.scss';


const Select = createClass({
  displayName: 'Select',
  mixins: [PureRenderMixin],
  propTypes: {
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    label: PropTypes.string,
    onSelect: PropTypes.func,
    options: PropTypes.object.isRequired,
    value: PropTypes.any.isRequired,
  },

  getInitialState() {
    return {
      value: '',
    };
  },

  getDefaultProps() {
    return {
      error: false,
      errorMessage: '',
      disabled: false,
    };
  },

  componentWillMount() {
    this.setState({
      value: this.props.value,
    });
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  },

  getValue() {
    return this.state.value;
  },

  dataChanged(e) {
    if (this.props.onSelect) {
      this.props.onSelect(e.target.value);
    }
    this.setState({
      value: e.target.value,
    });
  },

  render() {
    let error = this.props.error ? ' ' + styles.error : '';

    return <div className='form-group'>
      <label>{this.props.label}</label>
      <select className={'form-control' + error}  value={this.state.value} ref='select' disabled={this.props.disabled} onChange={this.dataChanged}>
        {Object.keys(this.props.options).sort((a, b) => {
          if (!a) {
            return -1;
          }
          if (!b) {
            return 1;
          }
          return this.props.options[a] < this.props.options[b] ? -1 : 1;
        })
          .map(key => <option key={key} value={key}>{this.props.options[key]}</option>)}
      </select>
        {this.props.error && this.props.errorMessage  ?  <span>{this.props.errorMessage} </span> : null}
    </div>;
  },
});

export default Select;
