import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import styles from './Logins.module.scss';
import InnerForm from 'containers/layout/InnerForm';
import Successful from './Successful/Successful';
import Failed from './Failed/Failed';
import {csvToUrl, arrayToCsv, textToCsv} from 'helpers/csv';

import {ReportSettings, LoginEventsReport} from 'models';

import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

const Logins = createClass({
  displayName: 'Logins',
  mixins: [PureRenderMixin],
  propTypes: {
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    report: PropTypes.instanceOf(LoginEventsReport),
    updateSettings: PropTypes.func.isRequired,
    getLoginEventsReport: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  },

  contextTypes: {
    canAccessAllDealers: PropTypes.bool.isRequired,
  },

  export() {
    let {formatMessage} = this.props.intl;
    let {report, settings} = this.props;
    const compare = report && report.compare && settings && settings.compare && settings.compare.from && settings.compare.to;


    let output = [[formatMessage({id: 'reports.logins.app'}), formatMessage({id: 'reports.successLogins.title'})]];
    if (compare) {
      output[0].push(formatMessage({id: 'reports.filters.compare'}));
    }
    output[0].push(formatMessage({id: 'reports.failedLogins.title'}));
    if (compare) {
      output[0].push(formatMessage({id: 'reports.filters.compare'}));
    }

    let rows = report.successesPerApp.keySeq().map(name => {
      let out = [name, report.successesPerApp.get(name)];
      if (compare) {
        out.push(report.compare.successesPerApp.get(name) || 0);
      }
      out.push(report.failuresPerApp.get(name));
      if (compare) {
        out.push(report.compare.failuresPerApp.get(name) || 0);
      }
      return out;
    }).toArray();

    output = output.concat(rows);

    let csv = csvToUrl(textToCsv(arrayToCsv(output), 'export.csv'));

    window.open(csv);
  },

  render() {
    let {formatMessage} = this.props.intl;
    let {settings, report} = this.props;
    return <div>
      <PageHeader title={formatMessage({id: 'reports.logins.pageHeader'})} visible={true} right={<div className={styles.exportButton}>
        <button className={'btn btn-primary'} disabled={!report} onClick={this.export}><FormattedMessage id='reports.export' /></button>
        </div>} backButton={true} />
      <InnerForm settings={settings} update={this.props.updateSettings} run={this.props.getLoginEventsReport} />
      {report ? <div>
        <Successful report={report} settings={settings} />
        {this.context.canAccessAllDealers ? <Failed report={report} settings={settings} /> : null}
      </div> : null}
    </div>;
  },
});

export default injectIntl(Logins);
