import React from 'react';
import { FormattedMessage } from 'react-intl';

import createRequest, { methodTypes } from 'helpers/request';
import Immutable from 'immutable';
import { UserModel, ToastModel } from 'models';
import {
  changingPasswordToast,
  changedPasswordToast,
  errorPasswordToast,
  savingUserToast,
  savedUserToast,
  errorUserToast,
  saveUserPermissionsError,
  emailPermissionsSending,
  emailPermissionsSent,
  emailPermissionsError
} from './toasts/user';
import { searchUsers, setUser, removeUser, savingUser, saveUserSuccess, navigateUserWizard } from 'redux/actions/users';
import { addToast } from 'redux/actions/toasts';

export function performSearch(query, page = 1, pageSize = 15, sort = '', order = 'asc') {
  return dispatch => {
    let request = createRequest(
      `/api/User/Search?query=${encodeURIComponent(query)}&` + `page=${page}&pageSize=${pageSize}&sort=${sort}&order=${order}`,
      { authorize: true }
    );
    request.promise().then(searchRes => {
      let users = Immutable.fromJS(searchRes.data);
      dispatch(searchUsers(users));
    });

    return request;
  };
}

export function getUser(id) {
  return dispatch => {
    let req = createRequest(`/api/User?id=${id}`, { authorize: true });
    req.promise().then(userRes => {
      let user = UserModel.fromApiFormat(userRes.data);
      dispatch(setUser(user));
    });
    return req;
  };
}

export function saveUser(user) {
  return dispatch => {
    dispatch(savingUserToast());
    dispatch(savingUser());
    let req = createRequest('/api/User', {
      authorize: true,
      method: methodTypes.post,
      body: user.toApiFormat()
    });

    req
      .promise()
      .then(userRes => {
        if (userRes.success) {
          dispatch(savedUserToast());
          let newUser = UserModel.fromApiFormat(userRes.data);
          dispatch(setUser(newUser));
          if (userRes.code === 403) {
            dispatch(saveUserPermissionsError());
          } else {
            dispatch(saveUserSuccess());
          }
          return true;
        }
        return Promise.reject(userRes);
      })
      .catch(e => {
        console.error(JSON.stringify(e, null, 2));
        dispatch(errorUserToast());
      });

    return req;
  };
}

export function deleteUser(id) {
  return dispatch => {
    let req = createRequest(`/api/User/Delete?id=${id}`, {
      authorize: true,
      method: methodTypes.post
    });

    req.promise().then(() => {
      dispatch(removeUser(id));
    });

    return req;
  };
}

export function lockUser(id, lockState = true) {
  return dispatch => {
    let req = createRequest(`/api/User/Lock?id=${id}&lockState=${lockState}`, {
      authorize: true,
      method: methodTypes.post
    });
    req.promise().then(userRes => {
      dispatch(setUser(UserModel.fromApiFormat(userRes.data)));
    });

    return req;
  };
}

export function addUserToGroup(userID, groupID) {
  return dispatch => {
    let req = createRequest(`/api/User/AddToGroup?userID=${userID}&groupID=${groupID}`, {
      authorize: true,
      method: methodTypes.post
    });

    req.promise().then(userRes => {
      dispatch(setUser(UserModel.fromApiFormat(userRes.data)));
    });

    return req;
  };
}

export function removeUserFromGroup(userID, groupID) {
  return dispatch => {
    let req = createRequest(`/api/User/RemoveFromGroup?userID=${userID}&groupID=${groupID}`, {
      authorize: true,
      method: methodTypes.post
    });

    req.promise().then(userRes => {
      dispatch(setUser(UserModel.fromApiFormat(userRes.data)));
    });

    return req;
  };
}

export function checkEmailUsed(user) {
  return dispatch => {
    let req = createRequest(`/api/User/EmailUsed?email=${encodeURIComponent(user.get('email'))}`, { authorize: true });

    req.promise().then(usedRes => {
      if (usedRes.data && Object.keys(usedRes.data).length > 0) {
        dispatch(navigateUserWizard(-1, UserModel.fromApiFormat(usedRes.data)));
      } else {
        dispatch(navigateUserWizard(1, user));
      }
    });

    return req;
  };
}

export function checkDealerEmailUsed(user) {
  return () => {
    let req = createRequest(`/api/User/EmailUsed?email=${encodeURIComponent(user.get('email'))}`, { authorize: true });

    req.promise();

    return req;
  };
}

export function resetPassword(email) {
  let req = createRequest(`/api/User/Reset?email=${email}`, { method: methodTypes.post });
  return req;
}

export function changePasswordReset(hash, password) {
  let req = createRequest('/api/User/ChangePasswordReset', {
    body: {
      hash,
      password
    },
    method: methodTypes.post
  });
  return req;
}

export function changePassword(currentPassword, newPassword) {
  return dispatch => {
    dispatch(changingPasswordToast());
    let req = createRequest('/api/User/ChangePassword', {
      authorize: true,
      body: {
        currentPassword,
        newPassword
      },
      method: methodTypes.post
    });

    req
      .promise()
      .then(passwordRes => {
        if (passwordRes.success) {
          dispatch(changedPasswordToast());
          return true;
        }
        return Promise.reject(passwordRes);
      })
      .catch(e => {
        console.error(JSON.stringify(e, null, 2));
        dispatch(errorPasswordToast());
      });
    return req;
  };
}

export function resendWelcomeEmail(id) {
  return dispatch => {
    dispatch(
      addToast(
        new ToastModel({
          key: 'resending-welcome-email',
          message: <FormattedMessage id="toasts.welcomeEmail.sending" />,
          ttl: null,
          closeable: true
        })
      )
    );

    let req = createRequest(`/api/User/ResendWelcomeEmail?id=${id}`, {
      authorize: true,
      method: methodTypes.post
    });

    req
      .promise()
      .then(res => {
        if (res && res.success) {
          dispatch(
            addToast(
              new ToastModel({
                key: 'sent-welcome-email',
                message: <FormattedMessage id="toasts.welcomeEmail.sent" />,
                ttl: 4000,
                closeable: true,
                clears: 'resending-welcome-email'
              })
            )
          );

          return true;
        }
        return Promise.reject(res);
      })
      .catch(e => {
        console.error(JSON.stringify(e, null, 2));
        dispatch(
          addToast(
            new ToastModel({
              key: 'failed-welcome-email',
              message: <FormattedMessage id="toasts.welcomeEmail.failed" />,
              ttl: 4000,
              closeable: true,
              clears: 'resending-welcome-email'
            })
          )
        );
      });

    return req;
  };
}

export function emailUserPermissions(dealerID, userID) {
  return dispatch => {
    dispatch(emailPermissionsSending());
    let req = createRequest('/api/User/EmailPermissionsReport', {
      authorize: true,
      body: {
        dealerID,
        userID
      },
      method: methodTypes.post
    });

    req
      .promise()
      .then(passwordRes => {
        if (passwordRes.success) {
          dispatch(emailPermissionsSent());
          return true;
        }
        return Promise.reject(passwordRes);
      })
      .catch(e => {
        console.error(JSON.stringify(e, null, 2));
        dispatch(emailPermissionsError());
      });
    return req;
  };
}
