import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {List} from 'immutable';

const SortPermission = createClass({
  displayName: 'SortPermission',
  mixins: [PureRenderMixin],
  propTypes: {
    input: PropTypes.instanceOf(List).isRequired,
  },

  getInitialState() {
    return {
      cards: this.props.input,
      indent: 40,
    };
  },

  render() {
    let cards = this.state.cards.map(x => <div key={x.id} style={{marginLeft: this.state.indent * x.horizontalIndent}}>
      {x.title}
    </div>
    );
    return <div style={{overflow: 'hidden'}}>
      {cards}
    </div>;
  },
});

export default SortPermission;
