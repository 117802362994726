import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {Set} from 'immutable';
import {browserHistory} from 'react-router';

import debounce from 'lodash.debounce';

import {GroupModel, ToastModel} from 'models';
import {groupTypes} from 'helpers/constants';

import Card from 'components/layout/Card/Card';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import {Input} from 'components/forms';

import ValidationGroup from 'helpers/validation';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const CreateStore = createClass({
  displayName: 'CreateStore',
  mixins: [PureRenderMixin],
  propTypes: {
    addToast: PropTypes.func.isRequired,
    checkShipTo: PropTypes.func.isRequired,
    dealer: PropTypes.instanceOf(GroupModel),
    getDealer: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    save: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  },

  getDefaultProps() {
    return {
      dealer: new GroupModel({type: groupTypes.dealer}),
    };
  },

  fetchData(props) {
    props.getDealer(props.params.id);
  },

  componentWillMount() {
    this.save = debounce(this._save, 500, {
      trailing: false,
      leading: true,
    });
    this.fetchData(this.props);
    this.group = new ValidationGroup(this.save);
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.id !== this.props.params.id) {
      this.fetchData(nextProps);
    }
  },

  checkShipTo(val) {
    return this.props.checkShipTo(val)
      .promise()
      .then(x => {
        return {
          valid: !x.data,
          message: 'Ship To # already exists.',
        };
      });
  },

  cancel() {
    browserHistory.push(this.props.path.replace('/new-store', ''));
  },

  _save() {
    let store = new GroupModel({type: groupTypes.branch});
    let keys = new Set(Object.keys(this.refs)).intersect(GroupModel.keys).toArray();
    for (let key of keys) {
      let ref = this.refs[key];
      if (ref && ref.getValue) {
        store = store.set(key, ref.getValue());
      }
    }

    let dealer = this.props.dealer;
    dealer = dealer.update('children', children => children.push(store));

    this.props.addToast(new ToastModel({
      key: 'saving-store',
      message: <FormattedMessage id='toasts.store.saving' />,
      ttl: null,
      closeable: true,
    }));

    this.props.save(dealer)
    .promise()
    .then(() => {
      this.props.addToast(new ToastModel({
        key: 'saved-store',
        message: <FormattedMessage id='toasts.store.saved' />,
        ttl: 4000,
        closeable: true,
        clears: 'saving-store',
      }));

      this.cancel();
    })
    .catch(err => {
      console.error(err);
      this.props.addToast(new ToastModel({
        key: 'saved-store',
        messaged: <FormattedMessage id='toasts.store.error' />,
        clears: 'saving-store',
        ttl: null,
        closeable: true,
      }));
    });
  },

  render() {
    let dealer = this.props.dealer;
    let {formatMessage} = this.props.intl;

    return <div>
      <PageHeader title={formatMessage({id: 'createDealerStore.pageHeader'}) + ': ' + dealer.displayName} />
      <Card>
        <div>
          <h2><FormattedMessage id='createDealerStore.title' /></h2>
          <div className='row'>
            <div className='col-md-4'>
              <Input required={true} label={formatMessage({id: 'createDealerStore.fields.name'}) + ':'} ref='displayName'
                value='' autoFocus group={this.group} />
            </div>
            <div className='col-md-4'>
              <Input required={true} label={formatMessage({id: 'createDealerStore.fields.shipTo'}) + ':'} ref='shipTo' limit={10} value=''
                group={this.group} validate={this.checkShipTo}
                validationMessage={formatMessage({id: 'fields.shipTo.duplicate'})} />
            </div>
          </div>
          <div className='row' style={{marginBottom: 20}}>
            <div className='col-md-12'>
              <button className='pull-left btn btn-default' onClick={this.cancel}><FormattedMessage id='createDealer.cancel' /></button>
              <button className='pull-right btn btn-primary' onClick={this.group.validate}><FormattedMessage id='createDealer.confirm' /></button>
            </div>
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(CreateStore);
