import {connect} from 'react-redux';
import ChangePassword from 'components/pages/Profile/Password/Password';
import {changePassword} from 'api/user';

function mapStateToProps({data}) {
  return {
    user: data.get('currentUser'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePassword: (currentPassword, password) => dispatch(changePassword(currentPassword, password)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
