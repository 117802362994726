import React, {createClass, PropTypes} from 'react';
import Card from 'components/layout/Card/Card';
import {getTemporaryToken} from 'api/report';

import {injectIntl, FormattedMessage} from 'react-intl';

const UsersExport = createClass({
  displayName: 'UsersExport',

  exportAll() {
    getTemporaryToken()
      .then(token => {
        return window.open(`/api/Report/AllUsers?token=${token}`);
      })
      .catch(err => {
        console.error(err);
      })
  },

  render() {
    return <div>
      <Card
        noPadding={true}
        minHeight={0}
        header={<h3><span style={{display: 'inline-block', marginTop: 6}}
      ><FormattedMessage id='reports.dashboard.usersExport' /></span>
          <button onClick={this.exportAll} className={'btn btn-transparent pull-right'}><FormattedMessage id='reports.dashboard.exportAllUsers' /></button></h3>}
        headerFontSize={16}
        lightHeader={true}
        style={{marginBottom: 20}}>
      </Card>
    </div>;
  },
});

export default injectIntl(UsersExport);
