import React, {Component} from 'react';

export default function readOnly(Field) {
  class ReadOnly extends Component {
    render() {
      return <Field {...this.props} readOnly={true} />;
    }
  }

  ReadOnly.displayName = `ReadOnly(${Field.displayName})`;

  return ReadOnly;
}
