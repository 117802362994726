import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

// Setup icon font here:
import './icons/biolab-icons.font';
import styles from './Icon.module.scss';

const Icon = createClass({
  displayName: 'Icon',
  mixins: [PureRenderMixin],
  propTypes: {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    verticalAlign: PropTypes.string,
    onClick: PropTypes.func,
  },

  getDefaultProps() {
    return {
      className: '',
      style: {},
    };
  },

  render() {
    const style = Object.assign({}, this.props.style);
    if (this.props.verticalAlign) {
      style.verticalAlign = this.props.verticalAlign;
    }

    const iconName = (this.props.name || '').includes('icon-') ? '' : `icon-${this.props.name}` ;
    return <i className={styles.icon + ' biolabicons' + ' ' + iconName + ' ' + (this.props.className || '')} style={style}  onClick={this.props.onClick}/>;

  },
});


export default Icon;
