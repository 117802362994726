import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import ReactDatePicker from 'react-datepicker';
import styles from './DatePicker.module.scss';
import Icon from 'components/Icon/Icon';

import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = createClass({
  displayName: 'DatePicker',
  mixins: [PureRenderMixin],
  propTypes: {
    label: PropTypes.string.isRequired,
    colSize: PropTypes.string,
    value: PropTypes.object,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  },

  handleChange: function(date) {
    if (this.props.onChange) {
      this.props.onChange(date);
    }
  },

  clickIcon() {
    if (this.refs.datepicker && !this.refs.datepicker.open) {
      this.refs.datepicker.setOpen(true);
    }
  },

  render() {
    return <div className={styles.datePicker + ' ' + this.props.colSize}>
      <div className='form-group'>
        <label>{this.props.label}</label>
        <div className={styles.pickerHolder}>
          <ReactDatePicker className='form-control'
            ref='datepicker'
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            selected={this.props.value}
            onChange={this.handleChange} />
          <Icon name='repsCalendar' onClick={this.clickcomponents / Icon} style={{cursor: 'pointer'}} />
        </div>
      </div>
    </div>;
  },
});

export default DatePicker;
