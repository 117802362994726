import {connect} from 'react-redux';
import {getApps, deleteApp} from 'api/app';
import {getGroups} from 'api/group';
import AppListing from 'components/pages/Apps/Apps';
import {toggleModal} from 'redux/actions/modals';

import readOnly from 'helpers/readOnly';


import {List} from 'immutable';

function mapStateToProps({data, ui, routing, locale}) {
  return {
    apps: new List(data.get('apps').values()),
    deleteModalVisible: ui.getIn(['modals', 'deleteApp']),
    groups: new List(data.get('groups').values()),
    path: routing.locationBeforeTransitions.pathname,
    loadData: data.get('currentPage') !== data.get('previousPage').replace('/edit', ''),
    rolesCopy: locale.get('roles'),
    locale: locale.get('locale'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteApp: (id) => dispatch(deleteApp(id)),
    toggleAppDeleteModal: (...args) => dispatch(toggleModal('deleteApp', ...args)),
    getApps: () => dispatch(getApps()),
    getGroups: (...args) => dispatch(getGroups(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(readOnly(AppListing));
