import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import WizardProgress from 'components/layout/WizardProgress/WizardProgress';

import {intlShape, injectIntl} from 'react-intl';

const DealerWizard = createClass({
  displayName: 'DealerWizard',
  mixins: [PureRenderMixin],
  propTypes: {
    active: PropTypes.number.isRequired,
    intl: intlShape.isRequired,
  },

  render() {
    let {formatMessage} = this.props.intl;
    return <WizardProgress steps={[
      formatMessage({id: 'dealerWizard.createDealer'}),
      formatMessage({id: 'dealerWizard.createStore'}),
      formatMessage({id: 'dealerWizard.createAdmin'}),
    ]} active={this.props.active} />;
  },
});

export default injectIntl(DealerWizard);
