import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import Card from 'components/layout/Card/Card';
import {Map} from 'immutable';
import TableResults from 'components/layout/ReportsGenerics/TableResults/TableResults';

import {FormattedMessage} from 'react-intl';

const EmailsSent = createClass({
  displayName: 'EmailsSent',
  mixins: [PureRenderMixin],
  propTypes: {
    emailsSent: PropTypes.instanceOf(Map).isRequired,
  },

  gotoPage() {
    browserHistory.push('/reports/emails');
  },

  render() {
    return <div>
      <Card header={<h3><span style={{display: 'inline-block', marginTop: 6}}><FormattedMessage id='reports.dashboard.emailsSent' /></span>
          <button onClick={this.gotoPage} className={'btn btn-transparent pull-right'}><FormattedMessage id='reports.dashboard.fullReportLink' /></button></h3>}
        lightHeader={true}
        headerFontSize={16}
        minHeight={340}
        style={{marginBottom: 20}} >
        <div className='row'>
          <div className='col-sm-12'>
            <TableResults header={<thead><tr>
                <th><FormattedMessage id='reports.dashboard.emailType' /></th>
                <th className='text-right'><FormattedMessage id='reports.dashboard.count' /></th>
                </tr></thead>}>
                {this.props.emailsSent.map((result, index) => {
                  return <tr key={index}>
                    <td>{index}</td>
                    <td className='text-right'>{result}</td>
                  </tr>;
                }).toArray()}
            </TableResults>
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default EmailsSent;
