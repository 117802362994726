import {SET_REPORT_DATA, SET_REPORT_SETTINGS} from './index';

export function setReportData(report, data) {
  return {
    type: SET_REPORT_DATA,
    value: data,
    report,
  };
}

export function setReportSettings(report, settings) {
  return {
    type: SET_REPORT_SETTINGS,
    value: settings,
    report,
  };
}
