import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';
import {PromiseStoreMixin} from 'helpers/request';
import {List} from 'immutable';

import debounce from 'lodash.debounce';

import Card from 'components/layout/Card/Card';
import PageHeader from 'components/layout/PageHeader/PageHeader';

import {GroupModel, ToastModel, UserModel} from 'models';

import DealerWizard from './DealerWizard/DealerWizard';

import CreateAdmin from './CreateAdmin/CreateAdmin';
import CreateStore from './CreateStore/CreateStore';
import DealerInformation from './DealerInformation/DealerInformation';
import EmailExists from './EmailExists/EmailExists';
import ShipToExists from './ShipToExists/ShipToExists';
import SoldToExists from './SoldToExists/SoldToExists';

import {groupTypes} from 'helpers/constants';

import styles from './Create.module.scss';

import {injectIntl, intlShape, FormattedMessage} from 'react-intl';

const Create = createClass({
  displayName: 'Create',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    addToast: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    checkEmail: PropTypes.func.isRequired,
    checkSoldTo: PropTypes.func.isRequired,
    checkShipTo: PropTypes.func.isRequired,
    clearWizard: PropTypes.func.isRequired,
    dealer: PropTypes.instanceOf(GroupModel).isRequired,
    duplicate: PropTypes.instanceOf(GroupModel),
    duplicateStore: PropTypes.instanceOf(GroupModel),
    duplicateUser: PropTypes.instanceOf(UserModel),
    navigateDealerWizard: PropTypes.func.isRequired,
    wizardPage: PropTypes.number.isRequired,
    user: PropTypes.instanceOf(UserModel).isRequired,
    intl: intlShape.isRequired,
    regions: PropTypes.instanceOf(List).isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
  },

  componentWillMount() {
    this.save = debounce((...args) => this._save(...args), 500, {
      trailing: false,
      leading: true,
    });
  },

  componentWillUnmount() {
    this.props.clearWizard();
  },

  continue(dealer, user) {
    let page = this.props.wizardPage + 1;

    if (page === 1) {
      return this.props.checkSoldTo(dealer.soldTo)
        .promise()
        .then(x => {
          if (x && x.data) {
            this.props.navigateDealerWizard(-1, dealer, user, GroupModel.fromApiFormat(x.data));
          } else {
            this.props.navigateDealerWizard(page, dealer, user);
          }
        });
    }
    if (page === 2) {
      return this.props.checkShipTo(dealer.branches.map(x => x.shipTo).toArray())
        .promise()
        .then(x => {
          if (x && x.data) {
            this.props.navigateDealerWizard(-2, dealer, user, null, GroupModel.fromApiFormat(x.data));
          } else {
            this.props.navigateDealerWizard(page, dealer, user);
          }
        });
    }

    return this.props.navigateDealerWizard(page, dealer, user);
  },

  cancel() {
    browserHistory.push('/dealers');
  },

  updateDealer(dealer) {
    return this.props.navigateDealerWizard(this.props.wizardPage, dealer);
  },

  back(dealer, user) {
    let page = this.props.wizardPage;
    if (page > 0) {
      this.props.navigateDealerWizard(page - 1, dealer, user);
    } else if (page < 0) {
      this.props.navigateDealerWizard(Math.abs(page) - 1, dealer, user);
    }
  },

  tryAgain() {
    this.props.navigateDealerWizard(0, new GroupModel({type: groupTypes.dealer}), new UserModel(), null);
  },

  useExistingUser() {
    this.save(this.props.dealer, this.props.duplicateUser, true);
  },

  _save(dealer, user, skip = false) {
    return this.props.checkEmail(user)
      .promise()
      .then(u => {
        if (u && u.data && !skip) {
          this.props.navigateDealerWizard(-3, dealer, user, null, null, UserModel.fromApiFormat(u.data));
        } else {
          this.props.addToast(new ToastModel({
            key: 'saving-new-dealer',
            ttl: null,
            message: <FormattedMessage id='toasts.dealer.saving' />,
          }));
          this.promises.add(this.props.save(dealer, user))
            .promise()
            .then(data => {
              if (data && data.data) {
                dealer = GroupModel.fromApiFormat(data.data);
                if (dealer && dealer.id) {
                  this.props.addToast(new ToastModel({
                    key: 'saved-new-dealer',
                    ttl: 4000,
                    message: <FormattedMessage id='toasts.dealer.saved' />,
                    clears: 'saving-new-dealer',
                  }));
                  browserHistory.push('/dealer/' + dealer.id);
                } else {
                  throw new Error(dealer);
                }
              } else {
                throw new Error(dealer);
              }
            })
            .catch(err => {
              console.error(err);
              this.props.addToast(new ToastModel({
                key: 'saved-new-dealer',
                ttl: null,
                message: <FormattedMessage id='toasts.dealer.error' />,
                clears: 'saving-new-dealer',
              }));
            });
        }
      });
  },

  addStore(dealer, save = false) {
    dealer = dealer.update('children', children => children.push(new GroupModel({
      type: groupTypes.branch,
    })));

    if (save) {
      this.updateDealer(dealer);
    }

    return dealer;
  },

  deleteStore(dealer, storeID, save = true) {
    dealer = dealer.update('children', children => children.filter(x => x._id !== storeID));

    if (save) {
      this.updateDealer(dealer);
    }

    return dealer;
  },

  getDealerPage() {
    let {dealer, regions, possibleLocales} = this.props;
    return <DealerInformation dealer={dealer} cancel={this.cancel} submit={this.continue} addStore={this.addStore}
      regions={regions} possibleLocales={possibleLocales} /> ;
  },

  getStorePage() {
    let dealer = this.props.dealer;
    return <CreateStore dealer={dealer} cancel={this.cancel} submit={this.continue} back={this.back} addStore={this.addStore} deleteStore={this.deleteStore} />;
  },

  getAdminPage() {
    let {dealer, regions, possibleLocales} = this.props;
    let user = this.props.user;
    return <CreateAdmin dealer={dealer} cancel={this.cancel} submit={this.save} back={this.back} user={user}
      regions={regions} possibleLocales={possibleLocales} />;
  },

  getSoldToExists() {
    let duplicate = this.props.duplicate;
    return <SoldToExists duplicate={duplicate} cancel={this.cancel} tryAgain={this.tryAgain} back={this.back} dealer={this.props.dealer} />;
  },

  getShipToExists() {
    let duplicateStore = this.props.duplicateStore;
    return <ShipToExists duplicate={duplicateStore} cancel={this.cancel} tryAgain={this.tryAgain} back={this.back} dealer={this.props.dealer} />;
  },

  getEmailExists() {
    let duplicateUser = this.props.duplicateUser;
    return <EmailExists duplicate={duplicateUser} cancel={this.cancel} tryAgain={this.tryAgain} useExisting={this.useExistingUser}
      back={this.back} dealer={this.props.dealer} user={this.props.user} />;
  },

  getPage(page) {
    switch (page) {
      case 0: {
        return this.getDealerPage();
      }
      case 1: {
        return this.getStorePage();
      }
      case 2: {
        return this.getAdminPage();
      }
      case -1: {
        return this.getSoldToExists();
      }
      case -2: {
        return this.getShipToExists();
      }
      case -3: {
        return this.getEmailExists();
      }
      default: {
        return this.getDealerPage();
      }
    }
  },

  render() {
    let {wizardPage} = this.props;

    let {formatMessage} = this.props.intl;

    let page = this.getPage(wizardPage);

    return <div className={styles.Create}>
      <PageHeader title={formatMessage({id: 'createDealer.pageHeader'})} minHeight={0} />

      <Card header={<DealerWizard active={this.props.wizardPage} />} noHeaderPadding={true} headerHeight={40}>
        <div style={{marginBottom: 10}}>
          {page}
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(Create);
