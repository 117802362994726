import { connect } from 'react-redux';
import ShowDealer from 'components/pages/Dealers/View/View';
import {getGroupUsers, getGroup, removeGroup} from 'api/group';
import { toggleModal } from 'redux/actions/modals';
import { emailUserPermissions } from 'api/user';

function mapStateToProps({ data, ui, routing, locale }, { params }) {
  return {
    dealerUsers: data.getIn(['groupUsers', parseInt(params.id)]),
    usersForGroup: id => data.getIn(['groupUsers', id]),
    dealer: data.get('groups').get(parseInt(params.id)),
    deleteModalVisible: ui.getIn(['modals', 'deleteDealer']),
    path: routing.locationBeforeTransitions.pathname,
    loadData: data.get('currentPage') !== data.get('previousPage').replace('/edit', ''),
    regions: locale.get('regions'),
    possibleLocales: locale.get('possibleLocales')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getGroup: id => dispatch(getGroup(id)),
    showDeleteModal: () => dispatch(toggleModal('deleteDealer', true)),
    hideDeleteModal: () => dispatch(toggleModal('deleteDealer', false)),
    deleteDealer: (...args) => dispatch(removeGroup(...args)),
    emailUserPermissions: (dealerID, userID) => dispatch(emailUserPermissions(dealerID, userID)),
    getGroupUsers: (...args) => dispatch(getGroupUsers(...args)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowDealer);
