import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import Icon from 'components/Icon/Icon';

import styles from './PaginatedTable.module.scss';

const PaginatedTable = createClass({
  displayName: 'PaginatedTable',
  mixins: [PureRenderMixin],
  propTypes: {
    children: PropTypes.element.isRequired,
    currentPage: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    spread: PropTypes.number,
    totalPages: PropTypes.number.isRequired,
  },

  getDefaultProps() {
    return {
      spread: 5,
    };
  },

  getPages(pages) {
    return pages.map(x => {
      if (x !== this.props.currentPage) {
        return <li key={x} onClick={this.props.setPage(x)}><a>{x}</a></li>;
      }
      return <li key={x}><a className={styles.activePage}>{x}</a></li>;
    }
    );
  },

  render() {
    let pages = [];
    let lowerBound = Math.max(1, this.props.currentPage - this.props.spread + 1);
    let upperBound = Math.min(this.props.totalPages, this.props.currentPage + this.props.spread - 1);

    for (let i = lowerBound; i <= upperBound; ++i) {
      pages.push(i);
    }

    return <div className={styles.paginatedTable}>
      {this.props.children}
      <nav className='text-center'>
        <ul className='pagination'>
          <li onClick={this.props.setPage(1)}><a href='#' className={styles.misclinks}><Icon name='leftDoubleArrow' /></a></li>
          <li onClick={this.props.setPage(this.props.currentPage - 1)}><a href='#' className={styles.misclinks}><Icon name='leftArrow' /></a></li>
          {this.getPages(pages)}
          <li onClick={this.props.setPage(this.props.currentPage + 1)}><a href='#' className={styles.misclinks}><Icon name='rightArrow' /></a></li>
          <li onClick={this.props.setPage(this.props.totalPages)}><a href='#' className={styles.misclinks}><Icon name='rightDoubleArrow' /></a></li>
        </ul>
      </nav>
    </div>;
  },
});

export default PaginatedTable;
