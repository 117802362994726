import React, {createClass, PropTypes} from 'react';
import {CallbackComponent, triggerAuthFlow} from 'redux-oidc';
import createTokenManagerConfig from 'helpers/tokenManager';
import {browserHistory} from 'react-router';

import Loader from '../../misc/Loader/Loader';

const LoginPage = createClass({
  propTypes: {
    token: PropTypes.object,
  },

  componentWillMount() {
    if (this.props.token && this.props.token.idToken && this.props.token.valid) {
      browserHistory.push('/');
    }
  },

  // This method gets called when the token validation fails
  onTokenValidationError(error) {
    console.error('Error getting token.');
    console.error(error);
    setTimeout(() => {
      triggerAuthFlow(createTokenManagerConfig());
    }, 500);
  },

  // Pass in custom content to render in the CallbackComponent
  customContent() {
    return <Loader />;
  },

  render() {
    return (<div>
      <CallbackComponent config={createTokenManagerConfig()} errorCallback={this.onTokenValidationError} redirectOnSuccess={true} redirectUri='/'>
        { this.customContent() }
      </CallbackComponent>
    </div>
    );
  },
});

export default LoginPage;
