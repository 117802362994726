import React, {createClass} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';

const LoginNeeded = createClass({
  displayName: 'LoginNeeded',
  mixins: [PureRenderMixin],
  login() {
    setTimeout(() => {
      browserHistory.push('/login');
    }, 700);
  },

  componentDidMount() {
    this.login();
  },

  render() {
    return <div>
    </div>;
  },
});

export default LoginNeeded;
