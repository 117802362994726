import React, {PropTypes, createClass} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {PromiseStoreMixin} from 'helpers/request';
import {browserHistory, Link} from 'react-router';

import debounce from 'lodash.debounce';

import {GroupModel} from 'models';

import {Input as ReadOnlyInput} from 'components/forms/ReadOnly';
import {Input, Select} from 'components/forms';

import Card from 'components/layout/Card/Card';
import Modal from 'components/layout/Modal/Modal';
import PageHeader from 'components/layout/PageHeader/PageHeader';

import EditStore from './EditStore/EditStore';

import ValidationGroup from 'helpers/validation';

import Icon from 'components/Icon/Icon';

import {Set, List} from 'immutable';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const Edit = createClass({
  displayName: 'Edit',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    addToast: PropTypes.func.isRequired,
    checkSoldTo: PropTypes.func.isRequired,
    checkShipTo: PropTypes.func.isRequired,
    dealer: PropTypes.instanceOf(GroupModel),
    getGroup: PropTypes.func.isRequired,
    deleteDealer: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    saveDealer: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    setDeleteStore: PropTypes.func.isRequired,
    storeDeleteVisible: PropTypes.bool.isRequired,
    clearDeleteStore: PropTypes.func.isRequired,
    storeToDelete: PropTypes.any,
    intl: intlShape.isRequired,
    regions: PropTypes.instanceOf(List).isRequired,
    possibleLocales: PropTypes.instanceOf(List).isRequired,
    getGroupUsers: PropTypes.func.isRequired,
    dealerUsers: PropTypes.instanceOf(List),
    usersForGroup: PropTypes.func.isRequired,
  },

  contextTypes: {
    canAccessAllDealers: PropTypes.bool.isRequired,
    canCreateDealerAdmin: PropTypes.bool.isRequired,
  },

  componentWillMount() {
    this.save = debounce(this._save, 500, {
      trailing: false,
      leading: true,
    });
    this.fetchData(this.props);
    this.group = new ValidationGroup(this.save);
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.params.id !== nextProps.params.id) {
      this.fetchData(nextProps);
    }
  },

  getInitialState() {
    return {
      selectedRegion: this.props.dealer && this.props.dealer.region ? this.props.dealer.region : 'US',
    };
  },

  fetchData(props) {
    this.promises.add(props.getGroup(props.params.id))
    .promise()
    .then(data => {
      if (!data || !data.data) {
        throw new Error(data.message);
      }
      }).catch(err => {
      console.error(err);
    });
  },


  setSelectedRegion(region) {
    this.setState({selectedRegion: region});
  },

  getDealer() {
    let dealer = this.props.dealer;

    let keys = new Set(Object.keys(this.refs)).intersect(GroupModel.keys);
    for (let key of keys) {
      dealer = dealer.set(key, this.refs[key].getValue());
    }

    dealer = dealer.update('children', children => children.map(group => {
      let ref = this.refs[group._id];
      if (ref) {
        return ref.getWrappedInstance().getStore();
      }
      return group;
    }));

    return dealer;
  },

  _save() {
    let dealer = this.getDealer();

    this.props.addToast({
      key: 'saving-dealer',
      message: 'Saving Dealer...',
      closeable: true,
      ttl: null,
    });

    this.promises.add(this.props.saveDealer(dealer))
      .promise()
      .then(dealerRes => {
        if (dealerRes.success) {
          this.props.addToast({
            key: 'saved-dealer',
            message: 'Saved dealer.',
            closeable: true,
            ttl: 4000,
            clears: 'saving-dealer',
          });
          browserHistory.push(this.props.path.replace('/edit', ''));
          return true;
        }
        return Promise.reject(dealerRes);
      })
      .catch(e => {
        console.error(e);
        this.props.addToast({
          key: 'saved-dealer',
          message: 'Failed to save dealer',
          closeable: true,
          ttl: null,
          clears: 'saving-dealer',
        });
      });
  },

  showDeleteStore(_id) {
    return () => {
      this.props.setDeleteStore(_id);
    };
  },

  deleteStoreConfirmation(id, val) {
    return () => {
      let typed = this.refs.deleteStoreConfirmation.getValue();
      if (typed === val) {
        this.promises.add(this.props.deleteDealer(id))
          .promise()
          .then(() => {
            this.fetchData(this.props);
          });
        this.props.clearDeleteStore();
      }
    };
  },

  checkSoldTo(val) {
    if (val.length <= 10) {
      return this.props.checkSoldTo(val, this.props.dealer.id)
        .promise()
        .then(data => {
          return {valid: data && !data.data, message: 'Sold To # already exists.'};
        });
    }
    return Promise.resolve({valid: false, message: '10 character limit.'});
  },

  checkShipTo(id) {
    return val => {
      if (val.length <= 20) {
        return this.props.checkShipTo(val, id)
          .promise()
          .then(data => {
            return {valid: data && !data.data, message: 'Ship To # already exists.'};
          });
      }
      return Promise.resolve({valid: false, message: '10 character limit'});
    };
  },

  cancel() {
    browserHistory.push(this.props.path.replace('/edit', ''));
  },

  render() {
    let {dealer} = this.props;
    dealer = dealer || new GroupModel();

    let deleteStore = this.props.storeToDelete ? dealer.branches.find(x => x._id === this.props.storeToDelete) : null;
    let deleteStoreTitle = '';
    if (deleteStore) {
      deleteStoreTitle = deleteStore.displayName.toUpperCase();
    }

    let {formatMessage} = this.props.intl;

    let storeValues = {
      deleteTerm: <strong>{deleteStoreTitle}</strong>,
    };

    let regionNames = this.props.regions.reduce((acc, x) => {
      acc[x.get('region')] = x.get('label');
      return acc;
    }, {});

    let localeOptions = this.props.possibleLocales
      .filter(x => x.get('regions').includes(this.state.selectedRegion))
      .reduce((acc, x) => {
        acc[x.get('locale')] = x.get('labels').get(x.get('locale'));
        return acc;
      }, {});

    if (!Object.keys(localeOptions).includes(dealer.locale) && Object.keys(localeOptions).length) {
      dealer = dealer.set('locale', Object.keys(localeOptions)[0]);
    }

    return <div>
      <PageHeader title={'Dealer: ' + (dealer ? dealer.displayName : '')} navTitle='Edit Dealer' right={<div>
        <button className='btn'>{/*<Icon name='info' />*/}</button>
        <button className='btn' onClick={this.group.validate}><Icon name='check' /></button>
        <button className='btn' onClick={this.cancel} title='Cancel'><Icon name='close' /></button>
      </div>} />

      <Modal visible={this.props.storeDeleteVisible} close={this.props.clearDeleteStore} id='store-delete-modal' size='md'
          header={<h3><FormattedMessage id='modals.deleteStore.header' /></h3>}
          footer={<div>
            <Input colSize='col-sm-8' ref='deleteStoreConfirmation' value='' autoFocus
              placeholder={formatMessage({id: 'modals.deleteStore.placeholder', values: storeValues})} />
            <button className='btn btn-primary' onClick={this.deleteStoreConfirmation(deleteStore ? deleteStore.id : null, deleteStoreTitle)}>
              <FormattedMessage id='modals.deleteStore.submit' />
            </button></div>}>
        <div>
          <p><FormattedMessage id='modals.deleteStore.warning' /></p>
          <p><FormattedMessage id='modals.deleteStore.instructions' values={storeValues} /></p>
        </div>
      </Modal>

      <Card minHeight={0}>
        <div className='row'>
          <div className='col-md-2'>
            <Input value={dealer.displayName} ref='displayName' label={formatMessage({id: 'dealer.fields.name'}) + ':'} autoFocus
              group={this.group} required={true} />
          </div>
          <div className='col-md-2'>
            {this.context.canAccessAllDealers ?
              <Input value={dealer.soldTo} ref='soldTo' label={formatMessage({id: 'dealer.fields.soldTo'}) + ':'}
                group={this.group} required={true} validate={this.checkSoldTo}
                validationMessage='Sold To # already exists.' limit={10} />
              : <ReadOnlyInput value={dealer.soldTo} label={formatMessage({id: 'dealer.fields.soldTo'}) + ':'} />}
          </div>
          <div className='col-md-2'>
            <Select value={dealer.region} ref='region' label={formatMessage({id: 'dealer.fields.region'}) + ':'} options={regionNames}
              onSelect={this.setSelectedRegion} />
          </div>
          <div className='col-md-2'>
            <Select value={dealer.locale} ref='locale' label={formatMessage({id: 'dealer.fields.locale'}) + ':'} options={localeOptions} />
          </div>
          <div className='col-md-2'>
            <div className='form-group'>
              <label><FormattedMessage id='dealer.fields.dealerAdmin' />:
                <div className='form-control-static'>
                  {dealer.primaryAdmins.map(x => <div key={x._id}><Link to={'/user/' + x.id}>{x.name}</Link></div>)}
                </div>
              </label>
            </div>
          </div>
        </div>
      </Card>

      {dealer.branches && dealer.branches.length ? <h2><FormattedMessage id='dealer.stores'/></h2> : null}
      {dealer.branches.map(branch => {
        return <EditStore store={branch} key={branch._id} ref={branch._id} promptDelete={this.showDeleteStore(branch._id)} group={this.group}
          checkShipTo={this.checkShipTo(branch.id)} getGroupUsers={this.props.getGroupUsers} users={this.props.usersForGroup(branch.id)}/>;
      })}
    </div>;
  },
});

export default injectIntl(Edit);
