import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';

import Icon from 'components/Icon/Icon';

import styles from './PageHeader.module.scss';

import {injectIntl, intlShape} from 'react-intl';

function updateTitle(title, siteTitle) {
  let displayTitle = siteTitle;
  if (title) {
    displayTitle = _combineTitles(title, displayTitle);
  }

  document.title = displayTitle;
}

function _combineTitles(...args) {
  return args.join(' | ');
}

const PageHeader = createClass({
  displayName: 'PageHeader',
  mixins: [PureRenderMixin],
  propTypes: {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    visible: PropTypes.bool,
    right: PropTypes.element,
    navTitle: PropTypes.string,
    backButton: PropTypes.bool,
    intl: intlShape.isRequired,
  },

  getDefaultProps() {
    return {
      backButton: true,
      visible: true,
      intl: intlShape.isRequired,
    };
  },

  componentWillMount() {
    let {formatMessage} = this.props.intl;
    updateTitle(this.props.navTitle || this.props.title || this.props.subTitle, formatMessage({id: 'site.title'}));
  },

  componentWillReceiveProps(nextProps) {
    let {formatMessage} = nextProps.intl;
    updateTitle(nextProps.navTitle || nextProps.title || nextProps.subTitle, formatMessage({id: 'site.title'}));
  },

  back() {
    browserHistory.goBack();
  },

  render() {
    let {title, subTitle, right} = this.props;

    if (!title || !this.props.visible) {
      return null;
    }
    return <h1 className={styles.pageHeader}>
      {this.props.backButton? <button className={'btn icon-button ' + styles.backButton} onClick={this.back}>
        <Icon name='backArrow' />
      </button> : null}
      {title}
      {subTitle ? <small> | {subTitle}</small> : null}
      {right ? <div className={styles.rightSection}>{right}</div> : null}
    </h1>;
  },
});

export default injectIntl(PageHeader);
