import Immutable from 'immutable';
import createRequest from 'helpers/request';
import {setDashboardInfo} from 'redux/actions/dashboard';

export function getDashboardInfo() {
  return dispatch => {
    let req = createRequest('/api/Dashboard', {authorize: true});

    req
      .promise()
      .then(dashRes => {
        dispatch(setDashboardInfo(Immutable.fromJS(dashRes.data)));
      });

    return req;
  };
}
