import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {List} from 'immutable';

import Card from 'components/layout/Card/Card';
import DatePicker from 'components/forms/DatePicker/DatePicker';
import styles from './GeneralForm.module.scss';
import moment from 'moment';
import Results from 'components/layout/ReportsGenerics/SmallResults/SmallResults';

import {ReportSettings} from 'models';

import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const GeneralForm = createClass({
  displayName: 'GeneralForm',
  mixins: [PureRenderMixin],
  propTypes: {
    results: PropTypes.instanceOf(List),
    run: PropTypes.func.isRequired,
    settings: PropTypes.instanceOf(ReportSettings).isRequired,
    update: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  },

  run() {
    if (this.props.run && this.props.update) {
      let settings = this.props.settings;
      this.props.run(settings);
    }
  },

  updateDate(key) {
    return value => {
      if (this.props.update) {
        let settings = this.props.settings;
        settings = settings.setIn(key, value);

        this.props.update(settings);
      }
    };
  },

  render() {
    let {settings} = this.props;
    let {formatMessage} = this.props.intl;
    return <div>
      <Card minHeight={0} style={{marginBottom: 20}}>
        <div className='row'>
          <DatePicker ref='startDate' label={formatMessage({id: 'reports.dashboardFilters.from'}) + ':'} colSize='col-sm-3'
            value={settings.from} onChange={this.updateDate(['from'])}
            maxDate={settings.to} />
          <DatePicker ref='endDate' label={formatMessage({id: 'reports.dashboardFilters.to'}) + ':'} colSize='col-sm-3'
            value={settings.to} minDate={settings.from}
            maxDate={moment()} onChange={this.updateDate(['to'])}  />
          <div className='col-sm-3'>
            <button className={styles.submitButton + ' btn btn-primary'} onClick={this.run}><FormattedMessage id='reports.dashboardFilters.run' /></button>
          </div>
        </div>
        <div className='row'>
          {this.props.results ? this.props.results.map((result, index) => {
            return <Results colSize={'col-sm-' + 12 / this.props.results.size} text={result.get('text')} key={index} number={result.get('number')} />;
          }).toArray() : null}
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(GeneralForm);
