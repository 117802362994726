import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {browserHistory} from 'react-router';

import {GroupModel} from 'models';

const ShipToExists = createClass({
  displayName: 'ShipToExists',
  mixins: [PureRenderMixin],
  propTypes: {
    dealer: PropTypes.instanceOf(GroupModel).isRequired,
    duplicate: PropTypes.instanceOf(GroupModel).isRequired,
    back: PropTypes.func.isRequired,
    tryAgain: PropTypes.func.isRequired,
  },

  back() {
    return this.props.back(this.props.dealer);
  },

  gotoProfile() {
    browserHistory.push('/dealer/' + this.props.duplicate.id);
  },

  render() {
    let store = this.props.duplicate;
    return <div>
      <h2>Store Ship To, {store.shipTo} already exists</h2>
      <div className='row'>
        <div className='col-md-4'>
          <div className='form-group'>
            <label>Name:</label>
            <div className='form-control-static'>{store.displayName}</div>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='form-group'>
            <label>Sold To #:</label>
            <div className='form-control-static'>{store.shipTo}</div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-xs-12'>
          <button className='btn btn-default pull-left' onClick={this.back}>
            Back
          </button>

          <button className='btn btn-primary pull-right' onClick={this.gotoProfile}>
            Go To Dealer Profile
          </button>

          <button className='btn btn-default pull-right' onClick={this.props.tryAgain}>Try Again</button>
        </div>
      </div>
    </div>;
  },
});

export default ShipToExists;
