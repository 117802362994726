import {ADD_TOAST, CLEAR_TOAST} from './index';

export function addToast(toast) {
  return {
    type: ADD_TOAST,
    value: toast,
  };
}

export function clearToast(key) {
  return {
    type: CLEAR_TOAST,
    value: key,
  };
}
