import React, {createClass, PropTypes} from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import Dropzone from 'react-dropzone';
import styles from './FileUpload.module.scss';

const FileUpload = createClass({
  displayName: 'FileUpload',
  mixins: [PureRenderMixin],
  propTypes: {
    label: PropTypes.string.isRequired,
    previewLabel: PropTypes.string.isRequired,
    innerText: PropTypes.string.isRequired,
    dropAreaCol: PropTypes.string.isRequired,
    previewAreaCol: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      files: [],
    };
  },

  onDrop(files) {
    this.setState({
      files: files,
    });
  },

  getFiles() {
    return this.state.files;
  },

  render() {
    return <div>
        <p><label>{this.props.label}</label></p>
        <div className='row'>
          <div className={this.props.dropAreaCol}>
            <Dropzone
                  accept='image/*'
                  activeClassName={styles.activeStyle}
                  className={styles.fileUpload}
                  multiple={false}
                  onDrop={this.onDrop}
                  ref='dropzone'
                  rejectClassName={styles.rejectStyle}>
              <div className={styles.fileUploadText}>{this.props.innerText}</div>
            </Dropzone>
          </div>
          <div className={this.props.previewAreaCol}>
            {this.state.files.length > 0 ?
              <div>
                <label>{this.props.previewLabel}</label>
                <div>{this.state.files.map((file, index) => <img src={file.preview}  key={index} />)}</div>
              </div>
            : null}
          </div>
        </div>
    </div>;
  },
});

export default FileUpload;
